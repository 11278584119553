const locale = {
    SEARCH: 'Search...',
    CONTACTS: 'Contacts',
    CLIENTS: 'Clients',
    ACCESS: 'Movveo access',
    NEW_CONTACT: 'New contact',
    EDIT_CONTACT: "Edit contact",
    NO_CONTACTS: 'No datas',
    NEW_CLIENT: 'New client',
    EDIT_CLIENT: "Edit client",
    ENTITY: 'Entity/Service',
    NAME: 'Name',
    LASTNAME: 'Lastname',
    PHONE: 'Phone',
    MOBILE: 'Mobile',
    FAX: 'Fax',
    EMAIL: 'Email',
    COMPANY: 'Company',
    JOBTITLE: 'Job title',
    JOB_TITLE: 'Job title',
    BIRTHDAY: 'Birthday',
    ADDRESS: 'Address',
    ADDRESS2: 'Address complement',
    ZIP: 'Zip code',
    CITY: 'City',
    COUNTRY: 'Country',
    ADD: 'Add',
    SAVE: 'Save',
    M: 'Mr',
    MME: "Ms",
    MLLE: "Miss",
    IS_USER: "Platform user",
    EMAIL_EXIST: "This user already exist! Please select a different email."
}

export default locale;
