import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'commandesApp', en);
i18next.addResourceBundle('fr', 'commandesApp', fr);


export const CommandesConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: '/commandeAdd/:siteId/:id',
            component: React.lazy(() => import('./CommandeAdd'))
        },
        {
            path: '/commande/:id',
            component: React.lazy(() => import('./CommandeDialogContent'))
        },

        {
            path: '/commandes/:id',
            component: React.lazy(() => import('./CommandesApp'))
        },
        {
            path: '/offres/:id',
            component: React.lazy(() => import('./CommandesApp'))
        },
        {
            path: '/commandes',
            component: () => <Redirect to="/commandes/all" />
        },
        {
            path: '/offres',
            component: () => <Redirect to="/offres/all" />
        }
    ]
};
