import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
////console.log(fr)
i18next.addResourceBundle('en', 'sitesApp', en);
i18next.addResourceBundle('fr', 'sitesApp', fr);


export const SitesConfig = {
    settings: {
        layout: {}
    },
    routes: [

        {
            path: '/site/new',
            component: React.lazy(() => import('./siteDialogContent'))

        },
        {
            path: '/site/:id/:type?',
            component: React.lazy(() => import('./site/SiteApp'))
        },
        {
            path: '/sites/:id/:type?',
            component: React.lazy(() => import('./sitesApp'))
        },
        {
            path: '/sites',
            component: () => <Redirect to="/sites/all" />
        }
    ]
};
