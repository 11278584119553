const locale = {
    SEARCH: 'Recherche...',
    CONTACTS: 'Contacts',
    CLIENTS: 'Clients',
    ACCESS: 'Accès plateforme',
    NO_CONTACTS: 'Pas de données',
    NEW_CONTACT: 'Nouveau contact',
    EDIT_CONTACT: "Modification d'un contact",
    NEW_CLIENT: 'Nouveau client',
    EDIT_CLIENT: "Modification d'un client",
    NAME: 'Nom',
    LASTNAME: 'Prénom',
    ENTITY: 'Entité/Service',
    PHONE: 'Téléphone',
    MOBILE: 'Mobile',
    FAX: 'Télécopie',
    EMAIL: 'Email',
    COMPANY: 'Entreprise',
    JOBTITLE: 'Fonction',
    JOB_TITLE: 'Fonction',
    BIRTHDAY: 'Date de naissance',
    ADDRESS: 'Adresse',
    ADDRESS2: "Complément d'adresse",
    ZIP: 'Code postal',
    CITY: 'Ville',
    COUNTRY: 'Pays',
    ADD: 'Ajouter',
    SAVE: 'Sauvegarder',
    M: 'Mr',
    MME: "Mme",
    MLLE: "Mlle",
    ENTITE: "Entité",
    IS_USER: "Utilisateur de la plateforme",
    EMAIL_EXIST: "Cet utilisateur est déjà enregistré! Veuillez saisir une adresse mail différente."
}

export default locale;
