import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from '@lodash';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { FormControl, RadioGroup, FormLabel, FormHelperText, FormControlLabel, Radio, Divider, TextareaAutosize } from '@material-ui/core'
import HTMLReactParser from 'html-react-parser';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import { useForm } from '@fuse/hooks';
import Editor from './editor_'

function formatArray(arr) {
    var outStr = "";
    if (arr.length === 1) {
        outStr = arr[0];
    } else if (arr.length === 2) {
        //joins all with "and" but no commas
        //example: "bob and sam"
        outStr = arr.join(' et ');
    } else if (arr.length > 2) {
        //joins all with commas, but last one gets ", and" (oxford comma!)
        //example: "bob, joe, and sam"
        outStr = arr.slice(0, -1).join(', ') + ' et ' + arr.slice(-1);
    }
    return outStr;
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
function a11BisyProps(index) {
    return {
        id: `vertical-tabbis-${index}`,
        'aria-controls': `vertical-tabbispanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
        //width:'90%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));
const map_appareil = (input, field) => {

    ////console.log(input, field)

    var t = []
    if (field == 'reglementation')
        _.map(input, c => {
            t = _.uniqBy(_.merge(t, c[field]))
        })
    else
        _.map(input, c => {
            t = _.sortBy(_.uniqBy(_.concat(t, c[field])))
        })
    ////console.log(t)
    return formatArray(t)


}
export default function VerticalTabs(props) {
    ////console.log(props)
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const ref = {}


    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };
    const { form, handleChange, setForm } = useForm({ ...props.form.audit });
    const ref_evaluation_generale_maintenance = useRef('evaluation_generale_maintenance')
    useEffect(() => {
        //console.log(ref_evaluation_generale_maintenance)
        if (!_.isEqual(props.form.audit, form))
            props.handleChange(props.tab, form)
    }, [form])


    return (

        <div className={classes.root}>
            <Tabs
                orientation="vertical"

                value={value}
                onChange={handleChangeTab}
                aria-label="Vertical tabs example"
                className={classes.tabs}

            >
                <Tab label="Évaluation générale" {...a11yProps(0)} />
                <Tab label="Couverture de la maintenance" {...a11yProps(1)} />
                <Tab label="Fiabilité" {...a11yProps(2)} />
                <Tab label="Sécurité" {...a11yProps(3)} />

                <Tab label="Aspect Général" {...a11yProps(4)} />
                <Tab label="Qualité de déplacement" {...a11yProps(5)} />
                <Tab label="Carnet d'entretien" {...a11yProps(6)} />
                <Tab label="Amiante" {...a11yProps(7)} />
                <Tab label="Développement Durable" {...a11yProps(8)} />

            </Tabs>

            <TabPanel value={value} index={0}>
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Qualité de la Maintenance</FormLabel>
                        <RadioGroup row={true} aria-label="" name="evaluation_generale.maintenance.value" defaultValue={form.evaluation_generale.maintenance.value} onChange={handleChange}>
                            {_.map(['satisfaisante', 'moyennement satisfaisante', 'insatisfaisante'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.evaluation_generale.maintenance.commentaires} id={'eg_maintenance'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, 'evaluation_generale.maintenance.commentaires', value))}></Editor>


                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Qualité du nettoyage</FormLabel>
                        <RadioGroup row={true} aria-label="" name="evaluation_generale.nettoyage.value" defaultValue={form.evaluation_generale.nettoyage.value} onChange={handleChange}>
                            {_.map(['satisfaisant', 'moyennement satisfaisant', 'insatisfaisant'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.evaluation_generale.nettoyage.commentaires} id={'eg_nettoyage'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, 'evaluation_generale.nettoyage.commentaires', value))}></Editor>


                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Qualité du graissage</FormLabel>
                        <RadioGroup row={true} aria-label="" name="evaluation_generale.graissage.value" defaultValue={form.evaluation_generale.graissage.value} onChange={handleChange}>
                            {_.map(['satisfaisant', 'moyennement satisfaisant', 'insatisfaisant'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.evaluation_generale.graissage.commentaires} id={'eg_graissage'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, 'evaluation_generale.graissage.commentaires', value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Qualité des réglages</FormLabel>
                        <RadioGroup row={true} aria-label="" name="evaluation_generale.reglages.value" defaultValue={form.evaluation_generale.reglages.value} onChange={handleChange}>
                            {_.map(['satisfaisants', 'moyennement satisfaisants', 'insatisfaisants'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.evaluation_generale.reglages.commentaires} id={'eg_reglages'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, 'evaluation_generale.reglages.commentaires', value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Qualité de la glisse</FormLabel>
                        <RadioGroup row={true} aria-label="" name="evaluation_generale.glisse.value" defaultValue={form.evaluation_generale.glisse.value} onChange={handleChange}>
                            {_.map(['satisfaisante', 'moyennement satisfaisante', 'insatisfaisante'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.evaluation_generale.glisse.commentaires} id={'eg_glisse'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, 'evaluation_generale.glisse.commentaires', value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />

            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Qualité de la Maintenance</FormLabel>
                        <RadioGroup row={true} aria-label="" name="maintenance.value" defaultValue={form.maintenance.value} onChange={handleChange}>
                            {_.map(['Les visites de maintenance sont correctement réalisées dans un intervalle de 6 semaines Maximum entre deux visites', 'Les visites de maintenance ne sont pas correctement réalisées. L’intervalle de 6 semaines Maximum entre deux visites n’est pas respecté'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.maintenance.commentaires} id={'maintenance'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, 'maintenance.commentaires', value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Fiabilité des appareils</FormLabel>
                        <RadioGroup row={true} aria-label="" name="fiabilite.value" defaultValue={form.fiabilite.value} onChange={handleChange}>
                            {_.map(["n'est plus assurée", 'est assurée'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.fiabilite.commentaires} id={'fiabilite'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, 'fiabilite.commentaires', value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
            </TabPanel>
            <TabPanel value={value} index={3}>
                {_.map([{ name: '2008-1325', label: 'DA' }, { name: '2004-964', label: 'DB' }], (l, i) => {
                    return <div key={i}><div className='flex flex-col min-w-500'>

                        <FormControl component="fieldset">
                            <FormLabel variant="h5">{`Décret ${l.name}`}</FormLabel>
                            <RadioGroup row={true} aria-label="" name={`securite.${l.label}.value`} defaultValue={form.securite[l.label].value} onChange={handleChange}>
                                {_.map(["conforme", 'non-conforme'], (m, i) => {
                                    return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                                })}
                            </RadioGroup>
                            <FormHelperText></FormHelperText>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel variant="h5">Commentaires</FormLabel>
                            <Editor values={form.securite[l.label].commentaires} id={'securite'}
                                handleChange={(value) => setForm(_.setIn({ ...form }, `securite.${l.label}.commentaires`, value))}></Editor>

                            <FormHelperText></FormHelperText>
                        </FormControl>
                    </div>
                        <Divider className='m-12' /></div>
                })}
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">{`Conformité à la règlementation ${map_appareil(props.form.installation.appareils, 'reglementation')}`}</FormLabel>
                        <RadioGroup row={true} aria-label="" name="securite.reglementation.value" defaultValue={form.securite.reglementation.value} onChange={handleChange}>
                            {_.map(["conforme", 'non-conforme'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>

                        <Editor values={form.securite.reglementation.commentaires} id={'reglementation'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, `securite.reglementation.commentaires`, value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
            </TabPanel>

            <TabPanel value={value} index={4}>
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Aspect général</FormLabel>
                        <RadioGroup row={true} aria-label="" name="aspect_general.value" defaultValue={form.aspect_general.value} onChange={handleChange}>
                            {_.map(["bon", 'moyen', 'mediocre'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.aspect_general.commentaires} id={'aspect_general'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, `aspect_general.commentaires`, value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Qualité du déplacement</FormLabel>
                        <RadioGroup row={true} aria-label="" name="qualite_deplacement.value" defaultValue={form.qualite_deplacement.value} onChange={handleChange}>
                            {_.map(["bon", 'moyen', 'médiocre'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.qualite_deplacement.commentaires} id={'qualite_deplacement'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, `qualite_deplacement.commentaires`, value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Carnet d'entretien</FormLabel>
                        <RadioGroup row={true} aria-label="" name="carnet_entretien.value" defaultValue={form.carnet_entretien.value} onChange={handleChange}>
                            {_.map(["est à jour et correctement rempli", "n'est pas correctement rempli", 'est absent'], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.carnet_entretien.commentaires} id={'entretien'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, `carnet_entretien.commentaires`, value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Amiante</FormLabel>
                        <RadioGroup row={true} aria-label="" name="amiante.value" defaultValue={form.amiante.value} onChange={handleChange}>
                            {_.map(["Sans objet", "Concerné"], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.amiante.commentaires} id={'amiante'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, `amiante.commentaires`, value))}></Editor>


                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>
                <Divider className='m-12' />
            </TabPanel>
            <TabPanel value={value} index={8}>
                <div className='flex flex-col min-w-500'>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Développement durable</FormLabel>
                        <RadioGroup row={true} aria-label="" name="developpement_durable.value" defaultValue={form.developpement_durable.value} onChange={handleChange}>
                            {_.map(["Sans objet", "Concerné"], (m, i) => {
                                return <FormControlLabel key={i} value={m} control={<Radio />} label={m} />

                            })}
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel variant="h5">Commentaires</FormLabel>
                        <Editor values={form.developpement_durable.commentaires} id={'developpement_durable'}
                            handleChange={(value) => setForm(_.setIn({ ...form }, `developpement_durable.commentaires`, value))}></Editor>

                        <FormHelperText></FormHelperText>
                    </FormControl>
                </div>

            </TabPanel>


        </div>

    );
}