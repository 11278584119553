import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import _ from '@lodash'
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "Welcome to React": "Welcome to React and react-i18next"
        }
    },
    fr: {
        translation: {
            "Welcome to React": "Welcome to React and react-i18next"
        }
    },
    ar: {
        translation: {
            "Welcome to React": "Welcome to React and react-i18next"
        }
    },
    tr: {
        translation: {
            "Welcome to React": "Welcome to React and react-i18next"
        }
    }
};
var lng = navigator.language || navigator.userLanguage;
if (lng)
    lng = lng.split('-')[0]

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lng && _.includes(_.keys(resources, lng)) ? lng : 'fr',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
