import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions';
import * as Actions from 'app/store/actions';
import _ from '@lodash'

import history from '@history'
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import moment from 'moment'
export const GET_COMMANDES = '[COMMANDES APP] GET COMMANDES';
export const GET_OFFRES = '[COMMANDES APP] GET OFFRES';
export const GET_FORMULAIRES = '[COMMANDES APP] GET FORMULAIRES';
export const OPEN_NEW_COMMANDE_DIALOG = '[COMMANDES APP] OPEN NEW COMMANDE DIALOG';
export const CLOSE_NEW_COMMANDE_DIALOG = '[COMMANDES APP] CLOSE NEW COMMANDE DIALOG';
export const CLOSE_EDIT_COMMANDE_DIALOG = '[COMMANDES APP] CLOSE EDIT COMMANDE DIALOG';
export const OPEN_EDIT_COMMANDE_DIALOG = '[COMMANDES APP] OPEN EDIT COMMANDE DIALOG';

export function getCommandes(routeParams) {
   ////console.log('getCommandes', routeParams)
    return (dispatch, getState) => {

        /// PURGE ERREUR COMMANDE VS DROPBOX ID
        /*   firebaseService.firestore.collection('documents').where('type', '==', 'commande').get().then(r => {
              if (r.size > 0) {
  
                  r.forEach(doc => {
                      var data = doc.data()
                      if (data.createdBy == 'D3TJBSHQ0FefoMowkfs6h36qWsl2') {
                         ////console.log(data)
                      }
                      var total_invoices = 0
                      if ((data.id != data.docInfos.id ) ) {
                         ////console.log(data.id, data.docInfos.id)
                       firebaseService.firestore.collection('documents').doc(data.docInfos.id).set({...data, id:data.docInfos.id})
        
                                //if (data.id)
                                    firebaseService.firestore.collection('documents').doc(doc.id).delete() 
                      }
                  })
              }
          }).catch(err => {
             ////console.log(err)
          })
          return  */
        dispatch(Actions.showMessage({ message: 'Chargement des commandes...' }))
        const { navigation } = getState().fuse;

        firebaseService.firestore.collection('documents').where('type', '==', 'commande').onSnapshot(async (r) => {
            const invoices = _.values(getState().custom.factures.facturation.entities)
            var commandes = {}
            if (r.size > 0) {

                var c = await r.forEach( doc => {
                    var data = doc.data()
                    if (data.createdBy == 'D3TJBSHQ0FefoMowkfs6h36qWsl2') {
                       ////console.log(data)
                    }

                    if (!data.siteId) {

                         firebaseService.firestore.collection('sites').doc(data.data.site.id).get().then(m => {
                            if (m.exists) {
                                data.siteId = m.data().dropbox_id.id
                            }

                        }).catch(e => {
                           ////console.log(e)
                            return
                        })

                    }
                    //////console.log(data.siteId)
                    /* if (!data.siteId )
                    data.siteId = data.data.site.dropbox_id.id */
                    var total_invoices = 0
                    if (data.id != data.docInfos.id && data.docInfos.id == 'id:CIU_Vp_hIFAAAAAAAARCeg') {
                       ////console.log(data.id, data.docInfos.id)
                        /*    firebaseService.firestore.collection('documents').doc(data.docInfos.id).set({...data, id:data.docInfos.id})
      
                              if (data.id)
                                  firebaseService.firestore.collection('documents').doc(data.id).delete() */
                    }

                    data.data.commande.prix_total = Number(data.data.commande.prix_total)
                    if (data.data.commande.duree_contrat) {

                        ////console.log(data.data.commande.type_mission,data.data.commande.duree_contrat.floatValue, data.data.commande.prix_total, data.data.commande.duree_contrat.floatValue*data.data.commande.prix_total)


                        data.data.commande.prix_total = data.data.commande.duree_contrat.floatValue * data.data.commande.prix_total
                    }


                    /*  data.data.site = { ...data.data.site, name: [data.data.site.address2, data.data.site.address, data.data.site.zip, data.data.site.city].join(' ') } */

                    data.data.commande = { ...data.data.commande, date_short_formatted: moment(data.data.commande.date, 'Do MMMM YYYY').format() }
                    /*   total_invoices = _.sum(_.compact(_.map(data.invoices, c => {
                          if (!c.isFrais && c.data.details )
                             // return Number(c.data.recap.total_mois_HT)
                          return _.sum(_.compact(_.map(c.data.details, v => {
                              return Number(v.montant_mois)
                          })))
 
                     }))) 
 
                     //total_invoices = data.montant_facturé
 
                     data.data.commande.prix_total = data.data.commande.prix_total.toString().replace(/[^\d.-]/g, '')
                     data.reste_a_facturer = Number(data.data.commande.prix_total) - total_invoices */
                    const invoicesCommande = _.filter(invoices, d => {
                        return d.data.commandeId == data.docInfos.id
                    })
                  
                    var facturé = 0
                    var frais = 0
                    var p = []
                    var e = data
                    var montant_frais_avancé = 0
                    var montant_frais_facturé = 0
                    var montant_frais = 0
                    var factureRef = ''
                    facturé = _.sum(_.compact(_.map(invoicesCommande, v => {
                        if (doc.id == 'id:CIU_Vp_hIFAAAAAAAAUVjA') {
                      
                            /*       ////console.log(doc.id, doc.data().name)
                              ////console.log(invoicesCommande) */
                        }

                        montant_frais_avancé = montant_frais_avancé + (v.data.montant_frais? v.data.montant_frais:0)
                        /*  var length = _.filter(invoicesCommande , k => {
                             return k.data.id == v.data.id
                         })
 
                         if (length.length == 1) {
                             if (!v.isFrais && v.data.id)
                                 return v.data.recap.total_mois_HT
                         } else if (length.length > 1) {
 
                             length = _.orderBy(length, ['docInfos.client_modified'], ['desc'])
 
                             v = length[0]
                             if (!v.isFrais && v.data.id && !_.includes(p, v.data.id)) {
                                 p.push(v.data.id)
                                 return v.data.recap.total_mois_HT
                             } else {
                              
 
                             }
 
 
                         } */
                        if (v.isFrais) {
                            if (doc.id == 'id:CIU_Vp_hIFAAAAAAAAUVjA') {
                                //console.log(v)
                                
                               
                            }
                            montant_frais_facturé = montant_frais_facturé + _.sum(_.compact(_.map(v.data.details, c => {
                                if (v.isAvoir) {
                                    if (doc.id == 'id:CIU_Vp_hIFAAAAAAAAUVjA') {
                                        ////console.log(c)
                                    }
                                    return Math.abs(Number(c.montant_mois)) * -1
                                } else
                                    return Number(c.montant_mois)

                            })))
                            return
                        }
                          
                        if (v.data.details) {
                          
                            return _.sum(_.compact(_.map(v.data.details, l => {
                               
                                if (v.isAvoir) {
                                    
                                    return Math.abs(l.montant_mois) * -1
                                } else return l.montant_mois
                            })))
                        }else if (v.isAvoir) {

                            return Math.abs(v.data.montant_mois) * -1
                        }
                        else return v.data.montant_mois

                    })))

                    if (doc.id == 'id:CIU_Vp_hIFAAAAAAAAQWMw') {
                        ////console.log(facturé)
                    }

                    var montant_facturé = facturé

                    var reste_a_facturer = data.data.commande.prix_total - facturé
                    reste_a_facturer = reste_a_facturer - (data.data.oldFacturé ? data.data.oldFacturé : 0)
                    commandes[data.docInfos.id] = { ...data, id: data.docInfos.id, invoices: invoicesCommande, reste_a_facturer, montant_facturé, montant_frais_avancé, montant_frais_facturé}
////console.log(data)
                    if (data.docInfos.id == 'id:CIU_Vp_hIFAAAAAAAAQWMw') {
                        ////console.log(commandes[doc.id])
                    }
                   
                })

                var commandesItem = _.find(navigation, { id: 'commandes' })
                commandesItem.badge.title = _.values(commandes).length
                dispatch(Actions.updateNavigationItem('commandes', commandesItem));

                dispatch(Actions.hideMessage())
                dispatch({
                    type: GET_COMMANDES,
                    payload: _.values(commandes),
                    routeParams
                })
               ////console.log(commandes)
             /*    Promise.all([dispatch({
                    type: GET_COMMANDES,
                    payload: _.values(commandes),
                    routeParams
                })]).then(r => {
                    dispatch(Actions.getOffres({ id: 'all' }))
                    //return dispatch(Actions.getAnalytics({ id: 'all' }))
                }) */
            }
            ////////////console.log(commandes)


        })


    }


}

export function getOffres(routeParams) {

    return (dispatch, getState) => {
        dispatch(Actions.showMessage({ message: 'Chargement des offres...' }))
        const { navigation } = getState().fuse;


        firebaseService.firestore.collection('documents').where('type', '==', 'offre').onSnapshot((r) => {
            ////////////console.log(r.size)
            var offres = {}
            const commandes = _.values(getState().custom.commandes.entities)
            if (r.size > 0) {

                r.forEach(doc => {
                    var data = doc.data()

                 
                    /*  if (!data.siteId)
                         data.siteId = data.data.site.dropbox_id.id */
                    ////////////console.log(data.data.commande)

                    /*  data.data.site = { ...data.data.site, name: [data.data.site.address2, data.data.site.address, data.data.site.zip, data.data.site.city].join(' ') } */

                    data.data.commande = { ...data.data.commande, date_short_formatted: moment(data.data.commande.date, 'Do MMMM YYYY').format() }

                    data.data.commande.prix_total = data.data.commande.prix_total.toString().replace(/[^\d.-]/g, '')
                    ////////////console.log(data.data.commande.date_short_formatted, data.data.commande.date)

                    if (data.data.name == '2021-MCM-93-89-273-A') {
                        ////console.log(doc.id,data)

                       
                    }

                    offres[doc.id] = { ...data, id: doc.id, status: _.find(commandes, { offreId: doc.id }) ? 'ordered' : data.status == 'ordered' ? 'send' : data.status }
                    /*  if (!doc.data().type)
                         firebaseService.firestore.collection('documents').doc(doc.id).set({ ...doc.data(), type: 'offre' }) */
                })
            }
            ////////////console.log(commandes)
            var commandesItem = _.find(navigation, { id: 'offres' })
            commandesItem.badge.title = _.values(offres).length
            dispatch(Actions.updateNavigationItem('offres', commandesItem));

            dispatch(Actions.hideMessage())
            return dispatch({
                type: GET_OFFRES,
                payload: _.values(offres),
                routeParams
            })

        })


    }


}
export function getFormulaires(type) {
    ////////////console.log(type)
    return (dispatch, getState) => {
        var fileList = []
        axios({
            method: 'post',
            url: `${URL_API}/dropbox/files/list_formulaires`,
            data: { type: type }
        }).then(response => {
//console.log(response)
            if (response.data.body && response.data.body.entries)
                _.map(response.data.body.entries, file => {
                    if (file['.tag'] === 'file')
                        fileList.push({ label: file.name, value: file.name, id: file.id })
                })
            //console.log('formualies', fileList)

            return dispatch({
                type: GET_FORMULAIRES,
                payload: fileList,

            })
            //setFormulairesList(fileList)
        }).catch(err => {
            return dispatch({
                type: GET_FORMULAIRES,
                payload: fileList,

            })
        })


    }

}
export function openNewCommandeDialog(type) {
    return {
        type: OPEN_NEW_COMMANDE_DIALOG,
        payload: type
    }
}

export function closeNewCommandeDialog() {
    return {
        type: CLOSE_NEW_COMMANDE_DIALOG
    }
}
export function closeEditCommandeDialog() {
    return {
        type: CLOSE_EDIT_COMMANDE_DIALOG
    }
}

export function openEditCommandeDialog(data, type) {
    //////////////console.log(data, type)
    return {
        type: OPEN_EDIT_COMMANDE_DIALOG,
        data: data, db: type
    }
}

export function addCommande(form, db, m, id) {
   ////console.log(form)
    if (!form.name) {
        form.name = form.commande.ref ? form.commande.ref : ''
    }
    form.commande.prix_total = Number(form.commande.prix_total.toFixed(2))
    if (!form.site.select)
        form.site.select = ''

    var response = null
    return async (dispatch, getState) => {
        dispatch(Actions.showMessage({ message: 'Enregistrement en cours', variant: 'warning', autoHideDuration: 100000 }))

        _.map(form, (f, i) => {
            _.map(f, (l, j) => {
                if (j == 'company') {
                    form[i][j] = f.id
                }
                if (j == 'address_facturation' || j == 'contact') {
                    form[i] = _.omit(form[i], j)
                }
            })
        })
        const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)
        /// ////console.log(_.values(getState().custom.sites.sites.entities))
        var siteId = _.find(_.values(getState().custom.sites.sites.entities), m => {
            return m.dropbox_id.name == form.site.name
        })
       ////console.log(siteId)
        form.site.name = siteId ? siteId.dropbox_id.name : form.site.name
        const request = axios({
            method: 'post',
            url: `${URL_API}/carbone/createDocument`,
            headers: {
                'Accept-Encoding': 'UTF-8',
                Authorization: `Bearer ${token}`
            },
            data: { formulaire: form.formRef.id, fileName: form.commande && form.commande.ref ? form.commande.ref : moment().format('YYYYMMDDHHmmss'), data: form, site: form.site.name, folder: '01-Offres MOVVEO' }
            //data: { ch: 'toto' }
        }).then(response => {
            ////////console.log(response)

            form = _.omit(form, 'dropbox_id')
            if (response.data && response.data.body && response.data.body.id) {
                dispatch(Actions.getPreview(response.data.body.id, 'editor'))
            } else
                return dispatch(Actions.showMessage({ message: 'Erreur lors de la création du document, Veuillez fermer le document ouvert dans votre navigateur afin de mettre le document à jour', variant: 'error' }))
            dispatch(Actions.hideMessage())
            dispatch(Actions.showMessage({ message: 'Document enregistré dans votre Dropbox....' }))

            dispatch(Actions.closeDialog())
            history.push('/offres/' + form.site.id)

            try {
                if (response.data && response.data.body && response.data.body.id)
                    if (m == 'update') {
                        firebaseService.firestore.collection('documents').doc(id ? id : response.data.body.id).set({
                            docInfos: { ...response.data.body }, data: { ...form }, status: 'saved', createdBy: firebaseService.auth.currentUser.uid, createdTime: moment().format('YYYY-MM-DD HH:mm:ss'), type: 'offre',
                        }).then(r => {


                        }).catch(err => {
                           ////console.log(err)
                        })
                    } else if (m == 'new') {
                       ////console.log(form)
                        firebaseService.firestore.collection('documents').doc(response.data.body.id).set({
                            docInfos: { ...response.data.body }, data: { ...form }, status: 'saved', createdBy: firebaseService.auth.currentUser.uid, createdTime: moment().format('YYYY-MM-DD HH:mm:ss'), type: 'offre',
                        }).then(r => {


                            firebaseService.firestore.collection('documents').doc(id).set({ status: 'archived', archive: { archivedBy: firebaseService.auth.currentUser.uid, archivedDate: moment().format('YYYY-MM-DD HH:mm:ss') } }, { merge: true }).catch(err => {
                               ////console.log(err)
                            })

                        }).catch(err => {
                           ////console.log(err)
                        })
                    }
            } catch (err) {
               ////console.log(err)

            }
        }).catch(err => {
           ////console.log(err)
            dispatch(Actions.showMessage({ message: 'Erreur lors de la création du document, Veuillez fermer le document ouvert dans votre navigateur afin de mettre le document à jour', variant: 'error' }))
            // localStorage.setItem()
        })

        /*    newContact.id = FuseUtils.generateGUID()
   
           const { routeParams } = getState().contactsApp.contacts;
           const { user } = getState().auth;
   
           var table = null
           switch (db) {
               case 'contact':
                   table = 'users'
                   break;
               case 'client':
                   table = 'clients'
                   break;
               default:
                   table = 'users'
                   break;
   
           }
           if (db === 'contact' && newContact.isUser) {
               var u = {
                   uid: newContact.id,
                   from: 'firebase',
                   role: ["user"],
                   data: {
                       displayName: newContact.lastName + ' ' + newContact.name,
                       email: newContact.email,
                       settings: user.data.settings
                   }
               }
               firebaseService.db.ref('/users/' + newContact.id).set(u)
           }
           if (table === 'users') {
               firebaseService.firestore.collection(table).doc(newContact.id).collection('favorites').doc('clients').set({
                   starred: {},
                   groups: {},
                   frequentContacts: {}
               })
               firebaseService.firestore.collection(table).doc(newContact.id).collection('favorites').doc('users').set({
                   starred: {},
                   groups: {},
                   frequentContacts: {}
               })
           }
           firebaseService.firestore.collection(table).doc(newContact.id).set(newContact).then(() => {
   
               Promise.all([
                   dispatch({
                       type: ADD_COMMANDE
                   })
               ]).then(() => dispatch(getContacts(routeParams)))
           }).catch(err => {
   
   
           })
    */

    };
}