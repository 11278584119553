import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: {},

    searchText: '',
    selectedFacturationsIds: [],
    routeParams: {},
    facturationDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,

    },

};

const facturationsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_FACTURES:
            {
                return {
                    ...state,
                    entities: action.payload,
                    routeParams: action.routeParams
                };
            }

        case Actions.UPDATE_FACTURES:
            {
                return {
                    ...state,

                };
            }



        default:
            {
                return state;
            }
    }
};

export default facturationsReducer;
