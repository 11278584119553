/**
 * Authorization Roles
 */
const authRoles = {
    superAdmin: ['superAdmin'],
    admin: ['superAdmin', 'admin'],
    staff: ['superAdmin', 'admin', 'staff'],
    user: ['superAdmin', 'admin', 'staff', 'user'],
    onlyGuest: []
};

export default authRoles;
