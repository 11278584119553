import React, { useState, useEffect, useRef , useMemo} from 'react';
import PropTypes from 'prop-types';

import * as ActionsAll from 'app/store/actions';
import _ from '@lodash'
import moment from "moment";
import "moment/locale/fr";
import { FuseUtils } from '@fuse';
import DataGrid, { Column, ColumnChooser, Pager, ColumnFixing, Editing, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Selection, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import deMessages from "app/custom/translation/fr.json";
import { loadMessages, locale } from 'devextreme/localization';
import { TextField, Button, Dialog, DialogActions, DialogContent, Checkbox, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from '@fuse/hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import SelectClients from 'app/custom/components/selects/selectClients';
import SelectContacts from 'app/custom/components/selects/selectContacts';

import SelectSites from 'app/custom/components/selects/selectSites';
import firebaseService from 'app/services/firebaseService';

const defaultFormState = {
        client: {},
        date:{},
        site: {},
        contact: {},
        gestionnaire: {},
};


const useStyles = makeStyles(theme => ({
        formControl: {
                margin: theme.spacing(3),
        },
}));
const GridElement = (form) => {
        
        return ''
}
const Infos = (props) => {
        //console.log(props)
        const dispatch = useDispatch();
        const wrapperRef = useRef(props.tab);
        const contacts = useSelector(({ contactsApp }) => contactsApp.contacts.entities);
        const clients = useSelector(({ contactsApp }) => contactsApp.contacts.clients);
        const sites = useSelector(({ custom }) => custom.sites.sites.entities)
        const grid = useRef('grid')
        const classes = useStyles();
        const userAuth = useSelector(({ auth }) => auth.user.uid);
        var variables = useSelector(({ custom }) => custom.variables.elements_verifies);
        const [list, setList] = useState(_.values())
        
        const { form, handleChange, setForm } = useForm({ ...defaultFormState, ...props.form[props.tab] })
    
        useMemo(() => {
                loadMessages(deMessages);

                locale(navigator.language);
        }, [])
        useEffect(() => {
               /*  if (props.match.params.id == 'newaudit') {
                        setForm({ ...form, type: 'audit' })
                } else {
                        setForm(props.form[props.tab])
                } */
                if (!_.isEqual(props.form[props.tab], form))
                setForm(props.form[props.tab])
                //console.log('params')
        }, [props.match.params, props.form[props.tab]]);
  
        useEffect(() => {
                if (!_.isEqual(props.form[props.tab], form))
                      setForm(form)
        }, [props.form[props.tab]]);
        useOutsideAlerter(wrapperRef,form);
        function useOutsideAlerter(ref, form){
                useEffect(() => {
                        /**
                         * Alert if clicked on outside of element
                         */
                        function handleClickOutside(event) {
                               
                                if (ref.current && !ref.current.contains(event.target)) {
                                     
                                        form.date.date_format = moment(form.date.date).format('DD-MM-YYYY')
                                        form.date.date_format_lng = moment(form.date.date).format('Do MMMM YYYY')
                                
                                        //console.log(!_.isEqual(props.form, form));
                                        if(!_.isEqual(props.form, form))
                                        props.handleChange(props.tab, form)
                                }
                        }

                        // Bind the event listener
                        document.addEventListener("mousedown", handleClickOutside);
                        return () => {
                                document.removeEventListener("mousedown", handleClickOutside);
                        };
                }, [ref, form]);
        }
        function handleChangeSite(val, id, key) {
                ////////////////console.log(val, key, id)
                if (val && val.id) {
                        return setForm({ ...form, [key]: { ...sites[val.id], id: val.id } })
                        return setForm({ ...form, [key]: { ...val, id: val.id } })
                } else if (val && !_.isObject(val)) {
                        return setForm({ ...form, [key]: { ...sites[val], id: val } })
                        firebaseService.firestore.collection('sites').doc(val).get().then(doc => {

                                if (doc.exists) {
                                        var f = form[key]

                                        return setForm({ ...form, [key]: { ..._.omit(doc.data(), 'dropbox_id'), id: doc.id } })
                                }
                        })
                } else
                        return setForm({ ...form, [key]: {} })
        }

        function handleChangeContact(val, id, key) {
                ////////////////console.log(val, key, id)
                if (val && val.id) {
                        return setForm({ ...form, [key]: { ...val, id: val.id } })
                } else if (val && !_.isObject(val)) {
                        return setForm({ ...form, [key]: { ...contacts[val], id: val } })
                        firebaseService.firestore.collection('users').doc(val).get().then(doc => {
                                if (doc.exists) {
                                        var f = form[key]

                                        return setForm({ ...form, [key]: { ...doc.data(), id: doc.id } })
                                }
                        })
                } else {
                        return setForm({ ...form, [key]: {} })
                }
        }
        function handleChangeCompany(val, id, key) {
                ////////////////console.log(val, key, id)
                if (val && val.id) {
                        return setForm({ ...form, [key]: { ...clients[val.id], id: val.id } })

                        firebaseService.firestore.collection('clients').doc(val.id).get().then(doc => {
                                if (doc.exists) {
                                        return setForm({ ...form, [key]: { ...doc.data(), id: doc.id }, site: {}, contact: {} })
                                }
                        })
                } else if (val && !_.isObject(val)) {
                        return setForm({ ...form, [key]: { ...clients[val], id: val } })
                        firebaseService.firestore.collection('clients').doc(val).get().then(doc => {
                                if (doc.exists) {
                                        return setForm({ ...form, [key]: { ...doc.data(), id: doc.id }, site: {}, contact: {} })
                                }
                        })
                } else
                        return setForm({ ...form, [key]: {}, site: {}, contact: {} })
        }
        return (
                <div className={classes.formControl} ref={wrapperRef}>
                        <TextField
                                label={'Date du rapport'}
                                name={'date.date'}
                                type={'date'}
                                defaultValue={form.date.date}
                                onChange={handleChange}
                                error={!form.date.date}
                                required
                                variant='outlined'
                                InputLabelProps={{ shrink: true }}
                        ></TextField>
                        <div className='flex flex-row'>
                                <SelectClients key={`${'client'}`} company={form['client'] && form['client'].id ? form['client'].id : ''} handleChange={handleChangeCompany} id={'client'} parent={'client'} label={'Client'} />
                                <SelectClients key={`${'gestionnaire'}`} company={form['gestionnaire'] && form['gestionnaire'].id ? form['gestionnaire'].id : ''} handleChange={handleChangeCompany} id={'gestionnaire'} parent={'gestionnaire'} label={'Gestionnaire'} />
                        </div>
                        <div className='flex flex-row'>
                        {form.client && form.client.id && <SelectContacts key={`${'contact'}`} client={form.client.id} gestionnaire={form.gestionnaire && form.gestionnaire.id ? form.gestionnaire.id : null} contact={form['contact'] && form['contact'].id ? form['contact'].id : ''} handleChange={handleChangeContact} id={'contact'} parent={'contact'} label={'contact'} />}
                                {form.client && form.client.id && <SelectSites key={`${'site'}`} client={form.client.id} contact={form.contact.id} site={form['site'] && form['site'].id ? form['site'].id : ''} handleChange={handleChangeSite} id={'site'} parent={'site'} label={'Site'} />}


                        </div>
                       
                        
                </div>
        )

}

export default withRouter(Infos);