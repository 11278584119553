import React from 'react';

import HTMLReactParser from 'html-react-parser';


import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';


const App = (props) => {

    ////console.log(props)
    return (
        <React.Fragment>
            <div className="widget-container">
                <HtmlEditor height="550px"
                    onFocusOut={(e) => {
                        //console.log(e)
                    }}
                    valueType={'html'}
                    onValueChanged={(e) => {
                        //console.log(e)
                        props.data.setValue(e.value,e.value)
                    }}
                >
                    <MediaResizing enabled={true} />
                    <Toolbar multiline={true}>
                        <Item formatName="undo" />
                        <Item formatName="redo" />
                        <Item formatName="separator" />
                        <Item
                            formatName="size"
                            formatValues={['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']}
                        />
                        <Item
                            formatName="font"
                            formatValues={['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']}
                        />
                        <Item formatName="separator" />
                        <Item formatName="bold" />
                        <Item formatName="italic" />
                        <Item formatName="strike" />
                        <Item formatName="underline" />
                        <Item formatName="separator" />
                        <Item formatName="alignLeft" />
                        <Item formatName="alignCenter" />
                        <Item formatName="alignRight" />
                        <Item formatName="alignJustify" />
                        <Item formatName="separator" />
                        <Item formatName="orderedList" />
                        <Item formatName="bulletList" />
                        <Item formatName="separator" />
                        <Item
                            formatName="header"
                            formatValues={[false, 1, 2, 3, 4, 5]}
                        />
                        <Item formatName="separator" />
                        <Item formatName="color" />
                        <Item formatName="background" />
                        <Item formatName="separator" />
                        <Item formatName="link" />
                        <Item formatName="image" />
                        <Item formatName="separator" />
                        <Item formatName="clear" />
                        <Item formatName="codeBlock" />
                        <Item formatName="blockquote" />
                        <Item formatName="image" />
                    </Toolbar>
                    {HTMLReactParser(props.values)}
                </HtmlEditor>

            </div>
        </React.Fragment>
    );


}

export default App;
