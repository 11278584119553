import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectBox from 'devextreme-react/select-box';
import DataSource from "devextreme/data/data_source";
import ArrayStore from 'devextreme/data/array_store';
import * as ActionsAll from 'app/store/actions';
import _ from '@lodash'
import moment from "moment";
import "moment/locale/fr";
import { FuseUtils } from '@fuse';
import { TextBox } from 'devextreme-react/text-box';
import { TagBox } from 'devextreme-react/tag-box';
import DataGrid, { Lookup, RequiredRule, Column, ColumnChooser, Pager, ColumnFixing, Editing, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Selection, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import deMessages from "app/custom/translation/fr.json";
import { loadMessages, locale } from 'devextreme/localization';
import { InputAdornment, Input, TextField, Button, Dialog, DialogActions, DialogContent, Checkbox, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from '@fuse/hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import SelectClients from 'app/custom/components/selects/selectClients';
import SelectContacts from 'app/custom/components/selects/selectContacts';

import SelectSites from 'app/custom/components/selects/selectSites';
import SelectVariables from 'app/custom/components/selects/selectVariable';
import firebaseService from 'app/services/firebaseService';
import CustomPopup from 'app/custom/rapports/audit/installation/customPopup'





const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
        height: '100%'
    },
}));
const GridElement = (form) => {

    return ''
}
const Infos = (props) => {
    //////console.log(props)
    const dispatch = useDispatch();
    const wrapperRef = useRef(props.tab);
    const grid = useRef(props.tab + 'A')
    const classes = useStyles();
    const userAuth = useSelector(({ auth }) => auth.user.uid);
    const variablesList = useSelector(({ custom }) => custom.variables);
    //////console.log(variablesList)
    const [years, setYears] = useState([])
    const [visible, setVisible] = useState(false)
    const [variables, setVariables] = useState({})
    //var variables = useSelector(({ custom }) => custom.variables[props.tab]);
    const [list, setList] = useState([])

    const [form, setForm] = useState(props.form[props.tab])

    useMemo(() => {
        loadMessages(deMessages);

        locale(navigator.language);
    }, [])
    useEffect(() => {

        setYears(_.reverse(rangeOfYears(new Date("Jun 26 1900").getFullYear(), new Date().getFullYear())))
        if (!_.isEqual(props.form[props.tab], form))
            setForm(props.form[props.tab])

    }, [props.match.params, props.form[props.tab]]);
    useEffect(() => {
        var v = {}
        _.map(variablesList, (c, f) => {
            //c.values = _.sortBy(c.values)
          // if(f!='reglementation')
            v[f] = new DataSource({
                store: _.map(c.values, (m, i) => {

                    return { id: i, value: m }
                }),
                key: 'id',
                sort: { getter: "value", asc: true }
               
            });
        
        })
        setVariables(v)


    }, [])

    useOutsideAlerter(wrapperRef, grid, form);
    function useOutsideAlerter(ref, grid, form) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {

                if (ref.current && !ref.current.contains(event.target)) {
                    //////console.log(grid.current.instance.getDataSource()._items)

                    form = grid.current.instance.getDataSource()._items
                    //////console.log(!_.isEqual(props.form[props.tab], form));
                    if (!_.isEqual(props.form[props.tab], form) && !visible)
                        props.handleChange(props.tab, form)
                }
            }


            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, form]);
    }

    function getFilteredBatteries(options) {
        //////console.log(options, props.form.batteries)
        return {
            store: props.form.batteries,
            filter: options.data ? ['batiment', '=', options.data.batiment] : null
        };
    }
    const rangeOfYears = (start, end) => Array(end - start + 1)
        .fill(start)
        .map((year, index) => year + index)


    const selectVariable = (cellInfo, bdd) => {
        //////console.log(cellInfo)
        //////console.log(variablesList[bdd])
        function customItemCreating(e) {

            const nextId = Math.max.apply(Math, variables[bdd].items().map(c => c.id)) + 1;
          
            e.customItem = { id: nextId, value: e.text };
    
            variables[bdd].store().insert(e.customItem);
            cellInfo.setValue(e.text, e.text)
            if (!_.includes(variablesList[bdd].values, e.text))
                firebaseService.firestore.collection('variables').doc(bdd).set({ name: variablesList[bdd].name, values: _.concat(variablesList[bdd].values, e.text) }).catch(e => {
                    ////console.log(e)
                })
            variables[bdd].reload();
            
        }

        return (
            <SelectBox
                dataSource={variables[bdd]}
                valueExpr="id"
                displayExpr="value"
                acceptCustomValue={true}
                onSelectionChanged={(e) => {
                    //////console.log(e)
                    cellInfo.setValue(e.selectedItem.value, e.selectedItem.value)
                }}
                onCustomItemCreating={customItemCreating}
            />
        );

    }

    const selectMultipleVariable = (cellInfo, bdd) => {
        ////console.log(cellInfo)
        //////console.log(variables[bdd])
        function customItemCreating(e) {
           
            const nextId = Math.max.apply(Math, variables[bdd].items().map(c => c.id)) + 1;

            e.customItem = { id: nextId, value: e.text };

            variables[bdd].store().insert(e.customItem);
            cellInfo.setValue(e.text, e.text)
            variables[bdd].reload();
           
          if (!_.includes(variablesList[bdd].values, e.text))
             firebaseService.firestore.collection('variables').doc(bdd).set({ name: variablesList[bdd].name, values: _.concat(variablesList[bdd].values, e.text) })
        }

        return (
            <TagBox
                dataSource={variables[bdd]}
               valueExpr="value"
                displayExpr="value"
                defaultValue={cellInfo.value}
                acceptCustomValue={true}
                onValueChanged={(e) => {
                    //////console.log(e)
                   cellInfo.setValue(e.value)
                }}
                onCustomItemCreating={customItemCreating}
            />
        );

    }

    const customTextField = (cellInfo, bdd, unit) => {

        const setEditedValue = valueChangedEventArg => {

            return cellInfo.setValue(valueChangedEventArg.value ? valueChangedEventArg.value : '');
        }
        return (


            <TextBox
                id={bdd}

                valueChangeEvent={'blur'}
                defaultValue={cellInfo.value}
                onValueChanged={setEditedValue}

                aria-describedby={bdd}
            />
        )
    }

    /* const customPopup = (cellInfo, bdd) => {
        //////console.log(cellInfo, visible, !visible)

        const setEditedValue = valueChangedEventArg => {
            //////console.log(valueChangedEventArg)
            setVisible(false)
            cellInfo.value = valueChangedEventArg
            cellInfo.text = valueChangedEventArg
            return cellInfo.setValue(valueChangedEventArg);
        }
        return (

            <div>
                <TextField
                    id={bdd}

                    // onBlur={(e) => setEditedValue(e.target.value)}
                    defaultValue={cellInfo.value}
                    aria-describedby={bdd}
                    onClick={(e) => {
                        setVisible(true)
                        dispatch(ActionsAll.openDialog({
                            children: <CustomPopup cell={cellInfo} form={form} handleChange={setEditedValue} />
                        }))

                    }}
                    disabled={true}
                />

            </div>
        )
    } */
    function setStateValue(rowData, value) {
        rowData.batiment = null;
        this.defaultSetCellValue(rowData, value);
    }
    //////console.log(form)
    return (
        <div className={classes.formControl} ref={wrapperRef}>


            <DataGrid
                id={props.tab}

                height={'100%'}
                ref={grid}
                dataSource={form}
                allowColumnResizing={true}
                allowColumnReordering={false}
                columnAutoWidth={true}
                columnResizingMode={'widget'}
                rowAlternationEnabled={true}
                showBorders={true}
                columnHidingEnabled={true}
                keyExpr="id"
                wordWrapEnabled={true}
                onInitNewRow={(e) => {
                    if (!grid.current.instance.getDataSource()._items.length)
                        e.data = { id: FuseUtils.generateGUID(), name: '', batiment: '', batterie: '', parentType:'appareil'}
                    else {
                        e.data = { ...grid.current.instance.getDataSource()._items[grid.current.instance.getDataSource()._items.length - 1], id: FuseUtils.generateGUID(), name: '' }
                    }
                }}

                onRowInserted={
                    (e) => {
            
                        props.handleChange(props.tab, grid.current.instance.getDataSource()._items)
                    }
                }
                onRowUpdated={
                    (e) => {
                        props.handleChange(props.tab, grid.current.instance.getDataSource()._items)

                    }}
                onRowRemoved={
                    (e) => {
                        props.handleChange(props.tab, grid.current.instance.getDataSource()._items)

                    }}
            >
                <Scrolling mode="virtual" />
                <StateStoring enabled={true} type="localStorage" storageKey={"audit_appareils"} /> 
                {/* 
                <ColumnFixing enabled={true} />
                <Export enabled={true} allowExportSelectedData={true} icon={'xlsxfile'} />
                <Selection mode={"multiple"} />
                <ColumnChooser enabled={true} />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} /> */}
                <SearchPanel visible={true}
                    //width={240}
                    placeholder="Recherche..." />
                <FilterRow visible={false} />
                <GroupPanel visible={true} />
                {/*   <StateStoring enabled={true} type="localStorage" storageKey={"appareils"} /> */}
                {/* <Paging defaultPageSize={10} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} /> */}
                <Editing
                    mode="batch"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={(e => {
                        return true
                    })} />
                <Column type={'buttons'} />
                <Column caption={'Informations Générales'}>
                    <Column caption={'Bâtiment'} minWidth={200} dataField="batiment" setCellValue={setStateValue}>
                        <Lookup dataSource={props.form.batiments} valueExpr={'id'} displayExpr={'name'} editorOptions={(e) => {
                            //////console.log(e)
                            return {
                                acceptCustomValue: true, onCustomItemCreating: (e) => {
                                    //////console.log(e)
                                }
                            }
                        }} />
                        <RequiredRule />
                    </Column>
                    <Column caption={'Batterie'} dataField="batterie" editorOptions={{
                        showClearButton: true
                    }} >
                        <Lookup dataSource={getFilteredBatteries} valueExpr={'id'} displayExpr={'name'} />
                    </Column>
                    <Column caption={'Nom'} dataField="name" dataType={'string'} >
                        <RequiredRule />
                    </Column>
                    <Column caption={'Marque'} dataField="marque" editCellRender={(e) => selectVariable(e, 'marque_appareil')}>
                    </Column>
                    <Column caption={'Entreprise de maintenance'} minWidth={200} dataField="maintenance" editCellRender={(e) => selectVariable(e, 'entreprise_maintenance')}>
                    </Column>
                    <Column caption={'Année de construction'} dataField="year" dataType={'string'} >
                        <RequiredRule />
                        <Lookup dataSource={years} />
                    </Column>
                </Column>
                <Column caption={'Détails Techniques'}>
                    <Column caption={'Type'} dataField="type" editCellRender={(e) => selectVariable(e, 'type_appareil')} />
                    <Column caption={'Situation Machinerie'} dataField="situation_machinerie" editCellRender={(e) => selectVariable(e, 'situation_machinerie')} />
                    <Column caption={'Contrôleur/Fabricant'} dataField="controleur" dataType={'string'} />
                    <Column caption={'Entraînement'} dataField="entrainement" editCellRender={(e) => selectVariable(e, 'entrainement')} />
                    <Column caption={'Machine/Fabricant'} dataField="machine" dataType={'string'} />
                    <Column caption={'Charge'} dataField="charge" editCellRender={(e) => customTextField(e, 'charge', 'kg')} />
                    <Column caption={'Vitesse'} dataField="vitesse" editCellRender={(e) => customTextField(e, 'vitesse', 'm/s')} />
                    <Column caption={'Nbre de niveaux'} dataField="nbre_niveaux" dataType={'number'} />
                    <Column caption={'Niveaux desservis'} dataField="niveaux_desservis" dataType={'string'} />
                    <Column caption={'Porte cabine'}>
                        <Column caption={'Type'} dataField="porte_cabine_type" editCellRender={(e) => selectVariable(e, 'porte_cabine_type')} />
                        <Column caption={'Nbre de vantaux'} dataField="porte_cabine_nbre_vantaux" dataType={'number'} />
                        <Column caption={'Ouverture'} dataField="porte_cabine_ouverture" editCellRender={(e) => selectVariable(e, 'porte_cabine_ouverture')} />
                        <Column caption={'Passage Libre'} dataField="porte_cabine_passage_libre" editCellRender={(e) => customTextField(e, 'porte_cabine_passage_libre', 'mm')} />
                    </Column>
                    <Column caption={'Porte palière'}>
                        <Column caption={'Type'} dataField="porte_paliere_type" editCellRender={(e) => selectVariable(e, 'porte_paliere_type')} />
                        <Column caption={'Nbre de vantaux'} dataField="porte_paliere_nbre_vantaux" dataType={'number'} />
                        <Column caption={'Ouverture'} dataField="porte_paliere_ouverture" editCellRender={(e) => selectVariable(e, 'porte_paliere_ouverture')} />
                           </Column>
                    <Column caption={'Type manoeuvre'} dataField="type_manoeuvre" editCellRender={(e) => selectVariable(e, 'type_manoeuvre')} />
                    <Column caption={'Suspension'} dataField="suspension" editCellRender={(e) => selectVariable(e, 'suspension')} />
                    <Column caption={'Local poulies'}>
                        <Column caption={'Local poulies'} dataField="local_poulies" dataType={'boolean'} />
                        <Column caption={'Commentaires'} dataField="local_poulies_comment" dataType={'textarea'} />
                    </Column>
                    {/*  <Column caption={'Porte cabine'} dataField="porte_cabine" dataType={'string'} editCellRender={(e) => customPopup(e, 'porte_cabine')} /> */}
                    <Column caption={'Règlementation'} dataField="reglementation" editCellRender={(e) => selectMultipleVariable(e, 'reglementation')} />
                </Column>



            </DataGrid>


        </div>
    )

}

export default withRouter(Infos);