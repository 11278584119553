

import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
import { useDispatch } from 'react-redux';
//////console.log(fr)
i18next.addResourceBundle('en', 'rapportsApp', en);
i18next.addResourceBundle('fr', 'rapportsApp', fr);


export const RapportsConfig ={ 

    settings: {
        layout: {}
    },
    routes: [
       /*  {
            path: '/rapports/new',
            component: React.lazy(() => import('./rapportContentDialog')),
            exact:true

        },
        {
            path: '/rapports/:id',
            component: React.lazy(() => import('./rapportContentDialog'))

        }, */
        {
            path: '/rapports/:id',
            component: React.lazy(() => import('./rapportApp')),
            exact: true
        },
        {
            path: '/rapports',
            component: (e) =>   <Redirect to="/rapports/all" />
            
        }
        
    ]
};
