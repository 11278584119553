import React,
{
    useState,
    useEffect
}
    from 'react';

import DataGrid,
{
    Column,
    Editing,
    Paging,
    Selection,
    Lookup,
    RequiredRule 
}
    from 'devextreme-react/data-grid';
import {
    CheckBox,
    SelectBox
}
    from 'devextreme-react';
import { useSelector , useDispatch} from 'react-redux';
import _ from '@lodash'
import firebaseService from 'app/services/firebaseService';
import * as Actions from 'app/store/actions'
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
const App = props => {


const dispatch= useDispatch()
    const variables = useSelector(({ custom }) => custom.variables);
    const [dataSource, setDataSource] = useState([])
    const [selectedRowKeys, setSelectedRowKeys ]= useState([])
    const initialForm = useSelector(({ custom }) => custom.rapports.form);
    const [state, setState] = useState({
        selectTextOnEditStart: true,
        startEditAction: 'click'
    })

    useEffect (()=> {
        setDataSource(props.value ? props.value :[])
    },[])
    useEffect(() => {
        if (!_.isEqual(dataSource, variables[props.name]))
            return setDataSource(variables[props.name] ? _.values(variables[props.name]):[])
    }, [props.name, variables[props.name]])

    useEffect(() => {
        var data = _.map(initialForm.data[props.name], c => c.id)
        //console.log(data)
        if (!_.isEqual(data, selectedRowKeys))
        setSelectedRowKeys(data)
    }, [initialForm.data[props.name]])

    function onSelectTextOnEditStartChanged(args) {
        setState({
            ...state,
            selectTextOnEditStart: args.value
        }

        );
    }

    function onStartEditActionChanged(args) {
        setState({
            ...state,
            startEditAction: args.value
        }

        );
    }
    //////console.log(selectedRowKeys)
    return (<div id="data-grid-demo" className={'min-w-full'}>
        <DataGrid
            width='100%'
            dataSource={dataSource}
            columnAutoWidth={true}
            selectedRowKeys={selectedRowKeys}
            wordWrapEnabled={true}
        keyExpr="id"

        showBorders={
            true
        }
        //onRowPrepared={(e) => //////console.log(e)}
        onSelectionChanged={
            (e) => {
                //console.log(props.name,e.selectedRowsData)
                
                dispatch(Actions.setFormAudit(initialForm.id, { ...initialForm.data, [props.name]: e.selectedRowsData}))

                //props.form.setFieldValue(props.name, e.selectedRowsData)

            }
        }

        onRowInserted={
            (e) => {
                ////////console.log(e.data)
                e.data.id = e.key 
                e.data.value = capitalize(e.data.value)
                e.component.selectRows([e.key], true);  
                ////////console.log(_.keyBy(_.concat(dataSource, _.omit(e.data, `${props.name}`)), 'id'))
                if (dataSource.length)
                    firebaseService.firestore.collection('variables').doc(props.name).set(_.keyBy(_.concat(dataSource, e.data),'id' ) )
                else { 
                    firebaseService.firestore.collection('variables').doc(props.name).set(_.keyBy(e.data,'id'))
                    
                }
    
            }}

        onRowUpdated = {
            (e) => {
                ////////console.log(e.data)
                e.data.id = e.key
                e.data.value = capitalize(e.data.value)
                e.component.selectRows([e.key], true);  
                firebaseService.firestore.collection('variables').doc(props.name).set(
                _.keyBy(_.concat(_.filter(dataSource, d => {
                        return d.id != e.data.id 
                    }), e.data) ,'id'))

            }}
            >
    <Selection mode="multiple"
           
        selectAllMode={
            'allPages'
        }
       
        showCheckBoxesMode={
            'always'
        }

    /> <Paging enabled={
        false
    }

    /> <Editing mode="batch"
        allowUpdating={
            true
        }
        allowAdding={
            true
        }
        allowDeleting={
         false
        }

        selectTextOnEditStart={
            state.selectTextOnEditStart
        }

        startEditAction={
            state.startEditAction
        }

    />
  

            <Column dataField={'value'} caption={''} /* editorType="dxTextArea" */ width={300} >
                <RequiredRule /></Column>
    </DataGrid >

    </div >);
}

export default App;