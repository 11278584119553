
import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './navigation-i18n/en';

import fr from './navigation-i18n/fr';

i18next.addResourceBundle('en', 'navigation', en);

i18next.addResourceBundle('fr', 'navigation', fr);

const navigationConfig = [
   /*  {
        id: 'dashboard',
        'title': 'Tableau de bord',
        'translate': '',
        auth: authRoles.user,
        'type': 'item',
        'icon': 'apps',
        'url': '',
        'children': [],
    }, */
    {
        id: 'movveo', 
        'title': '',
        'translate': '',
        auth: authRoles.user,
        'type': 'group',
        'icon': 'apps',
        'children': [],
    }, {
        'id': 'sites',
        'title': 'Sites',
        'translate': 'SITES',
        'type': 'item',
        'icon': 'domain',
        auth: authRoles.user,
        'url': '/sites',
        'badge': {
            'title': 0,
            //'bg': '#F44336',
            //'fg': '#FFFFFF'
        }
    },
    {
        'id': 'contacts',
        'title': 'Contacts',
        'translate': 'CONTACTS',
        'type': 'item',
        'icon': 'account_box',
        auth: authRoles.user,
        'url': '/contacts/all/contacts',
        'badge': {
            'title': 0,
            // 'bg': '#F44336',
            //'fg': '#FFFFFF'
        }
    }, {
        'id': 'clients',
        'title': 'Clients',
        'translate': 'CLIENTS',
        'type': 'item',
        'icon': 'domain',
        auth: authRoles.user,
        'url': '/contacts/all/clients',
        'badge': {
            'title': 0,
            //'bg': '#F44336',
            //'fg': '#FFFFFF'
        }
    }, {
        'id': 'leads',
        'title': 'Leads',
        'translate': 'LEADS',
        'type': 'item',
        'icon': 'domain',
        auth: authRoles.user,
        'url': '/leads/all',
        'badge': {
            'title': 0,
            //'bg': '#F44336',
            //'fg': '#FFFFFF'
        }
    }, {
        'id': 'offres',
        'title': 'Offres',
        'translate': 'OFFRES',
        'type': 'item',
        'icon': 'shopping_cart',
        auth: authRoles.user,
        'url': '/offres/all',
        'badge': {
            'title': 0,
            //'bg': '#F44336',
            //'fg': '#FFFFFF'
        }
    }, {
        'id': 'commandes',
        'title': 'Commandes',
        'translate': 'COMMANDES',
        'type': 'item',
        auth: authRoles.user,
        'icon': 'shopping_cart',
        'url': '/commandes/all',
        'badge': {
            'title': 0,
            //'bg': '#F44336',
            //'fg': '#FFFFFF'
        }
    }, {
        'id': 'rapports',
        'title': 'Rapports',
        'translate': 'RAPPORTS',
        auth: authRoles.user,
        'type': 'item',
        'icon': 'edit',
        'url': '/rapports/all',
        'badge': {
            'title': 0,
            //'bg': '#F44336',
            //'fg': '#FFFFFF'
        }

    }, {
        'id': 'factures',
        'title': 'Factures',
        'translate': 'FACTURES',
        auth: authRoles.admin,
        'type': 'item',
        'icon': 'euro_symbol',
        'url': '/factures/all',
        'badge': {
            'title': 0,
            //'bg': '#F44336',
            //'fg': '#FFFFFF'
        }

    }, {
        'id': 'analytics',
        'title': 'Analyse',
        'translate': 'ANALYTICS',
        auth: authRoles.user,
        'type': 'item',
        'icon': 'euro_symbol',
        'url': '/analytics/all',

    }/* , {
        'id': 'timeline',
        'title': 'Timeline',
        'translate': 'Timeline',
        auth: authRoles.user,
        'type': 'item',
        'icon': 'calendar',
        'url': '/timeline',

    } */



];

export default navigationConfig;
