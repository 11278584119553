import React, { useEffect, useCallback, useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector'; //https://github.com/country-regions/react-country-region-selector/issues/10 
import _ from '@lodash'
import { withStyles } from '@material-ui/core/styles';
import { useForm } from '@fuse/hooks';
import FuseUtils from '@fuse/FuseUtils';
import * as Actions from './store/actions';
import * as ActionsAll from 'app/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import reducer from './store/reducers';
import withReducer from 'app/store/withReducer';
import MuiPhoneNumber from 'material-ui-phone-number'
import SelectClients from 'app/custom/components/selects/selectClients'
import moment from 'moment'
import firebaseService from 'app/services/firebaseService';
const getRegions = country => {
    if (!country) {
        return [];
    }

    return country[2].split("|").map(regionPair => {
        let [regionName, regionShortCode = null] = regionPair.split("~");
        return regionName;
    });
};
const IOSSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
const defaultFormState = {
    contact: {
        id: '',
        gender: 'Mr',
        name: '',
        lastName: '',
        avatar: 'assets/images/avatars/profile.jpg',
        //nickname: '',
        company: '',
        jobTitle: '',
        email: '',
        phone: '',
        mobile: '',
        fax: '',
        address: '',
        birthday: '',
        notes: '',
        isUser: false,
        access: {}
    }, client: {
        id: '',
        name: '',
        entite: '',
        code_client: '',
        siret: '',
        tva: '',
        address_facturation: {},
        avatar: 'assets/images/avatars/profile.jpg',
        email: '',
        phone: '',
        fax: '',
        address: '',
        address2: '',
        zip: '',
        city: '',
        country: 'France',
        notes: '',

    }
};

function ContactDialog(props) {
    ////console.log(props)
    const dispatch = useDispatch();
    const contactDialog = useSelector(({ contactsApp }) => contactsApp.contacts.contactDialog);
    //console.log(contactDialog)

    const clients = useSelector(({ contactsApp }) => contactsApp.contacts.clients);
    const contacts = useSelector(({ contactsApp }) => contactsApp.contacts.entities);
    const userAuth = useSelector(({ auth }) => auth.user);
    const user = useSelector(({ custom }) => custom.contacts.user.infos);
    const { form, handleChange, setForm } = useForm(defaultFormState[props.type ? props.type : contactDialog && (!contactDialog.db || contactDialog.db) === 'contact' ? 'contact' : 'client']);
    const [country, setCountry] = useState([])
    const [client, setClient] = useState(null)
    const [error, setError] = useState({})
    const db = props.type ? props.type : contactDialog && (!contactDialog.db || contactDialog.db) === 'contact' ? 'contact' : 'client'
    const { t } = useTranslation('contactsApp');

    useEffect(() => {

    }, [clients])
    useEffect(() => {
        handleChangeCountry(defaultFormState.client.country)
    }, [])
    const initDialog = useCallback(
        () => {
            ////console.log(props, clients[contactDialog.data.client])
            if (contactDialog.data && contactDialog.data.handleChange) {
                var f = _.omit(contactDialog.data, 'select')
                if (contactDialog.data.select == 'contact') {
                    if (contactDialog.type === 'new') {

                        if (contactDialog.data.email)
                            f = _.find(_.values(contacts), c => c.email == contactDialog.data.email)




                        setForm({
                            ...defaultFormState[db],
                            ...f, updatedBy: '', updatedTime: '', createdBy: user.id, createdTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                            company: contactDialog.data.client && clients[contactDialog.data.client] ? clients[contactDialog.data.client] : {},
                            email: contactDialog.data.email ? contactDialog.data.email.toLowerCase() : '',
                            id: FuseUtils.generateGUID()
                        });
                    } else {
                        setForm({
                            ...defaultFormState[db],
                            ...f, updatedBy: user.id, updatedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                            company: contactDialog.data.client ? contactDialog.data.client : '',
                            email: contactDialog.data.email ? contactDialog.data.email.toLowerCase() : '',

                        });
                    }
                } else {
                    if (contactDialog.type === 'new') {

                        setForm({
                            ...defaultFormState[db], ...f,
                            updatedBy: '', updatedTime: '', createdBy: user.id, createdTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                            id: FuseUtils.generateGUID()
                        });

                    } else if (contactDialog.type === 'edit') {

                        setForm({
                            ...defaultFormState[db], ...f,
                            updatedBy: user.id, updatedTime: moment().format('YYYY-MM-DD HH:mm:ss'),


                        });

                    }

                }
            }
            /**
             * Dialog type: 'edit'
             */
            else if (contactDialog.type === 'edit') {
                setForm({
                    ...defaultFormState[db],
                    ...contactDialog.data,
                });
            }

            /**
             * Dialog type: 'new'
             */
            else if (contactDialog.type === 'new') {
                setForm({
                    ...defaultFormState[db],
                    ...contactDialog.data,
                    id: FuseUtils.generateGUID()
                });
            }
        },
        [contactDialog.data, contactDialog.type, setForm, props.type],
    );

    useEffect(() => {
        /**
         * After Dialog Open
         */

        if (props.type || contactDialog.props.open) {
            initDialog();
        }

    }, [contactDialog.props.open, initDialog]);

    function closeComposeDialog() {

        props.type ? dispatch(ActionsAll.closeDialog()) : contactDialog.type === 'edit' ? dispatch(Actions.closeEditContactDialog()) : dispatch(Actions.closeNewContactDialog())
    }

    function canBeSubmitted() {

        function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
        if (db === 'contact')
            return (
                form.email && validateEmail(form.email) && form.name && form.name.length > 0
            );
        else return (
            form.name && form.name.length > 0
        );
    }

    function handleSubmit(event) {
        event.preventDefault();
        const f = JSON.parse(JSON.stringify(form))
        if (db == 'contact' && !form.email)
            return dispatch(ActionsAll.showMessage({ message: 'Merci de remplir le champ email', variant: 'error' }));
        switch (f.gender) {
            case 'Mr':
                f.gender_lng = 'Monsieur'
                break;

            case 'Mme':
                f.gender_lng = 'Madame'
                break;
            case 'Mlle':
                f.gender_lng = 'Mademoiselle'
                break;


        }
        if (contactDialog.type === 'new' && contactDialog.data && contactDialog.data.select && contactDialog.data.select == 'contact') {
            //////console.log(f)

            Promise.all([dispatch(Actions.addContact({ ..._.omit(f, 'handleChange'), company: f.company.id }, 'contact'))]).then(r => {
                contactDialog.data.handleChange({ ..._.omit(f, 'handleChange'), company: f.company.id })
                closeComposeDialog();
            })

        } else if (contactDialog.type === 'edit' && contactDialog.data && contactDialog.data.select && contactDialog.data.select == 'contact') {
            //////console.log(f)

            Promise.all([dispatch(Actions.updateContact({ ..._.omit(f, 'handleChange'), company: f.company.id }, 'contact'))]).then(r => {
                contactDialog.data.handleChange({ ..._.omit(f, 'handleChange'), company: f.company.id })
                closeComposeDialog();
            })

        } else if (contactDialog.type === 'new' && contactDialog.data && contactDialog.data.select && contactDialog.data.select == 'client') {
            //////console.log(f)

            Promise.all([dispatch(Actions.addContact(_.omit(f, 'handleChange'), 'client'))]).then(r => {
                contactDialog.data.handleChange(_.omit(f, 'handleChange'))
                closeComposeDialog();
            })

        } else if (contactDialog.type === 'edit' && contactDialog.data && contactDialog.data.select && contactDialog.data.select == 'client') {
            //////console.log(f)

            Promise.all([dispatch(Actions.updateContact(_.omit(f, 'handleChange'), 'client'))]).then(r => {
                contactDialog.data.handleChange(_.omit(f, 'handleChange'))
                closeComposeDialog();
            })

        }
        else if (contactDialog.type === 'new') {
            dispatch(Actions.addContact(_.omit(f, 'handleChange'), db));
            closeComposeDialog();
        }
        else {
            ////////console.log(f)
            dispatch(Actions.updateContact(_.omit(f, 'handleChange'), db));
            closeComposeDialog();
        }

    }

    function handleRemove() {
        dispatch(Actions.removeContact(form.id, db));
        closeComposeDialog();
    }
    function handleChangeCountry(val) {

        for (var i = 0; i < CountryRegionData.length; i += 1) {

            if (CountryRegionData[i][0] === val) {

                setForm(_.set({ ...form }, 'country', val))
                setCountry(CountryRegionData[i])

            }
        }

    }

    function handleChangePhone(ev, name) {

        setForm({ ...form, [name]: ev })

    }
    function handleChangeEmail(ev) {

        if (!_.find(contacts, { email: ev.target.value })) {
            setError({
                ..._.omit(error, ev.target.name)
            })
            setForm({
                ...form, [ev.target.name]: ev.target.value
            })
        } else setError({ ...error, [ev.target.name]: t('EMAIL_EXIST') })
    }

    function handleChangeCompany(val, id, key) {
        console.log(val, key, id)
        if (val && val.id) {
            return setForm({ ...form, [key]: { ...val, id: val.id } })
        } else if (val && !_.isObject(val)) {
            firebaseService.firestore.collection('clients').doc(val).get().then(doc => {

                if (doc.exists) {
                    var f = form[key]
                    handleChangeSite(null, null, 'site')
                    handleChangeContact(val, null, 'contact')
                    return setForm({ ...form, [key]: { ...doc.data(), id: doc.id } })
                }
            })
        } else
            return setForm({ ...form, [key]: {} })


    }

    function handleChangeSite(val, id, key) {
        console.log(val, key, id)
        if (val && val.id) {
            return setForm({ ...form, [key]: { ...val, id: val.id } })
        } else if (val && !_.isObject(val)) {
            firebaseService.firestore.collection('sites').doc(val).get().then(doc => {

                if (doc.exists) {
                    var f = form[key]

                    return setForm({ ...form, [key]: { ...doc.data(), id: doc.id } })
                }
            })
        } else
            return setForm({ ...form, [key]: {} })


    }

    function handleChangeContact(val, id, key) {
        console.log(val, key, id)
        if (val && val.id) {
            return setForm({ ...form, [key]: { ...val, id: val.id } })
        } else if (val && !_.isObject(val)) {
            firebaseService.firestore.collection('users').doc(val).get().then(doc => {

                if (doc.exists) {
                    var f = form[key]

                    return setForm({ ...form, [key]: { ...doc.data(), id: doc.id } })
                }
            })
        } else {
            return setForm({ ...form, [key]: {} })
        }


    }
    ////console.log(db, form)
    return (

        <React.Fragment>
            <AppBar position="static" elevation={1}>
                <Toolbar className="flex w-full">
                    <Typography variant="subtitle1" color="inherit">
                        {contactDialog.type === 'new' && db === 'contact' ? t('NEW_CONTACT') : db === 'contact' ? t('EDIT_CONTACT') : contactDialog.type === 'new' ? t('NEW_CLIENT') : t('EDIT_CLIENT')}
                    </Typography>
                </Toolbar>
                <div className="flex flex-col items-center justify-center pb-24">
                    <Avatar className="w-96 h-96" alt="contact avatar" src={form.avatar} />
                    {contactDialog.type === 'edit' && (
                        <Typography variant="h6" color="inherit" className="pt-8">
                            {form.name}
                        </Typography>
                    )}
                </div>
            </AppBar>
            <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden">
                {db === 'contact' ? <DialogContent classes={{ root: "p-24" }}>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">email</Icon>
                        </div>
                        <TextField
                            error={error.email ? true : form.email ? false : true}
                            helperText={error.email ? t('EMAIL_EXIST') : ''}
                            className="mb-24"
                            label={t('EMAIL')}
                            autoFocus
                            id="email"
                            name="email"
                            value={form.email}
                            type={'email'}
                            onChange={handleChangeEmail}
                            variant="outlined"
                            fullWidth
                            required
                        //disabled={contactDialog.type === 'edit' ? true : false}
                        />
                    </div>

                    {_.includes(userAuth.role, 'admin') && <div><div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>
                        <FormControlLabel

                            control={<IOSSwitch size="small" checked={form.isUser ? form.isUser : false} onChange={handleChange} />}
                            label={t('IS_USER')}
                            className="flex flex-end mb-24 "
                            name={'isUser'}
                            id={'isUser'}
                        />
                        {form.isUser ? <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>
                            <TextField
                                className="mb-24 mr-12"
                                label={t('ABBREVIATION_CONSULTANT')}
                                error={!form.access.abbreviation_consultant ? true : false}
                                id="abbreviation_consultant"
                                name="access.abbreviation_consultant"
                                value={form.access.abbreviation_consultant}
                                onChange={handleChange}
                                variant="outlined"

                                required
                                fullWidth
                            />
                            <TextField
                                className="mb-24 mr-12"
                                label={t('CODE_CONSULTANT')}

                                id="code_consultant"
                                name="access.code_consultant"
                                error={!form.access.code_consultant ? true : false}
                                value={form.access.code_consultant}
                                onChange={handleChange}
                                variant="outlined"
                                type="number"
                                required
                                fullWidth
                            />
                            <TextField
                                className="mb-24 mr-12"
                                label={t('AGENCE')}

                                id="code_agence"
                                name="access.code_agence"
                                error={!form.access.code_agence ? true : false}
                                value={form.access.code_agence}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                select
                                fullWidth
                            >
                                <MenuItem value="89">Agence de Paris</MenuItem>
                                <MenuItem value="88">Agence de Nice</MenuItem>
                            </TextField>
                        </div> : ''}
                    </div>
                    </div>
                    }

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <RadioGroup className="mb-24" aria-label="gender" name="gender" value={form.gender} onChange={handleChange} row>
                            <FormControlLabel
                                value="Mlle"
                                control={<Radio
                                    color="secondary" />
                                }
                                label={t('MLLE')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="Mme"
                                control={<Radio
                                    color="secondary" />
                                }
                                label={t('MME')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="Mr"
                                control={<Radio
                                    color="secondary" />
                                }
                                label={t('M')}
                                labelPlacement="start"
                            />

                        </RadioGroup>

                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">account_circle</Icon>
                        </div>

                        <TextField
                            className="mb-24 mr-12"
                            label={t('NAME')}
                            error={!form.name ? true : false}
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                        </div>
                        <TextField
                            className="mb-24 mr-12"
                            label={t('LASTNAME')}
                            error={!form.lastName ? true : false}
                            id="lastName"
                            name="lastName"
                            value={form.lastName}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">domain</Icon>
                        </div>

                        <SelectClients handleChange={handleChangeCompany} company={form.company && form.company.id ? form.company.id : ''} label={t('COMPANY')} parent={'company'} id={'company'} />

                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">work</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label={t('JOB_TITLE')}
                            id="jobTitle"
                            name="jobTitle"
                            value={form.jobTitle}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">phone</Icon>
                        </div>

                        <MuiPhoneNumber
                            className=" mb-24"
                            label={t('PHONE')}
                            id="phone"
                            name="phone"

                            value={form.phone}
                            onChange={(e) => handleChangePhone(e, 'phone')}
                            variant="outlined"
                            defaultCountry='fr'
                            inputextraprops={{
                                id: "phone",
                                name: 'phone',
                                required: false,
                            }}
                            fullWidth

                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">phone_android</Icon>
                        </div>

                        <MuiPhoneNumber
                            className=" mb-24"
                            label={t('MOBILE')}
                            id="mobile"
                            name="mobile"
                            value={form.mobile}
                            onChange={(e) => handleChangePhone(e, 'mobile')}
                            variant="outlined"
                            defaultCountry='fr'
                            inputextraprops={{
                                id: "mobile",
                                name: "mobile",
                                required: false,
                            }}

                            fullWidth

                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">local_printshop
</Icon>
                        </div>
                        <MuiPhoneNumber
                            className=" mb-24"
                            label={t('FAX')}
                            id="fax"
                            name="fax"

                            value={form.fax}
                            onChange={(e) => handleChangePhone(e, 'fax')}
                            variant="outlined"
                            defaultCountry='fr'
                            inputextraprops={{
                                id: "fax",
                                name: 'fax',
                                required: false,
                            }}
                            fullWidth

                        />
                    </div>



                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">note</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label="Notes"
                            id="notes"
                            name="notes"
                            value={form.notes}
                            onChange={handleChange}
                            variant="outlined"
                            multiline
                            rows={5}
                            fullWidth
                        />
                    </div>
                </DialogContent> : <DialogContent classes={{ root: "p-24" }}>
                        <Typography variant='h6' variant="caption">{t('INFOS')}</Typography>
                        <Divider className="mb-24" />
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">domain</Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('NAME')}
                                autoFocus
                                id="name"
                                name="name"
                                value={form.name}
                                error={!form.name ? true : false}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">domain</Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('ENTITE')}
                                id="entite"
                                name="entite"
                                value={form.entite}
                                onChange={handleChange}
                                variant="outlined"

                                fullWidth
                            />
                        </div>
                        {/*  <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('CODE_CLIENT')}
                                id="code_client"
                                name="code_client"
                                value={form.code_client}
                                onChange={handleChange}
                                variant="outlined"

                                fullWidth
                            />
                        </div> */}
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('SIRET')}

                                id="siret"
                                name="siret"
                                value={form.siret}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('TVA')}

                                id="tva"
                                name="tva"
                                value={form.tva}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <Typography variant='h6' variant="caption">{t('ADDRESS')}</Typography>
                        <Divider className="mb-24" />
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">home</Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('ADDRESS')}
                                error={!form.address ? true : false}
                                id="address"
                                name="address"
                                value={form.address}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('ADDRESS2')}

                                id="address2"
                                name="address2"
                                value={form.address2}
                                onChange={handleChange}
                                variant="outlined"

                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('ZIP')}
                                error={!form.zip ? true : false}
                                id="zip"
                                name="zip"
                                value={form.zip}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />

                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>


                            <TextField
                                className="mb-24"
                                label={t('CITY')}
                                error={!form.city ? true : false}
                                id="city"
                                name="city"
                                value={form.city}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>


                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>


                            <TextField
                                className="mb-24"
                                label={t('COUNTRY')}
                                select
                                id="country"
                                name="country"
                                value={country}

                                onChange={(val) => handleChangeCountry(val.target.value[0])}
                                variant="outlined"
                                required
                                fullWidth
                            >
                                {CountryRegionData.map((option, index) => {
                                    // ////////////console.log(option, index)
                                    return (

                                        < MenuItem key={option[0]} value={option} >
                                            {option[0]}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </div>
                        {country && country.length && <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>


                            <TextField
                                className="mb-24"
                                label={t('REGION')}
                                select
                                id="region"
                                name="region"
                                value={form.region}
                                onChange={handleChange}
                                variant="outlined"

                                fullWidth
                            >
                                {getRegions(country).map(
                                    (option, index) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        </div>}

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">phone</Icon>
                            </div>
                            <MuiPhoneNumber
                                className=" mb-24"
                                label={t('PHONE')}
                                id="phone"
                                name="phone"
                                value={form.phone}
                                onChange={(e) => handleChangePhone(e, 'phone')}
                                variant="outlined"
                                defaultCountry='fr'
                                inputextraprops={{
                                    id: "phone",
                                    name: 'phone',
                                    required: false,
                                }}
                                fullWidth

                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">local_printshop
</Icon>
                            </div>
                            <MuiPhoneNumber
                                className=" mb-24"
                                label={t('FAX')}
                                id="fax"
                                name="fax"

                                value={form.fax}
                                onChange={(e) => handleChangePhone(e, 'fax')}
                                variant="outlined"
                                defaultCountry='fr'
                                inputextraprops={{
                                    id: "fax",
                                    name: 'fax',
                                    required: false,
                                }}
                                fullWidth

                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">email</Icon>
                            </div>
                            <TextField
                                className="mb-24"
                                label={t('EMAIL')}
                                id="email"
                                name="email"
                                value={form.email}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            // disabled={contactDialog.type === 'edit' ? true : false}
                            />
                        </div>


                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">note</Icon>
                            </div>
                            <TextField
                                className="mb-24"
                                label="Notes"
                                id="notes"
                                name="notes"
                                value={form.notes}
                                onChange={handleChange}
                                variant="outlined"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </div>
                    </DialogContent>}

                {contactDialog.type === 'new' ? (
                    <DialogActions className="justify-between p-8">
                        <div className="px-16">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                type="submit"
                                disabled={!canBeSubmitted()}
                            >
                                {t('ADD')}
                            </Button>
                        </div>
                    </DialogActions>
                ) : (
                        <DialogActions className="justify-between p-8">
                            <div className="px-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={!canBeSubmitted()}
                                >
                                    {t('SAVE')}
                                </Button>
                            </div>
                            <IconButton
                                onClick={handleRemove}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </DialogActions>
                    )}
            </form></React.Fragment >

    );
}

export default withReducer('contactsApp', reducer)(ContactDialog);;
