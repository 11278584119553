import React, { useEffect, useCallback, useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import _ from '@lodash'
import { withStyles } from '@material-ui/core/styles';
import { useForm } from '@fuse/hooks';
import FuseUtils from '@fuse/FuseUtils';
import * as Actions from './store/actions';
import * as ActionsAll from 'app/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import reducer from './store/reducers';
import withReducer from 'app/store/withReducer';
import MuiPhoneNumber from 'material-ui-phone-number'
import SelectClients from 'app/custom/components/selects/selectClients'
import SelectContacts from 'app/custom/components/selects/selectContacts'
import SelectVariables from 'app/custom/components/selects/selectVariable'
import { Select } from '@material-ui/core';
import history from '@history'
import moment from 'moment'
const IOSSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
const defaultFormState = {
    lead: {
        appareils: [{ qte: 1, type: '' }],
        type_batiment: "",
        id: '',
        name: '',
        entite: '',

        client: '',
        contact: '',
        libelle: '',
        montant: '',
        note: '',
        date_relance: '',
        a_relancer_le: '',
        chance_transformation: '',
        status: 'En Cours'



    }
};

function LeadDialog(props) {

    const dispatch = useDispatch();
    ////console.log(useSelector(({ leadsApp }) => leadsApp))


    const leads = useSelector(({ custom }) => custom.leads.entities);
    const { form, handleChange, setForm } = useForm({});
    const [country, setCountry] = useState([])
    const [error, setError] = useState({})
    const db = 'lead'
    const { t } = useTranslation('leadsApp');

    useEffect(() => {

        setForm({ ...defaultFormState.lead, ...props.form })
    }, [props.form]);

    useEffect(() => {

        var name = form.address2 ? form.address2 + ' - ' + form.address + ' ' + form.zip + ' ' + form.city : form.address + ' ' + form.zip + ' ' + form.city
        /*   if (_.find(_.values(leads), c => c.name.toLowerCase() == name.toLowerCase() && c.id !== form.id)) {
              setError({ address: 'Un lead avec la même adresse,  existe déjà !', address2: 'Un lead avec la même adresse,  existe déjà !' })
          } else {
              if (error.address)
                  setError({})
          } */
        canBeSubmitted()
    }, [form]);


    function canBeSubmitted() {

        return (form.contact)

    }

    function handleSubmit(event) {
        event.preventDefault();

        if (props.leadDialog.type === "new") {

            form.name = form.address2 ? form.address2 + ' - ' + form.address + ' ' + form.zip + ' ' + form.city : form.address + ' ' + form.zip + ' ' + form.city

            Promise.all([dispatch(Actions.addLead(form, db))]).then(() => {

                if (props.leadDialog.data.handleChange) {

                    console.log(form)
                    props.leadDialog.data.handleChange(form)
                }
                props.closeComposeDialog();
            })

        }
        else {
            dispatch(Actions.updateLead(form, db));
            props.closeComposeDialog();
        }
        if (!props.leadDialog.data.handleChange)
            history.push('/leads/all')

    }

    function handleRemove() {
        dispatch(Actions.removeLead(form.id, db));
        props.closeComposeDialog();
    }
    function handleChangeCountry(val) {

        for (var i = 0; i < CountryRegionData.length; i += 1) {

            if (CountryRegionData[i][0] === val) {

                setForm(_.set({ ...form }, 'country', val))
                setCountry(CountryRegionData[i])

            }
        }

    }
    function handleChangeContact(val, id) {
        //////////console.log(val, key, id)
        return setForm({ ...form, [id]: val })


    }
    function handleChangeType(val, id) {
        ////console.log(val, id)
        return setForm({ ...form, [id]: val })


    }
    function handleChangePhone(ev, name) {

        setForm({ ...form, [name]: ev })

    }
    function handleChangeName(ev) {

        if (!_.find(leads, { name: ev })) {
            setError({
                ..._.omit(error, 'address')
            })
            setForm({
                ...form, address: ev
            })
        } else setError({ ...error, address: t('NAME_EXIST') })
    }
    function handleChangeCompany(val, id, key) {

        if (val) {
            return setForm({ ...form, [key]: val })
        } else {
            return setForm({ ...form, [key]: '' })
        }


    }
    /*  function handleChangeCompany(val) {
         //////////console.log(val)
         setForm({ ...form, 'client': val })
     } */
    function handleChangeTypeAppareil(val, id, index) {
        ////////////////////////console.log(val, id)

        return setForm({
            ...form, appareils: _.map(form.appareils, (v, j) => {
                if (j === index) {
                    v.type = val
                }
                return v
            })

        })

    }
    console.log(form)
    ////console.log(props)
    return (

        <React.Fragment>
            <AppBar position="static" elevation={1}>
                <Toolbar className="flex w-full">
                    <Typography variant="subtitle1" color="inherit">
                        {props.leadDialog.type === 'new' ? 'NOUVEAU LEAD' : 'EDITION DU LEAD'}
                    </Typography>
                </Toolbar>

            </AppBar>
            <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden">
                <DialogContent classes={{ root: "p-24" }}>
                    <Typography variant='h6' variant="caption">{t('STATUT')}</Typography>
                    <div className="flex pb-20">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>
                        <Select
                            labelId="status"
                            id="status"
                            name="status"
                            value={form.status ? form.status : 'En Cours'}
                            label="Statut"
                            onChange={handleChange}
                            variant="outlined"
                        >
                            <MenuItem value={'En Cours'}>En Cours</MenuItem>
                            <MenuItem value={'Gagné'}>Gagné</MenuItem>
                            <MenuItem value={'Perdu'}>Perdu</MenuItem>
                            <MenuItem value={'Abandonné'}>Abandonné</MenuItem>
                        </Select>
                    </div>
                    <Typography variant='h6' variant="caption">{t('INFOS')}</Typography>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">note</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label="Description du lead"
                            id="note"
                            name="note"
                            value={form.note}
                            onChange={handleChange}
                            variant="outlined"
                            multiline
                            required
                            rows={5}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>

                  
                    <Divider className="m-24" />
                    {/*   <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">domain</Icon>
                        </div>

                        <TextField
                            error={error.name ? true : false}
                            helperText={error.name ? t('NAME_EXIST') : ''}
                            className="mb-24"
                            label={t('NAME')}
                            autoFocus
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={handleChangeName}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div> */}
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        { /* <TextField

                            helperText={t('OBJECT_HELPER')}
                            className="mb-24"
                            label={t('OBJECT')}
                            autoFocus
                            id="object"
                            name="object"
                            value={form.object}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        /> */}
                        <SelectVariables handleChange={handleChangeType} label={'Type de bâtiment'} value={form.type_batiment} id={'type_batiment'} />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <SelectClients handleChange={handleChangeCompany} company={form.client && form.client.id ? form.client.id : form.client} label={'Client'} id={'client'} parent={'client'} />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <SelectContacts client={form.client && form.client.id ? form.client.id : form.client} gestionnaire={form.gestionnaire && form.gestionnaire.id ? form.gestionnaire.id : form.gestionnaire} contact={form.contact && form.contact.id ? form.contact.id : form.contact} handleChange={handleChangeContact} id={'contact'} parent={''} label={t('CONTACT')} />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">settings</Icon>
                        </div>

                        <div className="flex flex-col">
                            {_.map(form.appareils, (val, i) => {
                                //////////////////////console.log(i)
                                return (<div key={i} className="flex">


                                    <TextField
                                        // customInput={TextField}
                                        name={'nombre_appareil'}
                                        key={'nombre_appareil'}
                                        id={'nombre_appareil'}
                                        label={"Nombre d'appareil"}
                                        InputLabelProps={{ shrink: true }}
                                        className="mt-12 mb-24 mr-12 max-w-224"


                                        onChange={(e) => {
                                            ////////////////////console.log(e)
                                            setForm({
                                                ...form, appareils: _.map(form.appareils, (v, j) => {
                                                    if (j === i) {
                                                        v.qte = Number(e.target.value)
                                                    }
                                                    return v
                                                })

                                            })
                                        }}
                                        value={val.qte}
                                        error={!val.qte ? true : false}
                                        required
                                        type={'number'}
                                        // thousandSeparator={' '} decimalSeparator={','} suffix={''}
                                        variant='outlined'
                                        fullWidth

                                    />
                                    <SelectVariables handleChange={handleChangeTypeAppareil} label={"Type d'appareil"} value={val.type ? val.type : ''} id={'type_appareil'} index={i} />




                                    {i == _.keys(form.appareils).length - 1 ? <Icon className="mt-12 mb-24 mr-12 " onClick={() => {
                                        form.appareils.push({ qte: 1, type: '', prix: '' })
                                        setForm({ ...form })
                                    }}>add_circle</Icon> : <Icon className="mt-12 mb-24 mr-12 " onClick={() => {

                                        form.appareils.splice(i, 1)
                                        setForm({ ...form })
                                    }}>remove_circle</Icon>}

                                </div>)
                            })}
                        </div>
                    </div>
                    <Typography variant='h6' variant="caption">{t('ADDRESS')}</Typography>
                    <Divider className="mb-24" />
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">home</Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label={"Adresse"}
                            InputLabelProps={{ shrink: true }}
                            id="address"
                            name="address"
                            value={form.address}
                            //error={error.address || !form.address ? true : false}
                            onChange={handleChange}
                            variant="outlined"
                            //required
                            fullWidth
                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label={"Complément d'adresse"}
                            error={error.address2 ? true : false}
                            id="address2"
                            name="address2"
                            value={form.address2}
                            onChange={handleChange}
                            variant="outlined"

                            fullWidth
                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label={'Code Postal'}
                            //error={form.zip ? false : true}
                            id="zip"
                            name="zip"
                            value={form.zip}
                            onChange={handleChange}
                            variant="outlined"
                            //required
                            fullWidth
                        />

                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>


                        <TextField
                            className="mb-24"
                            label={"Ville"}
                            ///error={form.city ? false : true}
                            id="city"
                            name="city"
                            value={form.city}
                            onChange={handleChange}
                            variant="outlined"
                            //required
                            fullWidth
                        />
                    </div>
                    {/*https://github.com/country-regions/react-country-region-selector/issues/10 */}



                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">note</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label="Commentaires/Notes"
                            id="comments"
                            name="comments"
                            value={form.comments}
                            onChange={handleChange}
                            variant="outlined"
                            multiline
                            required
                            rows={5}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">euro</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label="Montant HT"
                            id="montant"
                            name="montant"
                            value={form.montant}
                            onChange={handleChange}
                            variant="outlined"
                            type={'number'}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            className="mb-24"
                            label="% Chance de transformation"
                            id="chance_transformation"
                            name="chance_transformation"
                            value={form.chance_transformation * 100}
                            onChange={(e) => {
                                e.currentTarget.value = Number(e.currentTarget.value) / 100
                                handleChange(e)
                            }}
                            variant="outlined"
                            type={'number'}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">calendar</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label="A relancer le"
                            id="a_relancer_le"
                            name="a_relancer_le"
                            value={form.a_relancer_le}
                            onChange={handleChange}
                            variant="outlined"
                            type={'date'}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            className="mb-24"
                            label="Relancé le"
                            id="date_relance"
                            name="date_relance"
                            value={form.date_relance}
                            onChange={(e) => {
                                /*       console.log(e.currentTarget.value, e.currentTarget.name)
                                      console.log(moment( e.currentTarget.value).add(2, 'weeks').format('YYYY-MM-DD')) */

                                setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value, 'a_relancer_le': moment(e.currentTarget.value).add(2, 'weeks').format('YYYY-MM-DD') })
                                return handleChange(e)
                            }}
                            variant="outlined"
                            type={'date'}
                            InputLabelProps={{ shrink: true }}

                        />
                    </div>
                </DialogContent>

                <DialogActions className="justify-between p-8">
                    <div className="flex flex-end px-16">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={!canBeSubmitted()}
                        >
                            SAUVEGARDER
                        </Button>
                    </div>

                </DialogActions>
            </form></React.Fragment >

    )
}

export default LeadDialog;;
