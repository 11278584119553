import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import React from 'react'
import history from '@history'
import moment from 'moment'
import 'moment/locale/fr'

export const GET_TIMELINE = '[TIMELINE APP] GET TIMELINE';

export function getTimeline() {


    return (dispatch, getState) => {

        return dispatch({
            type: GET_TIMELINE,
            payload: {}

        })

        firebaseService.firestore.collection('timeline').where('unix', '>', moment().unix()).onSnapshot(r => {
            const TIMELINE = getState().custom.timeline.entities
            const users = getState().custom.contacts.contacts.users
            //  //console.log(users)

            var f = {}
            if (r.size > 0) {
                r.forEach(doc => {
                    var data = doc.data()
                    /*    var consultant=''
                       switch (data.table) {
                           case 'users':
                               switch ('update') {
                                   consultant = data.data.updateBy[users[data.data.updateBy]]
                                       break;
                               }
                               break;
                           
                       } */
                    if (!f[doc.id])
                        f[doc.id] = { ...data, id: doc.id }

                    //////////console.log(doc.data(), doc.id)
                })
                var t = _.orderBy(_.values({ ...TIMELINE, ...f }), 'unix').reverse()
                //console.log({ ...TIMELINE, ...f })

                dispatch({
                    type: GET_TIMELINE,
                    payload: _.keyBy(t, 'id')

                })
            }
        })

    }


}

export function getOldTimeline() {


    return (dispatch, getState) => {
        const users = getState().custom.contacts.contacts.users
        //console.log(users)
        firebaseService.firestore.collection('timeline').where('unix', '<=', moment().unix()).orderBy('unix', 'desc').get().then(r => {
            //////console.log(r.size)
            var f = {}
            if (r.size > 0) {
                r.forEach(doc => {
                    var data = doc.data()

                    if (data.table != 'documents')
                        f[doc.id] = { ...data, id: doc.id }



                })


                var t = _.orderBy(_.values({ ...f }), 'unix').reverse()



                Promise.all([dispatch({
                    type: GET_TIMELINE,
                    payload: _.keyBy(t, 'id')

                })]).then(() =>
                    dispatch(getTimeline()))
            }
        })

    }


}