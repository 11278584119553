import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
////console.log(fr)
i18next.addResourceBundle('en', 'sitesApp', en);
i18next.addResourceBundle('fr', 'sitesApp', fr);


export const FacturesConfig = {
    settings: {
        layout: {}
    },
    routes: [

        {
            path: '/facturation/:commandeId',
            component: React.lazy(() => import('./facturationDialogContent'))

        },
        {
            path: '/factures/:id',
            component: React.lazy(() => import('./facturationApp'))

        },
        {
            path: '/factures',
            component: () => <Redirect to="/factures/all" />
        }
    ]
};
