import React, { Component } from 'react';
import { FuseSplashScreen } from '@fuse';
import { connect } from 'react-redux';
import * as userActions from 'app/auth/store/actions';
import * as TimelineActions from 'app/custom/timeline/store/actions';
import { bindActionCreators } from 'redux';
import * as ActionsAnalytics from 'app/custom/analytics/store/actions';
import * as Actions from 'app/store/actions';
import * as ActionsContacts from 'app/custom/contacts/store/actions';
import * as ActionsSites from 'app/custom/sites/store/actions';
import firebaseService from 'app/services/firebaseService';
import { withRouter } from 'react-router-dom';
import _ from '@lodash'
import history from '@history'
import { useRadioGroup } from '@material-ui/core';

import schedule from 'node-schedule'
class Auth extends Component {

    state = {
        waitAuthCheck: true, load: true
    }

    componentDidMount() {
        //////////////////////console.log(window)
    return Promise.all([

            this.firebaseCheck(),

        ]).then((t) => {
           this.setState({ ...this.state, waitAuthCheck: false })
        }).catch(err => console.error(err))

        //this.firebaseCheck()
    }

    componentWillReceiveProps(p, n) {
        //////////////console.log(p, n)
        const { custom } = p
        ////////////console.log(custom)
        ////////////console.log(custom.sites.sites.entities, custom.contacts.contacts.entities, custom.contacts.contacts.clients)
        if (custom.sites.sites.entities && custom.contacts.contacts.entities && custom.contacts.contacts.clients) {
            ////////////console.log(custom.sites.sites.entities , custom.contacts.contacts.entities ,custom.contacts.contacts.clients)
        //    this.setState({ ...this.state, load: false })
        }
    }

    firebaseCheck = () => new Promise((resolve, reject) => {

        firebaseService.init(
            success => {
                ////console.log(success)
                if (!success) {
                    //resolve();
                }
            }
        );

        firebaseService.onAuthStateChanged(async authUser => {
            if (authUser) {
                ////console.log(authUser)


                var NotificationIsSupported = !!(window.Notification /* W3C Specification */ || window.webkitNotifications /* old WebKit Browsers */ || navigator.mozNotification /* Firefox for Android and Firefox OS */)
                this.props.showMessage({ message: 'Vous êtes connecté...' });
                //////////////////////console.log(window)
                /*   if (Notification in window) {
                      var token = await firebaseService.messaging.getToken()
                      //////////////////////console.log(token)
                      localStorage.setItem('INSTANCE_TOKEN', token);
                      navigator.serviceWorker.addEventListener("message", (message) =>{
  
                        //////////////////console.log(message)
                    }  );
                  } */
                /*     var key = 'AAAAp33BPY4:APA91bEoOAW5ifzaLLhRyKZzDXwyOO0iSAvIJNewqpRqfcLkwTpEy6p1uUywk_yGNtUc-t_JDB3ETSvQ3RDhx0o0okeW-7TkoaZQ5wTuo7OibzpgRW1_QTk-OB3hwVZYkt7GLFIf5Jj2';
                    var to = 'c6KMW2la9RxRP0P7xb8QoL:APA91bHRCWkdPq1GONubXQYi_jyMwKPIG7PqG9vJ2hvl6zLFXqpsHXd5XvNB8qBwQ6xH-hE7GZOBQt7Riz9W0aQLJCbyCSYJ34AE--MXjh9bksU1PB0TfZnsV6pgBaDO6OjZUDd9tHYp';
                    var notification = {
                        'title': 'Portugal vs. Denmarkererrere',
                        'body': '5 to 1',
                        'icon': 'https://image.shutterstock.com/z/stock-photo-large-beautiful-drops-of-transparent-rain-water-on-a-green-leaf-macro-drops-of-dew-in-the-morning-668593321.jpg',
                        'click_action': 'http://localhost:3000'
                    };
    
                    fetch('https://fcm.googleapis.com/fcm/send', {
                        'method': 'POST',
                        'headers': {
                            'Authorization': 'key=' + key,
                            'Content-Type': 'application/json'
                        },
                        'body': JSON.stringify({
                            'notification': notification,
                            'to': to
                        })
                    }).then(function (response) {
                        //////////////////////console.log(response);
                    }).catch(function (error) {
                        //////////////console.error(error);
                    }) */
                /**
                 * Retrieve user data from Firebase
                 */
                firebaseService.getUserData(authUser.uid).then(user => {
                    console.log(authUser)
                    console.log(user)
                    if (user) {
                        console.log(user)


                        if (!_.includes(user.role, 'superAdmin')) {
                            ////console.log(user)
                            this.props.removeNavigationItem('admin')

                        }
                        /*   this.props.checkConnection().then(r => {
                              ////console.log(r)
  
                              // this.props.getObjectifs({ id: 'all' })
  
  
                              //this.props.getOffres({ id: 'all' })
                              ////////////////console.log(r)
                              resolve();
  
                          })
  
                          var s = this.props.getSitesDropbox()
  
                          ////console.log(s) */
                        /*  Promise.all([this.props.setUserDataFirebase(user, authUser), this.props.getConsultants(),
                         this.props.getRapports(), this.props.getFactures({ id: 'all' }), this.props.getUserData(), this.props.getVariables(), this.props.showMessage({ message: 'Vous êtes connecté...' }),
                         this.props.getCommandes({ id: 'all' }),
                         this.props.getOffres({ id: 'all' }),
 
 
                         this.props.checkConnection()]).then(r => {
 
                             // this.props.getObjectifs({ id: 'all' })
 
 
 
                             ////////////////console.log(r)
                             resolve();
                         }).catch(err => {
                             ////////////////console.log(err)
                             reject()
                         }) */
                        /*  Promise.all([this.props.setUserDataFirebase(user, authUser), this.props.getConsultants(),
                     this.props.getRapports(), this.props.getFactures({ id: 'all' }), this.props.getUserData(), this.props.getVariables(), this.props.showMessage({ message: 'Vous êtes connecté...' }),
                     this.props.getCommandes({ id: 'all' }),
                     this.props.getOffres({ id: 'all' }),


                  
                     }).catch(err => {
                         ////////////////console.log(err)
                         reject()
                     }) */
                        this.props.checkConnection().then(r => {

                         schedule.scheduleJob('0 0 * * *', () => {
                                this.props.getLeads('load')

                            }) 
                            //console.log(r)
                            this.props.showMessage({ message: 'Vous êtes connecté...' })
                            Promise.all([this.props.getSitesDropbox(), this.props.getConsultants(),this.props.getUserData(),this.props.getVariables(), this.props.setUserDataFirebase(user, authUser)]).then(r => {

                                /*  Promise.all([]).then(r => {
 
                                 }).catch(err => {
                                     ////console.log(err)
                                     return err
                                 }) */


                                ////console.log(r)

                                //this.props.getOffres({ id: 'all' })
                                Promise.all([this.props.getSites({ id: 'all' })]).then(d => {
                                    Promise.all([this.props.getClients({ id: 'all' }), this.props.getContacts({ id: 'all' })]).then(d => {
                                        ////console.log(d)

                                        Promise.all([ this.props.getRapports({ id: 'all' }), this.props.getFactures({ id: 'all' })]).then(d => {
                                            ////console.log(d)

                                            Promise.all([this.props.getCommandes({ id: 'all' })]).then(d => {
                                                Promise.all([this.props.getOffres({ id: 'all' })]).then(d => {
                                                    ////console.log(d)
                                                    this.setState({ ...this.state, waitAuthCheck: false, load: false })
                                                    return Promise.resolve();
                                                }).catch(err => {
                                                    ////console.log(err)
                                                })
    
                                            }).catch(err => {
                                                ////console.log(err)
                                            }) 
                                           // this.setState({ ...this.state, load: false })
                                          

                                        }).catch(err => {
                                            ////console.log(err)
                                        })



                                    }).catch(err => {
                                        ////console.log(err)
                                    })
                                }).catch(err => {
                                    ////console.log(err)
                                })

                            }).then(d => {
                                ////console.log(d)

                            }).catch(err => {
                                ////console.log(err)
                            })

                        }).catch(err => {
                            ////console.log(err)
                            return reject()
                        })



                    } else { reject() }


                }).then(error => {
                    // history.push('/login')
                    console.error(error)
                    reject();
                })
            }
            else {

                history.push('/login')
                resolve();
            }
        });

     
    })

    render() {
     ////console.log(this.state.load, this.state.waitAuthCheck)
        return this.state.waitAuthCheck && this.state.load ? <FuseSplashScreen /> : <React.Fragment children={this.props.children} />;
    }
}
function mapStateToProps(state) {
    return state

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: userActions.logoutUser,
        setUserData: userActions.setUserData,
        setUserDataFirebase: userActions.setUserDataFirebase,
        showMessage: Actions.showMessage,
        hideMessage: Actions.hideMessage,
        getVariables: Actions.getVariables,
        checkConnection: Actions.checkConnection,
        removeNavigationItem: Actions.removeNavigationItem,
        getConsultants: ActionsContacts.getConsultants,
        getUserData: ActionsContacts.getUserData,
        getSites: ActionsSites.getSites,
        getSitesDropbox: ActionsSites.getSitesDropbox,
        getCommandes: Actions.getCommandes,
        getOffres: Actions.getOffres,
        getFactures: Actions.getFactures,
        getAnalytics: ActionsAnalytics.getAnalytics,
        getObjectifs: ActionsAnalytics.getObjectifs,
        getFormulaires: Actions.getFormulaires,
        getRapports: Actions.getRapports,
        getContacts: Actions.getContacts,
        getClients: Actions.getClients,

        getOldTimeline: TimelineActions.getOldTimeline
    },
        dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
