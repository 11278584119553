import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Actions from 'app/store/actions';
import CreatableSelect from 'react-select/creatable';
import _ from '@lodash'
import ContactDialog from 'app/custom/contacts/ContactDialogContent';
import { FuseUtils } from '@fuse';
type State = {
    options: [{ [string]: string }],
    value: string | void,
};

const createOption = (label: string) => ({
    label,
    value: FuseUtils.generateGUID(),
});

const defaultOptions = [

];
const customStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    }),

}

const reactSelectTheme = error => theme => {
    const errorStyling = error
        ? {
            primary: 'red',
            neutral10: 'red',
            neutral30: 'red',
            neutral20: 'red',
            neutral60: 'red',
        }
        : {};

    return ({
        ...theme,
        menu: base => ({
            ...theme.base,
            zIndex: 100
        }),
        colors: {
            ...theme.colors,
            ...errorStyling,
        },
    });
};
const CreatableAdvanced = (props) => {

    const contacts = useSelector(({ contactsApp }) => contactsApp.contacts.entities);
    const [state, setState] = useState({
        value: null,
        options: [],
        loading: false
    });


    const dispatch = useDispatch();




    useEffect(() => {
        var contact = contacts[props.contact]
        setState({
            value: contact ? { label: contact.name + ' ' + contact.lastName + ' - ' + contact.email, value: contact.id } : null, options: _.compact(_.map(contacts, c => {
                if (props.gestionnaire && c.company.id === props.gestionnaire)
                    return { label: c.name + ' ' + c.lastName + ' - ' + c.email, value: c.id }
                else if (props.client && c.company.id === props.client)
                    return { label: c.name + ' ' + c.lastName + ' - ' + c.email, value: c.id }
                else if (!props.client && !props.gestionnaire)
                    return { label: c.name + ' ' + c.lastName + ' - ' + c.email, value: c.id }
            })), isLoading: false
        })

    }, [props.client, props.gestionnaire, contacts])
    const handleChange = (newValue: any, actionMeta: any) => {






        setState({ value: newValue, options: state.options, isLoading: state.isLoading });
        props.handleChange(newValue ? contacts[newValue.value] : {}, props.id, props.parent)
    };
    const handleCreate = (inputValue: any) => {
        setState({ isLoading: true });
        ////console.log(inputValue)



        const { options } = state;










        setState({ isLoading: true });



        if (inputValue.id) {

            const { options } = state;




            var client = { label: inputValue.name + ' ' + inputValue.lastName + '-' + inputValue.email, value: inputValue.id }



            setState({
                isLoading: false,
                options: [options ? { ...options } : {}, client],
                value: client,
            });
            props.handleChange(client ? inputValue : {}, props.id, props.parent)
        }
        else {
            var data = {
                contact: props.contact,
                select: 'contact',
                client: props.client,
                handleChange: handleCreate,
                email: inputValue
            }
            dispatch(Actions.openNewContactDialog({ ...data }, 'contact'))

        }
    };

    const { isLoading, options, value } = state;

    return (
        <div className='flex flex-col'>
            <span>{'Nom du contact'}</span>
            <CreatableSelect theme={reactSelectTheme(!value || (value && !value.label))}
                className="mb-24 mr-12 min-w-320"
                styles={customStyles}
                label={'Nom du contact'}
                id="email"
                name="email"
                required
                variant="outlined"
                margin="dense"
                helperText="Tapez une adresse email pour ajouter un contact inexistant"
                fullWidth
                errorText={!value ? 'errue' : 'zsz'}
                isClearable
                isCreatable
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={handleChange}
                onCreateOption={handleCreate}
                options={options}
                value={value}
                placeholder={'Sélectionner...'}
            /></div>
    );

}

export default CreatableAdvanced