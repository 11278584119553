import * as Actions from 'app/store/actions';
import _ from '@lodash';
import DataGrid, {
    Column,
    Editing,
    Lookup,
    MasterDetail,
    Paging,
    Selection
} from 'devextreme-react/data-grid';
import deMessages from 'app/custom/translation/fr.json';
import dialog from 'app/store/reducers/fuse/dialog.reducer';
import firebaseService from 'app/services/firebaseService';
import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import TableAppareil from './tableAppareil';
import TableBatterie from './tableBatterie';
import { FuseUtils } from '@fuse';
import { loadMessages, locale } from 'devextreme/localization';
import { useDispatch, useSelector } from 'react-redux';



const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
const App = props => {

////console.log(props)
    const dispatch = useDispatch()

    const ref = useRef('')
    const variables = useSelector(({ custom }) => custom.variables);
    const initialForm = useSelector(({ custom }) => custom.rapports.form);
    const [dataSource, setDataSource] = useState([])
    const [address, setAddress] = useState('')
    const [selectingRowKeys, setSelectingRowKeys] = useState([])
    const [lookup, setLookup] = useState([])
    const [state, setState] = useState({
        batteries: "",
        appareils:""
    })

    useMemo(() => {
        loadMessages(deMessages);
        //////////////console.log(facturesList)
        locale(navigator.language);

    }, [])

    useEffect(() => {
        //////console.log(initialForm.data.batiments, dataSource)
        if (!_.isEqual(dataSource, _.uniqBy(initialForm.data.modernisations, 'id')))
            setDataSource(_.uniqBy(initialForm.data.modernisations, 'id')) }, [initialForm.data.modernisations])

    useEffect(() => {
        async function setBatch() {
            if (!_.isEqual(state.batteries, initialForm.data.batteries) || !_.isEqual(state.appareils, initialForm.data.appareils)) {
                setState({ batteries: initialForm.data.batteries, appareils: initialForm.data.appareils })
               // setLookup(_.spread(_.union)(initialForm.data.batteries, _.filter(initialForm.data.appareils, c => !c.batterieId)))
                //console.log(_.concat(initialForm.data.batteries, _.filter(initialForm.data.appareils, c => !c.batterieId)))
                setLookup(_.concat(initialForm.data.batteries, _.filter(initialForm.data.appareils, c => !c.batterieId)))
    }
        }
    setBatch()
},[initialForm.data.batteries, initialForm.data.appareils])

    function addNewRow() {
        ref.current.instance.addRow();
    }

    function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                hint: 'Ajouter une modernisation',
                text: "Nouvelle modernisation",
                icon: 'plus',
                onClick: addNewRow,

            }
        })
    }
   function getFilteredAppareils(options) {

       //console.log(options, lookup)
        return {
            store: lookup,
            filter: options.data ? ['batimentId', '=', options.data.batimentId] : null
        };
    }
//console.log(lookup)
    //////console.log(dataSource)
    return (<div id="data-grid-demo" className={''}>
        <DataGrid
            width={700}
            dataSource={dataSource}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnHidingEnabled={true}
            rowAlternationEnabled={true}
            columnAutoWidth={true}
            ref={ref}
            onToolbarPreparing={onToolbarPreparing}
            //defaultSelectingRowKeys={selectingRowKeys}
            keyExpr="id"

            showBorders={
                true
            }
            onRowPrepared={(e) => {

                // //////console.log(e)
            }}
            onSelectionChanged={
                (e) => {
                    //////console.log(e)
                    ////props.form.setFieldValue(field.name, e.selectingRowsData)

                }
            }
            onRowInserted={
                (e) => {

                    //////console.log(e.data)
                    e.data.description = capitalize(e.data.description)
                    //////console.log(ref.current.instance.getDataSource())
                    dispatch(Actions.setFormAudit(initialForm.id, { ...initialForm.data, modernisations: _.concat(initialForm.data.modernisations ? initialForm.data.modernisations : [], e.data) }))
                    ////props.form.setFieldValue('batiments', _.concat(initialForm.data.batiments ? initialForm.data.batiments : [], e.data), e.data)
                    /*    if (dataSource.length)
                           firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(_.concat(dataSource, e.data), 'id'))
                       else {
                           firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(e.data, 'id'))
   
                       } */

                }}

            onRowUpdated={
                (e) => {
                    //////console.log(e.data)

                    e.data.description= capitalize(e.data.description)

                    dispatch(Actions.setFormAudit(initialForm.id, {
                        ...initialForm.data, modernisations: _.concat(_.filter(initialForm.data.modernisations, c => {
                            return c.id !== e.data.id
                        }), e.data)
                    }))

                    /*   firebaseService.firestore.collection('variables').doc(field.name).set(
                          _.keyBy(_.concat(_.filter(dataSource, d => {
                              return d.id != e.data.id
                          }), e.data), 'id')) */

                }}

            onRowRemoved={
                (e) => {
                    //////console.log(e)
                    dispatch(Actions.setFormAudit(initialForm.id, {
                        ...initialForm.data, modernisations: _.filter(initialForm.data.modernisations, d => {

                            return d.id !== e.data.id
                        })
                    }))

                }}

            onInitNewRow={async (e) => {

                //////console.log(e)
                //////console.log(ref.current.instance.getDataSource())
                var initialDatas = ref.current.instance.getDataSource()._items
           
              /*   if (props.batterieId) {
                    F = { batterieId: props.batterieId, batimentId: props.data.batimentId}
                } else {
                    F = { appareilId: props.data.id , batimentId: props.data.batimentId}
                } */

                e.data = initialDatas.length ? { ...initialDatas[initialDatas.length - 1], id: FuseUtils.generateGUID() } : { id: FuseUtils.generateGUID(), year: '', description: '', }
            }}
        >
          { /*  <MasterDetail
                enabled={true}
                component={DetailTemplate}
                autoExpandAll={false}
            /> */}
            <Paging enabled={
                false
            }

            /> <Editing mode="batch"
                allowUpdating={
                    true
                }
                allowAdding={
                    false
                }
                allowDeleting={
                    true
                }


            />
            <Column dataField="batimentId" caption="Bâtiment" >
                <Lookup dataSource={initialForm.data.batiments} displayExpr="name" valueExpr="id" />
            </Column>
            <Column dataField={'appareil'} caption={'Appareil/Batterie'} >
                <Lookup dataSource={getFilteredAppareils} displayExpr="name" valueExpr="id" />
            </Column>
          
            <Column dataField={'year'} caption={'Année'} /* editorType="dxTextArea" */ />
            <Column dataField={'description'} caption={'Modernisation'} /* editorType="dxTextArea" */ />

        </DataGrid >

    </div >);
}

export default App;