import React, { useEffect, useCallback, useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import _ from '@lodash'
import { withStyles } from '@material-ui/core/styles';
import { useForm } from '@fuse/hooks';
import FuseUtils from '@fuse/FuseUtils';
import * as Actions from './store/actions';
import * as ActionsAll from 'app/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import reducer from './store/reducers';
import withReducer from 'app/store/withReducer';
import MuiPhoneNumber from 'material-ui-phone-number'
import SelectClients from 'app/custom/components/selects/selectClients'
import SelectContacts from 'app/custom/components/selects/selectContacts'
import SelectVariables from 'app/custom/components/selects/selectVariable'
import history from '@history'

function ltrim(str) {
    if (!str) return str;
    return str.replace(/^\s+/g, '');
}





function rtrim(str) {
    if (!str) return str;
    return str.replace(/\s+$/g, '');
}
const IOSSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
const defaultFormState = {
    site: {
        appareils: [{ qte: 1, type: '' }],
        type_batiment: "",
        address_facturation: {},
        id: '',
        name: '',
        entite: '',
        gestionnaire: '',
        client: '',
        contact: '',
        address: '',
        address2: '',
        zip: '',
        city: '',
        country: 'France',
        notes: '',
        dropbox_id: ''


    }, client: {}
};

function SiteDialog(props) {

    console.log(props)
    const dispatch = useDispatch();
    ////console.log(useSelector(({ sitesApp }) => sitesApp))


    const sites = useSelector(({ sitesApp }) => sitesApp.sites.entities);
    const { form, handleChange, setForm } = useForm({});
    const [country, setCountry] = useState([])
    const [error, setError] = useState({})
    const db = 'site'
    const { t } = useTranslation('sitesApp');

    useEffect(() => {

        setForm({...defaultFormState.site,...props.form})
    }, [props.form]);

    useEffect(() => {
        form.name = form.address2 ? rtrim(ltrim(form.address2)) + ' - ' + rtrim(ltrim(form.address)) + ' ' + rtrim(ltrim(form.zip)) + ' ' + rtrim(ltrim(form.city)) : rtrim(ltrim(form.address)) + ' ' + rtrim(ltrim(form.zip)) + ' ' + rtrim(ltrim(form.city))
        var name = form.name
        if (name && form.name != props.siteDialog.data.name && _.find(_.values(sites), c => c.name.toLowerCase() == name.toLowerCase() && c.id !== form.id)) {
            setError({ address: 'Un site avec la même nom existe déjà !', address2: 'Un site avec la même nom existe déjà !' })
        } else {
            setError({
                ..._.omit(error, 'address2')
            })
        }
        canBeSubmitted()
    }, [form]);


    function canBeSubmitted() {

        return (form.address && form.address.length > 0 && form.zip && form.zip.length === 5 && form.city && form.city.length > 2 && !_.values(error).length && form.client && form.type_batiment && form.contact)

    }

    function handleSubmit(event) {
        event.preventDefault();
        form.name = form.address2 ? rtrim(ltrim(form.address2)) + ' - ' + rtrim(ltrim(form.address)) + ' ' + rtrim(ltrim(form.zip)) + ' ' + rtrim(ltrim(form.city)) : rtrim(ltrim(form.address)) + ' ' + rtrim(ltrim(form.zip)) + ' ' + rtrim(ltrim(form.city))
        form.name = ltrim(rtrim(form.name))
        if (props.siteDialog.type === "new") {
            if (!_.find(sites, { name: form.name })) {
                setError({
                    ..._.omit(error, 'address2')
                })

            } else {

                return setError({ ...error, address2: `${form.name} ${t('NAME_EXIST')}` })
            }

            Promise.all([dispatch(Actions.addSite(form, db))]).then(() => {

                if (props.siteDialog.data.handleChange) {

                    console.log(form)
                    props.siteDialog.data.handleChange(form)
                }
                props.closeComposeDialog();
            })

        }
        else {

            console.log(props.siteDialog.data.name)
            console.log(form.name)

           Promise.all([dispatch(Actions.updateSite(form, db))]).then((r) => {
                console.log(r)
                props.closeComposeDialog()
            }).catch(err => {
                console.log(err)
            }) 
          /*  if (props.siteDialog.data.name != form.name)
                Promise.all([dispatch(Actions.renameSite(form.name, db, props.siteDialog.data.name))]).then((r) => {
                    console.log(r)
                }) 
           else
                dispatch(Actions.updateSite(form, db));
            props.closeComposeDialog();  */
        }
        if (!props.siteDialog.data.handleChange)
            history.push('/sites/all')

    }

    function handleRemove() {
        dispatch(Actions.removeSite(form.id, db));
        props.closeComposeDialog();
    }
    function handleChangeCountry(val) {

        for (var i = 0; i < CountryRegionData.length; i += 1) {

            if (CountryRegionData[i][0] === val) {

                setForm(_.set({ ...form }, 'country', val))
                setCountry(CountryRegionData[i])

            }
        }

    }
    function handleChangeContact(val, id) {
        //////////console.log(val, key, id)
        return setForm({ ...form, [id]: val })


    }
    function handleChangeType(val, id) {
        ////console.log(val, id)
        return setForm({ ...form, [id]: val })


    }
    function handleChangePhone(ev, name) {

        setForm({ ...form, [name]: ev })

    }
    function handleChangeName(ev) {
        console.log(ev)
        if (!_.find(sites, { name: ev })) {
            setError({
                ..._.omit(error, 'name')
            })
            setForm({
                ...form, name: ev
            })
        } else setError({ ...error, name: t('NAME_EXIST') })
    }
    function handleChangeCompany(val, id, key) {

        if (val) {
            return setForm({ ...form, [key]: val })
        } else {
            return setForm({ ...form, [key]: '' })
        }


    }
    /*  function handleChangeCompany(val) {
         //////////console.log(val)
         setForm({ ...form, 'client': val })
     } */
    function handleChangeTypeAppareil(val, id, index) {
        ////////////////////////console.log(val, id)

        return setForm({
            ...form, appareils: _.map(form.appareils, (v, j) => {
                if (j === index) {
                    v.type = val
                }
                return v
            })

        })

    }
    ////console.log(form)
    ////console.log(props)
    return (

        <React.Fragment>
            <AppBar position="static" elevation={1}>
                <Toolbar className="flex w-full">
                    <Typography variant="subtitle1" color="inherit">
                        {props.siteDialog.type === 'new' && db === 'site' ? t('NEW_SITE') : db === 'site' ? t('EDIT_SITE') : props.siteDialog.type === 'new' ? t('NEW_CLIENT') : t('EDIT_CLIENT')}
                    </Typography>
                </Toolbar>

            </AppBar>
            <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden">
                <DialogContent classes={{ root: "p-24" }}>

                    <Typography variant='h6' variant="caption">{t('INFOS')}</Typography>
                    <Divider className="mb-24" />
                    {/*   <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">domain</Icon>
                        </div>

                        <TextField
                            error={error.name ? true : false}
                            helperText={error.name ? t('NAME_EXIST') : ''}
                            className="mb-24"
                            label={t('NAME')}
                            autoFocus
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={handleChangeName}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>  */}

                    {/*  <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">home</Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label={t('NOM DU SITE')}

                            id="name"
                            name="name"
                            disabled={props.siteDialog.type === 'edit'? true:false}
                            value={form.name}
                            error={error.name || !form.name? true : false}
                            onChange={(e) => {
                                console.log(e.currentTarget.value)
                                var name = e.currentTarget.value
                                console.log(name)
                                if (name) {
                                    //name.replace(/\\|\//g, ' ')
                                    //name = name.replace(/\//g, " ")
                                    name = name.replace(/'\//g, "\\'")
                                    

                                    if (name.length > 40)
                                        name = name.substring(0, 40);
                                }
                            
                                
                                
                               // setForm({ ...form, name: name })
                                console.log(name)
                                handleChangeName(name)

                            }}
                            helperText="30 caractères maximum. Attention, le nom ne pourra plus être modifié après l'enregistrement "
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div> */}
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        { /* <TextField

                            helperText={t('OBJECT_HELPER')}
                            className="mb-24"
                            label={t('OBJECT')}
                            autoFocus
                            id="object"
                            name="object"
                            value={form.object}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        /> */}
                        <SelectVariables handleChange={handleChangeType} label={t('TYPE_DE_BATIMENT')} value={form.type_batiment} id={'type_batiment'} />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <SelectClients handleChange={handleChangeCompany} company={form.client && form.client.id ? form.client.id : form.client} label={t('CLIENT')} id={'client'} parent={'client'} />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <SelectClients handleChange={handleChangeCompany} company={form.gestionnaire && form.gestionnaire.id ? form.gestionnaire.id : form.gestionnaire} label={t('Gestionnaire')} id={'gestionnaire'} parent={'gestionnaire'} />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <SelectContacts client={form.client && form.client.id ? form.client.id : form.client} gestionnaire={form.gestionnaire && form.gestionnaire.id ? form.gestionnaire.id : form.gestionnaire} contact={form.contact && form.contact.id ? form.contact.id : form.contact} handleChange={handleChangeContact} id={'contact'} parent={''} label={t('CONTACT')} />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">settings</Icon>
                        </div>

                        <div className="flex flex-col">
                            {_.map(form.appareils, (val, i) => {
                                //////////////////////console.log(i)
                                return (<div key={i} className="flex">


                                    <TextField
                                        // customInput={TextField}
                                        name={'nombre_appareil'}
                                        key={'nombre_appareil'}
                                        id={'nombre_appareil'}
                                        label={"Nombre d'appareil"}
                                        InputLabelProps={{ shrink: true }}
                                        className="mt-12 mb-24 mr-12 max-w-224"


                                        onChange={(e) => {
                                            ////////////////////console.log(e)
                                            setForm({
                                                ...form, appareils: _.map(form.appareils, (v, j) => {
                                                    if (j === i) {
                                                        v.qte = Number(e.target.value)
                                                    }
                                                    return v
                                                })

                                            })
                                        }}
                                        value={val.qte}
                                        error={!val.qte ? true : false}
                                        required
                                        type={'number'}
                                        // thousandSeparator={' '} decimalSeparator={','} suffix={''}
                                        variant='outlined'
                                        fullWidth

                                    />
                                    <SelectVariables handleChange={handleChangeTypeAppareil} label={"Type d'appareil"} value={val.type ? val.type : ''} id={'type_appareil'} index={i} />




                                    {i == _.keys(form.appareils).length - 1 ? <Icon className="mt-12 mb-24 mr-12 " onClick={() => {
                                        form.appareils.push({ qte: 1, type: '', prix: '' })
                                        setForm({ ...form })
                                    }}>add_circle</Icon> : <Icon className="mt-12 mb-24 mr-12 " onClick={() => {

                                        form.appareils.splice(i, 1)
                                        setForm({ ...form })
                                    }}>remove_circle</Icon>}

                                </div>)
                            })}
                        </div>
                    </div>
                    <Typography variant='h6' variant="caption">{t('ADDRESS')}</Typography>
                    <Divider className="mb-24" />
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">home</Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label={t('ADDRESS')}

                            id="address"
                            name="address"
                            value={form.address}
                            error={error.address || !form.address ? true : false}
                            onChange={(e) => {
                                if (e.currentTarget.value)
                                    e.currentTarget.value= e.currentTarget.value.replace(/\/\\/g, e.currentTarget.value)
                                handleChange(e)
                            }}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label={t('ADDRESS2')}
                            error={error.address2 ? true : false}
                            id="address2"
                            name="address2"
                            value={form.address2}
                            onChange={(e) => {
                                console.log(e.currentTarget.name)
                                console.log(e.currentTarget.value)
                                if (e.currentTarget.value)
                                    e.currentTarget.value = e.currentTarget.value.replace(/\/\\/g, e.currentTarget.value)
                                handleChange(e)
                            }}
                            variant="outlined"

                            fullWidth
                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label={t('ZIP')}
                            error={form.zip ? false : true}
                            id="zip"
                            name="zip"
                            value={form.zip}
                            onChange={(e) => {
                                console.log(e)
                                console.log(e.currentTarget.name)
                                console.log(e.currentTarget.value)
                                if (e.currentTarget.value)
                                    e.currentTarget.value = e.currentTarget.value.replace(/\/\\/g, e.currentTarget.value)
                                handleChange(e)
                            }}
                            variant="outlined"
                            required
                            fullWidth
                        />

                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>


                        <TextField
                            className="mb-24"
                            label={t('CITY')}
                            error={form.city ? false : true}
                            id="city"
                            name="city"
                            value={form.city}
                            onChange={(e) => {
                                if (e.currentTarget.value)
                                    e.currentTarget.value = e.currentTarget.value.replace(/\/\\/g, e.currentTarget.value)
                                handleChange(e)
                            }}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>
                    {/*https://github.com/country-regions/react-country-region-selector/issues/10 */}

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>


                        <TextField
                            className="mb-24"
                            label={t('COUNTRY')}
                            select
                            id="country"
                            name="country"
                            value={country}
                            onChange={(val) => {

                                handleChangeCountry(val.target.value[0])
                            }}
                            variant="outlined"
                            required
                            fullWidth
                        >
                            {CountryRegionData.map((option, index) => {
                                // //////////console.log(option, index)
                                return (

                                    < MenuItem key={option[0]} value={option} >
                                        {option[0]}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </div>


                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">note</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label="Notes"
                            id="notes"
                            name="notes"
                            value={form.notes}
                            onChange={handleChange}
                            variant="outlined"
                            multiline
                            rows={5}
                            fullWidth
                        />
                    </div>
                </DialogContent>

                <DialogActions className="justify-between p-8">
                    <div className="flex flex-end px-16">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={!canBeSubmitted()}
                        >
                            {t('SAVE')}
                        </Button>
                    </div>

                </DialogActions>
            </form></React.Fragment >

    )
}

export default withReducer('sitesApp', reducer)(SiteDialog);;
