import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: {},
dropbox:[],
    searchText: '',
    selectedSitesIds: [],
    routeParams: {},
    siteDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
        db: null

    },

};

const sitesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_SITES_DROPBOX:
            {
                return {
                    ...state,
                    dropbox: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.GET_SITES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }

        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_SITES:
            {

                const siteId = action.siteId;

                let selectedSiteIds = [...state.selectedSiteIds];

                if (selectedSiteIds.find(id => id === siteId) !== undefined) {
                    selectedSiteIds = selectedSiteIds.filter(id => id !== siteId);
                }
                else {
                    selectedSiteIds = [...selectedSiteIds, siteId];
                }

                return {
                    ...state,
                    selectedSiteIds: selectedSiteIds
                };
            }
        case Actions.SELECT_ALL_SITES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedSiteIds = arr.map(site => site.id);

                return {
                    ...state,
                    selectedSiteIds: selectedSiteIds
                };
            }
        case Actions.DESELECT_ALL_SITES:
            {
                return {
                    ...state,
                    selectedSiteIds: []
                };
            }
        case Actions.OPEN_NEW_SITE_DIALOG:
            {

                console.group({
                    type: 'new',
                    props: {
                        open: true
                    },
                    data: action.data,
                    db: action.db

                })
                return {
                    ...state,

                    siteDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db

                    }
                };
            }
        case Actions.CLOSE_NEW_SITE_DIALOG:
            {
                return {
                    ...state,
                    siteDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null

                    }
                };
            }
        case Actions.OPEN_EDIT_SITE_DIALOG:
            {
                return {
                    ...state,
                    siteDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db

                    }
                };
            }
        case Actions.CLOSE_EDIT_SITE_DIALOG:
            {
                return {
                    ...state,
                    siteDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null

                    }
                };
            }

        default:
            {
                return state;
            }
    }
};

export default sitesReducer;
