import React, { useEffect, useCallback } from 'react';
import { Dialog, } from '@material-ui/core';
import { useForm } from '@fuse/hooks';
import FuseUtils from '@fuse/FuseUtils';
import * as Actions from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ContactDialogContent from './ContactDialogContent'
const defaultFormState = {
    id: '',
    name: '',
    lastName: '',
    avatar: 'assets/images/avatars/profile.jpg',
    nickname: '',
    company: '',
    jobTitle: '',
    email: '',
    phone: '',
    address: '',
    birthday: '',
    notes: ''
};

function ContactDialog(props) {
    const dispatch = useDispatch();
    const contactDialog = useSelector(({ contactsApp }) => contactsApp.contacts.contactDialog);

    const { form, handleChange, setForm } = useForm(defaultFormState);
    const { t } = useTranslation('contactsApp');
    ////console.log(contactDialog)
    const initDialog = useCallback(
        () => {
            /**
             * Dialog type: 'edit'
             */
            if (contactDialog.type === 'edit' && contactDialog.data) {
                setForm({ ...contactDialog.data });
            }

            /**
             * Dialog type: 'new'
             */
            if (contactDialog.type === 'new') {
                setForm({
                    ...defaultFormState,
                    ...contactDialog.data,
                    id: FuseUtils.generateGUID()
                });
            }
        },
        [contactDialog.data, contactDialog.type, setForm],
    );

    useEffect(() => {
        /**
         * After Dialog Open
         */
        if (contactDialog.props.open) {
            initDialog();
        }

    }, [contactDialog.props.open, initDialog]);

    function closeComposeDialog() {
        contactDialog.type === 'edit' ? dispatch(Actions.closeEditContactDialog()) : dispatch(Actions.closeNewContactDialog());
    }

    function canBeSubmitted() {
        return (
            form.name.length > 0
        );
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (contactDialog.type === 'new') {
            dispatch(Actions.addContact(form));
        }
        else {
            dispatch(Actions.updateContact(form));
        }
        closeComposeDialog();
    }

    function handleRemove() {
        dispatch(Actions.removeContact(form.id));
        closeComposeDialog();
    }

    return (
        <Dialog
            classes={{
                paper: "m-24"
            }}
            {...contactDialog.props}

            onClose={closeComposeDialog}
            fullWidth
            maxWidth="md"
        >
            <ContactDialogContent closeComposeDialog={closeComposeDialog} />
        </Dialog>
    );
}

export default ContactDialog;
