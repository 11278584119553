import * as Actions from 'app/store/actions';
import _ from '@lodash';
import DataGrid, {
    Column,
    Editing,
    Lookup,
    MasterDetail,
    Paging,
    Selection,
    RequiredRule,Position,Button,
  Form,Popup,
    FilterRow,
    Scrolling
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import deMessages from 'app/custom/translation/fr.json';
import dialog from 'app/store/reducers/fuse/dialog.reducer';
import firebaseService from 'app/services/firebaseService';
import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
    useCallback
} from 'react';
import { DropDownBox, TreeView } from "devextreme-react";
/* import TableAppareil fro../tableAppareileil';
import TableBatterie from '../tableBatterie'; */
import { FuseUtils } from '@fuse';
import { loadMessages, locale } from 'devextreme/localization';
import { useDispatch, useSelector } from 'react-redux';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from "devextreme/data/custom_store";
import DataSource from 'devextreme/data/data_source';
import SelectVariables from 'app/custom/components/selects/selectVariable';
import App3 from './dropdown_appareils'
import Amelioration from './amelioration_list'
var memo = ''

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
const App = props => {

////console.log('Annexces C',props)
    const dispatch = useDispatch()

    const ref = useRef('')
    const variables = useSelector(({ custom }) => custom.variables);
    const initialForm = useSelector(({ custom }) => custom.rapports.form);
    const [dataSource, setDataSource] = useState([])
    const [address, setAddress] = useState('')
    const [selectingRowKeys, setSelectingRowKeys] = useState([])
    const [lookup, setLookup] = useState([])
    const [load, setLoad] = useState(false)
    const [state, setState] = useState({
        batteries: [],
        appareils: [],
        batiments: [], 
        full:[]
    })

    //const [variables, setVariables] = useState([])

    useMemo(() => {
        loadMessages(deMessages);
        ////////////////////console.log(facturesList)
        locale(navigator.language);

    }, [])
/* useEffect(()=>{
  firebaseService.firestore.collection('annexeF').get().then(r => {

            console.log('load')
            var source = []
            if (r.size > 0)
                r.forEach(d => {
                    source.push(d.data())
                })
         
            setVariables(source)
        })
},[]) */
    useEffect(() => {
        ////////////console.log(initialForm.data.batiments, dataSource)
        if (!_.isEqual(dataSource, _.uniqBy(initialForm.data.annexeF, 'id')))
            setDataSource(_.uniqBy(initialForm.data.annexeF, 'id'))
    }, [initialForm.data.annexeF])

    useEffect(() => {
        async function setBatch() {
            if (!_.isEqual(state.batiments, initialForm.data.batiments) || !_.isEqual(state.batteries, initialForm.data.batteries) || !_.isEqual(state.appareils, initialForm.data.appareils)) {

                var full = [_.map(initialForm.data.batiments, c => {
                    return { ...c, expanded: true, isBatiment: true, isGroup: true}
                }), _.map(initialForm.data.batteries, c => {
                    return { ...c, parentId: c.batimentId, expanded: true, isBatterie: true, isGroup:true}
                }), _.map(initialForm.data.appareils, c => {
                    return { ...c, parentId: c.batterieId ? c.batterieId : c.batimentId, selected: true, isAppareil: true,
                        isGroup: false
                }
                })] 

                setState({
                    batteries: initialForm.data.batteries, appareils: _.map(initialForm.data.appareils, c => {
                        return {
                            ...c, batimentName: _.find(initialForm.data.batiments, d => {
                                return d.id == c.batimentId
                            }) ? _.find(initialForm.data.batiments, d => {
                                return d.id == c.batimentId
                            }).name : '', batterieName: _.find(initialForm.data.batteries, d => {
                                return d.id == c.batterieId
                            }) ? _.find(initialForm.data.batteries, d => {
                                return d.id == c.batterieId
                            }).name : ''
                        }
                    }), full: _.spread(_.union)(full) })
           
               // setLookup(_.spread(_.union)(initialForm.data.batteries, _.filter(initialForm.data.appareils, c => !c.batterieId)))
                ////////console.log(_.concat(initialForm.data.batteries, _.filter(initialForm.data.appareils, c => !c.batterieId)))
                setLookup(_.concat(initialForm.data.batteries, _.filter(initialForm.data.appareils, c => !c.batterieId)))

             
    }
        }
    setBatch()
    }, [initialForm.data.batteries, initialForm.data.appareils,  initialForm.data.batiments])

     function addNewRow() {
        ref.current.instance.addRow();
    }

    function onToolbarPreparing(e) {
        ////console.log('on toolbar preparing')
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                hint: 'Ajouter une amélioration',
                text: "Nouvelle amélioration",
                icon: 'plus',
                onClick: addNewRow,

            }
        })
    } 
   function getFilteredAppareils(options) {

        return {
            store: lookup,
            filter: options.data ? ['batimentId', '=', options.data.batimentId] : null
        };
    }
    function setCellAppareils(newData, value, currentRowData) {
        //////console.log(newData, value, currentRowData)
      
        newData.qte = 0
    }
    function setCellValue(newData, value, currentRowData) {
        //////console.log(newData, value, currentRowData)
        newData.pu = value;
        newData.pt = currentRowData.appareils.length * value;
        newData.appareils = currentRowData.appareils;
    }

    const saleAmountFormat = { style: 'currency', currency: 'EUR', useGrouping: true, minimumSignificantDigits: 3 };
   
    ////////////console.log(dataSource)
    return (<div id="data-grid-demo" className={''}>
        <DataGrid
            width='100%'
            dataSource={dataSource}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnHidingEnabled={true}
            rowAlternationEnabled={true}
            columnAutoWidth={true}
            wordWrapEnabled={true}
            onRowValidating={(e) => {
                
            
                    
const data = {...e.oldData,...e.newData}
                if (data.appareils && data.appareils.length && data.description && data.localisation && data.pu)
                    return e.isValid = true
                return e.isValid =false
                        /*    if (!e.value)
                               return e.isValid = false
                           return true */
                    
                }
            }
            ref={ref}
           onToolbarPreparing={onToolbarPreparing}
            //defaultSelectingRowKeys={selectingRowKeys}
            keyExpr="id"

            onRowPrepared={(e) => {

                // ////////////console.log(e)
            }}
            onSelectionChanged={
                (e) => {
                    ////////////console.log(e)
                    ////props.form.setFieldValue(field.name, e.selectingRowsData)

                }
            }
            onRowInserted={
                (e) => {

              console.log(e)
                    e.data.pu = Number(e.data.pu)
                    e.data.pt = e.data.appareils.length * Number(e.data.pu)
                    e.data.description = capitalize(e.data.description)
                    memo = { ...e.data, id: FuseUtils.generateGUID(), description: ''}
                    dispatch(Actions.setFormAudit(initialForm.id, { ...initialForm.data, annexeF: _.concat(initialForm.data.annexeF ? initialForm.data.annexeF : [], e.data) }))
                    ////props.form.setFieldValue('batiments', _.concat(initialForm.data.batiments ? initialForm.data.batiments : [], e.data), e.data)
                    /*    if (dataSource.length)
                           firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(_.concat(dataSource, e.data), 'id'))
                       else {
                           firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(e.data, 'id'))
   
                       } */

                }}

            onRowUpdated={
                (e) => {
                  console.log(e)
                    e.data.pu=    Number(e.data.pu)
    e.data.pt = e.data.appareils.length * Number(e.data.pu)
                    e.data.description = capitalize(e.data.description)
                    memo = { ...e.data, id: FuseUtils.generateGUID(), description: '' }
                    dispatch(Actions.setFormAudit(initialForm.id, {
                        ...initialForm.data, annexeF: _.concat(_.filter(initialForm.data.annexeF, c => {
                            return c.id !== e.data.id
                        }), e.data)
                    }))

                    /*   firebaseService.firestore.collection('variables').doc(field.name).set(
                          _.keyBy(_.concat(_.filter(dataSource, d => {
                              return d.id != e.data.id
                          }), e.data), 'id')) */

                }}

            onRowRemoved={
                (e) => {
                    ////////////console.log(e)
                    dispatch(Actions.setFormAudit(initialForm.id, {
                        ...initialForm.data, annexeF: _.filter(initialForm.data.annexeF, d => {

                            return d.id !== e.data.id
                        })
                    }))

                }}
            

            onInitNewRow={async (e) => {

               ////console.log(e)
                ////////////console.log(ref.current.instance.getDataSource())
               
           
                /*   if (props.batterieId) {
                      F = { batterieId: props.batterieId, batimentId: props.data.batimentId}
                  } else {
                      F = { appareilId: props.data.id , batimentId: props.data.batimentId}
                  } */
                if (!memo)
                    memo = { id: FuseUtils.generateGUID(), pu: '', description: '',  appareils: [] , localisation:''}
              

                e.data = memo
            }}

            onEditorPreparing={(e) => {

               /*  if (e.parentType == 'dataRow' && e.dataField == 'description') {
                    e.editorName = "dxTextArea";
                    e.editorOptions.height = 300;
                } */
            }}

     
        >
         
            <Paging enabled={
                false
            }

            /> <Editing mode="popup"
                allowUpdating={
                    true
                }
                allowAdding={
                    false
                }
                allowDeleting={
                    true
                }


            >  
            
            
            
            
            
            </Editing>
             <Column type="buttons">
              { /*      <Button name="save" cssClass="my-class" /> */}
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
         {/*    <Column dataField={'amelioration'} caption={"Type d'amélioration"}  editCellComponent={(e) => {
                ////console.log(e)
                return <Amelioration  {...e} values={e.data.data.amelioration} handleChange={(f) => {

                ////console.log(e.data, f)
                 e.data.setValue(f)
                }}/>
            }} cellRender={(e) => {
                ////console.log(e)
////console.log(e.value)
                return e.value
            }}    >  </Column> */}
            <Column dataField={'localisation'} caption={'Localisation'} /* editorType="dxTextArea" */ editCellComponent={(e) => {
            console.log(e)
        
                return <SelectVariables id={'defaut_localisation'} label={'Localisation'} handleChange={(f) => {

                ////console.log(e.data)
           setLoad(true)
                    e.data.setValue(f)
           setTimeout(() => {
               setLoad(false)  
           }, 1000);
                    
         
                }} value={e.data.data.localisation} />
            }} cellRender={(e) => {
                  ////console.log(e)
                return e.value
                }}
                />  
              <Column dataField={'description'} caption={'description'} /* editorType="dxTextArea" */ editCellComponent={(e) => {
              console.log(e)
                return <SelectVariables id={'description_annexeF'} label={'Description'} localisation={e.data.data.localisation} handleChange={(f) => {

               console.log(f)
           
                 e.data.setValue(f)
                }} value={e.data.data.description} />
            }} cellRender={(e) => {
                  ////console.log(e)
                return e.value
                }}
                />
            <Column dataField={'appareils'} caption={"Appareils concernés"} /* editorType="dxTextArea" */ editCellComponent={(e) => {
              console.log(e)
                return <App3 appareils={state.appareils} appareils_full={state.full} {...e} values={e.data.data.appareils}/>
            }}  cellRender={(e) => { 
                ////console.log(e)
                return _.map(e.value, c => {
                    return c.name
                }).join(',')
                }}    >  </Column>
            <Column dataField={'pu'} caption={'Prix/Appareil'} dataType={'number'} format={saleAmountFormat} /* setCellValue={setCellValue} */ alignment="right">  <RequiredRule /> </Column>
            <Column dataField={'pt'} caption={'Prix Total'} dataType={'number'} format={saleAmountFormat} allowEditing={false} alignment="right"/> 
                        <Column dataField={'option'} caption={'Option'} dataType={'boolean'}  allowEditing={true} cellValue={(e) => {
                ////console.log(e.value)
                if (e.value)
                    return true
                return false
            }} ></Column>
                          <Column dataField={'variante'} caption={'Variante'} dataType={'boolean'}  allowEditing={true} cellValue={(e) => {
                ////console.log(e.value)
                if (e.value)
                    return true
                return false
            }} ></Column>

        </DataGrid >

    </div >);
}
const gridColumns = ["batimentName", "batterieName", "name"];


  

export default App;