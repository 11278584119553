import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import React from 'react'
import history from '@history'
import moment from 'moment'
import 'moment/locale/fr'
export const GET_RAPPORTS = '[RAPPORTS APP] GET RAPPORTS';
export const UPDATE_RAPPORTS = '[RAPPORTS APP] UPDATE RAPPORTS';
export const OPEN_NEW_RAPPORT_DIALOG = '[RAPPORTS APP] OPEN NEW RAPPORT DIALOG';
export const CLOSE_NEW_RAPPORT_DIALOG = '[RAPPORTS APP] CLOSE NEW RAPPORT DIALOG';
export const OPEN_EDIT_RAPPORT_DIALOG = '[RAPPORTS APP] OPEN EDIT RAPPORT DIALOG';
export const CLOSE_EDIT_RAPPORT_DIALOG = '[RAPPORTS APP] CLOSE EDIT RAPPORT DIALOG';
export const OPEN_NEW_FORM_DIALOG = '[RAPPORTS APP] OPEN NEW FORM DIALOG';
export const CLOSE_NEW_FORM_DIALOG = '[RAPPORTS APP] CLOSE NEW FORM DIALOG';
export const OPEN_EDIT_FORM_DIALOG = '[RAPPORTS APP] OPEN EDIT FORM DIALOG';
export const CLOSE_EDIT_FORM_DIALOG = '[RAPPORTS APP] CLOSE EDIT FORM DIALOG';
export const SET_FORM = '[RAPPORTS APP] SET FORM';
export const SET_REGLEMENTATION = '[RAPPORTS APP] SET REGLEMENTATION';
export function getRapports() {


    return (dispatch, getState) => {
        const { navigation } = getState().fuse;
        firebaseService.firestore.collection('rapports').onSnapshot(r => {
            var f = {}
            if (r.size > 0) {
                r.forEach(doc => {
                    f[doc.id] = { ...doc.data(), id: doc.id }

                    //console.log(doc.data(), doc.id)
                })
                var sitesItem = _.find(navigation, { id: 'rapports' })
                sitesItem.badge.title = _.values(f).length
                dispatch(Actions.updateNavigationItem('rapports', sitesItem));

                dispatch({
                    type: GET_RAPPORTS,
                    payload: f,

                })
            }
        })

    }

}
export function setForm(form) {

    return (dispatch, getState) => {


    }

}

export function openNewRapportDialog(data, type) {
    //console.log(data, type)
    return {
        type: OPEN_NEW_RAPPORT_DIALOG,
        data: data, db: type
    }
}

export function closeNewRapportDialog() {
    return {
        type: CLOSE_NEW_RAPPORT_DIALOG
    }
}

export function openEditRapportDialog(data, type) {
    //////console.log(data, type)
    return {
        type: OPEN_EDIT_RAPPORT_DIALOG,
        data: data, db: type
    }
}


export function closeEditRapportDialog() {
    return {
        type: CLOSE_EDIT_RAPPORT_DIALOG
    }
}

export function openNewFormDialog(data, items, paragraphe, appareil, batiment) {
    //console.log(data, type)
    return {
        type: OPEN_NEW_FORM_DIALOG,
        data, items, paragraphe, appareil, batiment
    }
}

export function closeNewFormDialog() {
    return {
        type: CLOSE_NEW_FORM_DIALOG
    }
}

export function openEditFormDialog(data, items, paragraphe, appareil, batiment) {
    //////console.log(data, type)
    return {
        type: OPEN_EDIT_FORM_DIALOG,
        data, items, paragraphe, appareil, batiment
    }
}


export function closeEditFormDialog() {
    return {
        type: CLOSE_EDIT_FORM_DIALOG
    }
}

export function addRapport(form) {

}

export function editRapport(form) {

}

export function setFormAudit(id, form) {
    return (dispatch, getState) => {
console.log(id, form)

        dispatch({
            type: SET_FORM,
            payload: form,
            id: id
        })

    }

}

export function getReglementation() {
    return (dispatch, getState) => {
 firebaseService.firestore.collection('reglementation').onSnapshot(r => {
            var f = {}
            if (r.size > 0) {
                r.forEach(doc => {
                    f[doc.id] = { ...doc.data(), id: doc.id }

                    //console.log(doc.data(), doc.id)
                })

console.log('reglementation',f)
                dispatch({
            type: SET_REGLEMENTATION,
            payload: f,
           
        })
            } else {
              console.log('reglementation',f)
                dispatch({
            type: SET_REGLEMENTATION,
            payload: f,
           
        })  
            }

        })

      

    }

}