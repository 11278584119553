import React from 'react';
import { DropDownBox, TreeView } from 'devextreme-react';
import DataGrid, { Selection, Paging, FilterRow, Scrolling, Column, Editing, Lookup, RequiredRule, Position,Button
  ,Form,Popup } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import _ from '@lodash'
import ArrayStore from 'devextreme/data/array_store';
import TreeList from './treeList'
import { Item } from 'devextreme-react/form';
import SelectVariables from 'app/custom/components/selects/selectVariable';
const gridColumns = ['batimentName', 'batterieName', 'name'];
const list =[{ id: 'decret_2008–13258', name:'Décret 2008 – 1325 du 15 décembre 2008. Arrêtés Divers'},{id:'articlesAS',name:'SÉCURITÉ BATIMENTS IGH/ERP et ARTICLES AS'},{id:'urbanisme_habitat', name:'LOI URBANISME ET HABITAT DU 2 JUILLET 2003'}]
class App extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props)
        this.treeViewRef = React.createRef();
      
        this.gridDataSource = new ArrayStore({
            data: [], key: 'id'
        })
        this.state = {
           lookup:[],
            gridBoxValue: {},
        };

        this.getFilteredAppareils = this.getFilteredAppareils.bind(this)
        this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
        this.dataGrid_onSelectionChanged = this.dataGrid_onSelectionChanged.bind(this);
       
        this.dataGridRender = this.dataGridRender.bind(this);
    }
    /*  makeAsyncDataSource(jsonFile) {
         return new CustomStore({
             loadMode: 'processed',
             key: 'id',
             load: function () {
                 return _.keyBy(this.props.appareils, 'id')
             }
         });
     } */
    componentWillReceiveProps(p, o) {
        console.log(p)
        console.log(o)
    }
    setStateValue(rowData, value) {
        console.log(rowData, value)
        rowData.type = null;
        this.defaultSetCellValue(rowData, value);
    }
  getFilteredAppareils(options) {

    return {
        store: this.state.lookup,
        filter: options.data ? ['type', '=', options.data.type] : null
    };
}
    get treeView() {
        return this.treeViewRef.current.instance;
    }
    render() {
console.log(this.state.gridBoxValue)
        return (
         
               

               
                        <DropDownBox
                        value={_.find(this.state.gridBoxValue,{id:this.state.gridBoxValue.type }) ?_.find(this.state.gridBoxValue,{id:this.state.gridBoxValue.type }).name:''}
                           //valueExpr="id"
                            deferRendering={false}
                           // displayExpr="name"
                            placeholder="Sélectionnez le type d'amélioration"
                            showClearButton={true}
                            dataSource={this.gridDataSource}
                            onValueChanged={this.syncDataGridSelection}
                            contentRender={this.dataGridRender}
                        />
                  
       

           

        );
    }
setForm(e){
    console.log(e)
    return ( <Form>
              <Item itemType="group" colCount={1}>
             <Item itemType="group" >
            <Item
                  dataField="type"
         
                  colSpan={2}
            />
                  
                 </Item>
                      <Item itemType="group" colCount={1} colSpan={2}>
                            {e.data.type == 'articlesAS' ? <Item
                                            dataField="subType"
                                    
                                            colSpan={2}
                                        />: e.data.type == 'decret_2008–13258'?
                                        <Item
                                            dataField="localisation"
                                  
                                            colSpan={2}
                                        />:''}
                 
             </Item>
            <Item itemType="group" colCount={2} colSpan={2} >
                <Item
                  dataField="description"
                  editorType="dxTextArea"
                  colSpan={2}
                  editorOptions={{ height: 100 }} />
                 
                    </Item> 
                          </Item> 
                </Form> )
}

    dataGridRender() {
        return (
            <DataGrid
                width={'100%'}
                dataSource={this.gridDataSource}
                    wordWrapEnabled={true}
                      focusedRowEnabled={true}
       keyExpr="id"
   selection={{ mode: 'single' ,showCheckBoxesMode:"always"}}
                hoverStateEnabled={true}
                selectedRowKeys={_.map(this.state.gridBoxValue, c => c.id)}
                onSelectionChanged={this.dataGrid_onSelectionChanged}
                onRowInserting={(e)=>{
                    console.log(e)
                }}
                 onRowInserted={(e)=>{
                    console.log(e)
                }}

                 onRowUpdated={
                (e) => {
                    console.log(e)
                }}
                
                >
                 <FilterRow visible={true} />
               
                <Scrolling mode="virtual" />
                <Editing mode="popup"
                    allowUpdating={
                        true
                    }
                    allowAdding={
                       true
                    }
                    allowDeleting={
                        true
                    }


                >
                   <Popup title="Employee Info" showTitle={false} width={700} height={525}>
              <Position my="top" at="top" of={window} />
            </Popup>
           {this.setForm}
                </Editing>
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
 <Column type="buttons">
                    <Button name="save" cssClass="my-class" />
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
                <Column dataField={'type'} caption={'Référence'} /* editorType="dxTextArea" */ 
                    setCellValue={this.setStateValue}>
                     <RequiredRule />
                    <Lookup dataSource={[{ id: 'decret_2008–13258', name:'Décret 2008 – 1325 du 15 décembre 2008. Arrêtés Divers'},{id:'articlesAS',name:'SÉCURITÉ BATIMENTS IGH/ERP et ARTICLES AS'},{id:'urbanisme_habitat', name:'LOI URBANISME ET HABITAT DU 2 JUILLET 2003'}]} displayExpr="name" valueExpr="id" />
                </Column>
                <Column dataField={'categorie'} caption={'Catégorie'} /* editorType="dxTextArea" */     >

                </Column>
             
                <Column dataField={'description'} caption={'Description'}  editorType="dxTextArea"  >
 <RequiredRule />
                </Column>

            </DataGrid>
        );
    }

    





    syncDataGridSelection(e) {
        //this.props.data.setValue(e.value ? e.value : [])
        this.setState({
            gridBoxValue: e.value ? e.value : []
        });
    }
  

    dataGrid_onSelectionChanged(e) {
console.log(e)
        this.props.handleChange(e.selectedRowsData[0])
        this.setState({
            gridBoxValue: e.selectedRowsData[0]
        });

    }



}



export default App;