import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: {},

    searchText: '',
    selectedLeadsIds: [],
    routeParams: {},
    leadDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
        db: null

    },

};

const leadsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_LEADS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }

        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_LEADS:
            {

                const leadId = action.leadId;

                let selectedLeadIds = [...state.selectedLeadIds];

                if (selectedLeadIds.find(id => id === leadId) !== undefined) {
                    selectedLeadIds = selectedLeadIds.filter(id => id !== leadId);
                }
                else {
                    selectedLeadIds = [...selectedLeadIds, leadId];
                }

                return {
                    ...state,
                    selectedLeadIds: selectedLeadIds
                };
            }
        case Actions.SELECT_ALL_LEADS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedLeadIds = arr.map(lead => lead.id);

                return {
                    ...state,
                    selectedLeadIds: selectedLeadIds
                };
            }
        case Actions.DESELECT_ALL_LEADS:
            {
                return {
                    ...state,
                    selectedLeadIds: []
                };
            }
        case Actions.OPEN_NEW_LEAD_DIALOG:
            {

                console.group({
                    type: 'new',
                    props: {
                        open: true
                    },
                    data: action.data,
                    db: action.db

                })
                return {
                    ...state,

                    leadDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db

                    }
                };
            }
        case Actions.CLOSE_NEW_LEAD_DIALOG:
            {
                return {
                    ...state,
                    leadDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null

                    }
                };
            }
        case Actions.OPEN_EDIT_LEAD_DIALOG:
            {
                return {
                    ...state,
                    leadDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db

                    }
                };
            }
        case Actions.CLOSE_EDIT_LEAD_DIALOG:
            {
                return {
                    ...state,
                    leadDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null

                    }
                };
            }

        default:
            {
                return state;
            }
    }
};

export default leadsReducer;
