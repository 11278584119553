const locale = {
    'NEW_CONTACT': 'Nouveau contact',
    'NEW_CLIENT': 'Nouveau client',
    'NEW_QUOTE': 'Nouvelle Offre',
    'NEW_SITE': 'Nouveau site',
    'PROFILE': 'Mon profil',
    'NOTIFICATION_ALLOW': 'Activer/Désactiver les notifications',
    'LOGOUT': 'Se déconnecter',
    'LOGIN': 'Se connecter'
}
export default locale