import React from 'react';
import { DropDownBox, TreeView } from 'devextreme-react';
import DataGrid, { Selection, Paging, FilterRow, Scrolling , Column} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import _ from '@lodash'
import ArrayStore from 'devextreme/data/array_store';
import TreeList from './treeList'
const gridColumns = ['batimentName', 'batterieName', 'name'];

class App extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.treeViewRef = React.createRef();
        this.treeDataSource = this.props.appareils_full
        this.gridDataSource = new ArrayStore({
            data: this.props.appareils, key: 'id'
        })
        this.state = {
            treeBoxValue: this.props.appareils_full,
            gridBoxValue: this.props.values,
        };

        this.treeViewSelectionChanged = this.treeViewSelectionChanged.bind(this);
        this.treeViewContentReady = this.treeViewContentReady.bind(this);
        this.treeView_itemSelectionChanged = this.treeView_itemSelectionChanged.bind(this);
        this.syncTreeViewSelection = this.syncTreeViewSelection.bind(this);
        this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
        this.dataGrid_onSelectionChanged = this.dataGrid_onSelectionChanged.bind(this);
        this.treeViewRender = this.treeViewRender.bind(this);
        this.treeView_onContentReady = this.treeView_onContentReady.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);
    }
   /*  makeAsyncDataSource(jsonFile) {
        return new CustomStore({
            loadMode: 'processed',
            key: 'id',
            load: function () {
                return _.keyBy(this.props.appareils, 'id')
            }
        });
    } */
    componentWillReceiveProps(p, o) {
        ////console.log(p)
        ////console.log(o)
    }
    
    get treeView() {
        return this.treeViewRef.current.instance;
    }
    render() {
      
        return (
            <div>
                    <DropDownBox
                        value={_.compact(_.map(this.state.treeBoxValue, c => {
                            if (c.isAppareil)
                           return c.name
                        }))}
                      
                        deferRendering={false}
                        placeholder="Sélectionnez..."
                        showClearButton={true}
                        dataSource={this.treeDataSource}
                        onValueChanged={this.syncTreeViewSelection}
                        contentRender={this.treeViewRender}
                    />
           
               {/*  <div className="dx-field-value">
                        <DropDownBox
                        value={_.map(this.state.gridBoxValue, c => c.name)}
                           //valueExpr="id"
                            deferRendering={false}
                           // displayExpr="name"
                            placeholder="Sélectionnez les appareils concernés..."
                            showClearButton={true}
                            dataSource={this.gridDataSource}
                            onValueChanged={this.syncDataGridSelection}
                            contentRender={this.dataGridRender}
                        />
                  
                </div>  */}

            </div>
         
        );
    }

   treeViewRender() {
      // return <TreeList/>
       //console.log(this.treeViewRef)
        return (
            <TreeView
                searchEnabled={true}
                dataSource={this.treeDataSource}
                id="treeview"
                ref={this.treeViewRef}
                dataStructure="plain"
                hasItemsExpr="isAppareil"
                keyExpr="id"
                parentIdExpr="parentId"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                selectNodesRecursive={true}
                displayExpr="name"
                selectByClick={true}
                expandAllEnabled={true}
                onContentReady={this.treeViewContentReady}
               onSelectionChanged={this.treeViewSelectionChanged}
                // onItemSelectionChanged={this.treeView_itemSelectionChanged}
            />
        );
    } 

    dataGridRender() {
        return (
            <DataGrid
                width={'100%'}
                dataSource={this.gridDataSource}
                //columns={gridColumns}
               
                hoverStateEnabled={true}
                selectedRowKeys={_.map(this.state.gridBoxValue,c=>c.id)}
                onSelectionChanged={this.dataGrid_onSelectionChanged}>
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />

                <Column dataField={'batimentName'} caption={'Bâtiment'} /* editorType="dxTextArea" */ >
       
                </Column>
                <Column dataField={'batterieName'} caption={'Batterie'} /* editorType="dxTextArea" */ >

                </Column>
                <Column dataField={'name'} caption={'Appareil'} /* editorType="dxTextArea" */ >

                </Column>

            </DataGrid>
        );
    }

    treeViewSelectionChanged(e) {
        //console.log(e.node)
        this.syncSelection(e.component);
    }

    treeViewContentReady(e) {
        //console.log(e.node)
        this.syncSelection(e.component);
    }

    syncSelection(treeView) {
        const selectedNodes = 
            _.map(treeView.getSelectedNodes(),(node) => {
                return {
                    ...node.itemData,
               
                };
            });
        this.props.data.setValue(_.filter(selectedNodes, c=>c.isAppareil))
        this.setState(() => {
            return { treeBoxValue: selectedNodes };
        });
       // const selectedEmployees = treeView.getSelectedNodes()
       // //console.log(selectedEmployees)
    }


    syncTreeViewSelection(e) {
        //console.log(e)
        //console.log(this.treeViewRef)
        this.setState({
            treeBoxValue: e.value
        });
        if (!this.treeView) return;

        if (!e.value) {
       // this.treeViewRef.unselectAll();
        } else {
           // this.treeView.instance.selectItem(e.value);
        }
    }


    syncDataGridSelection(e) {
        this.props.data.setValue(e.value ? e.value : [])
        this.setState({
            gridBoxValue: e.value ? e.value :[]
        });
    }
    treeView_itemSelectionChanged(e) {
        //console.log(e.node)
        //console.log(this.treeView)

        this.treeView.unselectAll();
  
   
       // //console.log(this.treeView.instance.getSelectedNodeKeys())
       // //console.log(e.component.getSelectedNodes())
      /* this.setState({
            treeBoxValue: this.treeView.instance.getSelectedNodeKeys()
        });  */
    }

    dataGrid_onSelectionChanged(e) {
  
        this.props.data.setValue(e.selectedRowsData)
        this.setState({
            gridBoxValue: e.selectedRowsData
        });
   
    }

    treeView_onContentReady(e) {
        //console.log(e.component)
        e.component.selectItem(_.map(this.state.treeBoxValue,c=>c.id));
    }


}

function CheckLevel(p) {
//console.log(this.props.appareils)
    //console.log(p)
}

export default App;