import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import * as ActionsAll from 'app/store/actions';
import _ from '@lodash'
import moment from "moment";
import "moment/locale/fr";
import { FuseUtils } from '@fuse';
import DataGrid, {  RequiredRule,Column, ColumnChooser, Pager, ColumnFixing, Editing, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Selection, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import deMessages from "app/custom/translation/fr.json";
import { loadMessages, locale } from 'devextreme/localization';
import { TextField, Button, Dialog, DialogActions, DialogContent, Checkbox, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from '@fuse/hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import SelectClients from 'app/custom/components/selects/selectClients';
import SelectContacts from 'app/custom/components/selects/selectContacts';

import SelectSites from 'app/custom/components/selects/selectSites';
import firebaseService from 'app/services/firebaseService';




const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
        height: '100%'
    },
}));
const GridElement = (form) => {

    return ''
}
const Infos = (props) => {
    //////////console.log(props)
    const dispatch = useDispatch();
    const wrapperRef = useRef(props.tab);
    const grid = useRef('grid')
    const classes = useStyles();
    const userAuth = useSelector(({ auth }) => auth.user.uid);
    var variables = useSelector(({ custom }) => custom.variables[props.tab]);
    const [list, setList] = useState([])

    const { form, handleChange, setForm } = useForm(props.form[props.tab])

    useMemo(() => {
        loadMessages(deMessages);

        locale(navigator.language);
    }, [])
    useEffect(() => {
       /*  if (props.match.params.id == 'newaudit') {
            setForm(form)
        } else {
            setForm(props.form[props.tab])
        } */
        if (!_.isEqual(props.form[props.tab], form))
        setForm(props.form[props.tab])
        //////////console.log('params')
    }, [props.match.params, props.form[props.tab]]);
    useEffect(() => {
        var listBase = form
        listBase = _.concat(listBase, _.compact(_.map(_.values(variables), c => {
            if (!_.find(listBase, { id: c.id }))
                return { ...c, check: false }
        })))
        //////////console.log(listBase)
        setList(_.compact(listBase))
    }, [variables])
    /* useEffect(() => {
        if (!_.isEqual(props.form[props.tab], form))
            setForm(form)
    }, [props.form[props.tab]]); */
    useOutsideAlerter(wrapperRef, grid, form);
    function useOutsideAlerter(ref, grid, form) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {

                if (ref.current && !ref.current.contains(event.target)) {
                    //////////console.log(grid.current.instance.getDataSource()._items)
                   
                    form = _.filter(grid.current.instance.getDataSource()._items, { check: true })
                    //////////console.log(!_.isEqual(props.form, form));
                    if (!_.isEqual(props.form[props.tab], form))
                    props.handleChange(props.tab, form)
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, form]);
    }
    
    return (
        <div className={classes.formControl} ref={wrapperRef}>
           
            <div className=''>
                <DataGrid
                    id="elements_verifies"
                   
                    height={'100%'}
                    ref={grid}
                    dataSource={list}
                    allowColumnResizing={false}
                    allowColumnReordering={false}
                    columnAutoWidth={false}
                    columnHidingEnabled={false}
                    columnResizingMode={'widget'}
                    rowAlternationEnabled={true}
                    showBorders={false}

                    keyExpr="id"
                    wordWrapEnabled={true}
                    onInitNewRow={(e) => {
                        e.data = { id: FuseUtils.generateGUID(), createdBy: userAuth, check: true, value: '' }
                    }}
                    onRowInserted={
                        (e) => {

                            e.data.createdBy = userAuth
                            var tmp = _.concat(_.values(variables), _.omit(e.data, 'check'))
                         
                            firebaseService.firestore.collection('variables').doc('elements_verifies').set(_.keyBy(tmp, 'id')).then(() => {
                                //////////console.log('done')

                            }).catch(err => {
                                //////////console.log(err)
                            })
                            props.handleChange(props.tab, _.filter(grid.current.instance.getDataSource()._items))

                        }
                    }
                    onRowUpdated={
                        (e) => {
                         
                            if (!e.data.createdBy && !_.isEqual(e.data.value, variables[e.data.id].value)) {
                                e.data.createdBy = userAuth
                                var tmp = _.concat(_.values(variables), _.omit(e.data, 'check'))

                                firebaseService.firestore.collection('variables').doc('elements_verifies').set(_.keyBy(tmp, 'id')).then(() => {
                                    //////////console.log('done')

                                }).catch(err => {
                                    //////////console.log(err)
                                })
                            } else if (e.data.createdBy == userAuth && !_.isEqual(e.data.value, variables[e.data.id].value)) {
                                var tmp = _.concat(_.values(variables), _.omit(e.data, 'check'))

                                firebaseService.firestore.collection('variables').doc('elements_verifies').set(_.keyBy(tmp, 'id')).then(() => {
                                    //////////console.log('done')

                                }).catch(err => {
                                    //////////console.log(err)
                                })
                            }
                            props.handleChange(props.tab, _.filter(grid.current.instance.getDataSource()._items))

                        }}
                    onRowRemoved={
                        (e) => {
                           
                            if (_.find(_.values(variables), { id: e.data.id })) {
                                var l = _.cloneDeep(variables)
                                delete l[e.data.id];


                                firebaseService.firestore.collection('variables').doc('elements_verifies').set(l).then(() => {
                                    //////////console.log('done')

                                }).catch(err => {
                                    //////////console.log(err)
                                })
                            }
                            props.handleChange(props.tab, _.filter(grid.current.instance.getDataSource()._items))
                        }}
                >
                    <Scrolling mode="virtual" />
                    <StateStoring enabled={true} type="localStorage" storageKey={"audit_etenduExamen"} /> 
                    {/* 
                <ColumnFixing enabled={true} />
                <Export enabled={true} allowExportSelectedData={true} icon={'xlsxfile'} />
                <Selection mode={"multiple"} />
                <ColumnChooser enabled={true} />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} /> */}
                    <SearchPanel visible={true}
                        //width={240}
                        placeholder="Recherche..." />
                    <FilterRow visible={false} />
                    <GroupPanel visible={false} />
                    {/* <Paging defaultPageSize={10} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} /> */}
                    <Editing
                        mode="cell"
                        useIcons={true}
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={(e => {
                            if (e.row.data && e.row.data.createdBy && e.row.data.createdBy == userAuth)
                                return true
                            return false
                        })} />




                    <Column type={'buttons'} />
                    <Column caption={'Eléments Vérifiés'} with={300} dataField="value" dataType={'string'}  >
                        <RequiredRule />
                    </Column>
                    <Column caption={'Oui/Non'} with={50} dataField="check" dataType={'boolean'} />






                </DataGrid>
            </div>

        </div>
    )

}

export default withRouter(Infos);