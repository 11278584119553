import Login from './Login';
import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
////console.log(fr)
i18next.addResourceBundle('en', 'loginApp', en);
i18next.addResourceBundle('fr', 'loginApp', fr);
export const LoginConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/login',
            component: Login
        }
    ]
};

