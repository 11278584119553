import React, { useEffect, useCallback, useState } from 'react';
import { Dialog, } from '@material-ui/core';
import { useForm } from '@fuse/hooks';
import FuseUtils from '@fuse/FuseUtils';
import * as Actions from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SiteDialogContent from './siteDialogContent'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import _ from '@lodash'
const defaultFormState = {
    site: {
        appareils: [{ qte: 1, type: '' }],
        type_batiment: "",
        address_facturation: {},
        id: '',
        name: '',
        entite: '',
        gestionnaire: '',
        client: '',
        contact: '',
        address: '',
        address2: '',
        zip: '',
        city: '',
        country: 'France',
        notes: '',


    }, client: {}
};

function SiteDialog(props) {

    const dispatch = useDispatch();
    const siteDialog = useSelector(({ custom }) => custom.sites.sites.siteDialog);
    const [country, setCountry] = useState([])
    const { form, handleChange, setForm } = useForm(defaultFormState);
    const { t } = useTranslation('sitesApp');
    ////////console.log(siteDialog)
    const initDialog = useCallback(
        () => {
            var country = ''
            for (var i = 0; i < CountryRegionData.length; i += 1) {

                if (CountryRegionData[i][0] === form.country) {


                    // setCountry(CountryRegionData[i])
                    country = CountryRegionData[i]

                }
            }
            if (siteDialog.type === 'edit' && siteDialog.data) {
                setForm({
                    ...defaultFormState.site,
                    ..._.omit(siteDialog.data, 'handleChange'), country: country
                });
            }

            /**
             * Dialog type: 'new'
             */
            if (siteDialog.type === 'new') {
                setForm({
                    ...defaultFormState.site,
                    ..._.omit(siteDialog.data, 'handleChange'), country: country,
                    id: FuseUtils.generateGUID()
                });
            }
        },
        [siteDialog.data, siteDialog.type, setForm],
    );
    useEffect(() => {
        /**
         * After Dialog Open
         */
        if (siteDialog.props.open) {
            initDialog();
        }

    }, [siteDialog.props.open, initDialog]);

    function closeComposeDialog() {
        siteDialog.type === 'edit' ? dispatch(Actions.closeEditSiteDialog()) : dispatch(Actions.closeNewSiteDialog());
    }

    //console.log(siteDialog)

    return (
        <Dialog
            classes={{
                paper: "m-24"
            }}
            {...siteDialog.props}

            onClose={closeComposeDialog}
            fullWidth
            maxWidth="md"
        >
            <SiteDialogContent closeComposeDialog={closeComposeDialog} form={form} siteDialog={siteDialog} />
        </Dialog>
    );
}

export default SiteDialog;
