import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import deMessages from "app/custom/translation/fr.json";
import _ from '@lodash'
import DataGrid, { Column, ColumnChooser, ColumnFixing, Pager, Editing, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Selection, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import Batiments from 'app/custom/rapports/audit/installation/batiments'
import Batteries from 'app/custom/rapports/audit/installation/batteries'
import Appareils from 'app/custom/rapports/audit/installation/appareils'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { loadMessages, locale } from 'devextreme/localization';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { AppBar, Paper } from '@material-ui/core';
import Modernisations from 'app/custom/rapports/audit/installation/modernisations';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanelInstallation-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>

            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    root: {

        backgroundColor: theme.palette.background.paper,
        display: 'flex flex-col min-w-full',
        height: '100%',
        width: '100%'
    },
    tabs: {
        // borderRight: `1px solid ${theme.palette.divider}`,
        width: '100%'
    },
}));

export default function VerticalTabs(props) {
    //////////console.log(props)
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [form, setForm] = useState({})

    const handleChange = (event, newValue) => {
        
        setValue(newValue);
    };
    useEffect(() => {

        setForm(props.form[props.tab])
    }, [props.form[props.tab]])
    const handleChangeTab = (p, f) => {
        ////console.log(p, f)
        if (p == 'appareils') {
            
                f = _.compact(_.map(f, l => {
                    if (!l.batterie){
                        l.batterie = '-'
                        l.batiment_name = ''
                    }
                        return l
                }))
        }
        var newForm = {}
        var tree = []
     
            if (p == 'appareils') {
                tree = _.concat(tree, _.map(form['batiments'], c => {
                    return { ...c, parentId: 0 }
                }))
                tree = _.concat(tree, _.map(form['batteries'], c => {
                    if (c.batiment)
                        c.batiment_name = _.find(form['batiments'], { id: c.batiment }) ? _.find(form['batiments'], { id: c.batiment }).name:''
                    return {
                        ...c, parentId: c.batiment ? c.batiment : 0
                    }
                }))
                tree = _.concat(tree, _.map(f, c => {
                    if (c.batiment)
                        c.batiment_name = _.find(form['batiments'], { id: c.batiment }) ? _.find(form['batiments'], { id: c.batiment }).name : ''
                    if (c.batterie)
                        c.batterie_name = _.find(form['batteries'], { id: c.batterie }) ? _.find(form['batteries'], { id: c.batterie }).name : ''

                    var modernisations_ = _.compact(_.map(form['modernisations'], l => {
                        if (_.includes(l.appareils, c.id) && l.check)
                            return _.omit(l, 'appareils')

                    }))
                    return {
                        ...c, parentId: c.batterie ? c.batterie : c.batiment ? c.batiment : 0, modernisations: modernisations_
                    }
                }))
            }
            if (p == 'batteries') {
                tree = _.concat(tree, _.map(form['batiments'], c => {
                    return { ...c, parentId: 0 }
                }))
                tree = _.concat(tree, _.map(form['appareils'], c => {
                    if (c.batiment){
                        c.batiment_name = _.find(form['batiments'], { id: c.batiment }) ? _.find(form['batiments'], { id: c.batiment }).name : ''
                    } 
                    if (c.batterie)
                        c.batterie_name = _.find(form['batteries'], { id: c.batterie }) ? _.find(form['batteries'], { id: c.batterie }).name : ''
                    var modernisations_ = _.compact(_.map(form['modernisations'], l => {
                        if (_.includes(l.appareils, c.id) && l.check)
                            return _.omit(l, 'appareils')

                    }))
                    return {
                        ...c, parentId: c.batterie ? c.batterie : c.batiment ? c.batiment : 0, modernisations: modernisations_
                    }
                }))
                tree = _.concat(tree, _.map(f, c => {
                    if (c.batiment)
                        c.batiment_name = _.find(form['batiments'], { id: c.batiment }) ? _.find(form['batiments'], { id: c.batiment }).name : ''

                    return {
                        ...c, parentId: c.batiment ? c.batiment : 0
                    }
                }))
            }
            if (p == 'batiments') {
                tree = _.concat(tree, _.map(f, c => {
                    return { ...c, parentId: 0 }
                }))
                tree = _.concat(tree, _.map(form['batteries'], c => {
                    if (c.batiment)
                        c.batiment_name = _.find(form['batiments'], { id: c.batiment }) ? _.find(form['batiments'], { id: c.batiment }).name : ''

                    return {
                        ...c, parentId: c.batiment ? c.batiment : 0
                    }
                }))
                tree = _.concat(tree, _.map(form['appareils'], c => {
                    if (c.batiment)
                        c.batiment_name = _.find(form['batiments'], { id: c.batiment }) ? _.find(form['batiments'], { id: c.batiment }).name : ''
                    if (c.batterie)
                        c.batterie_name = _.find(form['batteries'], { id: c.batterie }) ? _.find(form['batteries'], { id: c.batterie }).name : ''
                    var modernisations_ = _.compact(_.map(form['modernisations'], l => {
                        if (_.includes(l.appareils, c.id) && l.check)
                            return _.omit(l, 'appareils')

                    }))
                    return {
                        ...c, parentId: c.batterie ? c.batterie : c.batiment ? c.batiment : 0, modernisations: modernisations_
                    }
                }))

            }
        
        if (p == 'modernisations') {
            tree = _.concat(tree, _.map(form['batiments'], c => {
                return { ...c, parentId: 0 }
            }))
            tree = _.concat(tree, _.map(form['batteries'], c => {
                if (c.batiment)
                    c.batiment_name = _.find(form['batiments'], { id: c.batiment }) ? _.find(form['batiments'], { id: c.batiment }).name : ''
                return {
                    ...c, parentId: c.batiment ? c.batiment : 0
                }
            }))
            tree = _.concat(tree, _.map(form['appareils'], c => {
                if (c.batiment)
                    c.batiment_name = _.find(form['batiments'], { id: c.batiment }) ? _.find(form['batiments'], { id: c.batiment }).name : ''
                if (c.batterie)
                    c.batterie_name = _.find(form['batteries'], { id: c.batterie }) ? _.find(form['batteries'], { id: c.batterie }).name : ''
                var modernisations_ = _.compact(_.map(f, l => {
                    if (_.includes(l.appareils, c.id) && l.check)
                        return _.omit(l, 'appareils')
    
}))
                return {
                    ...c, parentId: c.batterie ? c.batterie : c.batiment ? c.batiment : 0, modernisations: modernisations_
                }
            }))
        }

        if (p == 'modernisations')
            f = _.compact(_.map(f, l => {
                if ( l.check)
                    return l
            }))
            ////////console.log(tree)
            newForm = { ...form, [p]: f, tree }
        
        ////console.log(newForm)
       //setForm(newForm)
        props.handleChange(props.tab, newForm)


    };

    return (

        <Paper className={''}>
            <AppBar position='relative' color='inherit'>
                <Tabs
                    //orientation="horizontal"
                    variant="standard"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                //className={classes.tabs}

                >
                    <Tab label="Bâtiments" {...a11yProps(0)} />
                    <Tab label="Batteries" {...a11yProps(1)} />
                    <Tab label="Appareils" {...a11yProps(2)} />
                    <Tab label="Modernisations" {...a11yProps(3)} />


                </Tabs>
                <TabPanel value={value} index={0}>
                    <Batiments handleChange={handleChangeTab} tab={'batiments'} form={form} allForm={props.form} />
                </TabPanel>
                <TabPanel value={value} index={1} >
                    <Batteries handleChange={handleChangeTab} tab={'batteries'} form={form} allForm={props.form} />
                </TabPanel>
                <TabPanel value={value} index={2} >
                    <Appareils handleChange={handleChangeTab} tab={'appareils'} form={form} allForm={props.form} />
                </TabPanel>
                <TabPanel value={value} index={3} >
                    <Modernisations handleChange={handleChangeTab} tab={'modernisations'} form={form} allForm={props.form} />
                </TabPanel>
            </AppBar>
        </Paper>

    );
}