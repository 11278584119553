import * as Actions from 'app/store/actions';
import _ from '@lodash';
import DataGrid, {
    Column,
    Editing,
    Lookup,
    MasterDetail,
    Paging,
    Selection,
    SearchPanel,
    Grouping,
    RequiredRule,Position,
  Form,Popup,
    FilterRow,
    Scrolling
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import deMessages from 'app/custom/translation/fr.json';
import dialog from 'app/store/reducers/fuse/dialog.reducer';
import firebaseService from 'app/services/firebaseService';
import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
    useCallback
} from 'react';
import { DropDownBox, TreeView } from "devextreme-react";
/* import TableAppareil fro../tableAppareileil';
import TableBatterie from '../tableBatterie'; */
import { FuseUtils } from '@fuse';
import { loadMessages, locale } from 'devextreme/localization';
import { useDispatch, useSelector } from 'react-redux';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from "devextreme/data/custom_store";
import DataSource from 'devextreme/data/data_source';
import SelectVariables from 'app/custom/components/selects/selectVariable';
import App3 from './dropdown_appareils'
import Amelioration from './amelioration_list'


import { useForm } from '@fuse/hooks';
import {
    DialogContent, TextField, DialogActions, Button, TextareaAutosize, Checkbox, FormControl,
    FormControlLabel, InputLabel, Select, MenuItem,
    FormLabel} from '@material-ui/core';
import TinyMCE from 'app/custom/components/texteditor/tinyMce'
var memo = ''

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
const App = props => {

////console.log('Annexces D',props)
    const dispatch = useDispatch()

    const ref = useRef('')
    const user = useSelector(({ custom }) => custom.contacts.user.infos)
    const variables = useSelector(({ custom }) => custom.variables);
    const initialForm = useSelector(({ custom }) => custom.rapports.form);
    const reglementation = useSelector(({ custom }) => custom.rapports.reglementation);
       const [reglementationList, setReglementation] = useState({})
    const [dataSource, setDataSource] = useState([])
    const [address, setAddress] = useState('')
    const [selectingRowKeys, setSelectingRowKeys] = useState([])
    const [lookup, setLookup] = useState([])
    const [initialSource, setSource] = useState([])
    const [state, setState] = useState({
        batteries: [],
        appareils: [],
        batiments: [], 
        full:[],
        reglementation:[]
    })

    useEffect(() => {
        return () => {
            console.log('******************* UNMOUNTED');
        };
    }, []);
////console.log(reglementation,reglementationList)
    useMemo(() => {
        loadMessages(deMessages);
        ////////////////////console.log(facturesList)
        locale(navigator.language);

    }, [])
    useEffect(() => {

        firebaseService.firestore.collection('annexeE').get().then(r => {

            //console.log('load')
            var source = []
            if (r.size > 0)
                r.forEach(d => {
                    source.push(d.data())
                })
            setSource(source)
            dispatch(Actions.setFormAudit(initialForm.id, { ...initialForm.data, annexeE:_.map(source, c => {
                if (_.find(initialForm.data.annexeE, { id: c.id })) {
                    return { ...c, ..._.find(initialForm.data.annexeE, { id: c.id }) }
                } else return { ...c, check: false }
            }) }))
            setDataSource(_.map(source, c => {
                if (_.find(initialForm.data.annexeE, { id: c.id })) {
                    return { ...c, ..._.find(initialForm.data.annexeE, { id: c.id }) }
                } else return { ...c, check: false }
            }))
        })


},[])
  { /*  useEffect(() => {
        ////////////console.log(initialForm.data.batiments, dataSource)
        if (!_.isEqual(_.filter(dataSource, { check: true }), _.uniqBy(initialForm.data.annexeE, 'id'))) {
           
          
            setDataSource(_.concat(initialForm.data.annexeE, _.compact(_.map(initialSource, c => {
                if (_.find(initialForm.data.annexeE, { id: c.id })) {
                    return
                    //return { ...c, ..._.find(initialForm.data.annexeE, { id: c.id }) }
                } else return { ...c, check: false }
            }))))
        }  
            
    }, [initialForm.data.annexeE, initialSource]) */}
 

    useEffect(() => {
        async function setBatch() {
            if (!_.isEqual(state.batiments, initialForm.data.batiments) || !_.isEqual(state.batteries, initialForm.data.batteries) || !_.isEqual(state.appareils, initialForm.data.appareils)) {
                var data={}




                var full = [_.map(initialForm.data.batiments, c => {
                    return { ...c, expanded: true, isBatiment: true, isGroup: true}
                }), _.map(initialForm.data.batteries, c => {
                    return { ...c, parentId: c.batimentId, expanded: true, isBatterie: true, isGroup:true}
                }), _.map(initialForm.data.appareils, c => {
                    return { ...c, parentId: c.batterieId ? c.batterieId : c.batimentId, selected: true, isAppareil: true,
                        isGroup: false
                }
                })] 
         

                setState({
                    batteries: initialForm.data.batteries, appareils: _.map(initialForm.data.appareils, c => {
                        return {
                            ...c, batimentName: _.find(initialForm.data.batiments, d => {
                                return d.id == c.batimentId
                            }) ? _.find(initialForm.data.batiments, d => {
                                return d.id == c.batimentId
                            }).name : '', batterieName: _.find(initialForm.data.batteries, d => {
                                return d.id == c.batterieId
                            }) ? _.find(initialForm.data.batteries, d => {
                                return d.id == c.batterieId
                            }).name : ''
                        }
                    }), full: _.spread(_.union)(full),
          
                })
           
               // setLookup(_.spread(_.union)(initialForm.data.batteries, _.filter(initialForm.data.appareils, c => !c.batterieId)))
                ////////console.log(_.concat(initialForm.data.batteries, _.filter(initialForm.data.appareils, c => !c.batterieId)))
               // setLookup(_.concat(initialForm.data.batteries, _.filter(initialForm.data.appareils, c => !c.batterieId)))

             
    }
        }
    setBatch()
    }, [initialForm.data.batteries, initialForm.data.appareils,  initialForm.data.batiments])

     function addNewRow(e) {
         ////console.log(ref.current.instance)
         ////console.log(e)
         ////////////console.log(ref.current.instance.getDataSource())


         /*   if (props.batterieId) {
               F = { batterieId: props.batterieId, batimentId: props.data.batimentId}
           } else {
               F = { appareilId: props.data.id , batimentId: props.data.batimentId}
           } */
         if (!memo)
             memo = {  id: FuseUtils.generateGUID() }
         else memo = { ...memo, id: FuseUtils.generateGUID() }


         var F = { ...memo, id: FuseUtils.generateGUID(), createdBy: user.id }
         // e.data = initialDatas.length ? { ...initialDatas[initialDatas.length - 1], ...F, value: '' } : { ...F, value: '' }

         dispatch(Actions.openDialog({ children: <FormAdd {...F}  reglementation={state.reglementation} dataSource={dataSource} setDataSource={setDataSource} />, height: 500 }))
    }

    function onToolbarPreparing(e) {
        ////console.log('on toolbar preparing')
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                hint: 'Ajouter un article',
                text: "Nouvel article",
                icon: 'plus',
                onClick: addNewRow,

            }
        })
    } 
  
    function setCellAppareils(newData, value, currentRowData) {
        //////console.log(newData, value, currentRowData)
      
        newData.qte = 0
    }
    function setCellValue(newData, value, currentRowData) {
       ////console.log(newData, value, currentRowData)

        
      /*   newData.pu = value;
        newData.pt = currentRowData.appareils.length * value;
        newData.appareils = currentRowData.appareils; */
    }
    function handleChange(f, e) {
        //console.log(e)
        e.row.data['pu'] = f.pu
    }
    const saleAmountFormat = { style: 'currency', currency: 'EUR', useGrouping: true, minimumSignificantDigits: 3 };

    ////////////console.log(dataSource)
    return (<div id="data-grid-demo" className={''}>
        <DataGrid
            width='100%'
            dataSource={_.orderBy(dataSource,['reglementation', 'article'])}
            onToolbarPreparing={onToolbarPreparing}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnHidingEnabled={false}
            rowAlternationEnabled={true}
            columnAutoWidth={false}
            repaintChangesOnly={true}
            //selectedRowKeys={selectedRowKeys}
            wordWrapEnabled={true}
            onRowValidating={(e) => {
                
            
                ////console.log(e.newData)
                //if(e.newData.)

           /*      if (e.newData.appareils && e.newData.appareils.length && e.newData.description && e.newData.localisation && e.newData.pu)
                    return e.isValid = true
                return e.isValid =false */
                        /*    if (!e.value)
                               return e.isValid = false
                           return true */
                    
                }
            }
            ref={ref}
         
            //defaultSelectingRowKeys={selectingRowKeys}
            keyExpr="id"

            onRowPrepared={(e) => {

                // ////////////console.log(e)
            }}
            onSelectionChanged={
                (e) => {
                    ////////////console.log(e)
                    ////props.form.setFieldValue(field.name, e.selectingRowsData)

                }
            }
            onRowInserted={
                (e) => {

              
                  ////console.log(e.data)
                    //e.data.pt = e.data.appareils.length * Number(e.data.pu)
                    e.data.description = capitalize(e.data.description)
                    memo = e.data
                    e.data.check = true
                    firebaseService.firestore.collection('annexeE').doc(e.data.id).set({ id:e.data.id,reglementation:e.data.reglementation, article:e.data.article, description:e.data.description,createdBy: user.id})
                    dispatch(Actions.setFormAudit(initialForm.id, { ...initialForm.data, annexeE: _.concat(dataSource , e.data) }))
                    ////props.form.setFieldValue('batiments', _.concat(initialForm.data.batiments ? initialForm.data.batiments : [], e.data), e.data)
                    /*    if (dataSource.length)
                           firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(_.concat(dataSource, e.data), 'id'))
                       else {
                           firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(e.data, 'id'))
   
                       } */

                }}

            onRowUpdating={
                (e) => {
                    //console.log(e)
                   //console.log(dataSource)
                    e.data = {...e.oldData, ...e.newData}
                if(e.newData.description || e.newData.article || e.newData.reglementation){
                    ////console.log(dataSource)
                    e.data.description = capitalize(e.data.description)
                      firebaseService.firestore.collection('annexeE').doc(e.data.id).set({ id: e.data.id, reglementation: e.data.reglementation, article: e.data.article, description: e.data.description, createdBy: user.id })
                  
                       // firebaseService.firestore.collection('annexeE').doc(e.data.id).set({ id: e.data.id, reglementation: e.data.reglementation, article: e.data.article, description: e.data.description, createdBy: user.id })

                    }
                    dispatch(Actions.setFormAudit(initialForm.id, {
                        ...initialForm.data, annexeE: _.concat(_.filter(dataSource, c => {
                            return c.id !== e.data.id
                        }), e.data)
                    })) 
                  

                }}

            onRowRemoved={
                (e) => {
                    ////////////console.log(e)
                    dispatch(Actions.setFormAudit(initialForm.id, {
                        ...initialForm.data, annexeE: _.filter(dataSource, d => {

                            return d.id !== e.data.id
                        })
                    }))

                }}
            

            onInitNewRow={async (e) => {

               
            }}
           onEditorPreparing= {(e)=>{
         
      
        
              

        
        }} 


     
        >
         
            <Paging enabled={
                false
            }

            /> <Editing mode="batch"
                allowUpdating={
                    true
                }
                allowAdding={
                    false
                }
                allowDeleting={
                    false
                }


            >  
            
                <SearchPanel visible={true} />
                <Grouping autoExpandAll={true} />
            
            
            
            </Editing>
           
         {/*    <Column dataField={'amelioration'} caption={"Type d'amélioration"}  editCellComponent={(e) => {
                ////console.log(e)
                return <Amelioration  {...e} values={e.data.data.amelioration} handleChange={(f) => {

                ////console.log(e.data, f)
                 e.data.setValue(f)
                }}/>
            }} cellRender={(e) => {
                ////console.log(e)
////console.log(e.value)
                return e.value
            }}    >  </Column> */}
              {/*  <Column type="buttons">
        
                    <Button name="edit" />
                    <Button name="delete" />
                </Column> */}
            <Column dataField={'reglementation'} caption={'Règlementation'} groupIndex={0} allowEditing={false}> </Column>
            <Column dataField={'article'} caption={'Article'} allowEditing={false}> </Column>
            <Column dataField={'description'} caption={'Description'} width={400} allowEditing={false} /*editCellComponent={(e) => {
                ////console.log(e)
                return  <TextareaAutosize
                    style={{ minHeight: 100, border: '1px', minWidth:'100%'}}
/* 
                    name='qualite_maintenance_comments'
                    aria-label="commentaires"
                    placeholder="Commentaires" 
                    onBlur={(f) => {
                     e.data.setValue(f.currentTarget.value)
                    }}
                    defaultValue={e.data.data.description}
                />
            }}  */ cellRender={(e) => { 
  
                return <div dangerouslySetInnerHTML={{ __html: e && '<div>'+e.value+'</div>'?e.value:''}} />
                }}   >  </Column>

                  <Column dataField={'pu'} caption={'€ HT/Appareil'} allowAdding={false} allowEditing={true}> </Column>
            <Column dataField={'check'} caption='' dataType='boolean' allowAdding={false} allowEditing={true} cellValue={(e) => {
                ////console.log(e.value)
                if (e.value)
                    return true
                return false
            }} ></Column>
            <Column dataField={'appareils'} caption={"Appareils concernés"} /* editorType="dxTextArea" */ allowAdding={false} allowEditing={true}  editCellComponent={(e) => {
              
                return <App3 appareils={state.appareils} appareils_full={state.full} {...e} values={e.data.data.appareils}/>
            }}  cellRender={(e) => { 
                if(!e.value)
                return 'Tous'
                return _.map(e.value, c => {
                    return c.name
                }).join(',')
                }}>
            </Column>
     

        </DataGrid >

    </div >);
}
const gridColumns = ["batimentName", "batterieName", "name"];
const FormAdd = (props) => {
  console.log(props)
    const dispatch = useDispatch();
    const user = useSelector(({ custom }) => custom.contacts.user.infos)
    const { form, handleChange, setForm } = useForm({ id: props.id, value: props.value, reglementation: '', check:true });
    const handleSubmit = (e) => {
        e.preventDefault();
        //////////console.log(form)
       
        firebaseService.firestore.collection('annexeE').doc(form.id).set({...form, createdBy: user.id})
props.setDataSource(_.concat(props.dataSource, form))
       
        // props.component.addRow(form)
        dispatch(Actions.closeDialog())
    }

    const setValue = (e) => {
        ////console.log(e)

        setForm({...form,description:e})
    }
    return <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden" >
        <DialogContent classes={{ root: "p-24", height: '500px' }}>
         
               
                <TextField
                label='Réglementation'
                    className={'m-24'}
                 select
                    value={form.reglementation}
                    onChange={(e) => {
                        ////console.log(e.target ? e.target.value : '')
                        //////console.log(e.target ? e.target.value : '')
                       setForm({...form, reglementation:e.target.value})
                    }}
                    variant="filled"
                   fullWidth
                >
                {_.map(['Travaux et dispositifs à mettre en place avant le 31 décembre 2010', 'Travaux et dispositifs à mettre en place avant le 3 juillet 2014','Travaux et dispositifs à mettre en place avant le 3 juillet 2018'], c => {
                      return  <MenuItem key={c} value={c}>
                            {c}
                        </MenuItem>
                       
})}

                </TextField>
            
            <TextField
                className={'m-24'}
                fullWidth
                label="Article"
                name="article"
                value={form.article}
                onChange={handleChange}
                variant='outlined'
            />
            <FormControl component="fieldset" className={'m-24'}>
                        <FormLabel component="legend">Description:</FormLabel>
            <TinyMCE values={form.values} data={{setValue:setValue}}/>
            </FormControl>


        </DialogContent>
        <DialogActions className="justify-between p-8">
            <div className="flex flex-end px-16">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                //disabled={!canBeSubmitted()}
                >
                    Enregistrer
                </Button>
            </div>
        </DialogActions>
    </form>
}
const SetPrice = (props) => {
    ////console.log(props)
    const dispatch = useDispatch();
    const user = useSelector(({ custom }) => custom.contacts.user.infos)
    const { form, handleChange, setForm } = useForm({ pu:props.value ? props.value : ''});
    const handleSubmit = (e) => {
        e.preventDefault();
        //////////console.log(form)
//console.log(form)

        props.handleChange(form, props.data)
     /*    firebaseService.firestore.collection('annexeE').doc(form.id).set({ ...form, createdBy: user.id })
        props.setDataSource(_.concat(props.dataSource, form)) */

        // props.component.addRow(form)
        dispatch(Actions.closeDialog())
    }

    return <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden" >
        <DialogContent classes={{ root: "p-24" }}>


            <TextField
                className={'m-24'}
                fullWidth
                label="Prix par appareil"
                name="pu"
                value={form.pu}
                onChange={handleChange}
                variant='outlined'
                number
            />
           
        </DialogContent>
        <DialogActions className="justify-between p-8">
            <div className="flex flex-end px-16">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                //disabled={!canBeSubmitted()}
                >
                    Enregistrer
                </Button>
            </div>
        </DialogActions>
    </form>
}

export default App;