import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: {},
    formulaires: {},
    searchText: '',
    offres:{},
    selectedCommandesIds: [],
    routeParams: {},
    commandeDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,

    },

};

const commandesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_COMMANDES:
            {
                return {
                    ...state,
                    entities: _.keyBy(_.uniqBy(action.payload, 'id'), 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.GET_OFFRES:
            {
                return {
                    ...state,
                    offres: _.keyBy(_.uniqBy(action.payload, 'id'), 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.GET_FORMULAIRES:
            {
                return {
                    ...state,
                    formulaires: action.payload,

                };
            }

        /*   case Actions.SET_SEARCH_TEXT:
               {
                   return {
                       ...state,
                       searchText: action.searchText
                   };
               }
           case Actions.TOGGLE_IN_SELECTED_COMMANDES:
               {
   
                   const commandeId = action.commandeId;
   
                   let selectedCommandeIds = [...state.selectedCommandeIds];
   
                   if (selectedCommandeIds.find(id => id === commandeId) !== undefined) {
                       selectedCommandeIds = selectedCommandeIds.filter(id => id !== commandeId);
                   }
                   else {
                       selectedCommandeIds = [...selectedCommandeIds, commandeId];
                   }
   
                   return {
                       ...state,
                       selectedCommandeIds: selectedCommandeIds
                   };
               }
           case Actions.SELECT_ALL_COMMANDES:
               {
                   const arr = Object.keys(state.entities).map(k => state.entities[k]);
   
                   const selectedCommandeIds = arr.map(commande => commande.id);
   
                   return {
                       ...state,
                       selectedCommandeIds: selectedCommandeIds
                   };
               }
           case Actions.DESELECT_ALL_COMMANDES:
               {
                   return {
                       ...state,
                       selectedCommandeIds: []
                   };
               }*/
        case Actions.OPEN_NEW_COMMANDE_DIALOG:
            {
                return {
                    ...state,

                    commandeDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null,
                        db: action.payload
                    }
                };
            }
        case Actions.CLOSE_NEW_COMMANDE_DIALOG:
            {
                return {
                    ...state,
                    commandeDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null
                    }
                };
            }
        case Actions.OPEN_EDIT_COMMANDE_DIALOG:
            {
                return {
                    ...state,
                    commandeDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db
                    }
                };
            }
        case Actions.CLOSE_EDIT_COMMANDE_DIALOG:
            {
                return {
                    ...state,
                    commandeDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null
                    }
                };
            }

        default:
            {
                return state;
            }
    }
};

export default commandesReducer;
