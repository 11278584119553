import React,
{
    useState,
    useEffect, useRef
}
    from 'react';

import DataGrid,
{
    Column,
    Editing,
    Paging,
    Selection,
    Lookup,
    Grouping,
    GroupPanel,
Form,
    SearchPanel,
}
    from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import {
    CheckBox,
    SelectBox
}
    from 'devextreme-react';
import SelectVariables from 'app/custom/components/selects/selectVariable';
import { useSelector, useDispatch } from 'react-redux';
import _ from '@lodash'
import firebaseService from 'app/services/firebaseService';
import * as Actions from 'app/store/actions'
import { FuseUtils } from '@fuse';
import { useForm } from '@fuse/hooks';
import { DialogContent , TextField, DialogActions, Button} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
const App = props => {

    
    
    const dispatch = useDispatch()
    const variables = useSelector(({ custom }) => custom.variables);
    const [dataSource, setDataSource] = useState([])
    const user = useSelector(({ custom }) => custom.contacts.user.infos)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const ref = useRef('')
    const initialForm = useSelector(({ custom }) => custom.rapports.form);
    const [state, setState] = useState({
        batteries: '',
        batiments: '',
        appareils:''
    })
    const [initialVariables, setInitialVariables] = useState([])

   
    useEffect(() => {
      ref.current.instance.cancelEditData()
        if (!_.isEqual(initialVariables, variables[props.name]) || !_.isEqual(state.appareils, initialForm.data.appareils)) {
            

            
var memo=[]
              
            var data = _.map(initialForm.data.appareils, d => {
                if (_.includes(memo, d.batterieId))
                    return
                if (d.batterieId && !_.includes(memo))
                    memo.push(d.batterieId)
                return _.map(variables[props.name], c => {
                           var etages = ''
                    if (d.batterieId) {
                        var check = _.find(initialForm.data[props.name], e => {
                                
                            return d.batimentId == e.batimentId && d.batterieId == e.batterieId && c.value == e.value && c.localisation == e.localisation
                        })
                            
                        return {
                            batimentName: _.find(initialForm.data.batiments, { id: d.batimentId }) ? _.find(initialForm.data.batiments, { id: d.batimentId }).name : '', batimentId: d.batimentId, batterieName: _.find(initialForm.data.batteries, { id: d.batterieId }) ? _.find(initialForm.data.batteries, { id: d.batterieId }).name : '', batterieId: d.batterieId, value: c.value, id: FuseUtils.generateGUID(), check: check ? check.check : false, localisation: c.localisation, etages: check && check.etages ? check.etages : '', lever: check && check.lever ? check.lever : false
                        }
                    } else {
                        var check = _.find(initialForm.data[props.name], e => {

                            return d.batimentId == e.batimentId && d.id == e.appareilId && c.value == e.value && c.localisation == e.localisation
                        })
                               
                        return {
                            batimentName: _.find(initialForm.data.batiments, { id: d.batimentId }) ? _.find(initialForm.data.batiments, { id: d.batimentId }).name : '', batimentId: d.batimentId, appareilName: _.find(initialForm.data.appareils, { id: d.id }) ? _.find(initialForm.data.appareils, { id: d.id }).name : '', appareilId: d.id, value: c.value, id: FuseUtils.generateGUID(), check: check ? check.check : false, localisation: c.localisation, etages: check && check.etages ? check.etages : '', lever: check && check.lever ? check.lever : false
                                
                        }
                    }
                        })
                    })
            //////////console.log(data)
            var result = _.spread(_.union)(_.concat(data));
            setDataSource(result)
            setState({ appareils: initialForm.data.appareils})
            
        }
        return setInitialVariables(variables[props.name] ? _.values(variables[props.name]) : [])
    }, [props.name, variables[props.name]], initialForm.data.appareils)

 /*    useEffect(() => {
        var data = _.find(initialForm.data[props.name], c => {
            if(props.batterieId)
                return c.batterieId == props.batterieId
            else
                return c.appareilId == props.appareilId
        })
      
        if (data) {
            data = _.map(data.defaults, c => c.id)
            if (!_.isEqual(data, selectedRowKeys))
                setSelectedRowKeys(data)
        } else { 
            if (!_.isEqual([], selectedRowKeys))
                setSelectedRowKeys([])
        }
    }, [initialForm.data[props.name]]) */

    function addNewRow() {
        ref.current.instance.addRow();
    }
    function saveSelected() {
        //////////console.log(ref.current.instance.getDataSource());
var data=[]
        _.map(ref.current.instance.getDataSource()._items, c => {
            _.map(c.items, d => {
                _.map(d.items, e => {
                    _.map(e.items, l => {
                        _.map(l.items, f => {
                            if (f.check)
                                data.push(f)
                        })
                    })
                })
            })
        })
        //////////console.log(data)
        dispatch(Actions.setFormAudit(initialForm.id, { ...initialForm.data, [props.name]: data }))
    }
    function onToolbarPreparing(e) {
   
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                hint: 'Ajouter un défaut',
                text: "Nouveau défaut",
                icon: 'plus',
                onClick: addNewRow,

            }
        },{
            location: 'after',
            widget: 'dxButton',
            options: {
                hint: 'Sauvegarder',
                text: "",
                icon: 'save',
                onClick: saveSelected,

            }
        } /* ,{
            location: 'before',
            widget: 'dxButton',
            options: {
                //width: 136,
                text: ` ${props.batterieId ? '  Batterie ' + _.find(initialForm.data.batteries, { id: props.batterieId }).name + ' du bâtiment ' + _.find(initialForm.data.batiments, { id: props.batimentId }).name : 'Appareil ' + _.find(initialForm.data.appareils, { id: props.appareilId }).name+' du bâtiment ' + _.find(initialForm.data.batiments, { id: props.batimentId }).name}`,

            } */
        )
    }
   ////console.log(selectedRowKeys)
    return (<div id="data-grid-demo" className={'min-w-full'}>
        <DataGrid
            width='100%'
            dataSource={dataSource}
            onToolbarPreparing={onToolbarPreparing}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnHidingEnabled={false}
            rowAlternationEnabled={true}
            columnAutoWidth={false}
            repaintChangesOnly={true}
            selectedRowKeys={selectedRowKeys}
            wordWrapEnabled={true}
            keyExpr="id"
            ref={ref}
           
            //onRowPrepared={(e) => //////////////console.log(e)}
           /*  onSelectionChanged={
                (e) => {
                    //////////console.log(e)
                    
                    //////////console.log(initialForm.data[props.name])
                   var data = _.filter(initialForm.data[props.name], c => {
                            if (props.batterieId)
                                return c.batterieId != props.batterieId
                            else
                                return c.appareilId != props.appareilId
                        })
                    
          
                    var F={}
                    if (props.batterieId) 
                        F = { batterieId: props.batterieId, batimentId: props.batimentId, defaults: e.selectedRowsData }
                        else
                        F = { appareilId: props.appareilId, batimentId: props.batimentId, defaults: e.selectedRowsData }
                    
                 dispatch(Actions.setFormAudit(initialForm.id, { ...initialForm.data, [props.name]: _.concat(data,F)}))

                    //props.form.setFieldValue(props.name, e.selectedRowsData)

                }
            } */

         

            
            onInitNewRow={ (e) => {

                ////////////console.log(e)
                ////////////console.log(ref.current.instance.getDataSource())
                //var initialDatas = ref.current.instance.getDataSource()._items
////console.log(e)
              
                  var  F = { ...e.data, id: FuseUtils.generateGUID(), createdBy: user.id}
               // e.data = initialDatas.length ? { ...initialDatas[initialDatas.length - 1], ...F, value: '' } : { ...F, value: '' }

                dispatch(Actions.openDialog({ children: <FormAdd {...F} component={e.component} initialVariables={initialVariables}/>, height:500}))
            }}
        >
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={true} />
         { /*   <Selection mode="multiple"

                selectAllMode={
                    'allPages'
                }

                showCheckBoxesMode={
                    'always'
                }

            />  */}<Paging enabled={
                false
            }

            /> <Editing mode="batch"
                allowUpdating={
                    true
                }
                allowAdding={
                    false
                }
                allowDeleting={(e) => {
                   // ////console.log(e)
                    return  false
                }
                   
                }

              

            />
                
            <Column dataField={'batimentName'} caption={'Bâtiment'} groupIndex={0} allowEditing={false} allowAdding={false}/>
            <Column allowEditing='false' dataField={'batterieName'} caption={'Batterie'} groupIndex={1} allowAdding={false}/>
            <Column dataField={'appareilName'} allowEditing='false' caption={'Appareil'} groupIndex={2} allowAdding={false}/>
            <Column dataField={'localisation'} caption={'Localisation'} groupIndex={3} /* editorType="dxTextArea" */  defaultSortOrder={'desc'}>
                <Lookup dataSource={['Machinerie','Machinerie/Hydraulique','Toit de cabine','Opérateur / Porte cabine','Cabine','Gaine', 'Paliers','Cuvette']}></Lookup>
                </Column>
            <Column dataField={'value'} caption={'Etat des defaults'} /* editorType="dxTextArea" */ allowEditing={false}  />
            <Column dataField={'etages'} caption={'Niveaux concernés'} /* editorType="dxTextArea" */ allowEditing={true}  />
            <Column dataField={'check'} caption={''} dataType='boolean' allowAdding={false} allowEditing={true}/>
            <Column dataField={'lever'} caption={'Réserve levée'} dataType='boolean' allowAdding={false} allowEditing={true} />
          

        </DataGrid >

    </div >);
}

const FormAdd = (props) => {
    //////////console.log(props)
    const dispatch = useDispatch();
    const user = useSelector(({ custom }) => custom.contacts.user.infos)
    const { form, handleChange, setForm } = useForm({id:props.id,value:'', localisation:''});
    const handleSubmit = (e) => {
        e.preventDefault();
        //////////console.log(form)
        if (props.initialVariables.length) {
             firebaseService.firestore.collection('variables').doc('defauts').set(_.keyBy(_.concat(props.initialVariables, {...form, createdBy:user.id}), 'id'))
        } else {
            firebaseService.firestore.collection('variables').doc('defauts').set(_.keyBy({...form, createdBy:user.id}, 'id'))

        }
       // props.component.addRow(form)
        dispatch(Actions.closeDialog())
    }
    return <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden" >
        <DialogContent classes={{ root: "p-24" , height:'500px'}}>
            <SelectVariables id={'defaut_localisation'} label={'Localisation'} handleChange={(f) => {

           
                setForm({...form, localisation:f})
            }} value={''} />
    <TextField
    fullWidth
      label="Défaut"
     name="value"
      value={form.value}
                onChange={handleChange}
                variant='outlined'
    />
      

            </DialogContent>
        <DialogActions className="justify-between p-8">
            <div className="flex flex-end px-16">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                    //disabled={!canBeSubmitted()}
                >
                   Enregistrer
                </Button>
            </div>
        </DialogActions>
    </form>
}

export default App;