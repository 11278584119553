export const SET_ROUTES = '[ROUTES] SET ROUTES';



export function setRoute(route) {
    //console.log('route', route)
    return {
        type: SET_ROUTES,
        route

    }
}


