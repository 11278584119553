import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: {},
objectifs:[],
    routeParams: {},


};

const analyticsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ANALYTICS:
            {
                return {
                    ...state,
                    entities: action.payload,
                    routeParams: action.routeParams
                };
            }
        case Actions.GET_OBJECTIFS:
            {
                return {
                    ...state,
                    objectifs: _.keyBy(action.payload,'id')
                    //routeParams: action.routeParams
                };
            }


        default:
            {
                return state;
            }
    }
};

export default analyticsReducer;
