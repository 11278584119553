import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
////console.log(fr)
i18next.addResourceBundle('en', 'contactsApp', en);
i18next.addResourceBundle('fr', 'contactsApp', fr);


export const ContactsConfig = {
    settings: {
        layout: {}
    },
    routes: [

        {
            path: '/newcontact',
            component: React.lazy(() => import('./ContactDialog'))

        },
        {
            path: '/contacts/:id/:type',
            component: React.lazy(() => import('./ContactsApp'))
        },
        {
            path: '/contacts',
            component: () => <Redirect to="/contacts/all/contacts" />
        }
    ]
};
