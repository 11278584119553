import React, { useEffect, useState, useRef, useMemo} from 'react';
import { DataGrid, Column, MasterDetail, Editing, Lookup, Form, Popup, Position, StateStoring, RequiredRule} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import TableAppareil from './tableAppareil'
import SelectVariables from 'app/custom/components/selects/selectVariable'
import _ from '@lodash'
import variables from 'app/store/reducers/custom/selectVariables.reducers';
import { useSelector, useDispatch } from 'react-redux';
import { FuseUtils } from '@fuse';
import * as Actions from 'app/store/actions'
import { loadMessages, locale } from 'devextreme/localization';
import deMessages from "app/custom/translation/fr.json";
import TabsAppareils from './TabsAppareil'

function Batteries(props) {
    ////console.log(props)
const dispatch = useDispatch()
    const {  data , batimentId, batterieId} = props
    const variables = useSelector(({ custom }) => custom.variables);
    const initialForm = useSelector(({ custom }) => custom.rapports.form);
    //////console.log(initialForm)
    const [dataSource, setDataSource] = useState([])
    const ref = useRef('')
    const [state, setState] = useState({
        selectTextOnEditStart: true,
        startEditAction: 'click'
    })
    //////console.log(props)
    useMemo(() => {
        loadMessages(deMessages);
        //////////////console.log(facturesList)
        locale(navigator.language);

    }, [])
    useEffect(() => {
        var d = []
        if (batterieId) {
            d = _.filter(initialForm.data.appareils, c => {
          
                //////console.log(c.batimentId, data.data.batimentId, c.batterieId, data.data.id)
                return c.batimentId === batimentId && c.batterieId === batterieId
            })
        } else {
            d = _.filter(initialForm.data.appareils, c => {

                    return c.batimentId === data.data.id  && !c.batterieId
                })
         }
   

       if (!_.isEqual(d, dataSource))
            setDataSource(d)


    }, [initialForm.data.appareils])

    function addNewRow() {
        ref.current.instance.addRow();
    }

    function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                hint: 'Ajouter un appareil',
                text: "Nouvel appareil",
                icon: 'plus',
                onClick: addNewRow,

            }
        }, {
            location: 'before',
            widget: 'dxButton',
            options: {
                //width: 136,
                text: `APPAREILS ${props.batterieId ? ' - Batterie ' + _.find(initialForm.data.batteries, { id: batterieId }).name : 'du bâtiment '+_.find(initialForm.data.batiments, { id:data.data.id }).name}`,

            }
        })
    }
    function DetailTemplate(e) {
        ////////console.log(e)
        return <React.Fragment>

            <TabsAppareils {...e} ></TabsAppareils>


        </React.Fragment>
    }

   ////console.log('batimentId', batimentId ,dataSource)
    return (
        <React.Fragment>
          
            <DataGrid
                key='id'
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                 columnHidingEnabled={false}
                rowAlternationEnabled={true}
                onToolbarPreparing={onToolbarPreparing}
                dataSource={dataSource}
                width={'100%'}
                columnAutoWidth={true}
                ref={ref}
              
                onSelectionChanged={
                    (e) => {
                        //////console.log(e)
                    }
                }

                onRowInserted={
                    
                    (e) => {
                        //////console.log(e)
                        var F = {}
                        if (batterieId)
                            F = { ..._.omit(e.data, '__KEY__'), batimentId:  batimentId , batterieId: batterieId  }
                        else
                            F = { ..._.omit(e.data,'__KEY__'), batimentId: batimentId ? batimentId :data.data.id }
                       // 

                        dispatch(Actions.setFormAudit(initialForm.id, {
                            ...initialForm.data, appareils: _.concat(_.filter(initialForm.data.appareils, c => {
                                return c.id !== e.data.id
                            }), F)
                        }))
                       

                    }}

                onRowUpdated={
                    (e) => {
                        //////console.log(e)
                        var F = {}
                        if (batterieId)
                            F = { ..._.omit(e.data, '__KEY__'), batimentId: batimentId, batterieId: batterieId }
                        else
                            F = { ..._.omit(e.data, '__KEY__'), batimentId: batimentId ? batimentId : data.data.id}
                  
                     
                        dispatch(Actions.setFormAudit(initialForm.id, {
                            ...initialForm.data, appareils: _.concat(_.filter(initialForm.data.appareils, c => {
                                return c.id !== e.data.id
                            }), F)
                        }))
                    }}
           
                onRowRemoved={
                    (e) => {
                        //////console.log(e)
                        dispatch(Actions.setFormAudit(initialForm.id, {
                            ...initialForm.data, appareils: _.filter(initialForm.data.appareils, d => {

                                return d.id !== e.data.id
                            })
                        }))
                      
                    }}
                
                onInitNewRow={async (e) => {
                    
                    //////console.log(e)
                    //////console.log(ref.current.instance.getDataSource())
                    var initialDatas = ref.current.instance.getDataSource()._items
                    var F = {}
                    if (batterieId)
                        F = {
                            ..._.find(initialForm.data.batteries, c => {
                                return batterieId == c.id
                            }), ...e.data, id: FuseUtils.generateGUID(), batimentId: batimentId ? batimentId : data.data.id, batterieId: data.data.id
                        }
                    else
                        F = { ...e.data, id: FuseUtils.generateGUID(), batimentId: batimentId ? batimentId : data.data.id}
                    e.data = initialDatas.length ? { ...initialDatas[initialDatas.length - 1], ...F, name: '' } : {
                        ...F, name: '', reglementation_IGH: false, reglementation_ERP: false,
                        reglementation_habitation: false,   reglementation_code_travail:false}
                }}
            >
              {/*   <MasterDetail
                    
                    enabled={true}
                    component={DetailTemplate}
                    autoExpandAll={true}
                />  */}
              <StateStoring enabled={true} type="localStorage" storageKey={`rapport_appareils_${ batimentId ? batimentId : data.data.id}`} /> 
                <Editing mode="popup"
                    allowUpdating={
                        true
                    }
                    allowAdding={
                       false
                    }
                    allowDeleting={
                        true
                    }

                 /*    selectTextOnEditStart={
                        state.selectTextOnEditStart
                    }

                    startEditAction={
                        state.startEditAction
                    } */

                >
                    <Popup title="" showTitle={false} >
                        <Position my="top" at="top" of={window} />
                    </Popup>
                    <Form>
                     
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="name" />
                            <Item dataField="type_appareil" />
                            <Item dataField="marque_appareil" />
                      
                            <Item dataField="annee_construction" />
                            <Item dataField="situation_machinerie" />
                            <Item dataField="controleur" />
                            <Item dataField="entrainement" />
                            <Item dataField="machine" />
                            <Item dataField="charge" />
                            <Item dataField="vitesse" />
                          
                        </Item>
                        <Item itemType="group" caption="Niveaux" colCount={2} colSpan={1} >
                            <Item dataField="nombre_niveaux" />
                            <Item dataField="niveaux_desservis" />
                            </Item>
                        <Item itemType="group" caption="Portes Cabine" colCount={4} colSpan={1} >
                            <Item dataField="porte_cabine_type" />
                            <Item dataField="porte_cabine_nombre_vantaux" />
                            <Item dataField="porte_cabine_ouverture" />
                            <Item dataField="porte_cabine_passage_libre" />
                        </Item>
                        <Item itemType="group" caption="Portes Palières" colCount={3} colSpan={1}>
                            <Item dataField="porte_paliere_type" />
                            <Item dataField="porte_paliere_nombre_vantaux" />
                            <Item dataField="porte_paliere_ouverture" />
                       
                        </Item>
                        <Item itemType="group" caption="Type de manoeuvre" colCount={2} colSpan={1} >
                            <Item dataField="manoeuvre_type" />
                            <Item dataField="manoeuvre_destination" />

                        </Item>
                        <Item itemType="group" caption="Local poulies" colCount={2} colSpan={1}>
                            <Item dataField="local_poulies" />
                            <Item dataField="local_poulies_commentaires" />

                        </Item>
                        <Item itemType="group" caption="Classements" colCount={3} colSpan={1}>
                            <Item dataField="reglementation_ERP" />
                            <Item dataField="reglementation_IGH" />
                            <Item dataField="reglementation_habitation" />
                            <Item dataField="reglementation_code_travail" />
                             <Item dataField="reglementation_none" />
                        </Item>
                    </Form>
                </Editing>
                <Column type="buttons"/>
                <Column dataField="name" caption={'Nom'}>
                    <RequiredRule />
                </Column>
                <Column dataField="type_appareil" editCellComponent={(e) => {
                    //////console.log(e)
                    return < SelectVariables id={'type_appareil'} handleChange={(f) => {

                        e.data.setValue(f)
                    }} value={e.data.value} />
                }} >
            
                </Column>
                <Column dataField="marque_appareil" editCellComponent={(e) => {
                    //////console.log(e)
                    return < SelectVariables id={'marque_appareil'} handleChange={(f) => {

                        e.data.setValue(f)
                    }} value={e.data.value} />
                }} >
         
                </Column>
              
                <Column dataField="annee_construction" caption='Année de construction' dataType='number'>
                    <RequiredRule />
                </Column>
                <Column dataField="situation_machinerie" caption='Situation machinerie'>
                    <Lookup dataSource={['Haute', 'Basse déportée', 'Basse']}></Lookup>
                    <RequiredRule />
                </Column>
                <Column dataField="controleur" caption='Contrôleur / Fabricant'>
                    <RequiredRule />
                </Column>
                <Column dataField="entrainement" caption={'Entraînement'} >
                    <Lookup dataSource={['Adhérence', 'Tambour', 'Hydraulique']}></Lookup>
                    <RequiredRule />
                </Column>
                <Column dataField="machine" caption='Machine / Fabricant' >
                    <RequiredRule />
                </Column>
                <Column dataField="charge" caption='Charge (kg)' dataType="number" >
                    <RequiredRule />
                </Column>
                <Column dataField="vitesse" caption='Vitesse (m/s)' dataType="number" >
                    <RequiredRule />
                </Column>
                <Column caption="Portes cabines">
                    <Column dataField="porte_cabine_type" caption='Type' dataType="select" >
                        <Lookup dataSource={['Automatiques', 'Manuelles']}></Lookup>
                        <RequiredRule />
                    </Column>
                    <Column dataField="porte_cabine_nombre_vantaux" caption='Nbre Vantaux' dataType="number" >
                        <RequiredRule />
                    </Column>
                    <Column dataField="porte_cabine_ouverture" caption='Ouverture' dataType="select">
                        <Lookup dataSource={['Latérale', 'Centrale']}></Lookup>
                        <RequiredRule />
                    </Column>
                    <Column dataField="porte_cabine_passage_libre" caption='Passage libre (mm)' dataType="number" >
                        <RequiredRule />
                    </Column>
                </Column>
                 <Column caption="Niveaux">
                    <Column dataField="nombre_niveaux" dataType='number' caption={'Nombre de niveaux'}>
            <RequiredRule />
          </Column>
                    <Column dataField="niveaux_desservis" caption={'Niveaux desservis'}>
            <RequiredRule />
          </Column>
                </Column>
                <Column caption="Portes palières">
                    <Column dataField="porte_paliere_type" caption='Type' dataType="select" >
                        <Lookup dataSource={['Battantes pleines', 'Battantes manuelles']}></Lookup>
                        <RequiredRule />
                    </Column>
                    <Column dataField="porte_paliere_nombre_vantaux" caption='Nbre Vantaux' dataType="number" >
                        <RequiredRule />
                    </Column>
                    <Column dataField="porte_paliere_ouverture" caption='Ouverture' dataType="select">
                        <Lookup dataSource={['Latérale', 'Centrale']}></Lookup>
                        <RequiredRule />
                    </Column>
        
                </Column>
                <Column caption="Manoeuvre">
                    <Column dataField="manoeuvre_type" caption='Type' dataType="select" >
                        <Lookup dataSource={['Simplex', 'Duplex', 'Triplex', 'Quadruplex', 'Sextuplex', 'Octoplex']}></Lookup>
                        <RequiredRule />
                    </Column>
                    <Column dataField="manoeuvre_destination" caption='Destination' dataType="select" >
                        <Lookup dataSource={['Collective descente', 'Collective montée/descente', 'Blocage', 'À enregistrement de destination']}></Lookup>
                        <RequiredRule />
                    </Column>
                </Column>
                <Column dataField="suspension" caption='Suspension' dataType="select" >
                    <Lookup dataSource={['1/1', '2/1', '2/2', '4/2']}></Lookup>
                    <RequiredRule />
                </Column>
                <Column  caption="Local poulies">
                <Column dataField='local_poulies' caption='Type' dataType="select" >
                        <Lookup dataSource={['Sans objet', 'Local poulie']}></Lookup>
                        <RequiredRule />
                    </Column>
                <Column dataField="local_poulies_commentaires" caption='Commentaire' />
                </Column>
                <Column caption='Classement'>
                <Column dataField="reglementation_ERP" dataType='boolean'/>
                <Column dataField="reglementation_IGH" dataType='boolean' />
                    <Column dataField="reglementation_habitation" dataType='boolean' />
                    <Column dataField="reglementation_code_travail" dataType='boolean' />
                     <Column dataField="reglementation_none" dataType='boolean' />
                </Column>
             

            </DataGrid>
        </React.Fragment>
    );


}



export default Batteries;
