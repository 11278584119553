import { combineReducers } from 'redux';
import fuse from './fuse';

import auth from 'app/auth/store/reducers';
import custom from './custom'
const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        custom,
        ...asyncReducers
    });

export default createReducer;
