import React, { useState } from 'react'
import { Card, CardContent, Typography, Tabs, Tab, Divider } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { FuseAnimate } from '@fuse';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import FirebaseLoginTab from './tabs/FirebaseLoginTab';

import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => {


    return({
        root: {
            backgroundImage: "url('/assets/images/demo-content/morain-lake.jpg')",
        //background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + darken(theme.palette.primary.dark, 0.5) + ' 100%)',
        color: theme.palette.primary.contrastText
    }
})});

function Login() {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const { t } = useTranslation('loginApp');
    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    return (


        <div className={clsx(classes.root, "flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32")}>

            <div className="flex flex-col items-center justify-center w-full">

                <FuseAnimate animation="transition.expandIn">

                    <Card className="w-full max-w-384">

                        <CardContent className="flex flex-col items-center justify-center p-32">

                            <img className="w-128 m-32" src="assets/images/logos/fuse.svg" alt="logo" />

                            <Typography variant="h6" className="mt-16 mb-32">{t('LOGIN')}</Typography>

                            <FirebaseLoginTab t={t} />

                            <div className="my-24 flex items-center justify-center">
                                <Divider className="w-32" />
                                <span className="mx-8 font-bold">{t('OR')}</span>
                                <Divider className="w-32" />
                            </div>
                            <div className="flex flex-col items-center justify-center pt-32 pb-24">
                                <span className="font-medium">{t('PASSWORD_LOST')}?</span>
                                <Link className="font-medium" to="/forgot-password">{t('RENEW_PASSWORD')}</Link>
                            </div>


                            { /*   <div className="flex flex-col items-center justify-center pt-32 pb-24">
                                <span className="font-medium">Don't have an account?</span>
                                <Link className="font-medium" to="/pages/auth/register">Create an account</Link>
                            </div> */}

                        </CardContent>
                    </Card>
                </FuseAnimate>
            </div>
        </div>

    )
}

export default Login;
