import React, { useEffect, useState, useRef, useMemo} from 'react';
import { DataGrid, Column, MasterDetail, Editing, StateStoring, RequiredRule} from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import TableAppareil from './tableAppareil'
import SelectVariables from 'app/custom/components/selects/selectVariable'
//const tasks = service.getTasks();
import { useSelector, useDispatch } from 'react-redux';
import { FuseUtils } from '@fuse';
import * as Actions from 'app/store/actions'
import _ from '@lodash'
import { loadMessages, locale } from 'devextreme/localization';
import deMessages from "app/custom/translation/fr.json";
import TabsAppareils from './TabsAppareil'
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
function Batteries(props) {

    const { data } = props
    //////console.log(props)
    const dispatch = useDispatch()


    const initialForm = useSelector(({ custom }) => custom.rapports.form);
    const [dataSource, setDataSource] = useState([])
    const [state, setState] = useState({
        selectTextOnEditStart: true,
        startEditAction: 'click'
    })
    const ref = useRef('')
    useMemo(() => {
        loadMessages(deMessages);
        //////////////console.log(facturesList)
        locale(navigator.language);

    }, [])
    useEffect(() => {
        //////console.log(initialForm.data.batteries, dataSource)
        var d = _.filter(initialForm.data.batteries, c => {
            return c.batimentId === data.data.id
        })
        if (!_.isEqual(dataSource, d))
            setDataSource(d)
    }, [initialForm.data.batteries])

    function DetailTemplate(e) {
//////console.log(e, data)
        return (<React.Fragment>
           {/*  <TabsAppareils {...e} batterieId={e.data.data.id}></TabsAppareils> */}
            <TableAppareil {...e}  batimentId={data.data.id} batterieId={e.data.data.id}></TableAppareil>
     


        </React.Fragment>
      
)
    }

    function addNewRow() {
        ref.current.instance.addRow();
    }

    function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                hint: 'Ajouter une batterie',
                text: "Nouvelle batterie",
                icon: 'plus',
                onClick: addNewRow,

            }
        }, {
            location: 'before',
                widget: 'dxButton',
            options: {
                //width: 136,
                text: `Batteries du bâtiment ${_.find(initialForm.data.batiments, { id: data.data.id }).name}`,

            }
        })
    }
   
        return (
            <React.Fragment >
               
                <DataGrid
                    dataSource={dataSource}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    columnHidingEnabled={true}
                    rowAlternationEnabled={true}
                    ref={ref}
                    width={'100%'}
                    onToolbarPreparing={onToolbarPreparing}
                    columnAutoWidth={true}
                    onRowInserted={
                        (e) => {

                            //////console.log(e.data)
                            e.data.name = capitalize(e.data.name)
                            //////console.log(ref.current.instance.getDataSource())
                            dispatch(Actions.setFormAudit(initialForm.id, { ...initialForm.data, batteries: _.concat(initialForm.data.batteries ? initialForm.data.batteries : [], e.data) }))
                            ////props.form.setFieldValue('batiments', _.concat(initialForm.data.batiments ? initialForm.data.batiments : [], e.data), e.data)
                            /*    if (dataSource.length)
                                   firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(_.concat(dataSource, e.data), 'id'))
                               else {
                                   firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(e.data, 'id'))
           
                               } */

                        }}

                    onRowUpdated={
                        (e) => {
                            //////console.log(e.data)

                            e.data.name = capitalize(e.data.name)

                            dispatch(Actions.setFormAudit(initialForm.id, {
                                ...initialForm.data, batteries: _.concat(_.filter(initialForm.data.batteries, c => {
                                    return c.id !== e.data.id
                                }), e.data)
                            }))

                            /*   firebaseService.firestore.collection('variables').doc(field.name).set(
                                  _.keyBy(_.concat(_.filter(dataSource, d => {
                                      return d.id != e.data.id
                                  }), e.data), 'id')) */

                        }}
                    onRowRemoved={
                        (e) => {
                            //////console.log(e)
                            dispatch(Actions.setFormAudit(initialForm.id, {
                                ...initialForm.data, batteries: _.filter(initialForm.data.batteries, d => {

                                    return d.id !== e.data.id
                                }), appareils: _.filter(initialForm.data.appareils, d => {

                                    return d.batterieId !== e.data.id
                                }),
                            }))

                        }}
                    onInitNewRow={async (e) => {

                        //////console.log(e)
                        //////console.log(ref.current.instance.getDataSource())
                        var initialDatas = ref.current.instance.getDataSource()._items

                        e.data = initialDatas.length ? { ...initialDatas[initialDatas.length - 1], id: FuseUtils.generateGUID(), name: '', batimentId: data.data.id } : {
                            id: FuseUtils.generateGUID(), name: '', batimentId: data.data.id, reglementation_IGH: false, reglementation_ERP: false,
                        reglementation_habitation:false}
                    }}
                >
                    <StateStoring enabled={true} type="localStorage" storageKey={`rapport_batteries_${data.data.id}`} />
                    <MasterDetail
                        enabled={true}
                        component={DetailTemplate}
                        //autoExpandAll={true}
                    />
                    <Editing mode="form"
                        allowUpdating={
                            true
                        }
                        allowAdding={
                            false
                        }
                        allowDeleting={
                            true
                        }


                        

                    />
                    <Column type="buttons" />
                    <Column dataField="name" caption='Nom'>
                        <RequiredRule />
                    </Column>
                    <Column dataField="annee_construction" dataType='number'>
                        <RequiredRule />
                </Column>
                    <Column dataField="marque_appareil" editCellComponent={(e) => {
////////console.log(e.data)
                        return < SelectVariables id={'marque_appareil'} handleChange={(f) => {

                            e.data.setValue(f)
                        }} value={e.data.value} />
                    }} >
                        <RequiredRule />
                    </Column>
                    <Column caption='Classement'>
                    <Column dataField="reglementation_ERP"  dataType={'boolean'} />
                    <Column dataField="reglementation_IGH" dataType={'boolean'} />
                        <Column dataField="reglementation_habitation" dataType='boolean' />
                        <Column dataField="reglementation_code_travail" dataType='boolean' />
                        </Column>
                    <Column dataField="machinerie_commune" dataType={'boolean'} />
           
                   
                </DataGrid>
            </React.Fragment>
        );
    
  
}



export default Batteries;
