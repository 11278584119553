var locale = {
    'LOGIN': 'Connexion à votre compte',
    'OR': 'ou',
    EMAIL: 'Email',
    PASSWORD: "Mot de passe",
    CONNECT: "Connexion",
    PASSWORD_LOST: "Mot de passe perdu",
    RENEW_PASSWORD: "Renouveler mon mot de passe",
}

export default locale