import React, { useCallback, useEffect, useRef, useState, useMemo, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { Formik, Field, Form, ErrorMessage, FieldArray, TextField } from "formik";
import { useField } from "formik";
import SelectClients from 'app/custom/components/selects/selectClients'
import SelectSites from 'app/custom/components/selects/selectSites'
import SelectContacts from 'app/custom/components/selects/selectContacts'
import SelectVariables from 'app/custom/components/selects/selectVariable'
import ReactDOM from 'react-dom';
import _ from "@lodash";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Paper from "@material-ui/core/Paper";
import TableComponent from './tableVariables';
import TableBatiment from './tableBatiments';
import moment from 'moment'
import {
    Button,
    LinearProgress,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox, Typography, TextField
} from "@material-ui/core";
import MuiTextField from "@material-ui/core/TextField";
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Divider from '@material-ui/core/Divider'
import ReactStars from "react-rating-stars-component";
import * as Actions from 'app/store/actions'
import Modernisation from './Modernisation.js';
import DefaultsTab from './Defauts'
interface DefaultTabProps {
    
}

const Tab = (props) => {

    return <DefaultsTab {...props} name={'defauts'}/>
}
const DefaultTab: React.FC<DefaultTabProps> = () => {

    const dispatch = useDispatch()
    const initialForm = useSelector(({ custom }) => custom.rapports.form);
    const [state, setState] = useState('')
    useEffect(() => {
        async function fetchData() {
            var d = await _.filter(initialForm.data.appareils, c => {
                return c.batterieId
            })
            d = _.concat(d, _.filter(initialForm.data.appareils, c => {
                return !c.batterieId
            }))
        
  
            var bat = {}
            _.map(_.groupBy(d, 'batimentId'), (c, i) => {
                bat[i]= _.groupBy(c,'batterieId')
            })
            if(!_.isEqual(bat, state))
                setState(bat)
        }
        fetchData();
       
      
    }, [initialForm.data.batteries, initialForm.data.appareils,  initialForm.data.batiment])

    return (
        <div>
         { /*   {_.map(state, (bat, i) => {
                
                return _.map(bat, (batterie, j) => {
                      if(j!=='undefined')
                          return <Tab batimentId={i} batterieId={j} key={j} />
                      else {
                        return  _.map(batterie, (b, l) => {
                              return <Tab batimentId={i} appareilId={b.id}  key={b.id} />

                          })
                    }
                    })
                
        })} */}

            <DefaultsTab  name={'defauts'} />
        </div>
    );
};

export default DefaultTab;