import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";
//import CreatableSelect from 'react-select/creatable';
import * as Actions from 'app/custom/sites/store/actions';
import * as ActionsAll from 'app/store/actions'
import withReducer from 'app/store/withReducer';
import reducer from 'app/custom/sites/store/reducers';
import ReactSelectMaterialUi from 'react-select/creatable';
import _ from '@lodash'
import { FuseUtils } from '@fuse';
import EditSite from 'app/custom/sites/siteDialogContent'
type State = {
    options: [{ [string]: string }],
    value: string | void,
};

const createOption = (label: string) => ({
    label,
    value: FuseUtils.generateGUID(),
});

const defaultOptions = [

];
const customStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    })

}
const reactSelectTheme = error => theme => {
    const errorStyling = error
        ? {
            primary: 'red',
            neutral10: 'red',
            neutral30: 'red',
            neutral20: 'red',
            neutral60: 'red',
        }
        : {};

    return ({
        ...theme,
        menu: base => ({
            ...theme.base,
            zIndex: 100
        }),
        colors: {
            ...theme.colors,
            ...errorStyling,
        },
    });
};
const CreatableAdvanced = (props) => {
    ////////console.log(props)
    const sites = useSelector(({ custom }) => custom.sites.sites.entities);

    const [state, setState] = useState({
        value: null, options: [], isLoading: false
    });

    const dispatch = useDispatch();
    useEffect(() => {
        if (!_.keys(sites))
            dispatch(Actions.getSites())
    }, [])



    /*  useEffect(() => {
  
         setState({
             ...state, options: _.compact(_.map(sites, c => {
                 if (props.client && c.client === props.client)
                     return { label: c.address + ' ' + c.zip + ' ' + c.city, value: c.id }
                 else if (!props.client)
                     return { label: c.address + ' ' + c.zip + ' ' + c.city, value: c.id }
             })), isLoading: false
         })
         ////////////console.log(props.site, sites)
     }, [props.site, sites]) */
    useEffect(() => {
        //////console.log(props)
        var site = sites[props.site]
        setState({
            value: site ? { label: site.dropbox_id.name, value: site.id } : null, options: _.compact(_.map(sites, c => {
                if (props.client) {
                    if (props.client && c.client.id === props.client) {
                        ////////console.log(props.client, c.client === props.client, c.client, props.client)
                        return { label: c.dropbox_id.name, value: c.id }
                    }
                }
                else
                    ////////console.log(props.client, c.client === props.client, c.client, props.client)
                    return { label: c.dropbox_id.name, value: c.id }


                /*  else if (!props.client)
                     return { label: c.address + ' ' + c.zip + ' ' + c.city, value: c.id } */
            })), isLoading: false
        })
        ////console.log(props.client, sites, props.consultant)
    }, [props.client, sites, props.consultant])
    const handleChange = (newValue: any, actionMeta: any) => {
        //////////////console.group('Value Changed');
        //////////////console.log(newValue);
        //////////////console.log(`action: ${actionMeta.action}`);
        setState({ value: newValue, options: state.options, isLoading: state.isLoading });
        props.handleChange(newValue ? sites[newValue.value] : {}, props.id, props.parent)
        //////////////console.groupEnd();


    };
    const handleCreate = (inputValue: any) => {
        ////console.log(inputValue)
        setState({ isLoading: true });

        //////////////console.group('Option created');
        //////////////console.log('Wait a moment...');
        if (inputValue.id) {
            var s = sites[props.site]
            ////console.log(s)
            const { options } = state;

            //const newOption = createOption(inputValue);
            //////////////console.log(newOption);
            //////////////console.groupEnd();
            var site = { label: inputValue.address2 ? inputValue.address2 + ' ' + inputValue.address + ' ' + inputValue.zip + ' ' + inputValue.city : inputValue.address + ' ' + inputValue.zip + ' ' + inputValue.city, value: inputValue.id }
            // dispatch(Actions.addContact(client, 'client'));


            setState({
                isLoading: false,
                options: [options ? { ...options } : {}, site],
                value: site,
            });
            props.handleChange(site ? inputValue : null, props.id, props.parent)
        }
        else {
            var data = {
                select: site,
                client: props.client,
                handleChange: handleCreate

            }
            dispatch(ActionsAll.openNewSiteDialog({ ...data }, 'site'))
        }
        // dispatch(ActionsAll.openDialog({ children: <EditSite handleChange={handleCreate} client={props.client} contact={props.contact} /> }))

    };

    const { isLoading, options, value } = state;

    return (
        <div className='flex flex-col'>
            <span>{props.label ? props.label : 'Site'}</span>
            <ReactSelectMaterialUi theme={reactSelectTheme(!value || (value && !value.label))}

                className="mb-24 mr-12 min-w-320"

                styles={customStyles}
                label={props.label ? props.label : ''}
                id="site"
                name="site"
                required
                variant="outlined"
                margin="dense"
                helperText=""
                fullWidth
                isClearable
                isCreatable={!props.isCreatable ? true : false}
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={handleChange}
                onCreateOption={handleCreate}
                options={options}
                value={value}
                placeholder={'Sélectionner...'}
            /></div>
    );

}
CreatableAdvanced.propTypes = {
    label: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    site: PropTypes.string,
}


export default withReducer('sitesApp', reducer)(CreatableAdvanced)