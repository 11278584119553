import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import * as ActionsAll from 'app/store/actions';
import _ from '@lodash'
import moment from "moment";
import "moment/locale/fr";
import { FuseUtils } from '@fuse';
import DataGrid, {  Lookup,RequiredRule,Column, ColumnChooser, Pager, ColumnFixing, Editing, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Selection, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import deMessages from "app/custom/translation/fr.json";
import { loadMessages, locale } from 'devextreme/localization';
import { TextField, Button, Dialog, DialogActions, DialogContent, Checkbox, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from '@fuse/hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import SelectClients from 'app/custom/components/selects/selectClients';
import SelectContacts from 'app/custom/components/selects/selectContacts';

import SelectSites from 'app/custom/components/selects/selectSites';
import firebaseService from 'app/services/firebaseService';




const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
        height: '100%'
    },
}));
const GridElement = (form) => {

    return ''
}
const Infos = (props) => {
    //////console.log(props)
    const dispatch = useDispatch();
    const wrapperRef = useRef(props.tab);
    const grid = useRef(props.tab)
    const classes = useStyles();
    const userAuth = useSelector(({ auth }) => auth.user.uid);
    //var variables = useSelector(({ custom }) => custom.variables[props.tab]);
    const [list, setList] = useState([])

    const [form, setForm] = useState(props.form[props.tab])

    useMemo(() => {
        loadMessages(deMessages);

        locale(navigator.language);
    }, [])
    useEffect(() => {
      
        if (!_.isEqual(props.form[props.tab], form))
            setForm(props.form[props.tab])

    }, [props.match.params, props.form[props.tab]]);

    useOutsideAlerter(wrapperRef, grid, form);
    function useOutsideAlerter(ref, grid, form) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {

                if (ref.current && !ref.current.contains(event.target)) {
                    //////console.log(grid.current.instance.getDataSource()._items)

                    form = grid.current.instance.getDataSource()._items
                    //////console.log(!_.isEqual(props.form[props.tab], form));
                    if (!_.isEqual(props.form[props.tab], form))
                        props.handleChange(props.tab, form)
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, form]);
    }
    //////console.log(form)
    return (
        <div className={classes.formControl} ref={wrapperRef}>

          
                <DataGrid
                    id={props.tab}
          
                    height={'100%'}
                   
                    ref={grid}
                    dataSource={form}
                    allowColumnResizing={false}
                    allowColumnReordering={false}
                    columnAutoWidth={false}
                    columnHidingEnabled={false}
                    columnResizingMode={'widget'}
                    rowAlternationEnabled={true}
                    showBorders={false}

                    keyExpr="id"
                    wordWrapEnabled={true}
                    onInitNewRow={(e) => {
                        if (!grid.current.instance.getDataSource()._items.length)
                            e.data = { id: FuseUtils.generateGUID(), name: '', batiment: '', parentType: 'batterie' }
                        else {
                            e.data = { ...grid.current.instance.getDataSource()._items[grid.current.instance.getDataSource()._items.length - 1], id: FuseUtils.generateGUID(), name: '' }
                        }
                    }}
                   onRowInserted={
                        (e) => {
                            props.handleChange(props.tab, grid.current.instance.getDataSource()._items)
                        }
                    }
                    onRowUpdated={
                        (e) => {
                            props.handleChange(props.tab, grid.current.instance.getDataSource()._items)

                        }}
                    onRowRemoved={
                        (e) => {
                            props.handleChange(props.tab, grid.current.instance.getDataSource()._items)

                        }} 
                >
                    <Scrolling mode="virtual" />
                    <StateStoring enabled={true} type="localStorage" storageKey={"audit_batteries"} /> 
                    {/* 
                <ColumnFixing enabled={true} />
                <Export enabled={true} allowExportSelectedData={true} icon={'xlsxfile'} />
                <Selection mode={"multiple"} />
                <ColumnChooser enabled={true} />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} /> */}
                    <SearchPanel visible={true}
                        //width={240}
                        placeholder="Recherche..." />
                    <FilterRow visible={false} />
                    <GroupPanel visible={false} />
                    {/* <Paging defaultPageSize={10} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} /> */}
                    <Editing
                        mode="cell"
                        useIcons={true}
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={(e => {
                            return true
                        })} />



                <Column type={'buttons'} />
                    <Column caption={'Bâtiment'} with={300} dataField="batiment" dataType={'string'} >
                        <Lookup dataSource={props.form.batiments} valueExpr={'id'} displayExpr={'name'} />
                        <RequiredRule />
                        </Column>
                    <Column caption={'Nom'} with={200} dataField="name" dataType={'string'} >
                        <RequiredRule />
                    </Column>

                </DataGrid>
          

        </div>
    )

}

export default withRouter(Infos);