import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Card, CardContent, Typography, Switch, FormControlLabel, Tooltip } from '@material-ui/core';
import firebaseService from 'app/services/firebaseService';
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import axios from 'axios';

const ROOT_URL = URL_API; //
const { messaging } = firebaseService
const styles = theme => ({
    card: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    noteTextPos: {
        marginTop: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
    },
    customWidth: {

        maxWidth: 300,

    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = { gnibApptSubscriptionToggleSwitch: false }; // we set the toggle switch to false on component load
        this.gnibApptSubscriptionToggle = this.gnibApptSubscriptionToggle.bind(this);
        this.subscribeGnibApptNotifications = this.subscribeGnibApptNotifications.bind(this);
        this.unsubscribeGnibApptNotifications = this.unsubscribeGnibApptNotifications.bind(this);
        this.notificationPermission = this.notificationPermission.bind(this);
    }


    /**
     * If registration token is available in localStorage we enable the subscription option to indicate that the user has 
     * already subscribed
     */
    componentDidMount() {
        localStorage.getItem('GNIB_APPT_NOTIFICATION_SUBSCRIBED') === "TRUE" ? this.setState({ gnibApptSubscriptionToggleSwitch: true }) : this.setState({ gnibApptSubscriptionToggleSwitch: false });
    }
    /**
     * Check if user has already given permission for sending notifications
     * If not, request permission from user, generate instance token and store it in firestore
     */
    async notificationPermission() {
        let permissionGranted = false;
        try {
            /* request permission if not granted */
            /*   if (Notification.permission !== 'granted') {
                  await messaging.requestPermission();
              } */
            /* get instance token if not available */
            if (localStorage.getItem('INSTANCE_TOKEN') !== null) {
                permissionGranted = true;
            } else {

                permissionGranted = true;
            }
        } catch (err) {
            //console.log(err);
            if (err.hasOwnProperty('code') && err.code === 'messaging/permission-default') {
                //console.log('You need to allow the site to send notifications');
            }
            else if (err.hasOwnProperty('code') && err.code === 'messaging/permission-blocked') {
                //console.log('Currently, the site is blocked from sending notifications. Please unblock the same in your browser settings');
            } else {
                //console.log('Unable to subscribe you to notifications');
            }
        } finally {
            return permissionGranted;
        }
    }
    /* Send the subscription details(token and topic) to the server endpoint
    */
    async subscriptionActions(mode, token, topic) {
        var t = await axios({ url: `${ROOT_URL}/${mode}`, method: 'post', data: { token, topic } })

        //console.log(t)
        return t
    }

    /**
     * Subscribe app instance to notification topic if user permissions given
     */
    async subscribeGnibApptNotifications() {
        const notificationPermission = await this.notificationPermission();
        if (notificationPermission) {
            const isSubscribed = await this.subscriptionActions('SUBSCRIBE', localStorage.getItem('INSTANCE_TOKEN'), 'GNIB_APPT_NOTIFICATIONS_TOPIC');
            if (isSubscribed) {
                localStorage.setItem('GNIB_APPT_NOTIFICATION_SUBSCRIBED', "TRUE");
                this.setState({ gnibApptSubscriptionToggleSwitch: true });
                //console.log('GNIB(IRP) appointment notifications have been enabled for your device')
                //this.displayMessage(<span>GNIB(IRP) appointment notifications have been enabled for your device</span>);
            } else {
                //console.log('Unable to subscribe you to notifications')
                // this.displayMessage(<span>Unable to subscribe you to notifications</span>);
            }
        }
    }

    /**
     * Unsubscribe app instance from notification topic
     */
    async unsubscribeGnibApptNotifications() {
        const isUnSubscribed = await this.subscriptionActions('UNSUBSCRIBE', localStorage.getItem('INSTANCE_TOKEN'), 'GNIB_APPT_NOTIFICATIONS_TOPIC');
        if (isUnSubscribed) {
            localStorage.removeItem('GNIB_APPT_NOTIFICATION_SUBSCRIBED');
            // await this.deleteTokenFromDb();
            this.setState({ gnibApptSubscriptionToggleSwitch: false });
            //console.log('You have been unsubscribed from notifications')
            // this.displayMessage(<span>You have been unsubscribed from notifications</span>);
        } else {
            //console.log('Unsubscribe failed')
            // this.displayMessage(<span>Unsubscribe failed</span>);
        }
    }

    /**
     * Subscribe/UnSubscribe appointment notifications
     */
    gnibApptSubscriptionToggle(event, checked) {
        if (checked) this.subscribeGnibApptNotifications();
        else this.unsubscribeGnibApptNotifications();
        this.props.userMenuClose()
    }
    renderSubscriptionOptions(classes) {
        if (!('serviceWorker' in navigator) && !('PushManager' in window)) {
            return (
                <Typography className={classes.noteTextPos}>
                    Notification feature is supported only in:<br />
                    Chrome Desktop and Mobile (version 50+)<br />
                    Firefox Desktop and Mobile (version 44+)<br />
                    Opera on Mobile (version 37+)
                </Typography>
            );
        } else {
            return (
                <Fragment>
                    <Tooltip placement={'left'} title={"Notification feature is supported only in: Chrome Desktop and Mobile (version 50+), Firefox Desktop and Mobile (version 44+) & Opera on Mobile (version 37+)"} >

                        <FormControlLabel
                            control={<Switch />}
                            label={this.props.t('NOTIFICATION_ALLOW')}
                            onChange={this.gnibApptSubscriptionToggle}
                            checked={this.state.gnibApptSubscriptionToggleSwitch}
                        />
                    </Tooltip>
                </Fragment>
            );
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>

                {this.renderSubscriptionOptions(classes)}

            </Fragment>
        );
    }
}

Notifications.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Notifications);