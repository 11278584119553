

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectBox from 'devextreme-react/select-box';
import DataSource from "devextreme/data/data_source";
import ArrayStore from 'devextreme/data/array_store';
import * as ActionsAll from 'app/store/actions';
import _ from '@lodash'
import moment from "moment";
import "moment/locale/fr";
import { FuseUtils } from '@fuse';
import { TextBox } from 'devextreme-react/text-box';
import { TagBox } from 'devextreme-react/tag-box';
import DataGrid, { Lookup, RequiredRule, Column, ColumnChooser, Pager, ColumnFixing, Editing, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Selection, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import deMessages from "app/custom/translation/fr.json";
import { loadMessages, locale } from 'devextreme/localization';
import { InputAdornment, Input, TextField, Button, Dialog, DialogActions, DialogContent, Checkbox, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from '@fuse/hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import SelectClients from 'app/custom/components/selects/selectClients';
import SelectContacts from 'app/custom/components/selects/selectContacts';

import SelectSites from 'app/custom/components/selects/selectSites';
import SelectVariables from 'app/custom/components/selects/selectVariable';
import firebaseService from 'app/services/firebaseService';
import CustomPopup from 'app/custom/rapports/audit/installation/customPopup'
import TreeMenu from 'app/custom/rapports/audit/installation/treeMenu.js'
{/* 
 <Column dataField={'batiment'} caption={'Bâtiment'} groupIndex={0} allowEditing={false} allowAdding={false} />
            <Column allowEditing='false' dataField={'batterieName'} caption={'Batterie'} groupIndex={1} allowAdding={false} />
            <Column dataField={'appareilName'} allowEditing='false' caption={'Appareil'} groupIndex={2} allowAdding={false} />
            <Column dataField={'localisation'} caption={'Localisation'} groupIndex={3}  defaultSortOrder = { 'desc'} >
    <Lookup dataSource={['Machinerie', 'Machinerie/Hydraulique', 'Toit de cabine', 'Opérateur / Porte cabine', 'Cabine', 'Gaine', 'Paliers', 'Cuvette']}></Lookup>
            </Column >
            <Column dataField={'value'} caption={'Etat des defaults'}  allowEditing={false} />
            <Column dataField={'etages'} caption={'Niveaux concernés'}  allowEditing={true} />
            <Column dataField={'check'} caption={''} dataType='boolean' allowAdding={false} allowEditing={true} />
            <Column dataField={'lever'} caption={'Réserve levée'} dataType='boolean' allowAdding={false} allowEditing={true} />
*/}



const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
        height: '100%'
    },
}));
const GridElement = (form) => {

    return ''
}
const Infos = (props) => {
    ////console.log(props)
    const dispatch = useDispatch();
    const wrapperRef = useRef(props.tab);
    const grid = useRef(props.tab + 'C')
    const classes = useStyles();
    const userAuth = useSelector(({ auth }) => auth.user.uid);
    const variablesList = useSelector(({ custom }) => custom.variables);
    //////console.log(variablesList)
    const [years, setYears] = useState([])
    const [visible, setVisible] = useState(false)
    const [variables, setVariables] = useState({})
    //var variables = useSelector(({ custom }) => custom.variables[props.tab]);
    const [list, setList] = useState([])

    const [form, setForm] = useState(props.form[props.tab])
    const [appareilsFormat, setAppareilsFormat] = useState([])
    useMemo(() => {
        loadMessages(deMessages);

        locale(navigator.language);
    }, [])
    useEffect(() => {

        //setYears(_.reverse(rangeOfYears(new Date("Jun 26 1900").getFullYear(), new Date().getFullYear())))
        if (!_.isEqual(props.form[props.tab], form))
            setForm(props.form[props.tab])

    }, [props.match.params, props.form[props.tab]]);
    useEffect(() => {
        var v = {}
        _.map(variablesList, (c, f) => {
            if (f != 'modernisations')
                v[f] = new DataSource({
                    store: _.map(c.values, (m, i) => {

                        return { id: i, value: m }
                    }),
                    key: 'id',
                    sort: { getter: "value", asc: true }

                });
            else {
                ////console.log(c)
                v[f] = new DataSource({
                    store: _.values(c),
                    key: 'id',
                    sort: { getter: "value", asc: true }

                });
            }

        })
        setVariables(v)
        var batiment = _.keyBy(props.form.installation.batiments, 'id')
        var batterie = _.keyBy(props.form.installation.batteries, 'id')

        var t = _.map(props.form.installation.appareils, (c, i) => {

            var name = ''
            name += batiment[c.batiment].name + ' | '
            name += batterie[c.batterie] ? batterie[c.batterie].name + ' | ' + c.name : c.name
            return {
                ...c, fullName: {
                    id: c.id,
                    batiment: batiment[c.batiment].name,
                    batterie: batterie[c.batterie] ? batterie[c.batterie].name : '',
                    name: name,
                }
            }
        })
        setAppareilsFormat(t)


    }, [])

    useOutsideAlerter(wrapperRef, grid, form);
    function useOutsideAlerter(ref, grid, form) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {

                if (ref.current && !ref.current.contains(event.target)) {
                    //////console.log(grid.current.instance.getDataSource()._items)

                    form = grid.current.instance.getDataSource()._items
                    //////console.log(!_.isEqual(props.form[props.tab], form));
                    // if (!_.isEqual(props.form[props.tab], form) && !visible)
                    // props.handleChange(props.tab, form)
                }
            }


            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, form]);
    }

    function getFilteredBatteries(options) {
        //////console.log(options, props.form.batteries)
        return {
            store: props.form.batteries,
            filter: options.data ? ['batiment', '=', options.data.batiment] : null
        };
    }
    const rangeOfYears = (start, end) => Array(end - start + 1)
        .fill(start)
        .map((year, index) => year + index)

    const capital_letter = (str) => {

        str = str.split(' ');

        for (var i = 0, x = str.length; i < x; i++) {
            if (str[i][0])
                str[i] = str[i][0].toUpperCase() + str[i].substr(1);
        }

        return str.join(' ')

    }
    const selectVariable = (cellInfo, bdd) => {
        ////console.log(cellInfo)
        ////console.log(variablesList[bdd])
        function customItemCreating(e) {
            ////console.log(e)
            const nextId = Math.max.apply(Math, variables[bdd].items().map(c => c.id)) + 1;

            e.customItem = { id: nextId, value: e.text };

            variables[bdd].store().insert(e.customItem);
            cellInfo.setValue(e.text, e.text)


            variables[bdd].reload();

        }
        ////console.log(_.filter(_.values(variablesList[bdd]), { localisation: cellInfo.data.localisation }))
        return (
            <SelectBox
                dataSource={bdd != 'modernisations' ? variables[bdd] : new DataSource({
                    store: _.filter(_.values(variablesList[bdd]), { localisation: cellInfo.data.localisation }),
                    key: 'id',
                    sort: { getter: "value", asc: true }
                })}
                valueExpr={bdd != 'modernisations' ? "id" : 'value'}
                displayExpr="value"
                defaultValue={cellInfo.value}
                acceptCustomValue={true}
                onSelectionChanged={(e) => {
                    ////console.log(e)
                    if (e.selectedItem.value != cellInfo.value)
                    cellInfo.setValue(e.selectedItem.value, e.selectedItem.value)
                }}
                onCustomItemCreating={customItemCreating}
            />
        );

    }

    const selectMultipleVariable = (cellInfo, bdd) => {
        ////console.log(cellInfo)
        //////console.log(variables[bdd])
        function customItemCreating(e) {

            const nextId = Math.max.apply(Math, variables[bdd].items().map(c => c.id)) + 1;

            e.customItem = { id: nextId, value: e.text };

            variables[bdd].store().insert(e.customItem);
            cellInfo.setValue(e.text, e.text)
            variables[bdd].reload();

            if (!_.includes(variablesList[bdd].values, e.text))
                firebaseService.firestore.collection('variables').doc(bdd).set({ name: variablesList[bdd].name, values: _.concat(variablesList[bdd].values, e.text) })
        }

        return (
            <TagBox
                dataSource={variables[bdd]}
                valueExpr="value"
                displayExpr="value"
                defaultValue={cellInfo.value}
                acceptCustomValue={true}
                onValueChanged={(e) => {
                    //////console.log(e)
                    cellInfo.setValue(e.value)
                }}
                onCustomItemCreating={customItemCreating}
            />
        );

    }

    const customTextField = (cellInfo, bdd, unit) => {

        const setEditedValue = valueChangedEventArg => {

            return cellInfo.setValue(valueChangedEventArg.value ? valueChangedEventArg.value : '');
        }
        return (


            <TextBox
                id={bdd}

                valueChangeEvent={'blur'}
                defaultValue={cellInfo.value}
                onValueChanged={setEditedValue}

                aria-describedby={bdd}
            />
        )
    }

    /* const customPopup = (cellInfo, bdd) => {
        //////console.log(cellInfo, visible, !visible)
 
        const setEditedValue = valueChangedEventArg => {
            //////console.log(valueChangedEventArg)
            setVisible(false)
            cellInfo.value = valueChangedEventArg
            cellInfo.text = valueChangedEventArg
            return cellInfo.setValue(valueChangedEventArg);
        }
        return (
 
            <div>
                <TextField
                    id={bdd}
 
                    // onBlur={(e) => setEditedValue(e.target.value)}
                    defaultValue={cellInfo.value}
                    aria-describedby={bdd}
                    onClick={(e) => {
                        setVisible(true)
                        dispatch(ActionsAll.openDialog({
                            children: <CustomPopup cell={cellInfo} form={form} handleChange={setEditedValue} />
                        }))
 
                    }}
                    disabled={true}
                />
 
            </div>
        )
    } */
    function setStateValue(rowData, value) {
        rowData.batiment = null;
        this.defaultSetCellValue(rowData, value);
    }
    function selectTree(cellInfo, bdd) {
        ////console.log(cellInfo)

        const setEditedValue = valueChangedEventArg => {
            ////console.log(valueChangedEventArg)
            if (cellInfo.value != valueChangedEventArg){
            cellInfo.value = valueChangedEventArg
            cellInfo.text = valueChangedEventArg
            return cellInfo.setValue(valueChangedEventArg);}
        }
        ////console.log(props)

        var tree = []

        _.map(props.form.installation.batiments, c => {
            tree = _.concat(tree, { ...c, parentId: 0 })
        })
        _.map(props.form.installation.batteries, c => {
            tree = _.concat(tree, { ...c, parentId: c.batiment })

        })
        _.map(props.form.installation.appareils, c => {
            tree = _.concat(tree, { ...c, parentId: c.batterie == '-' ? c.batiment : c.batterie, selected: _.includes(cellInfo.value, c.id) ? true : false })

        })
        ////console.log(tree)
        return (

            <div>
                <TreeMenu
                    value={cellInfo.value}
                    data={cellInfo}
                    tree={tree}
                    handleChange={setEditedValue}
                />

            </div>
        )
    }
    ////console.log(appareilsFormat)
    ////console.log(variables)
    ////console.log(form)
    return (
        <div className={classes.formControl} ref={wrapperRef}>


            <DataGrid
                id={props.tab}

                height={'100%'}
                ref={grid}
                dataSource={form}
                allowColumnResizing={true}
                allowColumnReordering={false}
                columnAutoWidth={true}
                columnResizingMode={'widget'}
                rowAlternationEnabled={true}
                showBorders={true}
                columnHidingEnabled={true}
                keyExpr="id"
                wordWrapEnabled={true}

               /*  onCellClick={(e) => {
                    if (e.rowType == 'data' && e.column.dataField == "lever") {
                        ////console.log(e)
                        let list = _.keyBy(form, 'id')

                        list[e.key] = { ...list[e.key], lever: e.value }
                        props.handleChange(props.tab, _.values(list))
                    }
                }} */
                onValueChanged={(e) => {
                    ////console.log(e)
                }}
                onInitNewRow={(e) => {
                    if (!grid.current.instance.getDataSource()._items.length)
                        e.data = { id: FuseUtils.generateGUID(), value: '', localisation: '', appareils: []}
                    else {
                        e.data = { ...grid.current.instance.getDataSource()._items[grid.current.instance.getDataSource()._items.length - 1], id: FuseUtils.generateGUID(), value: '' }
                    }
                }}

                onRowInserted={
                    (e) => {
                        ////console.log(e.data)
                        var t = _.concat(form, e.data)
                        ////console.log(t)
                        props.handleChange(props.tab, _.uniqBy(t, 'id'))

                        if (e.data.value && e.data.localisation) {
                            if (!_.includes(variablesList['defaut_localisation'].values, e.data.localisation)) {
                                firebaseService.firestore.collection('variables').doc('defaut_localisation').set({ name: variablesList['defaut_localisation'].name, values: _.concat(variablesList['defaut_localisation'].values, capital_letter(e.data.localisation)) }).catch(e => {
                                    ////console.log(e)
                                })
                            }
                            if (!_.includes(variablesList.modernisations, { value: e.data.value, localisation: e.data.localisation })) {
                                var h = _.concat(_.values(variablesList.modernisations), _.omit({ ...e.data, createdBy: userAuth, value: e.data.value }, 'appareil', 'lever', 'etage'))
                                firebaseService.firestore.collection('variables').doc('modernisations').set(_.keyBy(h, 'id')).catch(e => {
                                    ////console.log(e)
                                })
                            }
                        }

                    }
                }
                onRowUpdated={
                    (e) => {
                    console.log(e.data)
                        var t = _.concat(form, e.data)
                        ////console.log(t)
                        props.handleChange(props.tab, _.uniqBy(t, 'id'))

                        if (e.data.value && e.data.localisation) {
                            if (!_.includes(variablesList['defaut_localisation'].values, e.data.localisation)) {
                                firebaseService.firestore.collection('variables').doc('defaut_localisation').set({ name: variablesList['defaut_localisation'].name, values: _.concat(variablesList['defaut_localisation'].values, capital_letter(e.data.localisation)) }).catch(e => {
                                    ////console.log(e)
                                })
                            }
                            if (!_.includes(_.values(variablesList.modernisations), { value: e.data.value, localisation: e.data.localisation })) {
                                var h = _.concat(_.values(variablesList.modernisations), _.omit({ ...e.data, createdBy: userAuth, value: e.data.value }, 'appareil', 'lever', 'etage'))
                                firebaseService.firestore.collection('variables').doc('modernisations').set(_.keyBy(h, 'id')).catch(e => {
                                    ////console.log(e)
                                })
                            }
                        }

                    }}
                onRowRemoved={
                    (e) => {
                       // props.handleChange(props.tab, grid.current.instance.getDataSource()._items)

                    }}
            >
          {/*       <Scrolling mode="virtual" /> */}
                {/* 
                <ColumnFixing enabled={true} />
                <Export enabled={true} allowExportSelectedData={true} icon={'xlsxfile'} />
                <Selection mode={"multiple"} />
                <ColumnChooser enabled={true} />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} /> */}
                  <StateStoring enabled={true} type="localStorage" storageKey={"audit_annexeC"} /> 
                <SearchPanel visible={true}
                    //width={240}
                    placeholder="Recherche..." />
                <FilterRow visible={false} />
                <GroupPanel visible={true} />
                <StateStoring enabled={true} type="localStorage" storageKey={"audit_annexeC"} /> 
                {/* <Paging defaultPageSize={10} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} /> */}
                <Editing
                    mode="cell"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={(e => {
                        return true
                    })} />
                <Column type={'buttons'} />

                <Column caption={'Localisation'} dataField="localisation" editCellRender={(e) => selectVariable(e, 'defaut_localisation')}  />



                <Column dataField={'value'} caption={'Amélioration'}  editCellRender={(e) => selectVariable(e, 'modernisations')}  />
                <Column caption={'Appareils concernés'} dataField="appareils" editCellRender={(e) => selectTree(e, 'modernisations')} cellRender={(e) => {

////console.log(e)
 
                    if (e && e.value) {
                        var batiments = _.keyBy(props.form.installation.batiments, 'id')
                        var batteries = _.keyBy(props.form.installation.batteries, 'id')
                        var appareils = _.keyBy(props.form.installation.appareils, 'id')
                        return <div>{_.map(e.value, f => {
                            ////console.log(f, appareils[f])
                            if (f) {
                                let c = appareils[f]
                                if (c)
                                    return <p key={f}>{`
                            ${c.batiment && batiments[c.batiment] ? batiments[c.batiment].name + ' | ' : ''}
                            ${c.batterie && batteries[c.batterie] ? batteries[c.batterie].name + ' | ' : ''}
                        ${c.name}
                        `}</p>
                            }
                        })} </div>
                    } else return <div></div>
                }} />
                <Column dataField={'prix'} caption={'Prix HT/appareil'} dataType='number' />
                <Column dataField={'prix'} caption={'Prix Total HT'} dataType='number' allowEditing={false} calculateCellValue={(e) => {
                    ////console.log(e)
                    if (e && e.appareils && e.appareils.length && e.prix)
                        return Math.round(e.appareils.length * e.prix * 100) / 100
                    if (e && e.appareils && !e.appareils.length && e.prix)
                        return Math.round(1 * e.prix * 100) / 100
                }}/>


            </DataGrid>


        </div>
    )

}

export default withRouter(Infos);