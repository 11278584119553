import * as Actions from '../actions';

const initialState = {
    role: [],//guest
    data: {
        'displayName': '',
        'photoURL': '',
        'email': '',
        shortcuts: []
    }
};

const user = function (state = initialState, action) {
    console.log(action, state, initialState)
    switch (action.type) {
        case Actions.SET_USER_DATA:
            {
                return {
                    ...initialState,
                    ...action.payload
                };
            }
        case Actions.REMOVE_USER_DATA:
            {
                return {
                    ...initialState
                };
            }
        case Actions.USER_LOGGED_OUT:
            {
                return initialState;
            }
        default:
            {
                return state
            }
    }
};

export default user;
