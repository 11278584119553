import * as Actions from 'app/store/actions';
import _ from '@lodash';
import DataGrid, {
    Column,
    Editing,
    Lookup,
    MasterDetail,
    Paging,
    Selection,
    StateStoring, RequiredRule
} from 'devextreme-react/data-grid';
import deMessages from 'app/custom/translation/fr.json';
import dialog from 'app/store/reducers/fuse/dialog.reducer';
import firebaseService from 'app/services/firebaseService';
import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import TableAppareil from './tableAppareil';
import TableBatterie from './tableBatterie';
import { FuseUtils } from '@fuse';
import { loadMessages, locale } from 'devextreme/localization';
import { useDispatch, useSelector } from 'react-redux';


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
const App = props => {
//console.log(props)

    const dispatch = useDispatch()

    const ref = useRef('')
    const variables = useSelector(({ custom }) => custom.variables);
    const initialForm = useSelector(({ custom }) => custom.rapports.form);
    const [dataSource, setDataSource] = useState([])
    const [address, setAddress] = useState('')
    const [selectingRowKeys, setSelectingRowKeys] = useState([])

    const [state, setState] = useState({
        selectTextOnEditStart: true,
        startingitAction: 'click'
    })

    useMemo(() => {
        loadMessages(deMessages);
        ////////////////console.log(facturesList)
        locale(navigator.language);

    }, [])

    useEffect(() => {
        ////////console.log(initialForm.data.batiments, dataSource)
        if (!_.isEqual(dataSource, _.uniqBy(initialForm.data.batiments, 'id')))
            setDataSource(initialForm.data.batiments ? _.uniqBy(initialForm.data.batiments, 'id') : [])
    }, [initialForm.data.batiments])

    useEffect(() => {

        if (initialForm.data.site && initialForm.data.site.address && !_.isEqual(address, initialForm.data.site.address))
            setAddress(initialForm.data.site.address)
    }, [initialForm.data.site])


    function addNewRow() {
        ref.current.instance.addRow();
    }

    function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                hint: 'Ajouter un bâtiment',
                text: "Nouveau bâtiment",
                icon: 'plus',
                onClick: addNewRow,

            }
        } ,{
            location: 'before',
                widget: 'dxButton',
            options: {
                //width: 136,
                text: 'Bâtiments',
                
            }
        })
    }
    function DetailTemplate(e) {
        //////////console.log(e)
        return <React.Fragment>

            <TableBatterie {...e} ></TableBatterie>
            <TableAppareil {...e} ></TableAppareil>

        </React.Fragment>
    }

    ////////console.log(dataSource)
    return (<div id="data-grid-demo" className={'max-w-full'}>
        <DataGrid
            width='100%'
            dataSource={dataSource}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            columnHidingEnabled={true}
            rowAlternationEnabled={true}
            columnAutoWidth={true}
            ref={ref}
            onToolbarPreparing={onToolbarPreparing}
            //defaultSelectingRowKeys={selectingRowKeys}
            keyExpr="id"

            showBorders={
                true
            }
            onRowPrepared={(e) => {

                // ////////console.log(e)
            }}
            onSelectionChanged={
                (e) => {
                    ////////console.log(e)
                    ////props.form.setFieldValue(field.name, e.selectingRowsData)

                }
            }
            onRowInserted={
                (e) => {

                    ////////console.log(e.data)
                    e.data.name = capitalize(e.data.name)
                    ////////console.log(ref.current.instance.getDataSource())
                    dispatch(Actions.setFormAudit(initialForm.id, { ...initialForm.data, batiments: _.concat(initialForm.data.batiments ? initialForm.data.batiments : [], e.data) }))
                    ////props.form.setFieldValue('batiments', _.concat(initialForm.data.batiments ? initialForm.data.batiments : [], e.data), e.data)
                    /*    if (dataSource.length)
                           firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(_.concat(dataSource, e.data), 'id'))
                       else {
                           firebaseService.firestore.collection('variables').doc(field.name).set(_.keyBy(e.data, 'id'))
   
                       } */

                }}

            onRowUpdated={
                (e) => {
                    ////////console.log(e.data)

                    e.data.name = capitalize(e.data.name)

                    dispatch(Actions.setFormAudit(initialForm.id, {
                        ...initialForm.data, batiments: _.concat(_.filter(initialForm.data.batiments, c => {
                            return c.id !== e.data.id
                        }), e.data)
                    }))

                    /*   firebaseService.firestore.collection('variables').doc(field.name).set(
                          _.keyBy(_.concat(_.filter(dataSource, d => {
                              return d.id != e.data.id
                          }), e.data), 'id')) */

                }}

            onRowRemoved={
                (e) => {
                    ////////console.log(e)
                    dispatch(Actions.setFormAudit(initialForm.id, {
                        ...initialForm.data, batiments: _.filter(initialForm.data.batiments, d => {

                            return d.id !== e.data.id
                        }),batteries: _.filter(initialForm.data.batteries, d => {

                            return d.batimentId !== e.data.id
                        }), appareils: _.filter(initialForm.data.appareils, d => {

                            return d.batimentId !== e.data.id
                        }),
                    }))

                }}

            onInitNewRow={async (e) => {

                ////////console.log(e)
                ////////console.log(ref.current.instance.getDataSource())
                var initialDatas = ref.current.instance.getDataSource()._items

                e.data = initialDatas.length ? { ...initialDatas[initialDatas.length - 1], id: FuseUtils.generateGUID(), name: '' } : { id: FuseUtils.generateGUID(), name: '', address: address }
            }}
        >
            <StateStoring enabled={true} type="localStorage" storageKey={'rapport_batiments'} />
            <MasterDetail
                enabled={true}
                component={DetailTemplate}
                //autoExpandAll={true}
            />
         <Paging enabled={
                false
            }

            /> <Editing mode="batch"
                allowUpdating={
                    true
                }
                allowAdding={
                    false
                }
                allowDeleting={
                    true
                }


            />


            <Column dataField={'name'} caption={'Nom'} /* editorType="dxTextArea" */ >
                <RequiredRule />
            </Column>
            <Column dataField={'address'} caption={'Adresse'} /* editorType="dxTextArea" */ >
                <RequiredRule />
            </Column>

        </DataGrid >

    </div >);
}

export default App;