import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '@fuse/index';
/* import { appsConfigs } from 'app/main/apps/appsConfigs';
import { pagesConfigs } from 'app/main/pages/pagesConfigs'; */
/* import { authRoleExamplesConfigs } from 'app/main/auth/authRoleExamplesConfigs';
import { UserInterfaceConfig } from 'app/main/user-interface/UserInterfaceConfig';
import { DocumentationConfig } from 'app/main/documentation/DocumentationConfig'; */
import { LoginConfig } from 'app/auth/login/LoginConfig';
import { RegisterConfig } from 'app/auth/register/RegisterConfig';
import { LogoutConfig } from 'app/auth/logout/LogoutConfig';
import { ResetPasswordPageConfig } from 'app/auth/reset-password/ResetPasswordPageConfig'
/* import { CallbackConfig } from 'app/main/callback/CallbackConfig'; */
import { ContactsConfig } from 'app/custom/contacts/ContactsAppConfig';
import { CommandesConfig } from 'app/custom/commandes/CommandesAppConfig';
import { ForgotPasswordPageConfig } from 'app/auth/forgot-password/ForgotPasswordPageConfig';
import { SitesConfig } from 'app/custom/sites/sitesAppConfig';
import { LeadsConfig } from 'app/custom/leads/leadsAppConfig';
import { FacturesConfig } from 'app/custom/facturation/facturationAppConfig';
import { DashBoardConfig } from 'app/custom/dashboard/dashBoardAppConfig';
import { AnalyticsConfig } from 'app/custom/analytics/analyticsAppConfig';
import { RapportsConfig } from 'app/custom/rapports/rapportAppConfig';
import { FormsConfig } from 'app/custom/forms/formAppConfig';
import { Error404PageConfig } from 'app/custom/errors/404/Error404PageConfig';
import { Error500PageConfig } from 'app/custom/errors/500/Error500PageConfig';
import { TimelineConfig } from 'app/custom/timeline/timelineAppConfig';
const routeConfigs = [
    /*   ...appsConfigs,
      ...pagesConfigs,
      ...authRoleExamplesConfigs,
      UserInterfaceConfig,
      DocumentationConfig, */
    /*  CallbackConfig, */
    //DashBoardConfig,
    ForgotPasswordPageConfig ,
    LeadsConfig,
    LogoutConfig,
    LoginConfig,
    RegisterConfig,
    LogoutConfig,
    TimelineConfig,
    ContactsConfig,
    CommandesConfig,
    SitesConfig,
    FacturesConfig,
    AnalyticsConfig,
    ResetPasswordPageConfig, 
    RapportsConfig,
    FormsConfig,
    Error500PageConfig,
    Error404PageConfig



];

const routes = [
    //if you want to make whole app auth protected by default change defaultAuth for example:
    // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
    // The individual route configs which has auth option won't be overridden.
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/" />
    },

    {
        component: () => <Redirect to="/errors/error-404" />
    }
];

export default routes;