import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import _ from '@lodash'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import shortUrl from 'node-url-shortener';
import './tinyMce.css'

const App = (props) => {
   


    const storage = firebaseService.storage
    const [state, setState] = useState(props.values)
    const ref = useRef(props.id)
    useEffect(() => {

        if (!_.isEqual(props.values, state))
            setState(props.values)
    }, [props.values])
    useEffect(() => {
        return () => {
            
                console.log('******************* UNMOUNTED');
      };
    }, []);
    const handleEditorChange = (content, editor) => {

        props.handleChange(content)
    }

    const toDataURL = url => fetch(url, { mode: 'cors' })
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))


  

    return (
        <Editor
id={props.id}
            ref={ref}
            selector={`#${props.id}`}
            apiKey="dk2q70wmjbf13m074umbko10z0t6zt8746wdbk3m81fu1jgu"
            initialValue={state}
            /*  onBlur={(e) => {
                if(props.id == 'eg_maintenance') console.log(e.target.value)
                   //handleEditorChange(e.target.value)
   
               }}  */
            init={{
                fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                entity_encoding: "raw",
                encoding: "UTF-8",
                // selector:  '#'+props.data.data.id,
                //theme: "modern",
                //selector:  'textarea',
                //height: 500,
                //inline: true,
                imagetools_cors_hosts: ['progiciel.movveo.fr', 'localhost:3000', 'localhost:5002', 'https://europe-west1-movveo-v0.cloudfunctions.net'],
                language: 'fr_FR',
            
                menu: {
                    file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
                    edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
                    view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
                    insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
                    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat' },
                    tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
                    table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
                    help: { title: 'Help', items: 'help' }
                },
                toolbar_mode: 'floating',
                plugins: [
                    'advlist autolink lists link charmap print preview anchor pagebreak',
                    'searchreplace visualblocks code fullscreen',
                    'wordcount table',
                    'wordcount image autoresize media ',
                    'insertdatetime paste help '
                ],
                
                file_picker_types: 'image',
                content_style: 'body { font-family:Arial,Helvetica,Calibri,sans-serif; font-size:12px }',
                object_resizing: true,
                image_dimensions:false,
                images_upload_handler: async (blobInfo, success, failure) => {
                    /*     https://sitechecker.pro/wp-content/uploads/2017/12/URL-meaning.png */
                    if (props.id == 'eg_maintenance') {
                        console.log(blobInfo)
                    }



                    var reader = new FileReader();
                    reader.readAsDataURL(blobInfo.blob());
                    reader.onloadend = function () {

                        console.log(reader)
                        var base64data = reader.result;
                        if (props.id == 'eg_maintenance') {
                            console.log(base64data);
                        }
                        success(base64data)
                        reader.onerror = function (err) {
                         
                            failure(err);
                        }
                    }
                },
              /*   toolbar:
                    'undo redo | sizeselect | bold italic backcolor | fontselect | fontsizeselect \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | image | help' */
            }}

            onEditorChange={handleEditorChange}
           /*  onKeyup={e => {
                e.stopPropagation();
                e.preventDefault();

                var delay = _.debounce(handleEditorChange, 500);

                delay();
            }} */
        />
    );

}

export default App;