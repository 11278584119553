import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
export const GET_COMPONENTS = '[COMPONENTS APP] GET_COMPONENTS';
export const OPEN_NEW_SELECT_DIALOG = '[COMPONENTS APP] OPEN NEW SELECT DIALOG';
export const CLOSE_NEW_SELECT_DIALOG = '[COMPONENTS APP] CLOSE NEW SELECT DIALOG';
export const OPEN_EDIT_SELECT_DIALOG = '[COMPONENTS APP] OPEN EDIT SELECT DIALOG';
export const CLOSE_EDIT_SELECT_DIALOG = '[COMPONENTS APP] CLOSE EDIT SELECT DIALOG';
export function getComponents(collection) {

    return async (dispatch, getState) => {

        firebaseService.firestore.collection(collection).onSnapshot((r) => {
            var components = {}
            if (r.size > 0)
                r.forEach(doc => {
                    components[doc.id] = { ...doc.data(), id: doc.id }
                });


            return dispatch({
                type: GET_COMPONENTS,
                payload: components
            })

        })
    }

}
export function openNewSelectDialog(data) {

    return {
        type: OPEN_NEW_SELECT_DIALOG,
        payload: data
    }
}

export function closeNewSelectDialog() {
    return {
        type: CLOSE_NEW_SELECT_DIALOG,
        payload: null
    }
}

export function openEditSelectDialog(data, type) {

    return {
        type: OPEN_EDIT_SELECT_DIALOG,
        payload: data
    }
}



export function closeEditSelectDialog() {
    return {
        type: CLOSE_EDIT_SELECT_DIALOG,
        payload: null
    }
}