import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";
//import CreatableSelect from 'react-select/creatable';
import * as Actions from 'app/custom/contacts/store/actions';
import firebaseService from 'app/services/firebaseService';
import ReactSelectMaterialUi from 'react-select/creatable'
import Select, { components } from 'react-select';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from '@lodash'
import { FuseUtils } from '@fuse';

function compare(a, b) {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
}
const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
];


const SortableMultiValue = SortableElement(props => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const innerProps = { onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
});
function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
}
//const SortableSelect = SortableContainer();
const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: base => ({
        ...base,
        zIndex: 9999
    })

}

const reactSelectTheme = error => theme => {
    ////////console.log(error)
    const errorStyling = error
        ? {
            primary: 'red',
            neutral10: 'red',
            neutral30: 'red',
            neutral20: 'red',
            neutral60: 'red',
        }
        : {};

    return ({
        ...theme,
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: base => ({
            ...theme.base,
            zIndex: 9999
        }),
        colors: {
            ...theme.colors,
            ...errorStyling,
        },
    });
};

const CreatableAdvanced = (props) => {
    ////console.log(props)

    var variables = useSelector(({ custom }) => custom.variables);

    const [state, setState] = useState({
        value: null,
        options: [],
        loading: false, error: false
    });

    useEffect(() => {
if(!props.localisation){
        if (_.keys(variables).length && !variables[props.id])
            firebaseService.firestore.collection('variables').doc(props.id).set({ name: props.label ? props.label : props.id, values: [] })
        }
    }, [_.keys(variables).length])


    useEffect(() => {
        var v = []
       
        v = variables[props.id]
  
         if(props.localisation)
         v = _.filter(variables[props.id],{localisation:props.localisation})
        ////console.log(v)
        if (props.id == 'description_annexeF'){
          return  setState({
                value: props.value ? { value: props.value, label: props.value } : null, options: _.orderBy(_.uniqBy(_.map(v, c => {
                    return { value: c.value, label: c.value }
                }), 'value'), 'value'), isLoading: false, error: props.value ? false : true
            })
        }
        if (v && !props.isMulti)
            setState({
                value: props.value ? { value: props.value, label: props.value } : null, options: _.orderBy(_.uniqBy(_.map(v.values, c => {
                    return { value: c, label: c }
                }), 'value'), 'value'), isLoading: false, error: props.value ? false : true
            })
        else if (v) {
            setState({
                value: props.value && _.values(props.value).length ? _.map(props.value, p => ({ value: p, label: p })) : [], options: _.orderBy(_.uniqBy(_.map(v.values, c => {
                    return { value: c, label: c }
                }), 'value'), 'value'), isLoading: false, error: props.value && _.values(props.value).length ? false : true
            })
        }
    }, [props.id, props.value, variables[props.id], props.index, props.localisation])

    const handleChange = (newValue: any, actionMeta: any) => {

        ////console.log(newValue, props.id, props.index)

        if (!props.isMulti) {
            setState({ value: newValue, options: state.options, isLoading: state.isLoading, error: newValue ? false : true });
            props.handleChange(newValue ? newValue.label : null, props.id, props.index, props.i)

        } else {
            setState({ value: newValue, options: state.options, isLoading: state.isLoading, error: newValue && newValue.length ? false : true });
            props.handleChange(_.map(newValue, n => n.label), props.id, props.index, props.i)

        }

    };

    const handleCreate = (inputValue: any) => {
        ////console.log(inputValue)
        setState({ isLoading: true });
        if (inputValue)
            inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
        //////////console.log(inputValue)

        ////////////////console.group('Option created');
        ////////////////console.log('Wait a moment...');
 
        // setTimeout(() => {
        const { options } = state;
        if (props.id == 'description_annexeF'){
 firebaseService.firestore.collection('variables').doc(props.id).collection('data').doc(FuseUtils.generateGUID()).set({localisation:props.localisation, value:inputValue?inputValue:''

                 }).then(() => {


        }).catch(err => {
            ////console.log(err)
        })
           } else{
        firebaseService.firestore.collection('variables').doc(props.id).set({
            ...variables[props.id], values: _.uniq(_.concat(variables[props.id].values, Number(inputValue)  ? Number(inputValue) : (props.id == 'marque_appareil' || props.id == 'entreprise_maintenance') ? inputValue.toUpperCase() : inputValue))
        }).then(() => {


        }).catch(err => {
            ////console.log(err)
        })
    }

        if (!props.isMulti) {
            if (props.id == 'marque_appareil' || props.id == 'entreprise_maintenance')
                inputValue = inputValue.toUpperCase()
            setState({
                ...state,
                isLoading: false,
                options: _.uniq(_.concat(options, { value: _.isNumber(inputValue) ? Number(inputValue) : inputValue, label: inputValue })),
                value: { value: _.isNumber(inputValue) ? Number(inputValue) : inputValue, label: inputValue },
            });
            props.handleChange(inputValue, props.id, props.index, props.i)
        } else {

            var val = _.concat(state.value, [{ value: inputValue, label: inputValue }])
            if (props.sort)
                val = val.sort(compare)
            setState({
                ...state,
                isLoading: false,
                options: _.uniq(_.concat(options, { value: inputValue, label: inputValue })),
                value: _.isNumber(val) ? Number(val) : val,
            });
            props.handleChange(_.map(val, v => _.isNumber(v.value) ? Number(v.value) : v.value), props.id, props.index, props.i)
        }


    };
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(state.value, oldIndex, newIndex);
        setState({ ...state, value: newValue });
        props.handleChange(newValue.map(i => i.value), props.id, props.index)
        ////////console.log("Values sorted:", newValue.map(i => i.value));
    };

    const remove = (index) => {
        const items = state.value;
        items.splice(index, 1);
        ////console.log(items)
        setState({ ...state, value: items })
        props.handleChange(_.map(items, v => _.isNumber(v.value) ? Number(v.value) : v.value), props.id, props.index)
    }
    const { isLoading, options, value, error } = state;
    function customFilter(option, searchText) {
        if (
            option.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    }
    ////////console.log(value)
    return (
        <div className='flex flex-col mb-24  ml-12'>
            {!props.noLabel && <span>{props.label ? props.label : variables[props.id] ? variables[props.id].name : ''}</span>}
            <ReactSelectMaterialUi theme={reactSelectTheme(error)}

                className=" min-w-1/4 "
                styles={customStyles}
                //distance={4}
                axis="xy"
                id={props.id}
                name={props.id}

                onSortEnd={onSortEnd}
                required
                //helperText={'toto'}
                variant="filled"
                margin="dense"
                isMulti={props.isMulti ? true : false}
                fullWidth
                isClearable
                isCreatable

                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={handleChange}
                onCreateOption={handleCreate}

                onRemove={(e) => {
                    ////console.log(e)
                    remove(e)
                }}
                placeholder={'Sélectionner...'}
                options={options}
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                /*  components={props.isMulti ? {
                     MultiValue: SortableMultiValue
                 } : ''} */
                value={value}
                closeMenuOnSelect={props.isMulti ? false : true}
            />
            {<FormHelperText>Tapez une nouvelle valeur pour l'enregistrer dans la liste</FormHelperText>}
            {/* props.isMulti && _.values(value).length > 1 && <FormHelperText>Cliquez/Déposez pour ordonner les options</FormHelperText> */}

        </div>
    );

}
CreatableAdvanced.propTypes = {
    label: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    company: PropTypes.string,
}


export default CreatableAdvanced