const locale = {
    'COMMANDES': 'Commandes',
    'FACTURES': 'Factures',
    'RAPPORTS': 'Rapports',
    'OFFRES': 'Offres',
    'ANALYTICS': 'Prévisionnel €',
    'FILE_MANAGER': 'Manager de fichiers',
    'CONTACTS': 'Contacts',
    'CLIENTS': 'Clients',
    'DROPBOX': 'Dropbox',
    'APPLICATIONS': 'Applications',
    'DASHBOARDS': 'Dashboards',
    'CALENDAR': 'Agenda',
    'ECOMMERCE': 'E-Commerce',
    'ACADEMY': 'Académie',
    'MAIL': 'Mail',
    'TODO': 'To-Do',
    'CHAT': 'Chat',
    'SCRUMBOARD': 'Scrumboard',
    'NOTES': 'Notes',
    'SITES': 'Sites',
    'LEADS': 'Leads'
};

export default locale;
