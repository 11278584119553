import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectBox from 'devextreme-react/select-box';
import DataSource from "devextreme/data/data_source";
import ArrayStore from 'devextreme/data/array_store';
import * as ActionsAll from 'app/store/actions';
import _ from '@lodash'
import moment from "moment";
import "moment/locale/fr";
import { FuseUtils } from '@fuse';
import { TextBox } from 'devextreme-react/text-box';
import { TagBox } from 'devextreme-react/tag-box';
import DataGrid, { Lookup, RequiredRule, Column, ColumnChooser, Pager, ColumnFixing, Editing, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Selection, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import deMessages from "app/custom/translation/fr.json";
import { loadMessages, locale } from 'devextreme/localization';
import { InputAdornment, Input, TextField, Button, Dialog, DialogActions, DialogContent, Checkbox, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from '@fuse/hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import SelectClients from 'app/custom/components/selects/selectClients';
import SelectContacts from 'app/custom/components/selects/selectContacts';
import TreeMenu from './treeMenu'
import SelectSites from 'app/custom/components/selects/selectSites';
import SelectVariables from 'app/custom/components/selects/selectVariable';
import firebaseService from 'app/services/firebaseService';
import CustomPopup from 'app/custom/rapports/audit/installation/customPopup'
import appareils from './appareils';





const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
        height: '100%'
    },
}));
const GridElement = (form) => {

    return ''
}
const Infos = (props) => {
  ////console.log(props)
    const dispatch = useDispatch();
    const wrapperRef = useRef(props.tab);
    const grid = useRef(props.tab + 'A')
    const classes = useStyles();
    const userAuth = useSelector(({ auth }) => auth.user.uid);
    var variables = useSelector(({ custom }) => custom.variables[props.tab]);
    //////console.log(variablesList)
    const [years, setYears] = useState([])
    const [visible, setVisible] = useState(false)
   
    //var variables = useSelector(({ custom }) => custom.variables[props.tab]);
    const [list, setList] = useState([])

    const [form, setForm] = useState([])

    useMemo(() => {
        loadMessages(deMessages);

        locale(navigator.language);
    }, [])
    useEffect(() => {
        var listBase = _.compact(_.map(props.form[props.tab], f => {
            if (f.id)
                return f
        }))
        listBase = _.concat(listBase, _.compact(_.map(_.values(variables), c => {
            if (!_.find(listBase, { id: c.id }))
                return { ...c, check: false }
        })))
        ////////console.log(listBase)
        setList(_.compact(listBase))
        setYears(_.reverse(rangeOfYears(new Date("Jun 26 1900").getFullYear(), new Date().getFullYear())))
        if (!_.isEqual(props.form[props.tab], form))
            setForm(props.form[props.tab]) 

    }, [props.match.params, props.form[props.tab]]);
  
   
    useOutsideAlerter(wrapperRef, grid, form);
    function useOutsideAlerter(ref, grid, form) {
     
      
    }

    const rangeOfYears = (start, end) => Array(end - start + 1)
        .fill(start)
        .map((year, index) => year + index)


    
    function selectTree(cellInfo, bdd) {
       ////console.log(cellInfo)

        const setEditedValue = valueChangedEventArg => {
            ////console.log(valueChangedEventArg)
         
            cellInfo.value = valueChangedEventArg
            cellInfo.text = valueChangedEventArg
            return cellInfo.setValue(valueChangedEventArg);
        }
        ////console.log(props)

        var tree = []

        _.map(props.form.batiments, c => {
            tree = _.concat(tree, { ...c, parentId: 0 })
        })
        _.map(props.form.batteries, c => {
            tree = _.concat(tree, { ...c, parentId: c.batiment })

        })
        _.map(props.form.appareils, c => {
            tree = _.concat(tree, { ...c, parentId: c.batterie == '-' ? c.batiment : c.batterie, selected: _.includes(cellInfo.value,c.id)?true:false})

        })
        return (

            <div>
                <TreeMenu
                    value={cellInfo.value}
                    data={cellInfo}
                    tree={tree}
                    handleChange={setEditedValue}
                />

            </div>
        )
    }
    ////console.log(form)
    ////console.log(variables)
    return (
        <div className={classes.formControl} ref={wrapperRef}>


            <DataGrid
                id={props.tab}

                height={'100%'}
                ref={grid}
                dataSource={list}
                allowColumnResizing={true}
                allowColumnReordering={false}
                columnAutoWidth={true}
               columnResizingMode={'widget'}
                rowAlternationEnabled={true}
                showBorders={true}
                columnHidingEnabled={true}
                keyExpr="id"
                wordWrapEnabled={true}
                onInitNewRow={(e) => {
                    if (!grid.current.instance.getDataSource()._items.length)
                        e.data = { id: FuseUtils.generateGUID(), value: '', appareils:[] , check:true}
                    else {
                        e.data = { ...grid.current.instance.getDataSource()._items[grid.current.instance.getDataSource()._items.length - 1], id: FuseUtils.generateGUID(), value: '' }
                    }
                }}

               
                onRowInserted={
                    (e) => {

                        e.data.createdBy = userAuth
                        var tmp = _.concat(_.values(variables), _.omit(e.data, 'check', 'appareils','year'))

                        firebaseService.firestore.collection('variables').doc(props.tab).set(_.keyBy(tmp, 'id')).then(() => {
                            ////////console.log('done')

                        }).catch(err => {
                            ////////console.log(err)
                        })
                        props.handleChange(props.tab, _.filter(grid.current.instance.getDataSource()._items,{check:true}))

                    }
                }
                onRowUpdated={
                    (e) => {
                        ////console.log(e)
                        ////console.log(variables)
                        if (!e.data.createdBy && !_.isEqual(e.data.value, variables[e.data.id].value)) {
                            e.data.createdBy = userAuth
                            var tmp = _.concat(_.values(variables), _.omit(e.data, 'check', 'appareils', 'year'))

                            firebaseService.firestore.collection('variables').doc(props.tab).set(_.keyBy(tmp, 'id')).then(() => {
                                ////////console.log('done')

                            }).catch(err => {
                                ////////console.log(err)
                            })
                        } else if (e.data &&e.data.createdBy == userAuth &&  variables[e.data.id] && 
                            !_.isEqual(e.data.value,
                                variables[e.data.id].value
                            )) {
                            var tmp = _.concat(_.values(variables), _.omit(e.data, 'check', 'appareils', 'year'))

                            firebaseService.firestore.collection('variables').doc(props.tab).set(_.keyBy(tmp, 'id')).then(() => {
                                ////////console.log('done')

                            }).catch(err => {
                                ////////console.log(err)
                            })
                        }
                        props.handleChange(props.tab, _.filter(grid.current.instance.getDataSource()._items, { check: true }))

                    }}
                onRowRemoved={
                    (e) => {

                        if (_.find(_.values(variables), { id: e.data.id })) {
                            var l = _.cloneDeep(variables)
                            delete l[e.data.id];


                            firebaseService.firestore.collection('variables').doc(props.tab).set(l).then(() => {
                                ////////console.log('done')

                            }).catch(err => {
                                ////////console.log(err)
                            })
                        }
                        props.handleChange(props.tab, _.filter(grid.current.instance.getDataSource()._items, { check: true }))
                    }}
            >
                <Scrolling mode="virtual" />
                {/* 
                <ColumnFixing enabled={true} />
                <Export enabled={true} allowExportSelectedData={true} icon={'xlsxfile'} />
                <Selection mode={"multiple"} />
                <ColumnChooser enabled={true} />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} /> */}
                <SearchPanel visible={true}
                    //width={240}
                    placeholder="Recherche..." />
                <FilterRow visible={false} />
                <GroupPanel visible={true} />
               <StateStoring enabled={true} type="localStorage" storageKey={"audit_modernisation"} /> 
                {/* <Paging defaultPageSize={10} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} /> */}
                <Editing
                    mode="cell"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={(e => {
                        if (e.row.data && e.row.data.createdBy && e.row.data.createdBy == userAuth)
                            return true
                        return false
                    })} />
                <Column type={'buttons'} />
                <Column caption={'Année'} dataField="year" dataType={'string'} >
                    <RequiredRule />
                    <Lookup dataSource={years} />
                </Column>
                <Column caption={'Type de modernisation'} dataField="value" dataType={'string'} >
                    <RequiredRule />
                </Column>
                <Column caption={'Oui/Non'} with={50} dataField="check" dataType={'boolean'} />
                <Column caption={'Appareils concernés'} dataField="appareils" editCellRender={(e) => selectTree(e, 'modernisations')} cellRender={(e) => {
          
                    
                   
                    if (e && e.value) {
                    var batiments = _.keyBy(props.form.batiments,'id')
                    var batteries = _.keyBy(props.form.batteries,'id')
                    var appareils = _.keyBy(props.form.appareils,'id')
                        return <div>{_.map(e.value, f => {
                            if (f ) {
                                let c = appareils[f]
                                if(c)
                                return <p key={f}>{`
                            ${c.batiment && batiments[c.batiment] ? batiments[c.batiment].name + '|' : ''}
                            ${c.batterie && batteries[c.batterie] ? batteries[c.batterie].name + '|' : ''}
                        ${c.name}
                        `}</p>
                            }
                        })} </div>
} else return <div></div>
                }} />



            </DataGrid>


        </div>
    )

}

export default withRouter(Infos);