import * as Actions from 'app/store/actions/custom';
import _ from '@lodash';

const initialState = {
    connection: false,

    /*  searchText: '',
     selectedSitesIds: [],
     routeParams: {},
     siteDialog: {
         type: 'new',
         props: {
             open: false
         },
         data: null,
 
     }, */

};


const firebaseConnection = function (state = initialState, action) {
    switch (action.type) {
        case Actions.CHECK_CONNECTION:
            {
                return {
                    ...state,
                    connection: action.payload

                };
            }



        default:
            {
                return state;
            }
    }
};

export default firebaseConnection;
