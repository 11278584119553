import React, { useState } from 'react';
import { Avatar, Button, Icon, ListItemIcon, ListItemText, Popover, MenuItem, Typography, Fab, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as authActions from 'app/auth/store/actions';
import { Link } from 'react-router-dom';
import Notifications from 'app/custom/components/messaging/notifications'
import * as Actions from 'app/store/actions';
import SiteDialog from 'app/custom/sites/siteDialog'
import RapportDialog from 'app/custom/rapports/rapportDialog'
import ContactDialog from 'app/custom/contacts/ContactDialog';
import ClientDialog from 'app/custom/contacts/ClientDialog';
import LeadDialog from 'app/custom/leads/leadsDialog';

import withReducer from 'app/store/withReducer';
import reducer from 'app/custom/contacts/store/reducers';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import en from './i18n/en';
import fr from './i18n/fr';
import { withRouter } from 'react-router-dom';
//////console.log(fr)
i18next.addResourceBundle('en', 'menuApp', en);
i18next.addResourceBundle('fr', 'menuApp', fr);
const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));

function AddMenu(props) {
    ////console.log(props)
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector(({ auth }) => auth.user);
    const { t } = useTranslation('menuApp');
    const [addMenu, setAddMenu] = useState(null);

    const addMenuClick = event => {
        setAddMenu(event.currentTarget);
    };

    const addMenuClose = (type) => {
        //console.log(type)

        switch (type) {
            case 'rapport':
                dispatch(Actions.openNewRapportDialog({}, type))
                break;
            case 'contact':
                dispatch(Actions.openNewContactDialog({}, type))
                break;
            case 'client':
                dispatch(Actions.openNewContactDialog({}, type))
                break;
            case 'site':
                dispatch(Actions.openNewSiteDialog({}, type))
                break;
            case 'lead':
                dispatch(Actions.openNewLeadDialog({}, type))
                break;
            default:
                break
        }

        setAddMenu(null);
    }



    return (

        <React.Fragment>
            <Tooltip title={t("Nouveau")} aria-label={t("Nouveau")}>
                <Fab onClick={addMenuClick} size={"small"} color='secondary' className={classes.fab}>
                    <Icon className="text-16 " variant="action">add</Icon>
                </Fab>
            </Tooltip>

            <Popover
                open={Boolean(addMenu)}
                anchorEl={addMenu}
                onClose={addMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                classes={{
                    paper: "py-8"
                }}
            >

                <React.Fragment>
                    <MenuItem onClick={() => addMenuClose('site')} role="button">
                        {/*    <ListItemIcon className="min-w-40">
                                    <Icon>account_circle</Icon>
                                </ListItemIcon> */}
                        <ListItemText primary={t('NEW_SITE')} />
                    </MenuItem>
                    <MenuItem onClick={() => addMenuClose('client')} role="button">
                        {/*    <ListItemIcon className="min-w-40">
                                    <Icon>account_circle</Icon>
                                </ListItemIcon> */}
                        <ListItemText primary={t('NEW_CLIENT')} />
                    </MenuItem>
                    <MenuItem onClick={() => addMenuClose('contact')} role="button">
                        {/*    <ListItemIcon className="min-w-40">
                                    <Icon>account_circle</Icon>
                                </ListItemIcon> */}
                        <ListItemText primary={t('NEW_CONTACT')} />
                    </MenuItem>
                    <MenuItem component={Link} to="/commande/new" role="button">
                        {/*    <ListItemIcon className="min-w-40">
                                    <Icon>account_circle</Icon>
                                </ListItemIcon> */}
                        <ListItemText primary={t('NEW_QUOTE')} />
                    </MenuItem>
                    <MenuItem onClick={() => addMenuClose('lead')} role="button">
                        {/*    <ListItemIcon className="min-w-40">
                                    <Icon>account_circle</Icon>
                                </ListItemIcon> */}
                        <ListItemText primary={t('Lead')} />
                    </MenuItem>
                    <MenuItem component={Link}
                        // the 'to' prop (and any other props not recognized by MenuItem itself)
                        // will be passed down to the Link component
                        to="/rapports/new" role="button">
                        <ListItemText primary={t('Rapport')} />
                    </MenuItem>

                </React.Fragment>

            </Popover>
            <ClientDialog />
            <ContactDialog />
            <SiteDialog />
            <RapportDialog />
            <LeadDialog />
        </React.Fragment>
    );
}

export default withRouter(withReducer('contactsApp', reducer)(AddMenu));
