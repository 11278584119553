// import routesConfig from 'app/fuse-configs/routesConfig';
import * as Actions from 'app/store/actions/fuse';
const initialState = {
    url: '/',
};

const routes = function (state = initialState, action) {
    switch (action.type) {

        case Actions.SET_ROUTES:
            {

                return {
                    ...state,
                    url: action.route

                };
            }
        default:
            {
                return state;
            }
    }
};

export default routes;
