import * as Actions from 'app/store/actions/custom';
import _ from '@lodash';

const initialState = {
    entities: {},
    selectDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,

    },
};


const componentsReducers = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_COMPONENTS:
            {
                return {
                    ...state,
                    entities: action.payload

                };
            }
        case Actions.OPEN_NEW_SELECT_DIALOG:
            {
                return {
                    ...state,

                    selectDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.payload,

                    }
                };
            }
        case Actions.CLOSE_NEW_SELECT_DIALOG:
            {
                return {
                    ...state,
                    selectDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null,

                    }
                };
            }
        case Actions.OPEN_EDIT_SELECT_DIALOG:
            {
                return {
                    ...state,
                    selectDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.payload,

                    }
                };
            }
        case Actions.CLOSE_EDIT_SELECT_DIALOG:
            {
                return {
                    ...state,
                    selectDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },

                    }
                };
            }

        default:
            {
                return state;
            }
    }
};

export default componentsReducers;
