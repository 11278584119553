import React, { useEffect, useCallback, useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem } from '@material-ui/core';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import _ from '@lodash'
import { useForm } from '@fuse/hooks';
import FuseUtils from '@fuse/FuseUtils';
import * as Actions from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import reducer from './store/reducers';
import withReducer from 'app/store/withReducer';
import MuiPhoneNumber from 'material-ui-phone-number'
import SelectClients from 'app/custom/components/selects/selectClients'
import moment from 'moment'
import firebaseService from 'app/services/firebaseService';
const getRegions = country => {
    if (!country) {
        return [];
    }

    return country[2].split("|").map(regionPair => {
        let [regionName, regionShortCode = null] = regionPair.split("~");
        return regionName;
    });
};

const defaultFormState = {
    contact: {
        id: '',
        gender: 'Mr',
        name: '',
        lastName: '',
        avatar: 'assets/images/avatars/profile.jpg',
        //nickname: '',
        company: '',
        jobTitle: '',
        email: '',
        phone: '',
        mobile: '',
        fax: '',
        address: '',
        address2: '',
        notes: '',
        starred: [],
        frequentContacts: [],
        groups: []
    }, client: {
        id: '',
        name: '',
        code_client: '',
        entite: '',
        siret: '',
        tva: '',
        avatar: 'assets/images/avatars/profile.jpg',
        address_facturation: {},
        email: '',
        phone: '',
        fax: '',
        address: '',
        address2: '',
        zip: '',
        city: '',
        country: 'France',
        notes: '',

    }
};

function ContactDialog(props) {
    //////console.log(props)
    const dispatch = useDispatch();
    const contactDialog = useSelector(({ contactsApp }) => contactsApp.contacts.clientDialog);
    const clients = useSelector(({ contactsApp }) => contactsApp.contacts.clients);
    const { form, handleChange, setForm } = useForm(defaultFormState.client);
    const user = useSelector(({ custom }) => custom.contacts.user.infos);
    const [country, setCountry] = useState([])
    const db = 'client'
    const { t } = useTranslation('contactsApp');
    //////console.log(contactDialog)
    useEffect(() => {
        handleChangeCountry(defaultFormState.client.country)
    }, [])
    const initDialog = useCallback(
        () => {
            /**
             * Dialog type: 'edit'
             */
            if (contactDialog.type === 'edit' && contactDialog.data) {
                setForm({ ..._.omit(contactDialog.data, 'handleChange'), updatedBy: user.id, updatedTime: moment().format('YYYY-MM-DD HH:mm:ss') });
            }

            /**
             * Dialog type: 'new'
             */
            if (contactDialog.type === 'new' && contactDialog.data.client) {
                setForm({
                    ...defaultFormState[db],
                    ..._.omit(contactDialog.data.client, 'handleChange'), address_facturation: '', updatedBy: '', updatedTime: '', createdBy: user.id, createdTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    id: FuseUtils.generateGUID()
                });
            } else if (contactDialog.type === 'new') {
                setForm({
                    ...defaultFormState[db],
                    ..._.omit(contactDialog.data, 'handleChange'), address_facturation: {}, updatedBy: '', updatedTime: '', createdBy: user.id, createdTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    id: FuseUtils.generateGUID()
                });
            }
        },
        [contactDialog.data, contactDialog.type, setForm],
    );

    useEffect(() => {
        /**
         * After Dialog Open
         */
        if (contactDialog.props.open) {
            initDialog();
        }

    }, [contactDialog.props.open, initDialog]);

    function closeComposeDialog() {
        return contactDialog.type === 'edit' ? dispatch(Actions.closeEditClientDialog()) : dispatch(Actions.closeNewClientDialog())
    }

    function canBeSubmitted() {
        return (
            form.name && form.name.length > 0
        );
    }

    function handleSubmit(event) {
        event.preventDefault();
        const f = JSON.parse(JSON.stringify(form))
        if (contactDialog.type === 'new' && contactDialog.data && contactDialog.data.select && contactDialog.data.select == 'facturation') {
            //////console.log(f)
            var m = { ...contactDialog.data.client, address_facturation: { ...contactDialog.data.client.address_facturation, [f.id]: f } }
            Promise.all([dispatch(Actions.updateContact(m, 'client'))]).then(r => {
                contactDialog.data.handleChange(f)
                closeComposeDialog();
            })

        } else if (contactDialog.type === 'new') {

            dispatch(Actions.addContact(f, db));
            closeComposeDialog();
        }
        else {
            dispatch(Actions.updateContact(f, db));
            closeComposeDialog();
        }

    }

    function handleRemove() {
        dispatch(Actions.removeContact(form.id, db));
        closeComposeDialog();
    }
    function handleChangeCountry(val) {

        for (var i = 0; i < CountryRegionData.length; i += 1) {

            if (CountryRegionData[i][0] === val) {

                setForm(_.set({ ...form }, 'country', val))
                setCountry(CountryRegionData[i])

            }
        }

    }

    function handleChangePhone(ev, name) {

        setForm({ ...form, [name]: ev })

    }

    function handleChangeCompany(val, id, key) {
        key = "company"
        if (val && val.id) {
            return setForm({ ...form, [key]: { ...val, id: val.id } })
        } else if (val && !_.isObject(val)) {
            firebaseService.firestore.collection('clients').doc(val).get().then(doc => {

                if (doc.exists) {
                    var f = form[key]
                    handleChangeSite(null, null, 'site')
                    handleChangeContact(val, null, 'contact')
                    return setForm({ ...form, [key]: { ...doc.data(), id: doc.id } })
                }
            })
        } else
            return setForm({ ...form, [key]: {} })


    }

    function handleChangeSite(val, id, key) {
        console.log(val, key, id)
        if (val && val.id) {
            return setForm({ ...form, [key]: { ...val, id: val.id } })
        } else if (val && !_.isObject(val)) {
            firebaseService.firestore.collection('sites').doc(val).get().then(doc => {

                if (doc.exists) {
                    var f = form[key]

                    return setForm({ ...form, [key]: { ...doc.data(), id: doc.id } })
                }
            })
        } else
            return setForm({ ...form, [key]: {} })


    }

    function handleChangeContact(val, id, key) {
        console.log(val, key, id)
        if (val && val.id) {
            return setForm({ ...form, [key]: { ...val, id: val.id } })
        } else if (val && !_.isObject(val)) {
            firebaseService.firestore.collection('users').doc(val).get().then(doc => {

                if (doc.exists) {
                    var f = form[key]

                    return setForm({ ...form, [key]: { ...doc.data(), id: doc.id } })
                }
            })
        } else {
            return setForm({ ...form, [key]: {} })
        }


    }
    //////console.log(contactDialog, form)
    return (

        <React.Fragment>
            <AppBar position="static" elevation={1}>
                <Toolbar className="flex w-full">
                    <Typography variant="subtitle1" color="inherit">
                        {contactDialog.type === 'new' && db === 'contact' ? t('NEW_CONTACT') : db === 'contact' ? t('EDIT_CONTACT') : contactDialog.type === 'new' ? t('NEW_CLIENT') : t('EDIT_CLIENT')}
                    </Typography>
                </Toolbar>
                <div className="flex flex-col items-center justify-center pb-24">
                    <Avatar className="w-96 h-96" alt="contact avatar" src={form.avatar} />
                    {contactDialog.type === 'edit' && (
                        <Typography variant="h6" color="inherit" className="pt-8">
                            {form.name}
                        </Typography>
                    )}
                </div>
            </AppBar>
            <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden">
                {db === 'contact' ? <DialogContent classes={{ root: "p-24" }}>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action"></Icon>
                        </div>

                        <RadioGroup className="mb-24" aria-label="gender" name="gender" value={form.gender} onChange={handleChange} row>
                            <FormControlLabel
                                value="Mademoiselle"
                                control={<Radio
                                    color="secondary" />
                                }
                                label={t('MLLE')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="Madame"
                                control={<Radio
                                    color="secondary" />
                                }
                                label={t('MME')}
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="Monsieur"
                                control={<Radio
                                    color="secondary" />
                                }
                                label={t('M')}
                                labelPlacement="start"
                            />

                        </RadioGroup>

                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">account_circle</Icon>
                        </div>

                        <TextField
                            className="mb-24"
                            label={t('NAME')}
                            autoFocus
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                        </div>
                        <TextField
                            className="mb-24"
                            label={t('LASTNAME')}
                            id="lastName"
                            name="lastName"
                            value={form.lastName}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">domain</Icon>
                        </div>
                        {  /*   <TextField
                            className="mb-24"
                            label={t('COMPANY')}
                            id="company"
                            name="company"
                            value={form.company}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                         
                        /> */}
                        <SelectClients handleChange={handleChangeCompany} company={form.company} />

                    </div>
                    {   /*     <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">star</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label={t('LASTNAME')}
                            id="nickname"
                            name="nickname"
                            value={form.nickname}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                        />
                    </div>
 */}
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">phone</Icon>
                        </div>
                        { /*  <TextField
                            className="mb-24"
                            label={t('PHONE')}
                            id="phone"
                            name="phone"
                            value={form.phone}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                        /> */}
                        <MuiPhoneNumber
                            className=" mb-24"
                            label={t('PHONE')}
                            id="phone"
                            name="phone"

                            value={form.phone}
                            onChange={(e) => handleChangePhone(e, 'phone')}
                            variant="outlined"
                            defaultCountry='fr'
                            inputextraprops={{
                                id: "phone",
                                name: 'phone',
                                required: false,
                            }}
                            fullWidth

                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">phone_android</Icon>
                        </div>

                        <MuiPhoneNumber
                            className=" mb-24"
                            label={t('MOBILE')}
                            id="mobile"
                            name="mobile"
                            value={form.mobile}
                            onChange={(e) => handleChangePhone(e, 'mobile')}
                            variant="outlined"
                            defaultCountry='fr'
                            inputextraprops={{
                                id: "mobile",
                                name: "mobile",
                                required: false,
                            }}

                            fullWidth

                        />
                    </div>
                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">local_printshop
</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label={t('FAX')}
                            id="fax"
                            name="fax"
                            value={form.fax}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">email</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label={t('EMAIL')}
                            id="email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            required
                        //disabled={false}
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">work</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label={t('JOB_TITLE')}
                            id="jobTitle"
                            name="jobTitle"
                            value={form.jobTitle}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                        />
                    </div>

                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">cake</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            id="birthday"
                            label={t('BIRTHDAY')}
                            type="date"
                            value={form.birthday}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </div>



                    <div className="flex">
                        <div className="min-w-48 pt-20">
                            <Icon color="action">note</Icon>
                        </div>
                        <TextField
                            className="mb-24"
                            label="Notes"
                            id="notes"
                            name="notes"
                            value={form.notes}
                            onChange={handleChange}
                            variant="outlined"
                            multiline
                            rows={5}
                            fullWidth
                        />
                    </div>
                </DialogContent> : <DialogContent classes={{ root: "p-24" }}>
                        <Typography variant='h6' variant="caption">{t('INFOS')}</Typography>
                        <Divider className="mb-24" />
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">domain</Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('NAME')}
                                autoFocus
                                id="name"
                                name="name"
                                value={form.name}
                                error={!form.name ? true : false}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">domain</Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('ENTITY')}
                                id="entite"
                                name="entite"
                                value={form.entite}
                                onChange={handleChange}
                                variant="outlined"

                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('CODE_CLIENT')}
                                id="code_client"
                                name="code_client"
                                value={form.code_client}
                                onChange={handleChange}
                                variant="outlined"

                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('SIRET')}

                                id="siret"
                                name="siret"
                                value={form.siret}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('TVA')}

                                id="tva"
                                name="tva"
                                value={form.tva}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <Typography variant='h6' variant="caption">{t('ADDRESS')}</Typography>
                        <Divider className="mb-24" />
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">home</Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('ADDRESS')}
                                error={!form.address ? true : false}
                                id="address"
                                name="address"
                                value={form.address}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('ADDRESS2')}

                                id="address2"
                                name="address2"
                                value={form.address2}
                                onChange={handleChange}
                                variant="outlined"

                                fullWidth
                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>

                            <TextField
                                className="mb-24"
                                label={t('ZIP')}
                                error={!form.zip ? true : false}
                                id="zip"
                                name="zip"
                                value={form.zip}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />

                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>


                            <TextField
                                className="mb-24"
                                label={t('CITY')}
                                error={!form.city ? true : false}
                                id="city"
                                name="city"
                                value={form.city}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                        {/*https://github.com/country-regions/react-country-region-selector/issues/10 */}

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>


                            <TextField
                                className="mb-24"
                                label={t('COUNTRY')}
                                error={!form.country ? true : false}
                                select
                                id="country"
                                name="country"
                                value={country}
                                onChange={(val) => handleChangeCountry(val.target.value[0])}
                                variant="outlined"
                                required
                                fullWidth
                            >
                                {CountryRegionData.map((option, index) => {
                                    // ////////////console.log(option, index)
                                    return (

                                        < MenuItem key={option[0]} value={option} >
                                            {option[0]}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </div>
                        {country && country.length && <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action"></Icon>
                            </div>


                            <TextField
                                className="mb-24"
                                label={t('REGION')}
                                select
                                id="region"
                                name="region"
                                value={form.region}
                                onChange={handleChange}
                                variant="outlined"

                                fullWidth
                            >
                                {getRegions(country).map(
                                    (option, index) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        </div>}

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">phone</Icon>
                            </div>
                            <MuiPhoneNumber
                                className=" mb-24"
                                label={t('PHONE')}
                                id="phone"
                                name="phone"
                                value={form.phone}
                                onChange={(e) => handleChangePhone(e, 'phone')}
                                variant="outlined"
                                defaultCountry='fr'
                                inputextraprops={{
                                    id: "phone",
                                    name: 'phone',
                                    required: false,
                                }}
                                fullWidth

                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">local_printshop
</Icon>
                            </div>
                            <MuiPhoneNumber
                                className=" mb-24"
                                label={t('FAX')}
                                id="fax"
                                name="fax"

                                value={form.fax}
                                onChange={(e) => handleChangePhone(e, 'fax')}
                                variant="outlined"
                                defaultCountry='fr'
                                inputextraprops={{
                                    id: "fax",
                                    name: 'fax',
                                    required: false,
                                }}
                                fullWidth

                            />
                        </div>
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">email</Icon>
                            </div>
                            <TextField
                                className="mb-24"
                                label={t('EMAIL')}
                                id="email"
                                name="email"
                                value={form.email}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            //disabled={contactDialog.type === 'edit' ? true : false}
                            />
                        </div>


                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">note</Icon>
                            </div>
                            <TextField
                                className="mb-24"
                                label="Notes"
                                id="notes"
                                name="notes"
                                value={form.notes}
                                onChange={handleChange}
                                variant="outlined"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </div>
                    </DialogContent>}

                {contactDialog.type === 'new' ? (
                    <DialogActions className="justify-between p-8">
                        <div className="px-16">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                type="submit"
                                disabled={!canBeSubmitted()}
                            >
                                {t('ADD')}
                            </Button>
                        </div>
                    </DialogActions>
                ) : (
                        <DialogActions className="justify-between p-8">
                            <div className="px-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={!canBeSubmitted()}
                                >
                                    {t('SAVE')}
                                </Button>
                            </div>
                            <IconButton
                                onClick={handleRemove}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </DialogActions>
                    )}
            </form></React.Fragment >

    );
}

export default withReducer('contactsApp', reducer)(ContactDialog);;
