import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
export const GET_FOLDERS = '[SITES APP] GET FOLDERS';

export function getFiles(site, parentId) {

    //console.log(site, parentId)

    if (site && site.dropbox_id && site.dropbox_id.id && !parentId)
        parentId = site.dropbox_id.id
    return async (dispatch, getState) => {

        const folders = getState().custom.sites.site.folders
        //console.log(folders)
        if (site && parentId && firebaseService.auth.currentUser) {
            dispatch(Actions.showMessage({ message: `Chargement des documents du site ${site.name}`, variant: 'info' }))
            const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)


            const request = axios({
                method: 'post',
                url: `${URL_API}/dropbox/files/list_folder`,
                headers: {
                    'Accept-Encoding': 'UTF-8',
                    Authorization: `Bearer ${token}`

                },
                data: { path: parentId }
            });

            request.then((response) => {
                ////console.log(response.data)

                if (response.data.error)
                    return dispatch({
                        type: GET_FOLDERS,
                        payload: []
                    })
                var tree = []

                _.map(response.data.body.entries, file => {
                    //////console.log(file)
                    var files = _.drop(file.path_display.split('/'), 4)

                    var memo = null
                    var i = 0
                    _.each(files, (f, k) => {
                        // //console.log(file)
                        if (!_.find(tree, { 'name': f })) {
                            if (file['.tag'] === 'folder') {

                                tree.push({
                                    id: file.id,
                                    name: f,
                                    level: k,
                                    siteId: site.id,
                                    parentId: k > 0 ? _.find(tree, { 'name': files[k - 1] }).id : '',
                                    type: 'folder',
                                    date_modification: file.client_modified ? moment(file.client_modified).format('ddd Do MMM YYYY, HH:mm:ss') : '',
                                    ...file

                                })
                                return dispatch({
                                    type: GET_FOLDERS,
                                    payload: tree
                                })
                            }
                            else {
                                firebaseService.firestore.collection('documents').doc(file.id).get().then(p => {

                                    if (p.exists && p.data().type === 'offre')
                                        tree.push({
                                            id: file.id,
                                            status: p.exists ? p.data().status : 'unknow',
                                            name: f,
                                            level: k,
                                            siteId: site.id,
                                            docType: p.data().type,
                                            commandes: p.exists && p.data().commandes ? p.data().commandes : '',
                                            parentId: k > 0 ? _.find(tree, { 'name': files[k - 1] }).id : '',
                                            type: file['.tag'] === 'folder' ? 'folder' : file.name.split('.')[file.name.split('.').length - 1],
                                            date_modification: file.client_modified ? moment(file.client_modified).format('ddd Do MMM YYYY, HH:mm:ss') : '',
                                            ...file

                                        })
                                    else if (p.exists && p.data().type === 'commande') {
                                        tree.push({
                                            id: file.id,
                                            status: p.exists ? p.data().status : 'unknow',
                                            name: f,
                                            level: k,
                                            siteId: site.id,
                                            docType: p.data().type,
                                            invoices: p.exists && p.data().invoices ? p.data().invoices : '',
                                            parentId: k > 0 ? _.find(tree, { 'name': files[k - 1] }).id : '',
                                            type: file['.tag'] === 'folder' ? 'folder' : file.name.split('.')[file.name.split('.').length - 1],
                                            date_modification: file.client_modified ? moment(file.client_modified).format('ddd Do MMM YYYY, HH:mm:ss') : '',
                                            ...file

                                        })
                                    } else if (p.exists && p.data().type === 'facture') {
                                        //console.log(file.id)
                                        tree.push({
                                            id: file.id,
                                            status: p.exists ? p.data().status : 'unknow',
                                            name: f,
                                            level: k,
                                            siteId: site.id,
                                            docType: p.data().type,
                                            invoices: p.exists && p.data().invoices ? p.data().invoices : '',
                                            parentId: k > 0 ? _.find(tree, { 'name': files[k - 1] }).id : '',
                                            type: file['.tag'] === 'folder' ? 'folder' : file.name.split('.')[file.name.split('.').length - 1],
                                            date_modification: file.client_modified ? moment(file.client_modified).format('ddd Do MMM YYYY, HH:mm:ss') : '',
                                            ...file

                                        })
                                    }
                                    return dispatch({
                                        type: GET_FOLDERS,
                                        payload: tree
                                    })

                                }).catch(err => {
                                    //console.log(err)
                                })
                            }

                        }

                    })

                    /*   if (file['.tag'] === 'file')
                          dispatch(getFileTemporaryLink(file.id)) */
                })

                if (response.data.body && response.data.body.has_more) {
                    dispatch(Actions.showMessage({ message: `Chargement des documents du site ${site.name}`, variant: 'info' }))
                    axios({
                        method: 'post',
                        url: `${URL_API}/dropbox/files/list_folder`,
                        headers: {
                            'Accept-Encoding': 'UTF-8',
                            Authorization: `Bearer ${token}`

                        },
                        data: { path: parentId, cursor: response.data.body.cursor }
                    }).then(async response => {

                        _.map(response.data.body.entries, file => {

                            var files = _.drop(file.path_display.split('/'), 4)
                            //////console.log(files)
                            var memo = null
                            var i = 0
                            _.each(files, async (f, k) => {
                                //////console.log(f)
                                if (!_.find(tree, { 'name': f })) {
                                    if (file['.tag'] === 'folder') {

                                        tree.push({
                                            id: file.id,
                                            name: f,
                                            level: k,
                                            siteId: site.id,
                                            parentId: k > 0 ? _.find(tree, { 'name': files[k - 1] }).id : '',
                                            type: 'folder',
                                            date_modification: file.client_modified ? moment(file.client_modified).format('ddd Do MMM YYYY, HH:mm:ss') : '',
                                            ...file

                                        })
                                        return dispatch({
                                            type: GET_FOLDERS,
                                            payload: tree
                                        })
                                    }
                                    else {
                                        firebaseService.firestore.collection('documents').doc(file.id).get().then(p => {
                                            if (p.exists)
                                                tree.push({
                                                    id: file.id,
                                                    status: p.exists ? p.data().status : 'unknow',
                                                    name: f,
                                                    level: k,
                                                    siteId: site.id,
                                                    commandes: p.exists && p.data().commandes ? p.data().commandes : '',
                                                    parentId: k > 0 ? _.find(tree, { 'name': files[k - 1] }).id : '',
                                                    type: file['.tag'] === 'folder' ? 'folder' : file.name.split('.')[file.name.split('.').length - 1],
                                                    date_modification: file.client_modified ? moment(file.client_modified).format('ddd Do MMM YYYY, HH:mm:ss') : '',
                                                    ...file

                                                })
                                            return dispatch({
                                                type: GET_FOLDERS,
                                                payload: tree
                                            })

                                        }).catch(err => {
                                            //console.log(err)
                                        })
                                    }

                                }

                            })

                            /*   if (file['.tag'] === 'file')
                                  dispatch(getFileTemporaryLink(file.id)) */
                        })

                    })
                } else return dispatch({
                    type: GET_FOLDERS,
                    payload: tree
                })

                function unflatten(arr) {
                    var Tree = [],
                        mappedArr = {},
                        arrElem,
                        mappedElem;

                    // First map the nodes of the array to an object -> create a hash table.
                    for (var i = 0, len = arr.length; i < len; i++) {
                        arrElem = arr[i];
                        mappedArr[arrElem.id] = arrElem;
                        mappedArr[arrElem.id]['children'] = [];
                    }


                    for (var id in mappedArr) {
                        if (mappedArr.hasOwnProperty(id)) {
                            mappedElem = mappedArr[id];
                            // If the element is not at the root level, add it to its parent array of children.
                            if (mappedElem.parent) {
                                mappedArr[mappedElem['parent']]['children'].push(mappedElem);
                            }
                            // If the element is at the root level, add it to first level elements array.
                            else {
                                Tree.push(mappedElem);
                            }
                        }
                    }
                    return Tree;
                }

                ////////console.log(list_to_tree(tree))

            })
        } else return dispatch({
            type: GET_FOLDERS,
            payload: []
        })
    }



}
export function getPreview(id, access, url) {
   console.log(id)
    return async (dispatch, getState) => {
        dispatch(Actions.showMessage({ message: 'Chargement du document...', variant: 'info' }))
        const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)


        const request = axios({
            method: 'post',
            url: `${URL_API}/dropbox/files/get_preview`, 
            headers: {
                'Accept-Encoding': 'UTF-8',
                Authorization: `Bearer ${token}`

            },
            data: { path: id, access: access }
        });

        console.log(request)
        var files = []
        request.then((response) => {
          console.log(response.data, url)
           // console.log(response.data.body.url)
            //console.log(response)
            /*   if (type && type === 'download') {
                  response.data.body.preview_url = response.data.body.preview_url.replace('?dl=0', '?dl=1')
                  //////console.log(response)
                  return download_file(response.data.body.preview_url, 'test'); //call function
              } else */
            var u = null
            if (response.data.body.url) {
                if (!url)
                    window.open(response.data.body.url)
                else return response.data.body.url
           } else if (response.data.body.links) {

                /*  var url = _.compact(_.map(response.data.body.links, f => {
                     console.log(f, f.link_permissions)
                     console.log(f.link_permissions.link_access_level['.tag'])
                     if (f.link_permissions.link_access_level['.tag'] == 'viewer')
                         return f
                 }))[0].url */
                if(!url){
                    const link = response.data.body.links.find(l => l[".tag"] === "file")
                    console.log(link)
                    if(link){
                        window.open(link.url)
                    } else {
                        alert("Aucun lien de partage n'est disponible pour ce document")
                    }
                //else return response.data.body.links[0].url
                }
            }
            //return response.data.body.links[0].url
            // dispatch(Actions.openDialog({ children: <iframe src={response.data.body.url} /> }))
        }).catch(err => {
         console.log(err)
        })

    }
}

function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
        save.download = fileName || filename;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            document.location = save.href;
            // window event not working here
        } else {
            var evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
}

function list_to_tree(list) {
    var map = {}, node, roots = [], i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== "0") {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parentId]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function getFileTemporaryLink(id) {
    return async (dispatch, getState) => {

        dispatch(Actions.showMessage({ message: 'Chargement du document...', variant: 'info' }))
        const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)


        const request = axios({
            method: 'post',
            url: `${URL_API}/getFileTemporaryLink`,
            headers: {
                'Accept-Encoding': 'UTF-8',
                Authorization: `Bearer ${token}`

            },
            data: {
                path: id
            }
        })
       
        request.then((response) => {
            console.log(response)
            if (response.data && response.data.body && response.data.body.link)
                return response.data.body.link
            else return

        })
    }
}
