import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import moment from 'moment'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
export const GET_LEADS = '[LEADS APP] GET LEADS';
export const SET_SEARCH_TEXT = '[LEADS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_LEADS = '[LEADS APP] TOGGLE IN SELECTED LEADS';
export const SELECT_ALL_LEADS = '[LEADS APP] SELECT ALL LEADS';
export const DESELECT_ALL_LEADS = '[LEADS APP] DESELECT ALL LEADS';
export const OPEN_NEW_LEAD_DIALOG = '[LEADS APP] OPEN NEW LEAD DIALOG';
export const CLOSE_NEW_LEAD_DIALOG = '[LEADS APP] CLOSE NEW LEAD DIALOG';
export const OPEN_EDIT_LEAD_DIALOG = '[LEADS APP] OPEN EDIT LEAD DIALOG';

export const CLOSE_EDIT_LEAD_DIALOG = '[LEADS APP] CLOSE EDIT LEAD DIALOG';
export const ADD_LEAD = '[LEADS APP] ADD LEAD';
export const UPDATE_LEAD = '[LEADS APP] UPDATE LEAD';
export const REMOVE_LEAD = '[LEADS APP] REMOVE LEAD';
export const REMOVE_LEADS = '[LEADS APP] REMOVE LEADS';
export const TOGGLE_STARRED_LEAD = '[LEADS APP] TOGGLE STARRED LEAD';
export const TOGGLE_STARRED_LEADS = '[LEADS APP] TOGGLE STARRED LEADS';
export const SET_LEADS_STARRED = '[LEADS APP] SET LEADS STARRED ';

export function getLeads(routeParams) {

    return (dispatch, getState) => {
        if (routeParams && routeParams.id == 'load')
            dispatch(Actions.showMessage({ message: 'Chargement des leads...', variant: 'info' }))
        
        //////console.log(getState().custom)


        firebaseService.firestore.collection('leads').where('id', '>', '').onSnapshot((r) => {
            const CLIENTS = getState().custom.contacts.contacts.clients
            const CONTACTS = getState().custom.contacts.contacts.entities
            const { navigation } = getState().fuse;
            var i = 0
            var leads = []
            if (r.size > 0) {

                r.forEach(doc => {
                    i++
                    var data = doc.data()

                    if (!data.createdBy) {
                        data.createdBy = data.updatedBy
              }

                    if (data.gestionnaire && CLIENTS[data.gestionnaire]) {
                        data.gestionnaire = { id: CLIENTS[data.gestionnaire].id, name: CLIENTS[data.gestionnaire].name, entite: CLIENTS[data.gestionnaire].entite }
                    } else { data.gestionnaire = {} }
                    if (CLIENTS[data.client]) {
                        data.client = { id: CLIENTS[data.client].id, name: CLIENTS[data.client].name, entite: CLIENTS[data.client].entite }
                    } else { data.client = {} }
                    if (CONTACTS[data.contact]) {
                        data.contact = {
                            id: CONTACTS[data.contact].id, company: CONTACTS[data.contact].company.name, entite: CONTACTS[data.contact].company.entite, email: CONTACTS[data.contact].email, name: CONTACTS[data.contact].name, lastName: CONTACTS[data.contact].lastName, mobile: CONTACTS[data.contact].mobile, phone: CONTACTS[data.contact].phone
                        }
                    } else {
                        data.contact = {}
                    }
                    if (data.createdBy && !CONTACTS[data.createdBy])
                        firebaseService.firestore.collection('users').doc(data.createdBy).get().then(r => {
                            if (r.exists)
                                data.updatedBy = { email: r.data().email, id: r.id, name: r.data().name, lastName: r.data().lastName, access: r.data().access }
                            else data.updatedBy = { email: '', id: '', name: '', lastName: '', access: {abbreviation_consultant:'', } }
                        })
                    else if (data.createdBy && CONTACTS[data.createdBy]) {
                        data.createdBy = { email: CONTACTS[data.createdBy].email, id: CONTACTS[data.createdBy].id, name: CONTACTS[data.createdBy].name, lastName: CONTACTS[data.createdBy].lastName, access: CONTACTS[data.createdBy].access }
                    }
                    if (data.updatedBy && !CONTACTS[data.updatedBy])
                        firebaseService.firestore.collection('users').doc(data.updatedBy).get().then(r => {
                            if (r.exists)
                                data.updatedBy = { email: r.data().email, id: r.id, name: r.data().name, lastName: r.data().lastName, access: r.data().access }
                            else  data.updatedBy = { email: '', id: '', name: '', lastName: '', access: {abbreviation_consultant:'', } }
                        })
                    else if (data.updatedBy && CONTACTS[data.updatedBy]) {
                        data.updatedBy = { email: CONTACTS[data.updatedBy].email, id: CONTACTS[data.updatedBy].id, name: CONTACTS[data.updatedBy].name, lastName: CONTACTS[data.updatedBy].lastName, access: CONTACTS[data.updatedBy].access }
                    }
                    if (moment(data.a_relancer_le).unix() <= moment(data.date_relance).unix())
                        data.a_relancer_le = moment(data.date_relance).add(2, 'weeks').format('YYYY-MM-DD')
                    data.a_relancer_unix = data.a_relancer_le? moment(data.a_relancer_le).unix():''
                    leads.push(data)

                    if (i == r.size) {

                        //console.log(getState())
                        //console.log(leads)
                        var valuesLeads  = _.keyBy(leads,'id')
                        var user = getState().auth.user
                       /*  if (!_.includes(getState().auth.user.role, 'admin')){
                            leads = _.filter(leads, c => {
                                return user.uid == c.updatedBy
                            })
                        } */

                        
                        var date = moment().unix()
                      /*   console.log(date)
                        console.log(user) */
                        var retardTotal = _.filter(leads, c => {
                            if (_.includes(user.role, 'admin') && (!c.status || c.status == 'En Cours'))
                            return c.a_relancer_unix <= date || !c.a_relancer_unix
                        })
                        var retard = _.filter(leads, c => {
                            if (!c.status || c.status=='En Cours')
                            return (c.a_relancer_unix <= date || !c.a_relancer_unix) && user.uid == c.createdBy.id
                        })
                        //console.log('retards', retard)
                        //console.log('retardsTotal', retardTotal)
                        var leadsItem = _.find(navigation, { id: 'leads' })

                        if (_.includes(user.role, 'admin')) {
                            
                        }
                        leadsItem.badge.title = leads.length
                        leadsItem.badge.retard= retard.length
                        leadsItem.badge.retardTotal = retardTotal.length
                        leadsItem.badge.type = 'leads'
                        //console.group(leadsItem)
                        dispatch(Actions.updateNavigationItem('leads', leadsItem));
                        //////////////console.log(leads)
                        dispatch(Actions.hideMessage())
                        ////////////console.log(leads)
                        dispatch({
                            type: GET_LEADS,
                            payload: _.orderBy(valuesLeads, 'a_relancer_unix'),
                            routeParams
                        })
                    }
                })
            }

        })
    }

}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedLeads(leadId) {
    return {
        type: TOGGLE_IN_SELECTED_LEADS,
        leadId
    }
}

export function selectAllLeads() {
    return {
        type: SELECT_ALL_LEADS
    }
}

export function deSelectAllLeads() {
    return {
        type: DESELECT_ALL_LEADS
    }
}

export function openNewLeadDialog(data, db) {
    return {
        type: OPEN_NEW_LEAD_DIALOG,
        data: data, db: db
    }
}

export function closeNewLeadDialog() {
    return {
        type: CLOSE_NEW_LEAD_DIALOG
    }
}

export function openEditLeadDialog(data, db) {
    //////////////console.log(data)
    return {
        type: OPEN_EDIT_LEAD_DIALOG,
        data: data, db: db
    }
}



export function closeEditLeadDialog() {
    return {
        type: CLOSE_EDIT_LEAD_DIALOG
    }
}

export function addLead(newLead, db) {
    newLead = _.omit(newLead, 'select')
    return async (dispatch, getState) => {


        const { routeParams } = getState().custom.leads;
        const { user } = getState().auth;

        var table = 'leads'
        switch (db) {

            default:
                table = 'leads'
                break;

        }

        dispatch(Actions.showMessage({ message: "Enregistrement du lead dans la base de données en cours...", variant: 'info' }))
        // newLead.dropbox_id = response.data.body.entries[0].metadata

        newLead.createdBy = firebaseService.auth.currentUser.uid
        newLead.createdTime = moment().format('YYYY-MM-DD HH:mm:ss')
        newLead.client = newLead.client.id ? newLead.client.id : newLead.client ? newLead.client : ''
        newLead.gestionnaire = newLead.gestionnaire.id ? newLead.gestionnaire.id : newLead.gestionnaire ? newLead.gestionnaire : ''
        newLead.contact = newLead.contact.id ? newLead.contact.id : newLead.contact ? newLead.contact : ''
        firebaseService.firestore.collection(table).doc(newLead.id).set(newLead).then(() => {
            dispatch({
                type: ADD_LEAD
            })
        }).catch(err => {
            return dispatch(Actions.showMessage({ message: "Echec de l'enregistrement du lead dans la base de données...", variant: 'error' }))

        })



    };
}

export function updateLead(lead, db) {
    lead = _.omit(lead, 'select')
    ////console.log(lead)
    return (dispatch, getState) => {

        const { routeParams } = getState().custom.leads;
        const CLIENTS = getState().custom.contacts.contacts.clients
        const CONTACTS = getState().custom.contacts.contacts.entities
        var table = 'leads'
        switch (db) {

            default:
                table = 'leads'
                break;

        }
        lead.client = lead.client.id ? lead.client.id : lead.client ? lead.client : ''
        lead.gestionnaire = lead.gestionnaire.id ? lead.gestionnaire.id : lead.gestionnaire ? lead.gestionnaire : ''
        lead.contact = lead.contact.id ? lead.contact.id : lead.contact ? lead.contact : ''
        lead.createdBy = lead.createdBy.id ? lead.createdBy.id : lead.createdBy ? lead.createdBy : ''

        lead.updatedBy = firebaseService.auth.currentUser.uid
        lead.updatedTime = moment().format('YYYY-MM-DD HH:mm:ss')
        firebaseService.firestore.collection(table).doc(lead.id).update(lead).then(() => {


            dispatch({
                type: UPDATE_LEAD
            })

        }).catch(err => {



        })

    };
}

export function removeLead(leadId, db) {

    return (dispatch, getState) => {

        const { routeParams } = getState().leadsApp.leads;
        var table = 'leads'
        switch (db) {

            default:
                table = 'leads'
                break;

        }

        firebaseService.firestore.collection(table).doc(leadId).delete().then(() => {


            dispatch({
                type: REMOVE_LEAD
            })

        }).catch(err => {
            //////////////console.log(err)


        })

    };
}


export function removeLeads(leadIds, db) {
    //////////////console.log(leadIds, db)
    return async (dispatch, getState) => {

        const { routeParams } = getState().leadsApp.leads;
        var table = 'leads'

        var promises = []
        _.map(leadIds, leadId => {
            //////////////console.log(leadId, table)
            promises.push(new Promise(async function (resolve, reject) {

                firebaseService.firestore.collection(table).doc(leadId).delete().then((err) => {
                    //////////////console.log(err)
                    resolve()
                }).catch(err => {
                    //////////////console.log(err)
                    reject()
                })
            }))
        })



        Promise.all(promises.concat([
            dispatch({
                type: REMOVE_LEADS
            }),
            dispatch({
                type: DESELECT_ALL_LEADS
            })
        ])).then(() => dispatch(getLeads(routeParams)))

    };
}

export function toggleStarredLead(leadId, db) {
    //////////////console.log(leadId, db)



    return (dispatch, getState) => {
        const { user } = getState().auth


        var table = 'leads'
        switch (db) {

            default:
                table = 'leads'
                break;

        }
        const { routeParams } = getState().leadsApp.leads;


    };
}

export function toggleStarredLeads(leadIds, db) {

    //////////////console.log(leadIds, db)
    return (dispatch, getState) => {
        var table = 'leads'
        switch (db) {

            default:
                table = 'leads'
                break;

        }
        const { routeParams } = getState().leadsApp.leads;

        const request = axios.post('/api/leads-app/toggle-starred-leads', {
            leadIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_LEADS
                }),
                dispatch({
                    type: DESELECT_ALL_LEADS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getLeads(routeParams)))
        );
    };
}

export function setLeadsStarred(leadIds, db) {
    //////////////console.log(leadIds, db)
    return (dispatch, getState) => {

        const { routeParams } = getState().leadsApp.leads;

        const request = axios.post('/api/leads-app/set-leads-starred', {
            leadIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_LEADS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_LEADS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getLeads(routeParams)))
        );
    };
}

export function setLeadsUnstarred(leadIds, db) {
    //////////////console.log(leadIds, db)
    return (dispatch, getState) => {

        const { routeParams } = getState().leadsApp.leads;


        const request = axios.post('/api/leads-app/set-leads-unstarred', {
            leadIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_LEADS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_LEADS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getLeads(routeParams)))
        );
    };
}
