import React, { useState, useRef, useSelector, useEffect } from 'react';
import { DropDownBox, TreeView } from 'devextreme-react';
import DataGrid, { Selection, Paging, FilterRow, Scrolling, Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import _ from '@lodash'
import ArrayStore from 'devextreme/data/array_store';

const gridColumns = ['batiment', 'batterie', 'name'];

const App = (props) => {
    function list_to_tree (list) {
        console.log(list)
        var map = {}, node, roots = [], i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // initialize the map
            list[i].items = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.parentId !== 0) {
                if (node.parentType == 'batterie')
                    node.expanded = true
                if (_.includes(props.values, node.id))
                    node.selected = true
                list[map[node.parentId]].items.push(node);
            } else {
                node.expanded = true
                roots.push(node);
            }
        }
        console.log(roots)
        return roots
        //setState({ ...state, treeDataSource: roots })
    }
    const ref = useRef(null)
    const [state, setState] = useState(
        {
            list: _.keyBy(props.tree, 'id'),
            treeBoxValue: props.value ? props.value : [],
            gridBoxValue: props.values ? props.values : [],
            treeDataSource: props.tree ? props.tree : [],
            values: props.values ? props.values : [],
            items: list_to_tree(props.tree)
        }
    )
    useEffect(() => {
       
        setState({ ...state, treeDataSource: list_to_tree(props.tree), items: list_to_tree(props.tree) })
    }, [props.tree])
    useEffect(() => {
        if(!_.isEqual(props.values, state.values))
            setState({ ...state, values: props.values, items: list_to_tree(props.tree)})
    }, [props.values])
  
    const gridDataSource = (props) => new ArrayStore({
        data: props.appareils, key: 'id'
    })

    

    function treeDataSourceRender() {
        // return <TreeList/>
        //////console.log(state.treeDataSourceRef)
        return (
            <TreeView
                searchEnabled={true}
                dataSource={props.tree}
                id="state.treeDataSource"
                items={state.treeDataSource}
                ref={ref}
                dataStructure="plain"
                hasItemsExpr="appareil"
                keyExpr="id"
                parentIdExpr="parentId"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                selectNodesRecursive={true}
                displayExpr="name"
                selectByClick={true}
                expandAllEnabled={true}
                onContentReady={treeDataSourceContentReady}
                onSelectionChanged={treeDataSourceSelectionChanged}


            />
        );
    }



    function treeDataSourceSelectionChanged(e) {
        ////console.log(e)
        syncSelection(e.component);
    }

    function treeDataSourceContentReady(e) {
        ////console.log(e)
        syncSelection(e.component);
    }

    function syncSelection(treeDataSource) {
        ////console.log(state.treeDataSource.getSelectedNodes())
        /*   ////console.log(state.treeDataSource.hasItemsGetter())
          ////console.log(state.treeDataSource.selectedGetter())
          ////console.log(state.treeDataSource.selection()) */
        const selectedNodes =
            _.map(treeDataSource.getSelectedNodes(), (node) => {
                return {
                    ...node.itemData, selected: node.itemData.selected ? true : false

                };
            });
        ////console.log(selectedNodes)
        var n = _.compact(_.map(selectedNodes, c => {
            if (c && c.parentType && c.parentType == 'appareil')
                return c.id
        }))
        if (!_.isEqual(n, state.treeBoxValue)) {

            props.handleChange(n)
            /*  setState(() => {
                 return { treeBoxValue: selectedNodes };
             }); */
        }
        // const selectedEmployees = state.treeDataSource.getSelectedNodes()
        // //////console.log(selectedEmployees)
    }


    function treeDataSourceSelection(e) {
        console.log(e)
        props.data.setValue(e.value ? e.value : [])
        //////console.log(state.treeDataSourceRef)
        setState({
            ...state,
            treeBoxValue: e.value
        });

    }



    return (
        <DropDownBox
            defaultValue={_.compact(_.map(state.treeBoxValue, c => {
                if (c && c.parentType && c.parentType == 'appareil')
                    return c.name
            }))}

            deferRendering={false}
            placeholder="Sélectionnez les appareils concernés..."
            showClearButton={true}
            dataSource={state.treeDataSource}
            onValueChanged={treeDataSourceSelection}
            contentRender={treeDataSourceRender}
        />

    )
}

export default App;