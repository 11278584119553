import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import { PDFReader } from 'reactjs-pdf-reader';
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import download from 'downloadjs'
import React from 'react'
import history from '@history'
import moment from 'moment'
import 'moment/locale/fr'
import Drawer from '@material-ui/core/Drawer'
import FileSaver from 'file-saver'

export const GET_FACTURES = '[FACTURES APP] GET FACTURES';
export const UPDATE_FACTURES = '[FACTURES APP] UPDATE FACTURES';
export function getFactures(routeParams) {


    return (dispatch, getState) => {
        const { navigation } = getState().fuse;
      

        firebaseService.firestore.collection('documents').where('type', '==', 'facture').orderBy('createdTime', 'desc').onSnapshot(async r => {
            const commandes = getState().custom.commandes.entities
            const consultants = getState().custom.contacts.contacts.users
            //console.log(commandes)
            var f = {}
            if (r.size > 0) {

                r.forEach( doc => {
                    var data = doc.data()
               
                    if (!data.commandeId) {
                        data.commandeId = data.data.commandeId
                    }
  
                    if (!data.siteId) {
                        var siteId =firebaseService.firestore.collection('sites').doc(data.data.site.id).get().then(m => {
                            if (m.exists) {
                                data.siteId = data.data.site.id
                                    data.siteIdDropboxId = m.data().dropbox_id.id
                            }
                            return

                        })
                    }
                    if (!data.commandeId) {
                        

                        var commandeId = firebaseService.firestore.collection('documents').doc(data.commandeId).get().then(r => {
                            ////console.log(r.data())
                            return r.data()
                        }).catch(err => {
                            //console.log(err)
                        })
                        //console.log(commandeId)
                        if (!commandeId) {
                            ////console.info(data)
                            return
                        }
                            
                        data.commandeId = commandeId.id

                             
                            

                    }
                        
                        if (!data.siteId || !data.commandeId) {
                            //console.log(data.siteId, data.commandeId)
                            //console.log(data)
                        }   


                    f[doc.id] = { ...data, id: doc.id, isFrais: doc.data().isFrais ? true : false }

                    ////console.log(doc.data(), commandes[doc.data().data.commandeId])


                })
                await _.map(f, (c, id) => {


                    if (c.isFrais && !c.isAvoir) {
                        // //console.log('frais',id, c)
                    } else if (c.isAvoir && !c.isFrais) {

                        // //console.log('avoir', id, c)

                        /*  if (!f[id])
                             return
                         else {
                             //console.log(f[id])
                         } */
                        f[id].data.montant_frais = f[id].data.montant_frais ? f[id].data.montant_frais : 0
                        var factureRef = f[c.data.factureRef]
                        // //console.log(factureRef)
                        if (!factureRef)
                            return
                        var repartition = {}
                        _.map(f[id].data.details, (d, k) => {
                            var n = Number(c.data.details[k].montant_mois) / Number(factureRef.data.recap.total_mois_HT)

                            var part_frais = Number(factureRef.data.montant_frais) * n
                            //////////console.log(part_frais)
                            f[id].data.montant_frais = Number(f[id].data.montant_frais ? f[id].data.montant_frais : 0) + Math.round(Number(part_frais) * 100) / 100
                            var montant_mois_hors_frais = Math.round((Number(c.data.details[k].montant_mois) - (c.isAvoir ? -part_frais : part_frais)) * 100) / 100
                            //////////console.log(montant_mois_hors_frais)

                            _.map(d.repartition, (m, consultant) => {

                                ////////////////////////////////console.log(_.find(_.values(consultants), { access: { abbreviation_consultant: consultant } }))
                                if (_.find(_.values(consultants), { access: { abbreviation_consultant: consultant } })) {
                                    if (!repartition[consultant]) {
                                        repartition[consultant] = 0
                                    }
                                    if (!factureRef.data.frais_a_facturer && !c.isFrais)
                                        repartition[consultant] = Math.round((repartition[consultant] + (montant_mois_hors_frais * (d.repartition[consultant] / 100))) * 100) / 100
                                    //data.details[k].repartition[consultant] = Math.round( (montant_mois_hors_frais * (d.repartition[consultant] / 100)) * 100) / 100
                                    else if (!c.isFrais)
                                        repartition[consultant] = Math.round((repartition[consultant] + (c.data.details[k].montant_mois * (d.repartition[consultant] / 100))) * 100) / 100

                                }
                            })
                            //////////console.log(repartition)
                            f[id].data.repartition = repartition
                        })

                        //////////console.log(f[id])
                    }
                    return f[id]
                })

                //////////console.log(f)
            
                var commandesItem = _.find(navigation, { id: 'factures' })
                commandesItem.badge.title = _.values(f).length
                dispatch(Actions.updateNavigationItem('factures', commandesItem));
                Promise.all([dispatch({
                    type: GET_FACTURES,
                    payload: f,
                    routeParams
                })]).then(r => {
                    dispatch(Actions.getCommandes({ id: 'all' }))
                    //return dispatch(Actions.getAnalytics({ id: 'all' }))
                })
            } else {
                
                var commandesItem = _.find(navigation, { id: 'factures' })
                commandesItem.badge.title = _.values(f).length
                dispatch(Actions.updateNavigationItem('factures', commandesItem));


                Promise.all([dispatch({
                    type: GET_FACTURES,
                    payload: f,
                    routeParams
                })]).then(r => {
                    dispatch(Actions.getCommandes({ id: 'all' }))
                    //return dispatch(Actions.getAnalytics({ id: 'all' }))
                })
            }
        })

    }


}
export function updateInvoice(invoice) {
    ////////////////////////console.log(invoice)
    return async (dispatch, getState) => {
        if (invoice.date_payment) {
            invoice.status = 'payed'
            invoice.data = { ...invoice.data, status: 'payed', date_payment: invoice.date_payment }
        }
        var commandes = getState().custom.commandes.entities
        var commande = commandes[invoice.data.commandeId]
        var invoices = commande.invoices
        var k = _.findKey(invoices, { id: invoice.id })
        ////////////////////////console.log(k)
        ////////////////////////console.log(invoice.data)


        commande.invoices[k] = invoice.data
        commande.invoices = _.values(commande.invoices)
        ////////////////////////console.log(commande, invoices)


        firebaseService.firestore.collection('documents').doc(invoice.id).set(invoice).then(r => {

        }).catch(err => {
            ////////////////////////console.log(err)
        })
        firebaseService.firestore.collection('documents').doc(invoice.data.commandeId).set(commande)



        dispatch({
            type: UPDATE_FACTURES,

        })
    }
}

export function addFacture(form, commandeB) {
   // //console.log(form, commandeB)


    const commande = _.cloneDeep(commandeB)
    ////console.log(commande)


    if (!form.isAvoir)
        form.isAvoir = false
    if (!form.isFrais)
        form.isFrais = false
    var response = null
    const tmp = _.clone(form.numero_facture_tmp)
    return async (dispatch, getState) => {


        const auth = getState().auth.user
        ////console.log(auth)
        if (form.recap.total_mois_HT != 0) {
            dispatch(Actions.showMessage({ message: 'Enregistrement en cours...', variant: 'warning', autoHideDuration: 10000 }))
            if (!form.numero_facture) {
                if (!form.numero_facture_tmp)
                    form.numero_facture_tmp = moment().format('YYYY-MM-DD HH:mm:ss')
            }

            var facturesList = _.compact(_.map(getState().custom.factures.facturation.entities, c => {

                if (c.data.commandeId == commande.id && form.id !== c.data.id && c.data.isAvoir) {

                    return c
                }
                if (c.data.commandeId == commande.id && form.id !== c.data.id && !c.data.isFrais) {
                    return c
                }
            }))
            //id: CIU_Vp_hIFAAAAAAAAPARw
            ////////////console.log(facturesList)
            var total_commande_TTC = Number(commande.data.commande.prix_total) + Math.round((Number(commande.data.commande.TVA) * Number(commande.data.commande.prix_total) / 100) * 100) / 100
            ////////////////console.log(total_commande_TTC)
            const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)

            //console.log(token)
            commande.data.commande.total_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(commande.data.commande.prix_total)
            commande.data.commande.total_tva_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Math.round((commande.data.commande.TVA * commande.data.commande.prix_total / 100) * 100) / 100)
            commande.data.commande.total_ttc_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(total_commande_TTC)
            var invoicesList = []
            if (!form.isFrais)
                invoicesList = _.concat(facturesList, { data: form })

            invoicesList = _.sortBy(invoicesList, ['data.date_facture_unix', 'desc'])
            ////////////console.log(invoicesList)

            var recap = form.recap
            recap.facturation = _.sum(_.compact(_.map(facturesList, c => {
                //////////console.log(c.data.recap)
                if (c.data)
                    return c.data.recap.total_TTC ? Number(c.data.recap.total_TTC) : 0
            })))

            recap.facturation = recap.facturation + form.recap.total_TTC
            recap.facturation_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(recap.facturation)

            recap.encaissement = _.sum(_.compact(_.map(_.filter(invoicesList, function (f) { return f.status == 'payed' || f.status == 'not_sold' }), c => {

                return c.montant_réglé ? Number(c.montant_réglé) : 0
            })))
            recap.encaissement_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(recap.encaissement)
            // recap.reste_a_payer = Math.round((Number(commande.data.commande.TVA) * Number(commande.data.commande.prix_total) / 100) * 100) / 100 + Number(commande.data.commande.prix_total) - (Number(recap.facturation) - Number(recap.encaissement)) + Number(form.recap.total_TTC)
            recap.reste_a_payer = Math.round((Number(commande.data.commande.TVA) * Number(commande.data.commande.prix_total) / 100) * 100) / 100 + Number(commande.data.commande.prix_total) - Number(recap.encaissement)

            recap.reste_a_payer_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(recap.reste_a_payer)
            ////////////console.log({ formulaire: FuseUtils.generateGUID(), fileName: form.numero_facture ? form.numero_facture : form.numero_facture_tmp, data: { ...form, invoicesList: _.map(invoicesList, c => c.data), commandeInfo: commande.data, recapGlobal: commande.isOld?false:recap }, site: form.site.dropbox_id ? form.site.dropbox_id.name : form.site.name, folder: '02-Commandes/Factures' })
            //console.log({ formulaire: FuseUtils.generateGUID(), fileName: form.numero_facture ? form.numero_facture : form.numero_facture_tmp, data: { ...form, invoicesList: _.map(invoicesList, c => c.data), commandeInfo: commande.data, recapGlobal: (commande.data.commande.oldFacturé || commande.data.isOld) ? false : recap }, site: form.site.dropbox_id ? form.site.dropbox_id.name : form.site.name, folder: '02-Commandes/Factures' })
            //console.log(URL_API)
            var siteId = _.find(_.values(getState().custom.sites.sites.entities), m => {
                return m.dropbox_id.name == form.site.name
            })
            //console.log(siteId)
            form.site.name = siteId ? siteId.dropbox_id.name : form.site.name
            const request = axios({
                method: 'post',
                url: `${URL_API}/carbone/createFacture`,
                headers: {
                    'Accept-Encoding': 'UTF-8',
                    Authorization: `Bearer ${token}`
                },
                data: { formulaire: FuseUtils.generateGUID(), fileName: form.numero_facture ? form.numero_facture : form.numero_facture_tmp, data: { ...form, invoicesList: _.map(invoicesList, c => c.data), commandeInfo: commande.data, recapGlobal: (commande.data.commande.oldFacturé || commande.data.isOld) ? false : recap }, site: form.site.name, folder: '02-Commandes/Factures' }
                //data: { ch: 'toto' }
            }).then(response => {

                //console.log(response)
                if (response.data && response.data.body && response.data.body.id) {
                    if (form.id)
                        dispatch(Actions.getPreview(response.data.body.id, 'editor'))
                    dispatch(Actions.closeDialog())
                } else {

                    //dispatch(addFacture(form, commandeB))
                    return dispatch(Actions.showMessage({ message: 'Erreur lors de la création de la facture,\n\nVeuillez fermer le document ouvert dans votre navigateur afin de mettre le document à jour', variant: 'error' }))
                }
                dispatch(Actions.hideMessage())
                dispatch(Actions.showMessage({ message: 'Document enregistrée dans Dropbox....' }))
                ////////////////////////console.log(form)

                // history.push('/commandes')
                try {
                    ////////console.log(form)
                    //////////console.log('test')
                    if (response.data && response.data.body && response.data.body.id) {

                        if (form && !form.createdBy) {
                            ////console.log(auth.uid)
                            form.createdBy = auth.uid ? auth.uid : ''
                            form.createdTime = moment().format('YYYY-MM-DD HH:mm:ss')
                        }
                        var values = {}
                        if (!form.id) {
                            var invoicesS = _.filter(_.values(getState().custom.factures.facturation.entities), c => c.data.commandeId == commande.id && !c.data.isFrais)
                            form.id = response.data.body.id
                            values = { docInfos: { ...response.data.body }, data: { ...form }, status: form.numero_facture ? 'saved' : 'draft', createdBy: auth.uid, createdTime: moment().format('YYYY-MM-DD HH:mm:ss'), type: 'facture', isFrais: form.isFrais ? true : false, isAvoir: form.isAvoir ? true : false }
                        } else {
                            var invoicesS = _.filter(_.filter(_.values(getState().custom.factures.facturation.entities), c => c.data.commandeId == commande.id && !c.data.isFrais), f => f.id != form.id)
                            values = { docInfos: { ...response.data.body }, data: { ...form }, status: form.numero_facture ? 'saved' : 'draft', createdBy: form.createdBy, createdTime: form.createdTime, updatedBy: auth.uid, updatedTime: moment().format('YYYY-MM-DD HH:mm:ss'), type: 'facture', isFrais: form.isFrais ? true : false, isAvoir: form.isAvoir ? true : false }
                        }

                        //console.log(form.id ? form.id : response.data.body.id, values)
                        firebaseService.firestore.collection('documents').doc(form.id ? form.id : response.data.body.id).set(values).then(r => {
                            //////////console.log(values)
                            if (!form.isFrais) {
                                var invoices = _.concat(invoicesS, values)
                                //////////////////console.log(invoices)

                                invoices = _.sortBy(invoices, ['data.date_facture_unix', 'desc'])


                                commande.last_invoice = moment.unix(invoices[0].data.date_facture_unix).format('MM/DD/YYYY')

                                commande.montant_facturé = _.sum(_.map(invoices, c => {
                                    //////////console.log(c)
                                    if (c.data.isAvoir)
                                        return c.data.recap.total_mois_HT ? Number(c.data.recap.total_mois_HT) : 0
                                    if (!c.data.isFrais)
                                        return c.data.recap.total_mois_HT ? Number(c.data.recap.total_mois_HT) : 0
                                }))
                                commande.reste_a_facturer = Number(commande.data.commande.prix_total) - commande.montant_facturé

                                firebaseService.firestore.collection('documents').doc(commande.id).set({ ...commande, invoices: invoices }).catch(err => {
                                    //console.log(err)
                                })
                            }
                        }).catch(err => {
                            //console.log(err)
                        })



                    }

                } catch (err) {
                    //console.log(err)
                }
            }).catch(err => {
                //console.log(err)
            })
        } else {
            if (!form.isFrais)
                firebaseService.firestore.collection('documents').doc(commande.id).set({ ...commande, updatedBy: auth.uid, updatedTime: moment().format('YYYY-MM-DD HH:mm:ss') }).catch(err => {
                    //console.log(err)
                })
            // dispatch(Actions.showMessage({ message: 'Le planning a été mis à jour....' }))
            dispatch(Actions.closeDialog())

        }

    };
}

export function addFactureFrais(form, commandeB) {
    //console.log(form, commandeB)
    const commande = _.cloneDeep(commandeB)
    //////////////////console.log(form, commande)
    if (!form.code_client)
        form.code_client = form.ref_commande_movveo ? form.ref_commande_movveo : ''
    var response = null
    ////console.log(form, commandeB)
    const tmp = _.clone(form.numero_facture_tmp)
    return async (dispatch, getState) => {
        const auth = getState().auth.user
        ////console.log(auth)
        if (form.recap.total_mois_HT != 0) {
            dispatch(Actions.showMessage({ message: 'Enregistrement en cours...', variant: 'warning', autoHideDuration: 10000 }))
            if (!form.numero_facture) {
                if (!form.numero_facture_tmp)
                    form.numero_facture_tmp = moment().format('YYYY-MM-DD HH:mm:ss')
            }

            var facturesList = _.compact(_.map(getState().custom.factures.facturation.entities, c => {
                ////////////console.log(c.data.commandeId, commande.id)


                if (c.data.commandeId == commande.id && /* c.data.date_facture_unix <= form.date_facture_unix && */ form.id !== c.data.id && c.data.name != form.name) {
                    return c
                }
            }))

            var total_commande_TTC = Number(commande.data.commande.prix_total) + Math.round((Number(commande.data.commande.TVA) * Number(commande.data.commande.prix_total) / 100) * 100) / 100
            ////////////////console.log(total_commande_TTC)
            const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)
            commande.data.commande.total_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(commande.data.commande.prix_total)
            commande.data.commande.total_tva_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Math.round((commande.data.commande.TVA * commande.data.commande.prix_total / 100) * 100) / 100)
            commande.data.commande.total_ttc_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(total_commande_TTC)

            var invoicesList = _.concat(facturesList, { data: form })


            ////////////////////console.log(invoicesList)

            var recap = form.recap
            recap.facturation = _.sum(_.compact(_.map(facturesList, c => {
                ////////////console.log(c.data)
                if (c.data)
                    return c.data.recap.total_TTC ? Number(c.data.recap.total_TTC) : 0
            })))

            recap.facturation = recap.facturation + form.recap.total_TTC
            recap.facturation_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(recap.facturation)

            recap.encaissement = _.sum(_.compact(_.map(_.filter(invoicesList, function (f) { return f.status == 'payed' || f.status == 'not_sold' }), c => {

                return c.montant_réglé ? Number(c.montant_réglé) : 0
            })))
            recap.encaissement_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(recap.encaissement)
            // recap.reste_a_payer = Math.round((Number(commande.data.commande.TVA) * Number(commande.data.commande.prix_total) / 100) * 100) / 100 + Number(commande.data.commande.prix_total) - (Number(recap.facturation) - Number(recap.encaissement)) + Number(form.recap.total_TTC)
            recap.reste_a_payer = Math.round((Number(commande.data.commande.TVA) * Number(commande.data.commande.prix_total) / 100) * 100) / 100 + Number(commande.data.commande.prix_total) - Number(recap.encaissement)

            recap.reste_a_payer_formatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(recap.reste_a_payer)
            //////////console.log(recap)
            ////////////console.log({ formulaire: FuseUtils.generateGUID(), fileName: form.numero_facture ? form.numero_facture : form.numero_facture_tmp, data: { ...form, invoicesList: _.map(invoicesList, c => c.data), commandeInfo: commande.data, recapGlobal: false }, site: form.site.dropbox_id ? form.site.dropbox_id.name : form.site.name, folder: '02-Commandes/Factures' })
            const request = axios({
                method: 'post',
                url: `${URL_API}/carbone/createFacture`,
                headers: {
                    'Accept-Encoding': 'UTF-8',
                    Authorization: `Bearer ${token}`
                },
                data: { formulaire: FuseUtils.generateGUID(), fileName: form.numero_facture ? form.numero_facture : form.numero_facture_tmp, data: { ...form, commandeInfo: commande.data, recapGlobal: false }, site: form.site.dropbox_id ? form.site.dropbox_id.name : form.site.name, folder: '02-Commandes/Factures' }
                //data: { ch: 'toto' }
            }).then(response => {

                ////////////console.log(response)
                if (response.data && response.data.body && response.data.body.id) {
                    if (!form.numero_facture && !form.createdBy) {
                        ////console.log(auth.uid)
                        form.createdBy = auth.uid
                        form.createdTime = moment().format('YYYY-MM-DD HH:mm:ss')
                    }
                    if (form.id)
                        dispatch(Actions.getPreview(response.data.body.id, 'editor'))
                    dispatch(Actions.closeDialog())
                } else {

                    //dispatch(addFacture(form, commandeB))
                    return dispatch(Actions.showMessage({ message: 'Erreur lors de la création de la facture,\n\nVeuillez fermer le document ouvert dans votre navigateur afin de mettre le document à jour', variant: 'error' }))
                }
                dispatch(Actions.hideMessage())
                dispatch(Actions.showMessage({ message: 'Document enregistrée dans Dropbox....' }))
                ////////////////////////console.log(form)

                // history.push('/commandes')
                try {
                    if (response.data && response.data.body && response.data.body.id) {
                        ////////////console.log('form.id ', form.id , response.data.body.id)

                        var values = {}
                        if (!form.id) {


                            values = { docInfos: { ...response.data.body }, data: { ...form }, status: form.numero_facture ? 'saved' : 'draft', createdBy: auth.uid, createdTime: moment().format('YYYY-MM-DD HH:mm:ss'), type: 'facture', isFrais: true }
                        } else {
                            values = { docInfos: { ...response.data.body }, data: { ...form }, status: form.numero_facture ? 'saved' : 'draft', updatedBy: auth.uid, updatedTime: moment().format('YYYY-MM-DD HH:mm:ss'), type: 'facture', isFrais: true }
                        }

                        firebaseService.firestore.collection('documents').doc(form.id ? form.id : response.data.body.id).set(values).then(r => {
                            var newSum = _.sum(_.compact(_.map(getState().custom.factures.facturation.entities, c => {
                                ////////////console.log('ref',c.data.id, form.factureRef)


                                if (form.factureRef && c.data.id == form.factureRef && c.isFrais && c.data.recap)
                                    return c.data.recap.total_mois_HT
                            })))
                            ////////////console.log(newSum)
                            var t = getState().custom.factures.facturation.entities[form.factureRef]
                            firebaseService.firestore.collection('documents').doc(form.factureRef).set({ ...t, data: { ...t.data, frais_facture: newSum } }).then(r => {

                            }).catch(err => {
                                ////////////console.log(err)
                            })



                        }).catch(err => {
                            ////////////console.log('====>>',err)
                        })



                    }

                } catch (err) {
                    ////////////console.log(err)
                }
            })
            dispatch(Actions.closeDialog())
        } else {

            dispatch(Actions.showMessage({ message: 'Le montant facturé ne peut être égal à 0', variant: 'danger' }))

            return

        }

    };
}

export function mergePDF(list, access) {

    //console.log(list)
    return async (dispatch, getState) => {
        const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)

        //dispatch(Actions.showMessage({ message: 'Chargement du document...', variant: 'info' }))
        var p = {}

        var request = axios({
            method: 'post',
            url: `${URL_API}/getPDF`,
            headers: {
                'Accept-Encoding': 'UTF-8',
                Authorization: `Bearer ${token}`

            },
            data: { files: list },
            responseType: 'blob',
            //dataType: 'blob'
        }).then((response) => {
            //console.log(response)

            var file = new Blob([response.data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        }).catch(err => {
            //console.log(err)
        })

        //return

        /*  await _.map(list, (c, i) => {
            //////////console.log(c)
             return p[i] = new Promise(async (resolve, reject) => {
                 var request = axios({
                     method: 'post',
                     url: `${URL_API}/dropbox/files/get_preview`,
                     headers: {
                         Authorization: `Bearer ${token}`
 
                     },
                     data: { path: c, access: 'viewer' }
                 });
                 var files = []
                 await request.then((response) => {
 
                    //////////console.log(response.data)
                     if (response.data.body.url)
                         return resolve(response.data.body.url)
                     else if (response.data.body.links.length)
                         return resolve(response.data.body.links[0].url)
                     else
                         return reject()
                   
                 })
                     .catch((err) => {
                         return reject()
                     })
             })
         })
 
 
        //////////console.log(p)
 
         Promise.all(_.values(p)).then(values => {
            //////////console.log(values)
 
 
 
 
 
             var request = axios({
                 method: 'post',
                 url: `${URL_API}/getPDF`,
                 headers: {
                     Authorization: `Bearer ${token}`
 
                 },
                 data: { files: list, access: 'viewer' },
                 //responseType: 'blob',
                 //dataType: 'blob'
             });
 
             request.then((response) => {
                //////////console.log(response)
 
 
                 var file = new File([response.data], 'test.pdf', { type: 'application/pdf' })
                //////////console.log(file)
                 var fileURL = URL.createObjectURL(file);
                 window.open(fileURL);
 
             })
 
         }, reason => {
            //////////console.log(reason)
         }
         ) */

    }

}

export function deleteFacture(id) {

    return async (dispatch, getState) => {
        var invoice = getState().custom.factures.facturation.entities[id]
        var commande = getState().custom.commandes.entities[invoice.data.commandeId]

        firebaseService.firestore.collection('documents').doc(id).delete()
        if (!invoice.isFrais && commande && commande.id) {
            var invoices = _.filter(_.values(getState().custom.factures.facturation.entities), c => c.data.commandeId == invoice.data.commandeId && c.id != id && !c.data.isFrais)

            invoices = _.sortBy(invoices, ['date_facture_unix', 'desc'])
            //////////////////console.log(invoices)
            commande.montant_facturé = 0
            if (invoices.length) {
                commande.last_invoice = moment.unix(invoices[0].data.date_facture_unix).format('MM/DD/YYYY')
                commande.montant_facturé = _.sum(_.compact(_.map(invoices, c => {
                    if (!c.data.isFrais)
                        return c.data.recap.total_mois_HT ? c.data.recap.total_mois_HT : 0
                    else return 0
                })))
            }
            else
                commande.last_invoice = ''

            commande.reste_a_facturer = Number(commande.data.commande.prix_total) - commande.montant_facturé
            ////////////////////////console.log(commande)

            ////////////////console.log(commande)

            firebaseService.firestore.collection('documents').doc(invoice.data.commandeId).set({ ...commande, invoices: invoices }).catch(err => {
                //////////////////console.log(err)
            })
        }

        dispatch(Actions.closeDialog())
        // dispatch(getFactures({ id: 'all' }))

    }


}