

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectBox from 'devextreme-react/select-box';
import DataSource from "devextreme/data/data_source";
import ArrayStore from 'devextreme/data/array_store';
import * as ActionsAll from 'app/store/actions';
import _ from '@lodash'
import moment from "moment";
import "moment/locale/fr";
import { FuseUtils } from '@fuse';
import { TextBox } from 'devextreme-react/text-box';
import { TagBox } from 'devextreme-react/tag-box';
import DataGrid, { Lookup, RequiredRule, Column, ColumnChooser, Pager, ColumnFixing, Editing, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Selection, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import deMessages from "app/custom/translation/fr.json";
import { loadMessages, locale } from 'devextreme/localization';
import { InputAdornment, Input, TextField, Button, Dialog, DialogActions, DialogContent, Checkbox, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from '@fuse/hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import SelectClients from 'app/custom/components/selects/selectClients';
import SelectContacts from 'app/custom/components/selects/selectContacts';

import SelectSites from 'app/custom/components/selects/selectSites';
import SelectVariables from 'app/custom/components/selects/selectVariable';
import firebaseService from 'app/services/firebaseService';
import CustomPopup from 'app/custom/rapports/audit/installation/customPopup'
import TreeMenu from 'app/custom/rapports/audit/installation/treeMenu.js'
import Editor  from './editor';
import { Variables } from 'devextreme-react/html-editor';
import { forEach } from 'lodash';
import { getVariables } from 'app/store/actions';
{/* 
 <Column dataField={'batiment'} caption={'Bâtiment'} groupIndex={0} allowEditing={false} allowAdding={false} />
            <Column allowEditing='false' dataField={'batterieName'} caption={'Batterie'} groupIndex={1} allowAdding={false} />
            <Column dataField={'appareilName'} allowEditing='false' caption={'Appareil'} groupIndex={2} allowAdding={false} />
            <Column dataField={'localisation'} caption={'Localisation'} groupIndex={3}  defaultSortOrder = { 'desc'} >
    <Lookup dataSource={['Machinerie', 'Machinerie/Hydraulique', 'Toit de cabine', 'Opérateur / Porte cabine', 'Cabine', 'Gaine', 'Paliers', 'Cuvette']}></Lookup>
            </Column >
            <Column dataField={'value'} caption={'Etat des defaults'}  allowEditing={false} />
            <Column dataField={'etages'} caption={'Niveaux concernés'}  allowEditing={true} />
            <Column dataField={'check'} caption={''} dataType='boolean' allowAdding={false} allowEditing={true} />
            <Column dataField={'lever'} caption={'Réserve levée'} dataType='boolean' allowAdding={false} allowEditing={true} />
*/}

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
        height: '100%'
    },
}));
const GridElement = (form) => {

    return ''
}
const Infos = (props) => {
    ////console.log(props)
    const dispatch = useDispatch();
    const wrapperRef = useRef(props.tab);
    const grid = useRef(props.tab + 'D')
    const classes = useStyles();
    const userAuth = useSelector(({ auth }) => auth.user.uid);
    const variablesList = useSelector(({ custom }) => custom.variables);
    //////console.log(variablesList)
    const [years, setYears] = useState([])
    const [visible, setVisible] = useState(false)
    const [variables, setVariables] = useState({})
    //var variables = useSelector(({ custom }) => custom.variables[props.tab]);
    const [list, setList] = useState([])

    const [form, setForm] = useState(props.form[props.tab])
    const [appareilsFormat, setAppareilsFormat] = useState([])
    useMemo(() => {
        loadMessages(deMessages);

        locale(navigator.language);
    }, [])
    useEffect(() => {
        var listBase = _.compact(_.map(props.form[props.tab], f => {
                return f
        }))
        console.log(_.cloneDeep(listBase), variablesList.reglementation_articles)
        _.map(_.values(variablesList.reglementation_articles), c => {
            if (!_.find(listBase, { id: c.id }))
                listBase = _.concat(listBase ,{ ...c, check: false })
        })
        console.log(listBase)
       if (!_.isEqual(list, _.sortBy(_.sortBy(_.compact(listBase), 'reglementation'), 'reference')))
        setList(_.sortBy(_.sortBy(_.compact(listBase),'reglementation'),'reference'))
       

    }, [props.match.params, props.form[props.tab], variablesList.reglementation_articles]);
    useEffect(() => {

   /*      async function getVariables(){
        var v = {}

        await firebaseService.firestore.collection('reglementation').where('id','>','').get().then(r => {
            console.log(r.size)
            if(r.size>0)
                return r.forEach(doc => {
                    var g = { ...doc.data(), reference: doc.data().article }
              v[doc.id] = _.omit(g, 'article')
            })
        })
            firebaseService.firestore.collection('variables').doc('reglementation_articles').set(v)
            console.log(v)
        setVariables({ ...variables, reglementation_articles: v })
        }
        getVariables() */
    },[])
    useEffect(() => {
       
        ////////console.log(listBase)

        var v = {}
        _.map(variablesList, (c, f) => {
            if (_.includes(['reglementation','reglementation_articles'], f)) {
                if ( f != 'reglementation_articles')
                    v[f] = new DataSource({
                        store: _.map(c.values, (m, i) => {

                            return { id: i, value: m }
                        }),
                        key: 'id',
                        sort: { getter: "value", asc: true }

                    });
                else {
                    ////console.log(c)
                    v[f] = new DataSource({
                        store: _.values(c),
                        key: 'id',
                        sort: { getter: "value", asc: true }

                    });
                }
            }
            })
    if(!_.isEqual(v,variables))
        setVariables(v)
    


    }, [variablesList])

    useOutsideAlerter(wrapperRef, grid, form);
    function useOutsideAlerter(ref, grid, form) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {

                if (ref.current && !ref.current.contains(event.target)) {
                    //////console.log(grid.current.instance.getDataSource()._items)

                    form = grid.current.instance.getDataSource()._items
                    //////console.log(!_.isEqual(props.form[props.tab], form));
                    // if (!_.isEqual(props.form[props.tab], form) && !visible)
                    // props.handleChange(props.tab, form)
                }
            }


            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, form]);
    }

    function getFilteredBatteries(options) {
        //////console.log(options, props.form.batteries)
        return {
            store: props.form.batteries,
            filter: options.data ? ['batiment', '=', options.data.batiment] : null
        };
    }
    const rangeOfYears = (start, end) => Array(end - start + 1)
        .fill(start)
        .map((year, index) => year + index)

    const capital_letter = (str) => {

        str = str.split(' ');

        for (var i = 0, x = str.length; i < x; i++) {
            if (str[i][0])
                str[i] = str[i][0].toUpperCase() + str[i].substr(1);
        }

        return str.join(' ')

    }
    const selectVariable = (cellInfo, bdd) => {
        ////console.log(cellInfo)
        ////console.log(variablesList[bdd])
        function customItemCreating(e) {
            ////console.log(e)
            const nextId = Math.max.apply(Math, variables[bdd].items().map(c => c.id)) + 1;

            e.customItem = { id: nextId, value: e.text };

            variables[bdd].store().insert(e.customItem);
            cellInfo.setValue(e.text, e.text)


            variables[bdd].reload();

        }
        ////console.log(_.filter(_.values(variablesList[bdd]), { localisation: cellInfo.data.localisation }))
        return (
            <SelectBox
                dataSource={bdd != 'modernisations' ? variables[bdd] : new DataSource({
                    store: _.filter(_.values(variablesList[bdd]), { localisation: cellInfo.data.localisation }),
                    key: 'id',
                    sort: { getter: "value", asc: true }
                })}
                valueExpr={bdd != 'modernisations' ? "id" : 'value'}
                displayExpr="value"
                defaultValue={cellInfo.value}
                acceptCustomValue={true}
                onSelectionChanged={(e) => {
                    ////console.log(e)
                    if (e.selectedItem.value != cellInfo.value)
                        cellInfo.setValue(e.selectedItem.value, e.selectedItem.value)
                }}
                onCustomItemCreating={customItemCreating}
            />
        );

    }

    const selectMultipleVariable = (cellInfo, bdd) => {
        ////console.log(cellInfo)
        //////console.log(variables[bdd])
        function customItemCreating(e) {

            const nextId = Math.max.apply(Math, variables[bdd].items().map(c => c.id)) + 1;

            e.customItem = { id: nextId, value: e.text };

            variables[bdd].store().insert(e.customItem);
            cellInfo.setValue(e.text, e.text)
            variables[bdd].reload();

            if (!_.includes(variablesList[bdd].values, e.text))
                firebaseService.firestore.collection('variables').doc(bdd).set({ name: variablesList[bdd].name, values: _.concat(variablesList[bdd].values, e.text) })
        }

        return (
            <TagBox
                dataSource={variables[bdd]}
                valueExpr="value"
                displayExpr="value"
                defaultValue={cellInfo.value}
                acceptCustomValue={true}
                onValueChanged={(e) => {
                    //////console.log(e)
                    cellInfo.setValue(e.value)
                }}
                onCustomItemCreating={customItemCreating}
            />
        );

    }

    const customTextField = (cellInfo, bdd, unit) => {

        const setEditedValue = valueChangedEventArg => {

            return cellInfo.setValue(valueChangedEventArg.value ? valueChangedEventArg.value : '');
        }
        return (


            <TextBox
                id={bdd}

                valueChangeEvent={'blur'}
                defaultValue={cellInfo.value}
                onValueChanged={setEditedValue}

                aria-describedby={bdd}
            />
        )
    }

    
    function setStateValue(rowData, value) {
        rowData.batiment = null;
        this.defaultSetCellValue(rowData, value);
    }

    function editorCell(cellInfo, bdd){
console.log(cellInfo)

      return  <Editor
          values={cellInfo.data[bdd]}
          data={cellInfo}
          id={cellInfo.data.id}
           
        />
    }
    function selectTree(cellInfo, bdd) {
       //console.log(cellInfo)

        const setEditedValue = valueChangedEventArg => {
           //console.log(valueChangedEventArg)
            if (cellInfo.value != valueChangedEventArg) {
                cellInfo.value = valueChangedEventArg
                cellInfo.text = valueChangedEventArg
                return cellInfo.setValue(valueChangedEventArg);
            }
        }
        ////console.log(props)

        var tree = []

        _.map(props.form.installation.batiments, c => {
            tree = _.concat(tree, { ...c, parentId: 0 })
        })
        _.map(props.form.installation.batteries, c => {
            tree = _.concat(tree, { ...c, parentId: c.batiment })

        })
        _.map(props.form.installation.appareils, c => {
            tree = _.concat(tree, { ...c, parentId: c.batterie == '-' ? c.batiment : c.batterie, selected: _.includes(cellInfo.value, c.id) ? true : false })

        })
        ////console.log(tree)
        return (

            <div>
                <TreeMenu
                    value={cellInfo.value}
                    data={cellInfo}
                    tree={tree}
                    handleChange={setEditedValue}
                />

            </div>
        )
    }
    ////console.log(appareilsFormat)
    ////console.log(variables)
    ////console.log(form)
    return (
        <div className={classes.formControl} ref={wrapperRef}>


            <DataGrid
                id={props.tab}

                height={'100%'}
                ref={grid}
                dataSource={list}
                allowColumnResizing={true}
                allowColumnReordering={false}
                columnAutoWidth={true}
                columnResizingMode={'widget'}
                rowAlternationEnabled={true}
                showBorders={true}
                columnHidingEnabled={true}
                keyExpr="id"
                wordWrapEnabled={true}
                onInitNewRow={(e) => {
                    console.log(grid.current.instance.getDataSource()._items)
                    if (!grid.current.instance.getDataSource()._items.length)
                        e.data = { id: FuseUtils.generateGUID(), description: '', reference: '', reglementation:'', check:true }
                    else {
                        e.data = { ...grid.current.instance.getDataSource()._items[grid.current.instance.getDataSource()._items.length - 1], id: FuseUtils.generateGUID(), description: '', reference: '', check: true}
                    }
                }}
                onRowInserted={
                    (e) => {
                       
                        var l = _.keyBy(list, 'id')
                        l[e.data.id] = e.data
                        
                        props.handleChange(props.tab, _.sortBy(_.sortBy(_.filter(_.values(l), { check: true }), 'reglementation'), 'reference'))

                        if (e.data.description && e.data.reglementation) {
                            if (!_.includes(variablesList['reglementation'].values, e.data.reglementation)) {
                                firebaseService.firestore.collection('variables').doc('reglementation').set({ name: variablesList['reglementation'].name, values: _.concat(variablesList['reglementation'].values, e.data.reglementation)}).catch(e => {
                                    ////console.log(e)
                                })
                            }
                            if (!_.includes(variablesList.reglementation_articles, { description: e.data.description, reglementation: e.data.reglementation})) {
                                var h = _.concat(_.values(variablesList.reglementation_articles), _.omit({ ...e.data, createdBy: userAuth, description: e.data.description }, 'appareil', 'lever', 'etage','check'))
                                firebaseService.firestore.collection('variables').doc('reglementation_articles').set(_.keyBy(h, 'id')).catch(e => {
                                    ////console.log(e)
                                })
                            }
                        }

                    }
                }
                onRowUpdated={
                    (e) => {
                        console.log(e)
                        var l = _.keyBy(list, 'id')
                        l[e.data.id] = e.data

                        props.handleChange(props.tab, _.sortBy(_.sortBy(_.filter(_.values(l), { check: true }), 'reglementation'), 'reference'))

                        if (e.data.description && e.data.reglementation) {
                            if (!_.includes(variablesList['reglementation'].values, e.data.reglementation)) {
                                firebaseService.firestore.collection('variables').doc('reglementation').set({ name: variablesList['reglementation'].name, values: _.concat(variablesList['reglementation'].values, e.data.reglementation) }).catch(e => {
                                    ////console.log(e)
                                })
                            }
                            if (!_.includes(variablesList.reglementation_articles, { description: e.data.description, reglementation: e.data.reglementation })) {
                                var h = _.concat(_.values(variablesList.reglementation_articles), _.omit({ ...e.data, createdBy: userAuth, description: e.data.description }, 'appareil', 'lever', 'etage', 'check'))
                                firebaseService.firestore.collection('variables').doc('reglementation_articles').set(_.keyBy(h, 'id')).catch(e => {
                                    ////console.log(e)
                                })
                            }
                        }

                    }}
                onRowRemoved={
                    (e) => {
                        //props.handleChange(props.tab, _.filter(grid.current.instance.getDataSource()._items, { check: true }))

                    }}
            >
               <Scrolling mode="virtual" /> 
               <StateStoring enabled={true} type="localStorage" storageKey={"audit_annexeD"} /> 
                {/* 
                <ColumnFixing enabled={true} />
                <Export enabled={true} allowExportSelectedData={true} icon={'xlsxfile'} />
                <Selection mode={"multiple"} />
                <ColumnChooser enabled={true} />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} /> */}
                <SearchPanel visible={true}
                    //width={240}
                    placeholder="Recherche..." />
                <FilterRow visible={false} />
                <GroupPanel visible={true} />
                <StateStoring enabled={true} type="localStorage" storageKey={"audit_annexeD"} /> 
                {/* <Paging defaultPageSize={10} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} /> */}
                <Editing
                    mode="batch"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={(e => {
                        return false
                    })} />

                <Column allowEditing={true} allowUpdating={true}caption={'Règlementation'} dataField="reglementation" editCellRender={(e) => selectVariable(e, 'reglementation')} />

                <Column allowEditing={true} allowUpdating={true} dataField={'reference'} caption={'Référence'} />
                <Column allowEditing={true} allowUpdating={true} caption={'Description'} dataField="description" editCellRender={(e) => editorCell(e, 'description')} cellRender={(e) => {
               
                   
                    return <div dangerouslySetInnerHTML={{ __html: e ? '<div>' + e.value.replace(/(?:\r\n|\r|\n)/g, '') + '</div>' : '' }} />
                }}    />
                <Column dataField={'check'} caption={'Oui/Non'} dataType='boolean' allowEditing={true} allowUpdating={true}/>
               

            </DataGrid>


        </div>
    )

}

export default withRouter(Infos);