import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
export const CHECK_CONNECTION = '[FIREBASE APP] CHECK CONNECTION';

export function checkConnection() {

    return async (dispatch, getState) => {

        var user = getState().auth.user
        //console.log(user)
        var offsetRef = firebaseService.db.ref(".info/serverTimeOffset");
        offsetRef.on("value", function (snap) {
            var offset = snap.val();
            
            var estimatedServerTimeMs = new Date().getTime() + offset;
            ////console.log(estimatedServerTimeMs)
        });

        var myConnectionsRef = firebaseService.db.ref(`users/${user.uid}/connections`);

        // stores the timestamp of my last disconnect (the last time I was seen online)
        var lastOnlineRef = firebaseService.db.ref(`users/${user.uid}/lastOnline`);
        /* firebaseService.firestore.collection('users').doc(user.uid).update(`users/${user.uid}/lastOnline`); */

        var connectedRef = firebaseService.db.ref('.info/connected');
        connectedRef.on('value', function (snap) {
            if (snap.val() === true) {
                // //////console.log("connected");
                // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
                var con = myConnectionsRef.push();

                // When I disconnect, remove this device
                con.onDisconnect().remove();

                // Add this device to my connections list
                // this value could contain info about the device or a timestamp too
                con.set(true);

                // When I disconnect, update the last time I was seen online
                lastOnlineRef.onDisconnect().set(firebaseService.database.ServerValue.TIMESTAMP);

                return user
            
            } else {
                // //////console.log("not connected");
            }
        });

   
    }

}