import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
entities:{},
    rapportDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
        db: null
    },
    formDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
        items: [], paragraphe: '', appareil: '', batiment: ''

    },
    form: { data: {}, id: 'new' }


};

const rapportsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_RAPPORTS:
            {
                return {
                    ...state,

                    entities : action.payload
                };
            }
        case Actions.OPEN_NEW_FORM_DIALOG:
            {
                return {
                    ...state,

                    formDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data,
                        items: action.items, paragraphe: action.paragraphe, appareil: action.appareil, batiment: action.batiment

                    }
                };
            }
        case Actions.CLOSE_NEW_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null,
                        items: [], paragraphe: '', appareil: '', batiment: ''

                    }
                };
            }
        case Actions.OPEN_EDIT_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        items: action.items, paragraphe: action.paragraphe, appareil: action.appareil, batiment: action.batiment
                    }
                };
            }
        case Actions.CLOSE_EDIT_FORM_DIALOG:
            {
                return {
                    ...state,
                    formDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null,
                        items: [], paragraphe: '', appareil: '', batiment: ''

                    }
                };
            }
        case Actions.OPEN_NEW_RAPPORT_DIALOG:
            {
                return {
                    ...state,

                    rapportDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db
                    }
                };
            }
        case Actions.CLOSE_NEW_RAPPORT_DIALOG:
            {
                return {
                    ...state,
                    rapportDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null
                    }
                };
            }
        case Actions.OPEN_EDIT_RAPPORT_DIALOG:
            {
                return {
                    ...state,
                    rapportDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db
                    }
                };
            }
        case Actions.CLOSE_EDIT_RAPPORT_DIALOG:
            {
                return {
                    ...state,
                    rapportDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null
                    }
                };
            }
        case Actions.SET_FORM:
            {
                return {
                    ...state,
                    form: {
                        data: action.payload,
                        id: action.id
                    }
                };
            }
             case Actions.SET_REGLEMENTATION:
            {
               return {
                    ...state,
                    reglementaion: action.payload
                };
            }

        default:
            {
                return state;
            }
    }
};

export default rapportsReducer;
