import { combineReducers } from 'redux';

import contacts from 'app/custom/contacts/store/reducers';
import sites from 'app/custom/sites/store/reducers';
import commandes from 'app/custom/commandes/store/reducers';
import factures from 'app/custom/facturation/store/reducers';
import analytics from 'app/custom/analytics/store/reducers';
import firebaseConnection from 'app/store/reducers/custom/firebaseConnection';
import variables from 'app/store/reducers/custom/selectVariables.reducers';
import requests from 'app/store/reducers/custom/request.reducers';
import components from 'app/store/reducers/custom/components.reducers';
import rapports from 'app/custom/rapports/store/reducers';
import timeline from 'app/custom/timeline/store/reducers';
import leads from 'app/custom/leads/store/reducers';
const customReducers = combineReducers({
    contacts, sites, commandes, firebaseConnection, variables, factures, analytics, requests, components, rapports, timeline, leads
});

export default customReducers;
