import * as Actions from '../actions';
const initialState = {
    favorites: {
        users: {
            starred: {},
            groups: {},
            frequentContacts: {}
        },
        clients: {
            starred: {},
            groups: {},
            frequentContacts: {}
        }
    },
    infos: {}

}
const userReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_USER_DATA:


            return {
                ...state,
                infos: action.payload
            };

        case Actions.GET_USER_FAVORITES:
            return {
                ...state,
                favorites: action.payload
            };
        default:
            return state;
    }
};

export default userReducer;