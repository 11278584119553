import React from 'react';
import { Redirect } from 'react-router-dom';



export const FormsConfig = {
    settings: {
        layout: {}
    },
    routes: [
       
        {
            path: '/forms/:id',
            component: React.lazy(() => import('./formApp'))

        },
        {
            path: '/forms',
            component: () => <Redirect to="/forms/all" />
        }
    ]
};
