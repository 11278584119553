const locale = {
    'COMMANDES': 'Orders / Quotes',
    'FACTURES': 'Invoices',
    'OFFRES': 'Quotes',
    'RAPPORTS': 'Reports',
    'ANALYTICS': 'Analytics €',
    'FILE_MANAGER': 'File Manager',
    'CONTACTS': 'Contacts',
    'CLIENTS': 'Clients',
    'DROPBOX': 'Dropbox',
    'APPLICATIONS': 'Applications',
    'DASHBOARDS': 'Dashboards',
    'CALENDAR': 'Calendar',
    'ECOMMERCE': 'E-Commerce',
    'ACADEMY': 'Academy',
    'MAIL': 'Mail',
    'TODO': 'To-Do',

    'CHAT': 'Chat',
    'SCRUMBOARD': 'Scrumboard',
    'NOTES': 'Notes',
    'SITES': 'Sites',
    'LEADS': 'Leads'
};

export default locale;
