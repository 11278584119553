import React, { useForm, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AuditTab from './auditTab'
import InfosGenerales from 'app/custom/rapports/infosGenerales'
import ElementsVerifies from 'app/custom/rapports/audit/etendueExamen'
import InstallationTabs from 'app/custom/rapports/audit/installationTabs'
import AppBar from '@material-ui/core/AppBar'
import { setForm } from '../store/actions';
import { Button } from '@material-ui/core';
import FinalRender from './finalRender'
import AnnexeB from './annexeB'
import AnnexeC from './annexeC'
import AnnexeD from './annexeD'
import AnnexeE from './annexeE'
import AnnexeF from './annexeF'
import { useSelector } from 'react-redux';
import { FuseLoading } from '@fuse';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
function a11BisyProps(index) {
    return {
        id: `vertical-tabbis-${index}`,
        'aria-controls': `vertical-tabbispanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex flex-col',
        //height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));
const defaultForm = {
    infos: {},
    elements_verifies: [],
    installation: {
        batiments: [],
        batteries: [],
        appareils: [],
        modernisations: []
    },
    audit: {
        evaluation_generale: {
            maintenance: {},
            nettoyage: {},
            graissage: {},
            reglages: {},
            glisse: {}
        },
        maintenance: {},
        fiabilite: {},
        securite: {
            DA: {}, DB: {}, reglementation: {}
        },
        aspect_general: {},
        qualite_deplacement: {},
        carnet_entretien: {},
        amiante: {},
        developpement_durable: {}

    },
    annexe_A: {},
    annexe_B: [],
    annexe_C: [],
    annexe_D: [],
    annexe_E: [],
    annexe_F: [],
    annexe_G: [],
    annexe_H: []

}


export default function VerticalTabs(props) {
    //console.log(props)

    const rapports = useSelector(({ custom }) => custom.rapports.entities)
    
    const classes = useStyles();
 
    const [value, setValue] = React.useState(localStorage.getItem("auditTab") === null ? 0 : JSON.parse(localStorage.getItem("auditTab")));

    const [form, setForm] = useState(null)
    //console.log(rapports)
    useMemo(() => {
        //////////console.log(JSON.parse(localStorage.getItem('audit')))
        setForm(props.match.params.id == 'new' && localStorage.getItem("audit") === null ?
            defaultForm : props.match.params.id == 'new' && localStorage.getItem("audit") ? {
                ...defaultForm, ...JSON.parse(localStorage.getItem('audit'))
            } : {...rapports[props.match.params.id]}
        )
    }, [props.match.params.id])
    const handleChange = (event, newValue) => {

        localStorage.setItem('auditTab', newValue);
        setValue(newValue);
        ////////////console.log(JSON.stringify(form))

    };

    const handleChangeForm = (p, f) => {
        //console.log(p,f)
        if (p != 'elements_verifies' && p != 'audit' && p != 'annexe_B' && p != 'annexe_C' && p != 'annexe_D' && p != 'annexe_E' && p != 'annexe_F'&& p != 'annexe_H') {
            var l = { ...form, [p]: { ...f } }
        } else {
            var l = { ...form, [p]: f }
        }
        localStorage.setItem('audit', JSON.stringify(l))
        setForm(l)



    };


    if (!form)
      return <FuseLoading/>
    return (
        <div className={'flex flex-col'}>

            <AppBar position='relative'>
                <Tabs
                    //orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={''}
                >

                    <Tab label={("Informations générales").toUpperCase()} {...a11yProps(0)} />
                    <Tab label={("Étendue de l'examen et du rapport").toUpperCase()} {...a11yProps(1)} />
                    <Tab label={("Ensemble de l'installation").toUpperCase()} {...a11yProps(2)} />
                    <Tab label={("Audit").toUpperCase()} {...a11yProps(3)} >
                    </Tab>
               
                    <Tab label="ANNEXE B : ÉTAT DES DÉFAUTS D’ENTRETIEN" {...a11yProps(4)} />
                    <Tab label="ANNEXE C : AMÉLIORATIONS POUR RÉPONDRE AUX EXIGENCES DE SÉCURITÉ Décret 2008 – 1325 du 15 décembre 2008. Arrêtés Divers" {...a11yProps(5)} />
                    <Tab label="ANNEXE D : AMÉLIORATIONS POUR RÉPONDRE AUX EXIGENCES DE SÉCURITÉ BATIMENTS IGH/ERP et ARTICLES AS" {...a11yProps(6)} />
                    <Tab label="ANNEXE E : AMÉLIORATIONS POUR RÉPONDRE A LA LOI URBANISME ET HABITAT DU 2 JUILLET 2003" {...a11yProps(7)} />
                    <Tab label="ANNEXE F : PÉRENNITÉS ET TRAVAUX DE SÉCURITÉ" {...a11yProps(8)} />
                    <Tab label="ANNEXE G : RÉCAPITULATIF FINANCIER DES TRAVAUX" {...a11yProps(9)} />
                    <Tab label="ANNEXE H : PHOTOS  DE L’EXAMEN TECHNIQUE" {...a11yProps(10)} />
                    <Tab label="VOIR LE DOCUMENT" textColor={'secondary'} {...a11yProps(11)} />
                </Tabs>

            </AppBar>


            <TabPanel value={value} index={0} >

                <InfosGenerales handleChange={handleChangeForm} tab={'infos'} form={form} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ElementsVerifies handleChange={handleChangeForm} tab={'elements_verifies'} form={form} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <InstallationTabs handleChange={handleChangeForm} tab={'installation'} form={form} />
            </TabPanel>
            <TabPanel value={value} index={3}>

                <AuditTab handleChange={handleChangeForm} tab={'audit'} form={form} />

            </TabPanel>

            <TabPanel value={value} index={4}>
                {/* ANNEXE B : ÉTAT DES DÉFAUTS D’ENTRETIEN DE L’ASCENSEUR */}
                <AnnexeB handleChange={handleChangeForm} tab={'annexe_B'} form={form} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <AnnexeC handleChange={handleChangeForm} tab={'annexe_C'} form={form} />   </TabPanel>
            <TabPanel value={value} index={6}>
                <AnnexeD handleChange={handleChangeForm} tab={'annexe_D'} form={form} />
            </TabPanel>
            <TabPanel value={value} index={7}>
                ANNEXE E : AMÉLIORATIONS POUR RÉPONDRE A LA LOI URBANISME ET HABITAT DU 2 JUILLET 2003
                  <AnnexeE handleChange={handleChangeForm} tab={'annexe_E'} form={form} />
      </TabPanel>
            <TabPanel value={value} index={8}>
                ANNEXE F : PÉRENNITÉS ET TRAVAUX DE SÉCURITÉ
                  <AnnexeF handleChange={handleChangeForm} tab={'annexe_F'} form={form} />
      </TabPanel>
            <TabPanel value={value} index={9}>
                ANNEXE G : RÉCAPITULATIF FINANCIER DES TRAVAUX
                 
                Ce document est généré automatiquement à partir des valeurs renseignées en annexe C et E
      </TabPanel>
            <TabPanel value={value} index={10}>
                ANNEXE H : PHOTOS  DE L’EXAMEN TECHNIQUE DE L’ASCENSEUR
      </TabPanel>
            <TabPanel value={value} index={11}>
                <FinalRender form={form} />
            </TabPanel>

        </div>
    );
}