import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions';
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import moment from 'moment'
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
export const GET_CONTACTS = '[CONTACTS APP] GET CONTACTS';
export const GET_PLATFORM_USERS = '[CONTACTS APP] GET PLATFORM USERS';
export const GET_CLIENTS = '[CONTACTS APP] GET CLIENTS';
export const SET_SEARCH_TEXT = '[CONTACTS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_CONTACTS = '[CONTACTS APP] TOGGLE IN SELECTED CONTACTS';
export const SELECT_ALL_CONTACTS = '[CONTACTS APP] SELECT ALL CONTACTS';
export const DESELECT_ALL_CONTACTS = '[CONTACTS APP] DESELECT ALL CONTACTS';
export const OPEN_NEW_CONTACT_DIALOG = '[CONTACTS APP] OPEN NEW CONTACT DIALOG';
export const CLOSE_NEW_CONTACT_DIALOG = '[CONTACTS APP] CLOSE NEW CONTACT DIALOG';
export const OPEN_EDIT_CONTACT_DIALOG = '[CONTACTS APP] OPEN EDIT CONTACT DIALOG';
export const CLOSE_EDIT_CLIENT_DIALOG = '[CONTACTS APP] CLOSE EDIT CLIENT DIALOG';
export const OPEN_NEW_CLIENT_DIALOG = '[CONTACTS APP] OPEN NEW CLIENT DIALOG';
export const OPEN_EDIT_CLIENT_DIALOG = '[CONTACTS APP] OPEN EDIT CLIENT DIALOG';
export const CLOSE_NEW_CLIENT_DIALOG = '[CONTACTS APP] CLOSE NEW CLIENT DIALOG';
export const CLOSE_EDIT_CONTACT_DIALOG = '[CONTACTS APP] CLOSE EDIT CONTACT DIALOG';
export const ADD_CONTACT = '[CONTACTS APP] ADD CONTACT';
export const UPDATE_CONTACT = '[CONTACTS APP] UPDATE CONTACT';
export const REMOVE_CONTACT = '[CONTACTS APP] REMOVE CONTACT';
export const REMOVE_CONTACTS = '[CONTACTS APP] REMOVE CONTACTS';
export const TOGGLE_STARRED_CONTACT = '[CONTACTS APP] TOGGLE STARRED CONTACT';
export const TOGGLE_STARRED_CONTACTS = '[CONTACTS APP] TOGGLE STARRED CONTACTS';
export const SET_CONTACTS_STARRED = '[CONTACTS APP] SET CONTACTS STARRED ';
export const STOP_CONTACTS = '[CONTACTS APP] STOP_CONTACTS ';
export const STOP_CLIENTS = '[CONTACTS APP] STOP_CLIENTS ';
var contactsF, clientsF;
export function getContacts(routeParams, stop) {

    ////console.log(routeParams, stop)
    return (dispatch, getState) => {
        if (routeParams && routeParams.id == 'load')
            dispatch(Actions.showMessage({ 'message': 'Chargement de la liste des contacts...', 'variant': 'info' }))
        const { navigation } = getState().fuse;
        if (!stop) {
            var CLIENTS = getState().custom.contacts.contacts.clients
            ////console.log(CLIENTS)
            let CONTACTS = {};
            let CONSULTANTS = {};
            var i = 0
            contactsF = firebaseService.firestore.collection('users').where('id', '>', '')
                .onSnapshot((docSnaps) => {

                    docSnaps.forEach((doc) => {
                        i++
                        CONTACTS[doc.id] = doc.data();
                        var company = {}
                        if (CLIENTS[doc.data().company]) {
                            company = {
                                id: CLIENTS[doc.data().company].id, name: CLIENTS[doc.data().company].name, entite: CLIENTS[doc.data().company].entite
                            }
                        }
                        CONTACTS[doc.id].company = company

                        if (i == docSnaps.size) {
                            var C = _.map(CONTACTS, c => {
                                if (c.createdBy && !c.createdBy.id && CONTACTS[c.createdBy])
                                    c.createdBy = { email: CONTACTS[c.createdBy].email, id: CONTACTS[c.createdBy].id, name: CONTACTS[c.createdBy].name, lastName: CONTACTS[c.createdBy].lastName, access: CONTACTS[c.createdBy].access }

                                else
                                    c.createdBy = {}
                                if (c.updatedBy && !c.updatedBy.id && CONTACTS[c.updatedBy])
                                    c.updatedBy = { email: CONTACTS[c.updatedBy].email, id: CONTACTS[c.updatedBy].id, name: CONTACTS[c.updatedBy].name, lastName: CONTACTS[c.updatedBy].lastName, access: CONTACTS[c.updatedBy].access }

                                else
                                    c.updatedBy = {}
                                return c
                            })

                            CONSULTANTS = _.filter(C, c => c && c.access && c.access.abbreviation_consultant)
                            var contactsItem = _.find(navigation, { id: 'contacts' })
                            contactsItem.badge.title = _.values(CONTACTS).length
                            dispatch(Actions.updateNavigationItem('contacts', contactsItem));

                            dispatch(Actions.hideMessage())



                            Promise.all([
                                dispatch({
                                    type: GET_PLATFORM_USERS,
                                    payload: _.keyBy(CONSULTANTS, 'id'),
                                    routeParams
                                }),
                                dispatch({
                                    type: GET_CONTACTS,
                                    payload: _.keyBy(C, 'id'),
                                    routeParams
                                })
                            ]).then(() => {
                                console.log(routeParams)
                                dispatch(Actions.getLeads(routeParams))
                                //dispatch(Actions.getSites(routeParams))
                            }
                            )


                            i = 0
                        }

                    })
                });
        } else {
            contactsF()
            dispatch({
                type: STOP_CONTACTS,

            })
        }

    }


}
export function getClients(routeParams, stop) {
    ////console.log(routeParams)
    return (dispatch, getState) => {
        var CONTACTS = getState().custom.contacts.contacts.users
        if (routeParams && routeParams.id == 'load')
            dispatch(Actions.showMessage({ 'message': 'Chargement de la liste des clients...', 'variant': 'info' }))
        if (!stop) {
            clientsF = firebaseService.firestore.collection('clients').where('id', '>', '').onSnapshot((r) => {
                const { navigation } = getState().fuse;
                var clientList = []
                if (r.size > 0) {

                    r.forEach(doc => {
                        var data = doc.data()
                        if(data){


                        if (data.createdBy && data.createdBy.id)
                            data.createdBy = data.createdBy.id
                        if (data.updatedBy && data.updatedBy.id)
                            data.updatedBy = data.updatedBy.id
                        if (data.createdBy && !CONTACTS[data.createdBy])
                            firebaseService.firestore.collection('users').doc(data.createdBy).get().then(r => {
                                data.createdBy = { email: data.email, id: r.id, name: data.name, lastName: data.lastName, access: data.access }
                            })
                        else if (data.createdBy && CONTACTS[data.createdBy]) {
                            data.createdBy = { email: CONTACTS[data.createdBy].email, id: CONTACTS[data.createdBy].id, name: CONTACTS[data.createdBy].name, lastName: CONTACTS[data.createdBy].lastName, access: CONTACTS[data.createdBy].access }
                        }
                        if (data.updatedBy && !CONTACTS[data.updatedBy])
                            firebaseService.firestore.collection('users').doc(data.updatedBy).get().then(r => {
                                data.updatedBy = { email: data.email, id: r.id, name: data.name, lastName: data.lastName, access: data.access }
                            })
                        else if (data.updatedBy && CONTACTS[data.updatedBy]) {
                            data.updatedBy = { email: CONTACTS[data.updatedBy].email, id: CONTACTS[data.updatedBy].id, name: CONTACTS[data.updatedBy].name, lastName: CONTACTS[data.updatedBy].lastName, access: CONTACTS[data.updatedBy].access }
                        }

                        clientList.push(data)}
                    })
                }


                var clientsItem = _.find(navigation, { id: 'clients' })
                clientsItem.badge.title = clientList.length
                dispatch(Actions.updateNavigationItem('clients', clientsItem));

                return Promise.all([
                    dispatch({
                        type: GET_CLIENTS,
                        payload: _.keyBy(clientList, 'id'),
                        routeParams
                    })
                ]).then(() => dispatch(getContacts(routeParams, null)))



            })
        } else {
            clientsF()
            return dispatch({
                type: STOP_CLIENTS,

            })
        }

    }
}
export function getConsultants(routeParams) {
    return (dispatch, getState) => {
        dispatch(Actions.showMessage({ 'message': 'Chargement de la liste des consultants...', 'variant': 'info' }))
        var CONSULTANTS = {};
        var i = 0
        firebaseService.firestore.collection('users').where('access.abbreviation_consultant', '>', '').onSnapshot((r) => {


            if (r.size > 0) {
                r.forEach(d => {
                    i++
                    CONSULTANTS[d.id] = d.data()

                    if (i == r.size) {


                        return Promise.all([
                            dispatch({
                                type: GET_PLATFORM_USERS,
                                payload: CONSULTANTS,

                            })
                        ]).then(() => {

                            dispatch(getClients({ id: 'load' }, null))
                           // dispatch(Actions.getOldTimeline())
                        })
                    }
                })
            }




        })
    }
}
export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedContacts(contactId) {
    return {
        type: TOGGLE_IN_SELECTED_CONTACTS,
        contactId
    }
}

export function selectAllContacts() {
    return {
        type: SELECT_ALL_CONTACTS
    }
}

export function deSelectAllContacts() {
    return {
        type: DESELECT_ALL_CONTACTS
    }
}

export function openNewContactDialog(data, type) {
    ////console.log(data, type)
    return {
        type: OPEN_NEW_CONTACT_DIALOG,
        data: data, db: type
    }
}

export function closeNewContactDialog() {
    return {
        type: CLOSE_NEW_CONTACT_DIALOG
    }
}

export function openEditContactDialog(data, type) {
    ////////console.log(data, type)
    return {
        type: OPEN_EDIT_CONTACT_DIALOG,
        data: data, db: type
    }
}
export function openNewClientDialog(data, db) {
    return {
        type: OPEN_NEW_CLIENT_DIALOG,
        data: data, db: db
    }
}
export function closeNewClientDialog() {
    return {
        type: CLOSE_NEW_CLIENT_DIALOG,
        payload: null

    }
}

export function closeEditClientDialog() {
    return {
        type: CLOSE_EDIT_CLIENT_DIALOG
    }
}


export function openEditClientDialog(data, type) {

    return {
        type: OPEN_EDIT_CLIENT_DIALOG,
        data: data, db: type
    }
}

export function closeEditContactDialog() {
    return {
        type: CLOSE_EDIT_CONTACT_DIALOG
    }
}

export function addContact(newContact, db) {
    ////console.log(newContact)
    const defaultFormState = {
        contact: {
            id: '',
            gender: 'Mr',
            name: '',
            lastName: '',
            avatar: 'assets/images/avatars/profile.jpg',
            //nickname: '',
            company: '',
            jobTitle: '',
            email: '',
            phone: '',
            mobile: '',
            fax: '',
            address: '',
            birthday: '',
            notes: '',
            isUser: false
        }, client: {
            id: '',
            name: '',
            entite: '',
            siret: '',
            tva: '',
            avatar: 'assets/images/avatars/profile.jpg',
            email: '',
            phone: '',
            fax: '',
            address: '',
            address2: '',
            zip: '',
            city: '',
            country: 'France',
            notes: '',

        }
    };
    return (dispatch, getState) => {

        newContact = _.omit(newContact, 'select')
        const { routeParams } = getState().contactsApp.contacts;
        const { user } = getState().auth;

        var table = null
        switch (db) {
            case 'contact':
                table = 'users'
                break;
            case 'client':
                table = 'clients'
                break;
            default:
                table = 'users'
                break;

        }
        if (db === 'contact' && newContact.isUser) {
            var u = {
                uid: newContact.id,
                from: 'firebase',
                role: ["user"],
                data: {
                    displayName: newContact.lastName + ' ' + newContact.name,
                    email: newContact.email,
                    settings: user.data.settings
                }
            }
            firebaseService.db.ref('/users/' + newContact.id).set(u)
        }
        /*  if (table === 'users') {
             firebaseService.firestore.collection(table).doc(newContact.id).collection('favorites').doc('clients').set({
                 starred: {},
                 groups: {},
                 frequentContacts: {}
             })
             firebaseService.firestore.collection(table).doc(newContact.id).collection('favorites').doc('users').set({
                 starred: {},
                 groups: {},
                 frequentContacts: {}
             })
         } */

        if (db == 'client' && (!newContact.address_facturation || (newContact.address_facturation && !_.values(newContact.address_facturation).length))) {
            var j = FuseUtils.generateGUID()
            newContact.address_facturation = {}
            newContact.address_facturation[newContact.id] = _.omit(newContact, 'address_facturation')
        }
        if (!newContact.createdBy) {
            newContact.createdBy = firebaseService.auth.currentUser.uid
            newContact.createdTime = moment().format('YYYY-MM-DD HH:mm:ss')
        }

        if (table == 'users')
            newContact.company = newContact.company.id ? newContact.company.id : newContact.company ? newContact.company : ''

        firebaseService.firestore.collection(table).doc(newContact.id).set({ ...defaultFormState[db], ...newContact }).then(() => {

            dispatch({
                type: ADD_CONTACT
            })
        }).catch(err => {
            ////console.log(err)

        })


    };
}

export function updateContact(contact, db) {

    contact = _.omit(contact, 'select')
    const defaultFormState = {
        contact: {
            id: '',
            gender: 'Mr',
            name: '',
            lastName: '',
            avatar: 'assets/images/avatars/profile.jpg',
            //nickname: '',
            company: '',
            jobTitle: '',
            email: '',
            phone: '',
            mobile: '',
            fax: '',
            address: '',
            birthday: '',
            notes: '',
            isUser: false
        }, client: {
            id: '',
            name: '',
            entite: '',
            siret: '',
            tva: '',
            avatar: 'assets/images/avatars/profile.jpg',
            email: '',
            phone: '',
            fax: '',
            address: '',
            address2: '',
            zip: '',
            city: '',
            country: 'France',
            notes: '',

        }
    };
    return (dispatch, getState) => {

        const { routeParams } = getState().contactsApp.contacts;
        var table = null
        switch (db) {
            case 'contact':
                table = 'users'
                break;
            case 'client':
                table = 'clients'
                break;
            default:
                table = 'users'
                break;

        }
        contact.updatedBy = firebaseService.auth.currentUser.uid
        contact.updatedTime = moment().format('YYYY-MM-DD HH:mm:ss')
        if (db == 'client' && (!contact.address_facturation || (contact.address_facturation && !_.values(contact.address_facturation).length))) {
            var j = FuseUtils.generateGUID()
            contact.address_facturation = {}
            contact.address_facturation[contact.id] = _.omit({ ...contact, id: contact.id }, 'address_facturation')
        }
        if (!contact.createdBy) {
            contact.createdBy = firebaseService.auth.currentUser.uid
            contact.createdTime = moment().format('YYYY-MM-DD HH:mm:ss')
        }

        if (table == 'users')
            contact.company = contact.company.id ? contact.company.id : contact.company ? contact.company : ''
        firebaseService.firestore.collection(table).doc(contact.id).set({ ...defaultFormState[db], ...contact }).then(() => {

            dispatch({
                type: UPDATE_CONTACT
            })
        }).catch(err => {



        })

    };
}

export function removeContact(contactId, db) {

    return (dispatch, getState) => {

        const { routeParams } = getState().contactsApp.contacts;
        var table = null
        switch (db) {
            case 'contact':
                table = 'users'
                break;
            case 'client':
                table = 'clients'
                break;
            default:
                table = 'users'
                break;

        }

        firebaseService.firestore.collection(table).doc(contactId).delete().then(() => {

            dispatch({
                type: REMOVE_CONTACT
            })
        }).catch(err => {
            ////////console.log(err)


        })

    };
}


export function removeContacts(contactIds, db) {
    ////////console.log(contactIds, db)
    return async (dispatch, getState) => {

        const { routeParams } = getState().contactsApp.contacts;
        var table = db

        var promises = []
        _.map(contactIds, contactId => {
            ////////console.log(contactId, table)
            promises.push(new Promise(async function (resolve, reject) {
                if (table === 'users') {

                }
                firebaseService.firestore.collection(table).doc(contactId).delete().then((err) => {
                    ////////console.log(err)
                    resolve()
                }).catch(err => {
                    ////////console.log(err)
                    reject()
                })
            }))
        })



        Promise.all(promises.concat([
            dispatch({
                type: REMOVE_CONTACTS
            }),
            dispatch({
                type: DESELECT_ALL_CONTACTS
            })
        ])).then(() => dispatch(getContacts(routeParams, null)))

    };
}

export function toggleStarredContact(contactId, db) {
    ////////console.log(contactId, db)



    return (dispatch, getState) => {
        const { user } = getState().auth


        var table = null
        switch (db) {
            case 'contact':
                table = 'users'
                break;
            case 'client':
                table = 'clients'
                break;
            default:
                table = 'users'
                break;

        }
        const { routeParams } = getState().contactsApp.contacts;
        const { favorites } = getState().contactsApp.user;
        ////////console.log(favorites)
        if (favorites[table].starred[contactId])
            delete favorites[table].starred[contactId]
        else favorites[table] = { ...favorites[table], starred: { ...favorites[table].starred, [contactId]: {} } }
        firebaseService.firestore.collection('users').doc(user.uid).collection('favorites').doc(table).set(favorites[table]).then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CONTACT
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getContacts(routeParams, null)))
        );
    };
}

export function toggleStarredContacts(contactIds, db) {

    ////////console.log(contactIds, db)
    return (dispatch, getState) => {
        var table = null
        switch (db) {
            case 'contact':
                table = 'users'
                break;
            case 'client':
                table = 'clients'
                break;
            default:
                table = 'users'
                break;

        }
        const { routeParams } = getState().contactsApp.contacts;

        const request = axios.post('/api/contacts-app/toggle-starred-contacts', {
            contactIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_CONTACTS
                }),
                dispatch({
                    type: DESELECT_ALL_CONTACTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getContacts(routeParams)))
        );
    };
}

export function setContactsStarred(contactIds, db) {
    ////////console.log(contactIds, db)
    return (dispatch, getState) => {

        const { routeParams } = getState().contactsApp.contacts;

        const request = axios.post('/api/contacts-app/set-contacts-starred', {
            contactIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CONTACTS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CONTACTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getContacts(routeParams)))
        );
    };
}

export function setContactsUnstarred(contactIds, db) {
    ////////console.log(contactIds, db)
    return (dispatch, getState) => {

        const { routeParams } = getState().contactsApp.contacts;


        const request = axios.post('/api/contacts-app/set-contacts-unstarred', {
            contactIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_CONTACTS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_CONTACTS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getContacts(routeParams)))
        );
    };
}
