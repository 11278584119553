const locale = {
    'NEW_CONTACT': 'New contact',
    'NEW_CLIENT': 'New client',
    'NEW_QUOTE': 'New quote',
         'NEW_SITE': 'New site',
    'PROFILE': 'My profile',
    'NOTIFICATION_ALLOW': 'Enable/Disable Notifications',
    'LOGOUT': 'Logout',
    'LOGIN': 'Login'
}
export default locale