import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';

////console.log(fr)



export const DashBoardConfig = {
    settings: {
        layout: {}
    },
    routes: [

        {
            path: '',
            component: React.lazy(() => import('./dashboardApp'))

        }
       
    ]
};
