
const prodConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "movveo-v0.firebaseapp.com",
    databaseURL: "https://movveo-v0.firebaseio.com",
    projectId: "movveo-v0",
    storageBucket: "movveo-v0.appspot.com",
    messagingSenderId: "719369354638",
    appId: "1:719369354638:web:4af0266f61d69b30b478df",
    measurementId: "G-QV4T4M355N"
};

// const devConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_KEY,
//     authDomain: "movveo-dev-78ade.firebaseapp.com",
//     projectId: "movveo-dev-78ade",
//     storageBucket: "movveo-dev-78ade.appspot.com",
//     databaseURL: "https://movveo-dev-78ade-default-rtdb.europe-west1.firebasedatabase.app/",
//     messagingSenderId: "452200925238",
//     appId: "1:452200925238:web:ba9b04d1fc3d618956f097"
// };

export const config = prodConfig;


export const URL = process.env.REACT_APP_URL_API || "https://api.movveo.fr"
export const URL_API = process.env.REACT_APP_URL_API || "https://api.movveo.fr"

console.log(process.env.REACT_APP_URL_API , URL_API, process.env.NODE_ENV)