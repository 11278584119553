import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'analyticsApp', en);
i18next.addResourceBundle('fr', 'analyticsApp', fr);


export const AnalyticsConfig = {
    settings: {
        layout: {}
    },
    routes: [

        {
            path: '/analytics/:id',
            component: React.lazy(() => import('./analyticsApp'))
        },
        {
            path: '/analytics',
            component: () => <Redirect to="/analytics/all" />
        }
    ]
};
