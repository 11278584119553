import * as Actions from '../actions';
import _ from '@lodash';
console.log(Actions)
const initialState = {
    entities: '',
    clients: '',
    users: {},
    searchText: '',
    selectedContactIds: [],
    routeParams: {},
    contactDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
        db: null
    },
    clientDialog: {
        type: 'edit',
        props: {
            open: false
        },
        data: null,
        db: null

    }
};

const contactsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.STOP_CONTACTS: {
            return {
                ...state,

            };
        }
        case Actions.STOP_CLIENTS: {
            return {
                ...state,

            };
        }
        case Actions.GET_CONTACTS:
            {
                return {
                    ...state,
                    entities: action.payload,
                    routeParams: action.routeParams
                };
            }
        case Actions.GET_PLATFORM_USERS:
            {
                return {
                    ...state,
                    users: action.payload,
                    routeParams: action.routeParams
                };
            }
        case Actions.GET_CLIENTS:
            {
                return {
                    ...state,
                    clients: action.payload,
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_CONTACTS:
            {

                const contactId = action.contactId;

                let selectedContactIds = [...state.selectedContactIds];

                if (selectedContactIds.find(id => id === contactId) !== undefined) {
                    selectedContactIds = selectedContactIds.filter(id => id !== contactId);
                }
                else {
                    selectedContactIds = [...selectedContactIds, contactId];
                }

                return {
                    ...state,
                    selectedContactIds: selectedContactIds
                };
            }
        case Actions.SELECT_ALL_CONTACTS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedContactIds = arr.map(contact => contact.id);

                return {
                    ...state,
                    selectedContactIds: selectedContactIds
                };
            }
        case Actions.DESELECT_ALL_CONTACTS:
            {
                return {
                    ...state,
                    selectedContactIds: []
                };
            }
        case Actions.OPEN_NEW_CONTACT_DIALOG:
            {
                return {
                    ...state,

                    contactDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db
                    }
                };
            }
        case Actions.CLOSE_NEW_CONTACT_DIALOG:
            {
                return {
                    ...state,
                    contactDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null
                    }
                };
            }
        case Actions.OPEN_EDIT_CONTACT_DIALOG:
            {
                return {
                    ...state,
                    contactDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db
                    }
                };
            }
        case Actions.CLOSE_EDIT_CONTACT_DIALOG:
            {
                return {
                    ...state,
                    contactDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null
                    }
                };
            }
        case Actions.CLOSE_NEW_CLIENT_DIALOG:
            {
                return {
                    ...state,
                    clientDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null
                    }
                };
            }

        case Actions.OPEN_EDIT_CLIENT_DIALOG:
            {
                return {
                    ...state,
                    clientDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        db: action.db

                    }
                };
            }
        case Actions.OPEN_NEW_CLIENT_DIALOG:
            {
                return {
                    ...state,
                    clientDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: action.data,


                    }
                };
            }
        case Actions.CLOSE_EDIT_CLIENT_DIALOG:
            {
                return {
                    ...state,
                    clientDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null,
                        db: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default contactsReducer;
