import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
export const GET_VARIABLES = '[VARIABLES APP] GET_VARIABLES';




export function getVariables() {

    return async (dispatch, getState) => {
        var variables = getState().custom.variables

        firebaseService.firestore.collection('variables').onSnapshot(r => {
            var v = {}
            if (r.size > 0)
                r.forEach(async doc => {
                    //console.log(doc.id, doc.data().batiment)
                    v[doc.id] = doc.data()
                    if (doc.data().batiment) {
                       await firebaseService.firestore.collection('variables').doc('type_batiment').get()
                            .then(res => {
                                v[doc.id] = res.data()
                            
                            })
                            .catch(err => console.error(err));
                    } 
                    if (doc.id == 'description_annexeF') {
                        v[doc.id]=[]
                        await firebaseService.firestore.collection('variables').doc(doc.id).collection('data').get()
                            .then(res => {
                                    if(res.size>0)
                                    res.forEach(d => {
                                    v[doc.id].push(d.data())   
                                    })
                                                        

                            })
                            .catch(err => console.error(err));
                    }


                })
            //console.log({ ...variables, ...v })
     
            return dispatch({
                type: GET_VARIABLES,
                payload: { ...variables, ...v }
            })


        })

    }

}

