import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    folders: {},

    /*  searchText: '',
     selectedSitesIds: [],
     routeParams: {},
     siteDialog: {
         type: 'new',
         props: {
             open: false
         },
         data: null,
 
     }, */

};


const siteReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_FOLDERS:
            {
                return {
                    ...state,
                    folders: _.keyBy(action.payload, 'id'),

                };
            }



        default:
            {
                return state;
            }
    }
};

export default siteReducer;
