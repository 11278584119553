import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions';
import * as Actions from 'app/store/actions';
import _ from '@lodash'

import history from '@history'
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import moment from 'moment'
export const GET_ANALYTICS = '[ANALYTICS APP] GET ANALYTICS';
export const GET_OBJECTIFS = '[ANALYTICS APP] GET OBJECTIFS';
export function getAnalytic(routeParams) {
    return (dispatch, getState) => {
        //dispatch(Actions.showMessage({ message: 'Chargement des commandes...' }))
        var analytics = getState().custom.analytics.entities
        //////////console.log(getState)
        return dispatch({
            type: GET_ANALYTICS,
            payload: analytics,
            routeParams
        })


    }

}
export function getAnalytics(routeParams) {
    //////////console.log('getAnalytics', routeParams)
    return (dispatch, getState) => {
        //dispatch(Actions.showMessage({ message: 'Chargement des commandes...' }))
        const { navigation } = getState().fuse;
     
        firebaseService.firestore.collection('documents').where('type', '==', 'commande').onSnapshot((r) => {
            const auth = getState().auth.user
            const user = getState().custom.contacts.user.infos
            //////console.log(getState().auth, getState().custom.contacts.user)

            var factures = getState().custom.factures.facturation.entities
            //console.log('load')
            var i = 0
            var analytics = []
            var commandes = []
            if (r.size > 0) {

                r.forEach(doc => {
                    var data = doc.data()
                 
                    if (data && data.status !== 'archived') {

                        commandes.push({ ...data, id: doc.id })
                        if (data && data.type === 'commande') {

                            _.map(data.invoices, f => {

                                var fact = factures[f.data.id]
                                if(fact)
                                    _.map(fact.data.repartition, (rep, consultant) => {
                                     
                                        if ( _.includes(auth.role, 'admin'))
                                            analytics.push({
                                                id: i++,
                                                type: 'Facturé',
                                                status: fact.status,
                                                //phase: d.objet ? d.objet : d.ref,
                                                value: rep,
                                                mission: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length > 2 && data.data.commande.ref.split('-')[1],
                                                agence: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length>3 && data.data.commande.ref.split('-')[3],
                                                consultant: consultant,
                                                site: data.data.site.name,
                                                client: data.data.client.name,
                                                entity: data.data.client.entity,
                                                date: new Date(moment(f.data.date_facture, 'Do MMMM YYYY').endOf('month').format('YYYY-MM-DD')),
                                                date_payment: new Date(moment(f.date_payment, 'Do MMMM YYYY').endOf('month').format('YYYY-MM-DD'))


                                            })
                                        else if (user && consultant == user.access.abbreviation_consultant)
                                            analytics.push({
                                                id: i++,
                                                type: 'Facturé',
                                                status: fact.status,
                                                //phase: d.objet ? d.objet : d.ref,
                                                value: rep,
                                                mission: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length > 2 && data.data.commande.ref.split('-')[1],
                                                agence: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length > 3 && data.data.commande.ref.split('-')[3],
                                                consultant: consultant,
                                                site: data.data.site.name,
                                                client: data.data.client.name,
                                                entity: data.data.client.entity,
                                                date: new Date(moment(f.data.date_facture, 'Do MMMM YYYY').endOf('month').format('YYYY-MM-DD')),
                                                date_payment: new Date(moment(f.date_payment, 'Do MMMM YYYY').endOf('month').format('YYYY-MM-DD'))


                                            })
                                    })
                             
                            })
                            if (data.data.PHASES) {
                                var details = _.spread(_.union)([_.values(data.data.PHASES.CONCEPTION.FERME), _.values(data.data.PHASES.CONCEPTION.CONDITIONNELLE), _.values(data.data.PHASES.EXECUTION.FERME), _.values(data.data.PHASES.EXECUTION.CONDITIONNELLE)])
                                //////////console.log(details)

                                _.map(details, val => {
 
                                    if (_.includes(data.data.commande.frais, 'sont inclus')) {
                                       ////console.log(data, val)
                                       var part = Number(val.prix) / Number(data.data.commande.prix_total)
                                     ////console.log(part)
                                    val.prix = Math.round((Number(val.prix) - Number(data.data.commande.montant_frais*part))*100)/100
                                    }
                                    if (!val.calendar)
                                        val.calendar = moment().format('MMMM YYYY') + ' - ' + moment().format('MMMM YYYY')
                                    var ref = val.ref
                                    var repartition = val.repartition
                                    if (val.mensuelle) {
                                        var date = [moment(val.calendar.split(' - ')[0], 'MMMM YYYY').unix(), moment(val.calendar.split(' - ')[1], 'MMMM YYYY').unix()]
                                        var months = [];
                                        var n = moment(moment.unix(date[0])).unix();
                                        var end = moment(moment.unix(date[1])).unix();
                                        months.push(moment(moment.unix(n)).format('MM/YYYY'))

                                        while (n < end) {
                                            n = moment(moment.unix(n))
                                                .add(1, "months")
                                                .unix();
                                            months.push(moment(moment.unix(n)).format('MM/YYYY'));
                                        }

                                        //////////console.log(months)
                                        var total_month = val.prix / months.length
                                        _.map(repartition, (rep, consultant) => {
                                            _.map(months, (month) => {
                                                analytics.push({
                                                    id: i++,
                                                    type: 'Prévisionnel',
                                                    status: data.status,
                                                    //phase: val.ref,
                                                    value: total_month * rep / 100,
                                                    mission: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length > 2 && data.data.commande.ref.split('-')[1],
                                                    agence: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length > 3 && data.data.commande.ref.split('-')[3],

                                                    consultant: consultant,
                                                    site: data.data.site.name,
                                                    client: data.data.client.name,
                                                    entity: data.data.client.entity,
                                                    date: new Date(moment(month, 'MM/YYYY').endOf('month').format('YYYY-MM-DD'))


                                                })
                                            })
                                        })

                                    } else {
                                        var date = [moment(val.calendar.split(' - ')[0], 'MMMM YYYY').unix(), moment(val.calendar.split(' - ')[1], 'MMMM YYYY').unix()]
                                        var months = [];


                                        months.push(moment(val.calendar.split(' - ')[1], 'MMMM YYYY').format('MM/YYYY'))
                                        var total_month = val.prix / months.length
                                        _.map(repartition, (rep, consultant) => {


                                            _.map(months, (month) => {
                                                analytics.push({
                                                    id: i++,
                                                    type: 'Prévisionnel',
                                                    status: data.status,
                                                    //phase: val.ref,
                                                    value: total_month * rep / 100,
                                                    mission: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length > 2 && data.data.commande.ref.split('-')[1],
                                                    agence: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length > 3 && data.data.commande.ref.split('-')[3],

                                                    consultant: consultant,
                                                    site: data.data.site.name,
                                                    client: data.data.client.name,
                                                    entity: data.data.client.entity,
                                                    date: new Date(moment(month, 'MM/YYYY').endOf('month').format('YYYY-MM-DD'))


                                                })
                                            })
                                        })
                                        //////////////console.log(months)

                                    }
                                })

                            } else {


                                var val = data.data.commande

                                if (_.includes(data.data.commande.frais, 'sont inclus')) {
                                   ////console.log(data, val)
                                    var part = Number(val.prix) / Number(data.data.commande.prix_total)
                                   ////console.log(part)
                                  val.prix = Math.round((Number(val.prix) - Number(data.data.commande.montant_frais * part)) * 100) / 100
                                }
                                if (!val.calendar)
                                    val.calendar = moment().format('MMMM YYYY') + ' - ' + moment().format('MMMM YYYY')
                                var date = [moment(val.calendar.split(' - ')[0], 'MMMM YYYY').unix(), moment(val.calendar.split(' - ')[1], 'MMMM YYYY').unix()]
                                var months = [];


                                months.push(moment(val.calendar.split(' - ')[1], 'MMMM YYYY').format('MM/YYYY'))
                                var total_month = val.prix ? val.prix : val.prix_total / months.length
                                _.map(val.repartition, (rep, consultant) => {
                                    _.map(months, (month) => {
                                        analytics.push({
                                            id: i++,
                                            type: 'Prévisionnel',
                                            status: data.status,
                                            //phase: val.objet ? val.objet : val.ref,
                                            value: total_month * rep / 100,
                                            mission: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length > 2 && data.data.commande.ref.split('-')[1],
                                            agence: data.data.commande.ref.split('-').length && data.data.commande.ref.split('-').length > 3 && data.data.commande.ref.split('-')[3],

                                            consultant: consultant,
                                            site: data.data.site.name,
                                            client: data.data.client.name,
                                            entity: data.data.client.entity,
                                            date: new Date(moment(month, 'MM/YYYY').endOf('month').format('YYYY-MM-DD'))


                                        })
                                    })
                                })


                            }



                        }


                    }

                })
            }
           //console.log(analytics)
          
            return dispatch({
                type: GET_ANALYTICS,
                payload: analytics,
                routeParams
            })



        })


    }
}

export function updateObjectif(data, type) {
    ////////console.log(type)
   /*  return (dispatch, getState) => {
        firebaseService.firestore.collection('objectif').doc('objectif').set(data).then(() => {
            dispatch(getAnalytics({ id: 'all' }))
        })
    } */
}

export function getObjectifs(routeParams) {
    //console.log('getoBEJCTIFS')
    return (dispatch, getState) => {
        const auth = getState().auth.user
        const user = getState().custom.contacts.user.infos
        //console.log(auth)
        if (_.includes(auth.role, 'admin')){
        firebaseService.firestore.collection('objectifs').where('consultant', '>', '').onSnapshot(r => {
            ////////console.info('SIZE',r.size)
            var objectifs = []
            if (r.size) {
                r.forEach(doc => {
                    
                        objectifs.push({ ...doc.data(), id: doc.id })
                    
                })
            }
            Promise.all([dispatch({
                type: GET_OBJECTIFS,
                payload: objectifs,
                routeParams
            })]).then(r => {
                dispatch(Actions.hideMessage())
                return dispatch(Actions.getAnalytics({ id: 'all' }))
            })
        })
        } else if(user && user.access){
            //console.log(user)
            firebaseService.firestore.collection('objectifs').where('consultant', '==', user.access.abbreviation_consultant).onSnapshot(r => {

               
               
                ////////console.info('SIZE',r.size)
                var objectifs = []
                if (r.size) {
                    r.forEach(doc => {

                        objectifs.push({ ...doc.data(), id: doc.id })

                    })
                }
            
                Promise.all([dispatch({
                    type: GET_OBJECTIFS,
                    payload: objectifs,
                    routeParams
                })]).then(r => {
                    dispatch(Actions.hideMessage())
                    return //dispatch(Actions.getAnalytics({ id: 'all' }))
                })
            })
        }
    }
}