var locale = {
    'NO_SITES': 'Pas de sites enregistrés',
    'ADDRESS2': "Complément d'adresse ou Nom du bâtiment",
    'NEW_SITE': 'Nouveau site',
    'EDIT_SITE': 'Edition du site',
    'CLIENT': 'Client',
    'ADDRESS': 'Adresse',
    'ZIP': 'Code postal',
    'CITY': 'Ville',
    'COUNTRY': 'Pays'
}
export default locale;