import * as Actions from 'app/store/actions/custom';
import _ from '@lodash';

const initialState = {

};


const variables = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_VARIABLES:
            {
                return {
                    ...state,
                    ...action.payload

                };
            }
 



        default:
            {
                return state;
            }
    }
};

export default variables;
