import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { FuseUtils } from '@fuse'
import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
export const GET_REQUESTS = '[REQUESTS APP] GET_REQUESTS';


export function getRequest() {
    return async (dispatch, getState) => {
        var variables = getState().custom.requests.datas
   
        return dispatch({
            type: GET_REQUESTS,
            payload: variables
        })

    }

}

export function getRequests(query) {
console.log(query)
    return async (dispatch, getState) => {
        const variables = getState().custom.requests.datas
console.log(variables)
        return dispatch({
            type: GET_REQUESTS,
            payload: { ...variables }
        })

    }

}

export function getForm(query) {
    console.log(query)
    return async (dispatch, getState) => {
        const variables = getState().custom.variables
        console.log(variables)
        firebaseService.firestore.collection('forms').onSnapshot(r => {
            var v = {}
            if (r.size > 0)
                r.forEach(async doc => {
                    console.log(doc.id, doc.data().batiment)
                    v[doc.id] = doc.data()


                })
            console.log({ ...variables, ...v })

            return dispatch({
                type: GET_REQUESTS,
                payload: { ...variables, ...v }
            })


        })

    }

}


