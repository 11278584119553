

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectBox from 'devextreme-react/select-box';
import DataSource from "devextreme/data/data_source";
import ArrayStore from 'devextreme/data/array_store';
import * as ActionsAll from 'app/store/actions';
import _ from '@lodash'
import moment from "moment";
import "moment/locale/fr";
import { FuseUtils } from '@fuse';
import { TextBox } from 'devextreme-react/text-box';
import { TagBox } from 'devextreme-react/tag-box';
import DataGrid, { Lookup, RequiredRule, Column, ColumnChooser, Pager, ColumnFixing, Editing, Export, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Selection, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import deMessages from "app/custom/translation/fr.json";
import { loadMessages, locale } from 'devextreme/localization';
import { InputAdornment, Input, TextField, Button, Dialog, DialogActions, DialogContent, Checkbox, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from '@fuse/hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import SelectClients from 'app/custom/components/selects/selectClients';
import SelectContacts from 'app/custom/components/selects/selectContacts';

import SelectSites from 'app/custom/components/selects/selectSites';
import SelectVariables from 'app/custom/components/selects/selectVariable';
import firebaseService from 'app/services/firebaseService';
import CustomPopup from 'app/custom/rapports/audit/installation/customPopup'
import TreeMenu from 'app/custom/rapports/audit/installation/treeMenu.js'
import Editor from './editor';
{/* 
 <Column dataField={'batiment'} caption={'Bâtiment'} groupIndex={0} allowEditing={false} allowAdding={false} />
            <Column allowEditing='false' dataField={'batterieName'} caption={'Batterie'} groupIndex={1} allowAdding={false} />
            <Column dataField={'appareilName'} allowEditing='false' caption={'Appareil'} groupIndex={2} allowAdding={false} />
            <Column dataField={'localisation'} caption={'Localisation'} groupIndex={3}  defaultSortOrder = { 'desc'} >
    <Lookup dataSource={['Machinerie', 'Machinerie/Hydraulique', 'Toit de cabine', 'Opérateur / Porte cabine', 'Cabine', 'Gaine', 'Paliers', 'Cuvette']}></Lookup>
            </Column >
            <Column dataField={'value'} caption={'Etat des defaults'}  allowEditing={false} />
            <Column dataField={'etages'} caption={'Niveaux concernés'}  allowEditing={true} />
            <Column dataField={'check'} caption={''} dataType='boolean' allowAdding={false} allowEditing={true} />
            <Column dataField={'lever'} caption={'Réserve levée'} dataType='boolean' allowAdding={false} allowEditing={true} />
*/}

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
        height: '100%'
    },
}));
const GridElement = (form) => {

    return ''
}
const Infos = (props) => {
    //console.log(props)
    const dispatch = useDispatch();
    const wrapperRef = useRef(props.tab);
    const grid = useRef(props.tab + 'E')
    const classes = useStyles();
    const userAuth = useSelector(({ auth }) => auth.user.uid);
    const variablesList = useSelector(({ custom }) => custom.variables);
    ////////console.log(variablesList)
    const [years, setYears] = useState([])
    const [visible, setVisible] = useState(false)
    const [variables, setVariables] = useState({})
    //var variables = useSelector(({ custom }) => custom.variables[props.tab]);
    const [list, setList] = useState([])

    const [form, setForm] = useState(props.form[props.tab])
    const [appareilsFormat, setAppareilsFormat] = useState([])
    useMemo(() => {
        loadMessages(deMessages);

        locale(navigator.language);
    }, [])
    useEffect(() => {
        var listBase = _.compact(_.map(props.form[props.tab], f => {
            if (f.id)
                return { ...f}
        }))
        listBase = _.concat(listBase, _.compact(_.map(_.values(variablesList.modernisation_articles), c => {
            if (!_.find(listBase, { id: c.id }))
                return { ...c, check: false , appareils:_.map(props.form.installation.appareils,c=>c.id)}
        })))
        //////////console.log(listBase)
        setList(_.sortBy(_.sortBy(_.compact(listBase), 'decret'),'reference'))
        if (!props.form[props.tab].length)
            props.handleChange(props.tab, _.sortBy(_.sortBy(_.compact(listBase), 'decret'), 'reference'))
        else if (!_.isEqual(props.form[props.tab], form))
            setForm(props.form[props.tab])
      

    }, [props.match.params, props.form[props.tab]]);
    useEffect(() => {
        var listBase = _.compact(_.map(props.form[props.tab], f => {
            if (f.id)
                return f
        }))
        listBase = _.concat(listBase, _.compact(_.map(_.values(variablesList.modernisation_articles), c => {
            if (!_.find(listBase, { id: c.id }))
                return { ...c, check: false, appareils: _.map(props.form.installation.appareils, c => c.id)}
        })))
        //////////console.log(listBase)
        setList(_.sortBy(_.sortBy(_.compact(listBase), 'decret'), 'reference'))
        var v = {}
        _.map(variablesList, (c, f) => {
            if (_.includes(['modernisation_decret', 'modernisation_articles'],f)){
            if (f != 'modernisation_articles')
                v[f] = new DataSource({
                    store: _.map(c.values, (m, i) => {

                        return { id: i, value: m }
                    }),
                    key: 'id',
                    sort: { getter: "value", asc: true }

                });
            else {
                //////console.log(c)
                v[f] = new DataSource({
                    store: _.values(c),
                    key: 'id',
                    sort: { getter: "value", asc: true }

                });
            }
}
        })
        setVariables(v)


    }, [variablesList.modernisation_decret,variablesList.modernisation_articles])

    useOutsideAlerter(wrapperRef, grid, form);
    function useOutsideAlerter(ref, grid, form) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {

                if (ref.current && !ref.current.contains(event.target)) {
                    ////////console.log(grid.current.instance.getDataSource()._items)

                    form = grid.current.instance.getDataSource()._items
                    ////////console.log(!_.isEqual(props.form[props.tab], form));
                    // if (!_.isEqual(props.form[props.tab], form) && !visible)
                    // props.handleChange(props.tab, form)
                }
            }


            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, form]);
    }

    function getFilteredBatteries(options) {
        ////////console.log(options, props.form.batteries)
        return {
            store: props.form.batteries,
            filter: options.data ? ['batiment', '=', options.data.batiment] : null
        };
    }
    const rangeOfYears = (start, end) => Array(end - start + 1)
        .fill(start)
        .map((year, index) => year + index)

    const capital_letter = (str) => {

        str = str.split(' ');

        for (var i = 0, x = str.length; i < x; i++) {
            if (str[i][0])
                str[i] = str[i][0].toUpperCase() + str[i].substr(1);
        }

        return str.join(' ')

    }
    const selectVariable = (cellInfo, bdd) => {
        //////console.log(cellInfo)
        //////console.log(variablesList[bdd])
        function customItemCreating(e) {
            //////console.log(e)
            const nextId = Math.max.apply(Math, variables[bdd].items().map(c => c.id)) + 1;

            e.customItem = { id: nextId, value: e.text };

            variables[bdd].store().insert(e.customItem);
            cellInfo.setValue(e.text, e.text)


            variables[bdd].reload();

        }
        //////console.log(_.filter(_.values(variablesList[bdd]), { localisation: cellInfo.data.localisation }))
        return (
            <SelectBox
                dataSource={bdd != 'modernisation_articles' ? variables[bdd] : new DataSource({
                    store: _.filter(_.uniqBy(_.values(variablesList[bdd]),'value'), { localisation: cellInfo.data.localisation }),
                    key: 'id',
                    sort: { getter: "value", asc: true }
                })}
                valueExpr={bdd != 'modernisation_articles' ? "id" : 'value'}
                displayExpr="value"
                defaultValue={cellInfo.value} 
                acceptCustomValue={true}
                onSelectionChanged={(e) => {
                    //////console.log(e)
                    if (e.selectedItem.value != cellInfo.value)
                        cellInfo.setValue(e.selectedItem.value, e.selectedItem.value)
                }}
                onCustomItemCreating={customItemCreating}
            />
        );

    }

    const selectMultipleVariable = (cellInfo, bdd) => {
        //////console.log(cellInfo)
        ////////console.log(variables[bdd])
        function customItemCreating(e) {

            const nextId = Math.max.apply(Math, variables[bdd].items().map(c => c.id)) + 1;

            e.customItem = { id: nextId, value: e.text };

            variables[bdd].store().insert(e.customItem);
            cellInfo.setValue(e.text, e.text)
            variables[bdd].reload();

            if (!_.includes(variablesList[bdd].values, e.text))
                firebaseService.firestore.collection('variables').doc(bdd).set({ name: variablesList[bdd].name, values: _.concat(variablesList[bdd].values, e.text) })
        }

        return (
            <TagBox
                dataSource={variables[bdd]}
                valueExpr="value"
                displayExpr="value"
                defaultValue={cellInfo.value}
                acceptCustomValue={true}
                onValueChanged={(e) => {
                    ////////console.log(e)
                    cellInfo.setValue(e.value)
                }}
                onCustomItemCreating={customItemCreating}
            />
        );

    }

    const customTextField = (cellInfo, bdd, unit) => {

        const setEditedValue = valueChangedEventArg => {

            return cellInfo.setValue(valueChangedEventArg.value ? valueChangedEventArg.value : '');
        }
        return (


            <TextBox
                id={bdd}

                valueChangeEvent={'blur'}
                defaultValue={cellInfo.value}
                onValueChanged={setEditedValue}

                aria-describedby={bdd}
            />
        )
    }


    function setStateValue(rowData, value) {
        rowData.batiment = null;
        this.defaultSetCellValue(rowData, value);
    }

    function editorCell(cellInfo, bdd) {
        ////console.log(cellInfo)

        return <Editor
            values={cellInfo.value}
            data={cellInfo}

        />
    }
    function selectTree(cellInfo) {
        //////console.log(cellInfo)

        const setEditedValue = valueChangedEventArg => {
            //////console.log(valueChangedEventArg)
            if (cellInfo.value != valueChangedEventArg) {
                cellInfo.value = valueChangedEventArg
                cellInfo.text = valueChangedEventArg
                return cellInfo.setValue(valueChangedEventArg);
            }
        }
        //////console.log(props)

        var tree = []

        _.map(props.form.installation.batiments, c => {
            tree = _.concat(tree, { ...c, parentId: 0 })
        })
        _.map(props.form.installation.batteries, c => {
            tree = _.concat(tree, { ...c, parentId: c.batiment })

        })
        _.map(props.form.installation.appareils, c => {
            tree = _.concat(tree, { ...c, parentId: c.batterie == '-' ? c.batiment : c.batterie, selected: _.includes(cellInfo.value, c.id) ? true : false })

        })
        //////console.log(tree)
        return (

            <div>
                <TreeMenu
                    value={cellInfo.value}
                    data={cellInfo}
                    tree={tree}
                    handleChange={setEditedValue}
                />

            </div>
        )
    }
    //////console.log(appareilsFormat)
    //////console.log(variables)
    //////console.log(form)
    return (
        <div className={classes.formControl} ref={wrapperRef}>


            <DataGrid
                id={props.tab}

                height={'100%'}
                ref={grid}
                dataSource={list}
                allowColumnResizing={true}
                allowColumnReordering={false}
                columnAutoWidth={true}
                columnResizingMode={'widget'}
                rowAlternationEnabled={true}
                showBorders={true}
                columnHidingEnabled={true}
                keyExpr="id"
                wordWrapEnabled={true}
                onCellPrepared={(e) => {
                    if (e.rowType === "data" && e.column.dataField =='description') {
                        ////console.log(e)
                       e.cellElement.id=e.data.id
                    }


                }}
                 onCellClick={(e) => {
                     if (e.rowType == 'data' && e.column.dataField == "lever") {
                         //////console.log(e)
                         let list = _.keyBy(form, 'id')
 
                         list[e.key] = { ...list[e.key], lever: e.value }
                         props.handleChange(props.tab, _.values(list))
                     }
                 }} 
                onValueChanged={(e) => {
                    //////console.log(e)
                }}
                onInitNewRow={(e) => {
                    if (!grid.current.instance.getDataSource()._items.length)
                        e.data = { id: FuseUtils.generateGUID(), description: '', reference: '', check: false, appareils: _.map(props.form.installation.appareils,c=>c.id)}
                    else {
                        e.data = { ...grid.current.instance.getDataSource()._items[grid.current.instance.getDataSource()._items.length - 1], id: FuseUtils.generateGUID(), description: '', reference: '' , check:false}
                    }
                }}

                onRowInserted={
                    (e) => {
                        
                        var l = _.keyBy(list, 'id')
                        l[e.data.id] = e.data
                        var t = _.concat(form, e.data)
                    
                        props.handleChange(props.tab, _.sortBy(_.sortBy(_.values(l), 'decret'), 'reference'))

                        if (e.data.description && e.data.reference) {
                            if (!_.includes(variablesList['modernisation_decret'].values, e.data.reference)) {
                                firebaseService.firestore.collection('variables').doc('modernisation_decret').set({ name: variablesList['modernisation_decret'].name, values: _.concat(variablesList['modernisation_decret'].values, e.data.reference) }).catch(e => {
                                    //////console.log(e)
                                })
                            }
                            if (!_.includes(variablesList.modernisation_articles, { description: e.data.description, reference: e.data.reference })) {
                                var h = _.concat(_.values(variablesList.modernisation_articles), _.omit({ ...e.data, createdBy: userAuth, description: e.data.description }, 'appareils', 'prix', 'check'))
                                firebaseService.firestore.collection('variables').doc('modernisation_articles').set(_.keyBy(h, 'id')).catch(e => {
                                    //////console.log(e)
                                })
                            }
                        }

                    }
                }
                onRowUpdated={
                    (e) => {
                        //console.log(e.data)
                        var l = _.keyBy(list, 'id')
                        l[e.data.id] = e.data
                        var t = _.concat(form, e.data)
                        //console.log(l)
                        props.handleChange(props.tab, _.sortBy(_.sortBy(_.values(l),'decret'), 'reference'))

                        if (e.data.description && e.data.reference) {
                            if (!_.includes(variablesList['modernisation_decret'].values, e.data.reference)) {
                                firebaseService.firestore.collection('variables').doc('modernisation_decret').set({ name: variablesList['modernisation_decret'].name, values: _.concat(variablesList['modernisation_decret'].values, e.data.reference) }).catch(e => {
                                    //////console.log(e)
                                })
                            }
                            if (!_.includes(variablesList.modernisation_articles, { description: e.data.description, reference: e.data.reference })) {
                                var h = _.concat(_.values(variablesList.modernisation_articles), _.omit({ ...e.data, createdBy: userAuth, description: e.data.description }, 'appareils', 'prix', 'check'))
                                firebaseService.firestore.collection('variables').doc('modernisation_articles').set(_.keyBy(h, 'id')).catch(e => {
                                    //////console.log(e)
                                })
                            }
                        }

                    }}
                onRowRemoved={
                    (e) => {
                        /* var l = _.keyBy(list, 'id')
                        l[e.data.id] = e.data
                        var t = _.concat(form, e.data)
                        props.handleChange(props.tab, _.values(l)) */

                    }}
            >
                  <StateStoring enabled={true} type="localStorage" storageKey={"audit_annexeE"} /> 
                <Scrolling mode="virtual" />
                {/* 
                <ColumnFixing enabled={true} />
                <Export enabled={true} allowExportSelectedData={true} icon={'xlsxfile'} />
                <Selection mode={"multiple"} />
                <ColumnChooser enabled={true} />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} /> */}
                <SearchPanel visible={true}
                    //width={240}
                    placeholder="Recherche..." />
                <FilterRow visible={false} />
                <GroupPanel visible={true} />
                <StateStoring enabled={true} type="localStorage" storageKey={"audit_annexeE"} /> 
                {/* <Paging defaultPageSize={10} />
                <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} /> */}
                <Editing
                    mode="batch"
                    useIcons={true}
                    allowAdding={false}
                    allowUpdating={(e) => {
                       return true
                    }}
                    allowDeleting={(e => {
                        
                        return false
                    })} />

              
                <Column dataField={'decret'} caption={'Décret'} allowEditing={true} allowUpdating={false}>
                    <Lookup dataSource={['31 décembre 2010', '3 juillet 2014','3 juillet 2018']}/>
                </Column>
                <Column dataField={'reference'} caption={'Référence'} allowEditing={true} allowUpdating={false} editCellRender={(e) => selectVariable(e, 'modernisation_decret')}/>
                <Column caption={'Description'} dataField="description" allowEditing={true} allowUpdating={false} editCellRender={(e) => editorCell(e, 'modernisation_articles')} cellRender={(e) => {
        

                    return <div dangerouslySetInnerHTML={{ __html: e ? '<div>' + e.value.replace(/(?:\r\n|\r|\n)/g, '') + '</div>' : '' }} />
                }} />

                <Column dataField={'check'} caption={'Concerné'} dataType='boolean' allowEditing={true} allowUpdating={true}/>
                <Column caption={'Appareils concernés'} dataField="appareils" editCellRender={(e) => selectTree(e)} allowEditing={true} allowUpdating={true} cellRender={(e) => {

                

                    if (e && e.value ) {
                        var batiments = _.keyBy(props.form.installation.batiments, 'id')
                        var batteries = _.keyBy(props.form.installation.batteries, 'id')
                        var appareils = _.keyBy(props.form.installation.appareils, 'id')
                        return <div>{_.map(e.value, f => {
                            //////console.log(f, appareils[f])
                            if (f) {
                                let c = appareils[f]
                                if (c)
                                    return <p key={f}>{`
                            ${c.batiment && batiments[c.batiment] ? batiments[c.batiment].name + ' | ' : ''}
                            ${c.batterie && batteries[c.batterie] ? batteries[c.batterie].name + ' | ' : ''}
                        ${c.name}
                        `}</p>
                            }
                        })} </div>
                    } else return ''
                }} />
                <Column dataField={'prix'} caption={'Prix HT/appareil'} dataType='number' allowEditing={true} allowUpdating={true}/>
                <Column dataField={'prix'} caption={'Prix Total HT'} dataType='number'  calculateCellValue={(e) => {
                  ////console.log(e)
                    if (e && e.appareils && e.appareils.length && e.prix && e.check)
                        return Math.round(e.appareils.length * e.prix * 100) / 100
                }} />


            </DataGrid>


        </div>
    )

}

export default withRouter(Infos);