import React from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash'
const useStyles = makeStyles(theme => ({
    root: {
        padding        : '0 7px',
        fontSize       : 11,
        fontWeight     : 600,
        height         : 20,
        minWidth       : 20,
        borderRadius   : 20,
        display        : 'flex',
        alignItems     : 'center',
        backgroundColor: theme.palette.secondary.main,
        color          : theme.palette.secondary.contrastText
    }
}));

function FuseNavBadge(props){

    const classes = useStyles(props);
    const { className, badge } = props;
    const userRole = useSelector(({ auth }) => auth.user.role);
    /* if (badge.type && badge.type =="leads") {
        console.log(props)
        console.log(userRole)
    } */
    if (badge.type == "leads") {
       
        return <div className={clsx(classes.root, className, "item-badge")}>
            {badge.retardTotal && _.includes(userRole, 'admin') ? <span
                className={clsx(classes.root, className, "item-badge")}
                style={{
                    backgroundColor: 'red',
                    color: 'white'
                }}
            >
                {badge.retardTotal}
            </span>:''}

            {badge.retard? <span
                className={clsx(classes.root, className, "item-badge")}
                style={{
                    backgroundColor: 'red',
                    color: 'white'
                }}
            >
                {badge.retard}
            </span> : ''}
            <span
                className={clsx(classes.root, className, "item-badge")}
                style={{
                    backgroundColor: badge.bg,
                    color: badge.fg
                }}
            >
                {badge.title}
            </span>
            </div>
        
        
    }
    else return (
        <div>
       {/*  <span
            className={clsx(classes.root, className, "item-badge")}
            style={{
                backgroundColor: badge.bg,
                color          : badge.fg
            }}
        >
            {badge.title}
        </span> */}
        <span
            className={clsx(classes.root, className, "item-badge")}
            style={{
                backgroundColor: badge.bg,
                color          : badge.fg
            }}
        >
            {badge.title}
            </span>
        </div>
    )
}

FuseNavBadge.propTypes = {
    badge: PropTypes.shape(
        {
            title: PropTypes.node,
            bg   : PropTypes.string,
            fg   : PropTypes.string
        })
};
FuseNavBadge.defaultProps = {};

export default React.memo(FuseNavBadge);
