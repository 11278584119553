import React from 'react';
import { Redirect } from 'react-router-dom';
/* import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
////console.log(fr)
i18next.addResourceBundle('en', 'sitesApp', en);
i18next.addResourceBundle('fr', 'sitesApp', fr); */


export const LeadsConfig = {
    settings: {
        layout: {}
    },
    routes: [


        {
            path: '/leads/:id',
            component: React.lazy(() => import('./leadsApp'))

        },
        {
            path: '/leads',
            component: () => <Redirect to="/leads/all" />
        }
    ]
};
