import React, { useEffect, useCallback } from 'react';
import { Dialog, } from '@material-ui/core';
import { useForm } from '@fuse/hooks';
import FuseUtils from '@fuse/FuseUtils';
import * as Actions from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import RapportDialogContent from './rapportContentDialog'
const defaultFormState = {

};
function ContactDialog(props) {
    //console.log(props)
    const dispatch = useDispatch();
    const rapportDialog = useSelector(({ custom }) => custom.rapports.rapportDialog);
    const rapports = useSelector(({ custom }) => custom.rapports.entities);
    const { form, handleChange, setForm } = useForm(defaultFormState);
//console.log(rapports)
    //////console.log(rapportDialog)
    const initDialog = useCallback(
        () => {
            /**
             * Dialog type: 'edit'
             */
            if (rapportDialog.type === 'edit' && rapportDialog.data) {
                setForm({ ...rapportDialog.data });
            }

            /**
             * Dialog type: 'new'
             */
            if (rapportDialog.type === 'new') {
                setForm({
                    ...defaultFormState,
                    ...rapportDialog.data,
                    id: FuseUtils.generateGUID()
                });
            }
        },
        [rapportDialog.data, rapportDialog.type, setForm],
    );

    useEffect(() => {
        /**
         * After Dialog Open
         */
        if (rapportDialog.props.open) {
            initDialog();

        }

    }, [rapportDialog.props.open, initDialog]);

    function closeComposeDialog() {
        rapportDialog.type === 'edit' ? dispatch(Actions.closeEditRapportDialog()) : dispatch(Actions.closeNewRapportDialog());
    }

    function canBeSubmitted() {
        return (
            form.name.length > 0
        );
    }
    function handleSubmit(event) {
        event.preventDefault();

        if (rapportDialog.type === 'new') {
            // dispatch(Actions.addContact(form));
        }
        else {
            // dispatch(Actions.updateContact(form));
        }
        closeComposeDialog();
    }

    return (
        <Dialog
            classes={{
                paper: "m-24"
            }}
            {...rapportDialog.props}

            onClose={closeComposeDialog}
            fullWidth
            maxWidth="md"
        >
            <RapportDialogContent closeComposeDialog={closeComposeDialog} />
        </Dialog>
    );
}

export default ContactDialog;
