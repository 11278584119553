import React, { useState, useEffect } from 'react';
import { Avatar, Button, Icon, ListItemIcon, ListItemText, Popover, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { useSelector, useDispatch } from 'react-redux';
import * as authActions from 'app/auth/store/actions';
import * as Actions from 'app/store/actions';
import { Link } from 'react-router-dom';
import Notifications from 'app/custom/components/messaging/notifications'
import AddNew from './AddNew'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import en from './i18n/en';
import fr from './i18n/fr';
import { withRouter } from 'react-router-dom';
i18next.addResourceBundle('en', 'menuApp', en);
i18next.addResourceBundle('fr', 'menuApp', fr);
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
}));
function UserMenu(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [user, setUser] = useState({})
    const userAuth = useSelector(({ auth }) => auth.user);
    const userInfos = useSelector(({ custom }) => custom.contacts.user);
    const [time, setTime] = useState(Date.now());
    const { t } = useTranslation('menuApp');
    const [userMenu, setUserMenu] = useState(null);
    useEffect(() => {
        //console.log(props)
        setUser({ ...userAuth, data: { ...userAuth.data, ...userInfos.infos } })
    }, [userInfos, userAuth])
    const userMenuClick = event => {
        setUserMenu(event.currentTarget);
    };
    /*  useEffect(() => {
         const interval = setInterval(() => setTime(Date.now()), 1000);
         return () => {
             clearInterval(interval);
         };
     }, []);
    useEffect(() => {
         //console.log(props, timer)
        clearInterval(timer);
     }, [dispatch, props.match.params]);  */

    const userMenuClose = () => {
        setUserMenu(null);
    };


    if (!user.data)
        return ''
    return (
        <React.Fragment>
            {user && user.role && user.role[0] != 'guest' ? <AddNew /> : ''}
            <Button className="h-64" onClick={userMenuClick}>
                {user.data.photoURL ?
                    (
                        <Avatar className="" alt="user photo" src={user.data.photoURL} />
                    )
                    :
                    (
                        <Avatar className={classes.orange}>
                            {user.data.access ? user.data.access.abbreviation_consultant : ''}
                        </Avatar>
                    )
                }

                <div className="hidden md:flex flex-col mx-12 items-start">
                    <Typography component="span" className="normal-case font-600 flex">
                        {user.data.name ? user.data.lastName + ' ' + user.data.name : user.data.displayName}
                    </Typography>
                    <Typography className="text-11 capitalize" color="textSecondary">
                        {user.role.toString()}
                    </Typography>
                </div>

                <Icon className="text-16 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
            </Button>

            <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={userMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                classes={{
                    paper: "py-8"
                }}
            >
                {!user.role || user.role.length === 0 ? (
                    <React.Fragment>
                        <MenuItem component={Link} to="/login" role="button">
                            <ListItemIcon className="min-w-40">
                                <Icon>lock</Icon>
                            </ListItemIcon>
                            <ListItemText primary={t('LOGIN')} />
                        </MenuItem>
                        {  /*  <MenuItem component={Link} to="/register" role="button">
                            <ListItemIcon className="min-w-40">
                                <Icon>person_add</Icon>
                            </ListItemIcon>
                            <ListItemText primary={t('REGISTER')} />
                        </MenuItem> */}
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            {/*  <MenuItem onClick={() => dispatch(Actions.openEditContactDialog(user.data, 'contact'))} role="button">
                                <ListItemIcon className="min-w-40">
                                    <Icon>account_circle</Icon>
                                </ListItemIcon>
                                <ListItemText primary={t('PROFILE')} />
                            </MenuItem> */}
                            {/*    <MenuItem component={Link} to="/apps/mail" onClick={userMenuClose} role="button">
                                <ListItemIcon className="min-w-40">
                                    <Icon>mail</Icon>
                                </ListItemIcon>
                                <ListItemText primary={t('INBOX')} />
                            </MenuItem> */}

                            <MenuItem

                            >
                                {/* <Notifications userMenuClose={userMenuClose} t={t} /> */}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    dispatch(authActions.logoutUser());
                                    userMenuClose();
                                }}
                            >
                                <ListItemIcon className="min-w-40">
                                    <Icon>exit_to_app</Icon>
                                </ListItemIcon>
                                <ListItemText primary={t('LOGOUT')} />
                            </MenuItem>



                        </React.Fragment>
                    )}
            </Popover>
        </React.Fragment>
    );
}

export default withRouter(UserMenu);
