
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import * as ActionsAll from 'app/store/actions';
import _ from '@lodash'
import moment from "moment";
import * as Actions from 'app/store/actions';
import "moment/locale/fr";
import firebaseService from 'app/services/firebaseService'
import Divider from '@material-ui/core/Divider'
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from 'app/store/actions';
import { Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Markup } from 'interweave';
import { format } from 'number-currency-format'
const colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF']
const Heading = (value) => React.createClass({
    render() {
        return value
    }
});
const Render = (props) => {
    ////////console.log(props)
    const dispatch = useDispatch()
    const [value, setValue] = useState({})
    const [data, setData] = useState({})
    const [appareils, setAppareils] = useState([])
    const [table, setTable] = useState(null)
    const form = props.form
    const user = useSelector(({ custom }) => custom.contacts.user.infos)
    ////console.log(user)
    useEffect(() => {

        var data = {}
        var batiments = _.keyBy(form.installation.batiments, 'id')
        var batteries = _.keyBy(form.installation.batteries, 'id')

        var appareilsList = []
        data.batiments = _.orderBy(_.compact(_.map(form.installation.batiments, c => {
            return {
                ...c, batteries: _.orderBy(_.compact(_.map(form.installation.batteries, e => {
                    e.batiment_name = e.batiment && batiments[e.batiment] ? batiments[e.batiment].name : ' '

                    if (e.batiment === c.id) {

                        return {
                            ...e, appareils: _.sortBy(_.sortBy(_.sortBy(_.compact(_.map(form.installation.appareils, k => {
                                k.modernisations = _.filter(form.installation.modernisations, m => {
                                    return _.includes(m.appareils, k.id)
                                })
                                k.batiment_name = e.batiment && batiments[k.batiment] ? batiments[k.batiment].name : ' '
                                k.batterie_name = e.batterie && batteries[k.batterie] ? batteries[k.batterie].name : ' '
                                if (k.batterie === e.id)
                                    return k
                            })), 'name'), 'batterie_name'), 'batiment_name')
                        }
                    }
                })), 'batiment_name', 'name'), appareils: _.sortBy(_.sortBy(_.sortBy(_.compact(_.map(form.installation.appareils, e => {
                    e.modernisations = _.filter(form.installation.modernisations, m => {
                        return _.includes(m.appareils, e.id)
                    })
                    e.batiment_name = e.batiment && batiments[e.batiment] ? batiments[e.batiment].name : ' '
                    e.batterie_name = e.batterie && batteries[e.batterie] ? batteries[e.batterie].name : ' '

                    appareilsList = _.concat(appareilsList, e)
                    if (e.batiment === c.id && !e.batterie)
                        return e
                })), 'name'), 'batterie_name'), 'batiment_name')
            }
        })), 'name')

        setAppareils(_.sortBy(_.sortBy(_.sortBy(_.uniqBy(appareilsList, 'id'), 'name'), 'batterie_name'), 'batiment_name'))
        ////////console.log(data)


        setData(data)



    }, [])

    useEffect(() => {
        async function updateTable() {
            var modernisations = []
            var installations = []
            var descriptif = []
            var annexeB = []
            var annexeC = []
            var annexeD = []
            var annexeE = []
            var annexeF = []
            var annexeG = []
            var annexeH = []
            _.map(data.batiments, async c => {

                /* MODERNISATIONS */
                ReactDOM.render(
                    await createTableModernisations(c, appareils, props.form.installation.modernisations, 'modernisations'),
                    document.getElementById(c.id + '_modernisations')
                )
                // ////////////////////console.log(document.getElementById(c.id).outerHTML)
                modernisations.push({ data: document.getElementById(c.id + '_modernisations').outerHTML })
                /* INSTALLATION */
                ReactDOM.render(
                    await createTableModernisations(c, appareils, appareils, 'installations'),
                    document.getElementById(c.id + '_installations')
                )
                // ////////////////////console.log(document.getElementById(c.id).outerHTML)
                installations.push({ data: document.getElementById(c.id + '_installations').outerHTML })
                ReactDOM.render(
                    await createTableModernisations(props.form.installation, c.id, appareils, 'descriptif'),
                    document.getElementById(c.id + '_descriptif')
                )
                // ////////////////////console.log(document.getElementById(c.id).outerHTML)
                descriptif.push({ data: document.getElementById(c.id + '_descriptif').outerHTML })
                ////////console.log(c, appareils, props.form.annexe_C)
                ReactDOM.render(
                    await createTableAnnexeB(c, appareils, props.form.annexe_B, '_annexeB'),
                    document.getElementById(c.id + '_annexeB')
                )
                // ////////////////////console.log(document.getElementById(c.id).outerHTML)
                annexeB.push({ data: document.getElementById(c.id + '_annexeB').outerHTML })



            })

            ReactDOM.render(
                await createTableAnnexeC(data.batiments, appareils, props.form.annexe_C, '_annexeC'),
                document.getElementById('_annexeC')
            )
            // ////////////////////console.log(document.getElementById(c.id).outerHTML)
            annexeC.push({ data: document.getElementById('_annexeC').outerHTML })
            ReactDOM.render(
                await createTableAnnexeD(data.batiments, appareils, props.form.annexe_D, '_annexeD'),
                document.getElementById('_annexeD')
            )
            // ////////////////////console.log(document.getElementById(c.id).outerHTML)
            annexeD.push({ data: document.getElementById('_annexeD').outerHTML })
            ReactDOM.render(
                await createTableAnnexeE(data.batiments, appareils, props.form.annexe_E, '_annexeE'),
                document.getElementById('_annexeE')
            )
            // ////////////////////console.log(document.getElementById(c.id).outerHTML)
            annexeE.push({ data: document.getElementById('_annexeE').outerHTML })

            ReactDOM.render(
                await createTableAnnexeF(data.batiments, appareils, props.form.annexe_F, props.form, '_annexeF'),
                document.getElementById('_annexeF')
            )
            // ////////////////////console.log(document.getElementById(c.id).outerHTML)
            annexeF.push({ data: document.getElementById('_annexeF').outerHTML })

            ReactDOM.render(
                await createTableAnnexeG(data.batiments, appareils, props.form, '_annexeG'),
                document.getElementById('_annexeG')
            )

            annexeG.push({ data: document.getElementById('_annexeG').outerHTML })
            setTable({ modernisations, installations, descriptif, annexeB, annexeC, annexeD, annexeE, annexeF, annexeG })
        }
        updateTable()
    }, [data])

    const handleSubmit = () => {
        //////console.log(table)
        ////////console.log(props)

        setTimeout(async () => {

            var form = _.cloneDeep(props.form)
            form.createdBy = user.id
            if (props.match.params.id == 'new' || !form.ref) {
                form.createdBy = user.id
                form.createdTime = moment().format('YYYY-MM-DD HH:mm:ss')
                await firebaseService.database().ref('stats/rapports' + moment(form.infos.date.date).format('YYYY')).once('value').then(val => {
                    ////////console.log('val', val.val(), Number(val.val().total) + 1, `${moment(form.date_commande.eng, 'MM/DD/YYYY').format('YYYY')}${Number(val.val().total) + 1 < 10 ? '00' : Number(val.val().total) + 1 < 100 ? 0 : ''}${Number(val.val().total) + 1}`)
                    return form.ref = val.val() && val.val().total ? `${moment(form.infos.date.date).format('YYYY')}-${Number(val.val().total) + 1 < 10 ? `00` : Number(val.val().total) + 1 < 100 ? 0 : ''}${Number(val.val().total) + 1}-${'RA'}` : `${moment(form.infos.date.date).format('YYYY')}-${'001'}-${'RA'}`
                }).catch(f => {
                    ////////////////console.log(f)

                })
            } else {
                form.updatedBy = user.id
                form.updatedTime = moment().format('YYYY-MM-DD HH:mm:ss')
            }
            form.type = form.type ? form.type :'audit'
            ////console.log(user)
            ////console.log(form)

dispatch(ActionsAll.showMessage({message:'Génération du document en cours...', variant:'warning'}))
            const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)
            const request = axios({
                method: 'post',
                url: `${URL_API}/carbone/createRapport`,
                headers: {
                    'Accept-Encoding': 'UTF-8',
                    Authorization: `Bearer ${token}`
                },
                data: { formulaire: "Rapport d'Audit.docx", fileName: form.ref, data: { ...form, infos: { ...form.infos, redacteur: !form.id ? _.omit(user, 'createdBy') : form.infos.redacteur }, table: table }, site: form.infos.site.name, folder: '08-Rapports' }
                //data: { ch: 'toto' }
            }).then(async response => {
                ////////console.log(form.id, response)
                if (response.data && response.data.body && response.data.body.id) {
                    dispatch(ActionsAll.hideMessage({ message: 'Génération du document en cours...', variant: 'warning' }))
                    dispatch(ActionsAll.showMessage({ message: 'Document généré avec succès...', variant: 'success' }))
                    await dispatch(Actions.getPreview(response.data.body.id, 'editor'))
                    //////////////console.log(t)


                    firebaseService.firestore.collection('rapports').doc(response.data.body.id).set({ ...form, infos: { ...form.infos, redacteur: !form.id ? _.omit(user, 'createdBy') : form.infos.redacteur }, id: response.data.body.id, docInfos: response.data.body }).then((err) => {
                        localStorage.removeItem('audit')
                        localStorage.removeItem('auditTab')
                        props.history.push('/rapports/all')
                    }).catch(err => {
                        console.log(err)
                        dispatch(ActionsAll.showMessage({ message: "Le document a été généré dans Dropbox mais il y a un pb d'enregistrement dans la base de données...", variant: 'danger' }))
                    })
                }

            }).catch(err => {
                console.log(err)
                dispatch(ActionsAll.showMessage({ message: "Erreur lors de l'enregistrment dans Dropbox...", variant: 'danger' }))

            })

        }, 500)
    }
    function createTableAnnexeB(batiment, app1, values, table) {
        const app = _.cloneDeep(app1)
        console.log(values)
        console.log(batiment)
        console.log(app)
        var Results = {};
        _.map(app, c => {
            if (c.batiment == batiment.id) {
                if (!Results[c.batiment_name])
                    Results[c.batiment_name] = {};

                //batterie & appareils
                if (c.batterie && c.batiment) {
                    if (!Results[c.batiment_name][c.batterie_name && c.batterie != '-' ? c.batterie_name + ' | ' + c.name : c.name])
                        Results[c.batiment_name][c.batterie_name && c.batterie != '-' ? c.batterie_name + ' | ' + c.name : c.name] = {};
                    if (!Results[c.batiment_name][c.batterie_name && c.batterie != '-' ? c.batterie_name + ' | ' + c.name : c.name])
                        Results[c.batiment_name][c.batterie_name && c.batterie != '-' ? c.batterie_name + ' | ' + c.name : c.name] = {};
                    _.map(
                        values,
                        e => {

               
                                if (!Results[c.batiment_name][c.batterie_name && c.batterie != '-' ? c.batterie_name + ' | ' + c.name : c.name][e.localisation])
                                    Results[c.batiment_name][c.batterie_name && c.batterie != '-' ? c.batterie_name + ' | ' + c.name : c.name][e.localisation] = _.filter(
                                        values,
                                        f => {

                                            console.log(f)
                                            return (

                                          _.includes(f.appareils, c.id) &&
                                                f.localisation === e.localisation
                                            );
                                        }
                                    );
                         
                         
                        })
                }

                return c;
            }
        });

console.log(Results);



        return _.map(Results, (c, batiment) => {
            return (
                <table
                    key={batiment}
                    style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        font: "Arial",
                        fontSize: "10px",
                        whiteSpace: "pre-line",
                        wordWrap: "break-word"
                    }}
                >
                    <thead>
                        <tr>
                            <th
                                colSpan={1}
                                style={{
                                    border: "1px solid black",
                                    textAlign: "center",
                                    backgroundColor: "#c5d1d0",
                                    font: "Arial",
                                    whiteSpace: "pre-line",
                                    wordWrap: "break-word"
                                }}
                            >
                                <font face="Arial" size="10px">
                                    {""}
                                </font>
                            </th>
                            <th

                                style={{
                                    border: "1px solid black",
                                    textAlign: "center",
                                    backgroundColor: "#c5d1d0",
                                    font: "Arial",
                                    fontSize: "10px",
                                    whiteSpace: "pre-line",
                                    wordWrap: "break-word"
                                }}
                            >
                                <font face="Arial" size="10px">
                                    {batiment}
                                </font>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(c, (d, batterie) => {
                            var length = 1;
                            ////////console.log(d)
                            return (
                                <tr key={batterie}>
                                    <td
                                        style={{
                                            border: "1px solid black",
                                            textAlign: "center",
                                            font: "Arial",
                                            fontSize: "10px",
                                            whiteSpace: "pre-line",
                                            wordWrap: "break-word",
                                            verticalAlign: "top",
                                            width: 150
                                        }}

                                    >
                                        <b  style={{
                                                        textAlign: "left", margin: 10, font: "Arial",
                                                        fontSize: "10px"
                                                    }}>{batterie}</b>
                                    </td>
                                    <td
                                        style={{
                                            border: "1px solid black",
                                            textAlign: "center",
                                            font: "Arial",
                                            fontSize: "10px",
                                            whiteSpace: "pre-line",
                                            wordWrap: "break-word",
                                            overflowWrap: 'break-word'
                                        }}
                                    >
                                        {_.map(d, (e, localisation) => {
                                            return (
                                                <div
                                                    key={localisation}
                                                    style={{ textAlign: "left", margin: 10 ,font: "Arial",
                                                    fontSize: "10px"}}
                                                >

                                                    <b><u style={{
                                                        textAlign: "left", margin: 10, font: "Arial",
                                                        fontSize: "10px"
                                                    }}>{localisation}</u></b>


                                                    {_.map(e, m => {
                                                        if (m.lever)
                                                            return (
                                                                <div key={m.id} style={{ marginLeft: '12px' }}>
                                                                    <font face="Arial" size="10px">
                                                                        <strike><span>{length++} - {m.value} </span><span style={{ marginLeft: '12px' }}>{m.etage ? 'Niveaux concernés: ' + m.etage : ''}</span></strike>
                                                                    </font>
                                                                </div>
                                                            );
                                                        return (
                                                            <div key={m.id} style={{ marginLeft: '12px' }}>
                                                                <font face="Arial" size="10px">
                                                                    <span>{length++} - {m.value} </span><span style={{ marginLeft: '12px' }}>{m.etage ? 'Niveaux concernés: ' + m.etage : ''}</span>
                                                                </font>
                                                            </div>
                                                        );
                                                    })}

                                                    <br />
                                                </div>
                                            );
                                        })}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        });
    }
    function formatArray(arr) {
        var outStr = "";
        if (arr.length === 1) {
            outStr = arr[0];
        } else if (arr.length === 2) {
            //joins all with "and" but no commas
            //example: "bob and sam"
            outStr = arr.join(' et ');
        } else if (arr.length > 2) {
            //joins all with commas, but last one gets ", and" (oxford comma!)
            //example: "bob, joe, and sam"
            outStr = arr.slice(0, -1).join(', ') + ' et ' + arr.slice(-1);
        }
        return outStr;
    }
    function createTableAnnexeC(batiment, app1, values, table) {


        const app = _.cloneDeep(app1)
        ////////console.log(app)

        var Results = {};
        _.map(values, c => {

            if (!Results[c.localisation])
                Results[c.localisation] = {};
            if (!Results[c.localisation][c.value])
                Results[c.localisation][c.value] = _.filter(
                    values,
                    e => {
                        return (
                            e.value == c.value &&
                            e.localisation === c.localisation
                        );
                    }
                )


            return c;

        });

        ////////console.log(Results);




        return (
            <table
                style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    border: "1px solid black",
                    font: "Arial",
                    fontSize: "10px",
                    whiteSpace: "pre-line",
                    wordWrap: "break-word"
                }}
            >
                <tbody>
                    {_.map(Results, (d, localisation) => {
                        var length = 1;
                        return (
                            <tr key={localisation}>
                                <td
                                    style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        font: "Arial",
                                        fontSize: "10px",
                                        whiteSpace: "pre-line",
                                        wordWrap: "break-word",
                                        verticalAlign: "top",
                                        width: 150
                                    }}

                                >
                                    <font face="Arial" size="10px"><b>{localisation}</b></font>
                                </td>
                                <td
                                    style={{
                                        border: "1px solid black",
                                        //textAlign: "center",
                                        font: "Arial",
                                        fontSize: "10px",
                                        whiteSpace: "pre-line",
                                        wordWrap: "break-word",
                                        overflowWrap: 'break-word'
                                    }}
                                >
                                    {_.map(d, (e, description) => {

                                        return (
                                            <div
                                                key={description}
                                              
                                            >
                                                {_.map(e, (m, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <div   style={{ textAlign: "left", margin: '10px', padding: '10px' , width:'100%'}} >  <font face="Arial" size="10px">
                                                                            {length++} - {m.value}
                                                            </font>
                                                                </div>
                                                            <div style={{ textAlign: "right", margin: '10px', padding: '10px' }}> <div> <em> <font face="Arial" size="10px">
                                                                <u>Appareils concernés</u>:<ul >{m.appareils.length == props.form.installation.appareils.length ? <li>Tous</li> : _.map(m.appareils, (c, i) => {

                                                                            var ap = _.keyBy(props.form.installation.appareils, 'id')
                                                                            return <li key={c}>{ap[c].batterie != '-' ? ap[c].batiment_name + ' | ' + ap[c].batterie_name + ' | ' + ap[c].name : ap[c].batiment_name + ' | ' + ap[c].name}</li>

                                                                        })}
                                                                            </ul>
                                                                        </font>
                                                            </em> </div>
                                                            
                                                            <div >
                                                                <font face="Arial" size="10px">  Prix HT/appareil : {format(m.prix, {
                                                                    currency: '€',
                                                                    spacing: false,
                                                                    decimalSeparator: ',',
                                                                    currencyPosition: 'RIGHT',
                                                                    thousandSeparator: ' '
                                                                })}</font>
                                                          
                                                                <font face="Arial" size="10px"> -  Prix Total HT : <b>{format(m.prix * m.appareils.length, {
                                                                    currency: '€',
                                                                    spacing: false,
                                                                    decimalSeparator: ',',
                                                                    currencyPosition: 'RIGHT',
                                                                    thousandSeparator: ' '
                                                                })} </b></font>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        


                                                    );
                                                })}

                                                <br />

                                            </div>
                                        );
                                    })}

                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td></td>
                        <td style={{
                            //border: "1px solid black",
                            textAlign: "center",
                            margin: '12px',
                            font: "Arial",
                            fontSize: "10px",
                            whiteSpace: "pre-line",
                            wordWrap: "break-word",
                            overflowWrap: 'break-word'
                        }}>
                            <b> <font face="Arial" size="12px">Montant estimatif HT des travaux : {format(_.sum(_.map(values, c => {
                                return c.prix * c.appareils.length
                            })), {
                                currency: '€',
                                spacing: false,
                                decimalSeparator: ',',
                                currencyPosition: 'RIGHT',
                                thousandSeparator: ' '
                            })} </font></b>

                        </td>
                    </tr>
                </tbody>
            </table >
        )

    }

    function createTableAnnexeD(batiment, app1, values, table) {


        const app = _.cloneDeep(app1)
        ////////console.log(app)

        var Results = {};
        _.map(values, c => {


            Results[c.reglementation] = _.filter(
                values,
                e => {
                    return (

                        e.reglementation === c.reglementation && e.check
                    );
                }
            )


            return c;

        });

        ////////console.log(Results);




        return (
            <table
                style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    border: "1px solid black",
                    font: "Arial",
                    fontSize: "10px",
                    whiteSpace: "pre-line",
                    wordWrap: "break-word"
                }}
            >
                <tbody>
                    {_.map(Results, (d, reglementation) => {
                        var length = 1;
                        return (
                            <tr key={reglementation}>
                                <td
                                    style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        font: "Arial",
                                        fontSize: "10px",
                                        whiteSpace: "pre-line",
                                        wordWrap: "break-word",
                                        verticalAlign: "top",
                                        width: 150
                                    }}

                                >
                                    <font face="Arial" size="10px"><b>{reglementation}</b></font>
                                </td>
                                <td
                                    style={{
                                        border: "1px solid black",
                                        //textAlign: "center",
                                        font: "Arial",
                                        fontSize: "10px",
                                        whiteSpace: "pre-line",
                                        wordWrap: "break-word",
                                        overflowWrap: 'break-word'
                                    }}
                                >

                                    {!d.length ? <font face="Arial" size="10px">Sans objet</font> : _.map(d, (e, description) => {


                                        return (
                                            <div style={{ margin: '12px' }} key={e.reference}>
                                                <font face="Arial" size="10px">
                                                    <b><u>{e.reference}</u></b>: <Markup content={'<div>' + e.description.replace(/(?:\r\n|\r|\n)/g, '').replace(/font-size:\d+/g, "10") + '</div>'} />
                                                </font>
                                            </div>

                                        );
                                    })}

                                </td>
                            </tr>
                        );
                    })}

                </tbody>
            </table >
        )

    }

    function createTableAnnexeE(batiment, app1, values, table) {


        const app = _.cloneDeep(app1)
        ////console.log(values)

        var Results = {};
        _.map(values, c => {

            if (!Results[c.decret])
                Results[c.decret] = _.filter(
                    values,
                    e => {
                        return (

                            e.decret === c.decret
                        );
                    }
                )
        });

        ////console.log(Results);


        return _.map(Results, (d, decret) => {

            ////console.log(d)

            return (
                <table key={decret}
                    style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        border: "1px solid black",
                        font: "Arial",
                        fontSize: "10px",
                        whiteSpace: "pre-line",
                        wordWrap: "break-word"
                    }}
                >
                    <thead>
                        <tr>
                            <th
                                colSpan={2}
                                rowSpan={2}
                                style={{
                                    width: "80%",
                                    border: "1px solid black",
                                    textAlign: "center",
                                    backgroundColor: "#c5d1d0",
                                    font: "Arial",
                                    whiteSpace: "pre-line",
                                    wordWrap: "break-word"
                                }}
                            >
                                <font face="Arial" size="12px">
                                    Travaux et dispositifs à mettre en place avant le {decret}
                                </font>
                            </th>
                            <th
                                colSpan={2}
                                style={{
                                    width: "20%",
                                    border: "1px solid black",
                                    textAlign: "center",
                                    backgroundColor: "#c5d1d0",
                                    font: "Arial",
                                    whiteSpace: "pre-line",
                                    wordWrap: "break-word"
                                }}
                            >
                                <font face="Arial" size="10px">
                                    {'Concerné'}
                                </font>
                            </th>
                        </tr>
                        <tr>
                            <th
                                colSpan={1}
                                style={{
                                    border: "1px solid black",
                                    textAlign: "center",
                                    backgroundColor: "#c5d1d0",
                                    font: "Arial",
                                    whiteSpace: "pre-line",
                                    wordWrap: "break-word"
                                }}
                            >
                                <font face="Arial" size="10px">
                                    {'Oui'}
                                </font>
                            </th>
                            <th
                                colSpan={1}
                                style={{
                                    border: "1px solid black",
                                    textAlign: "center",
                                    backgroundColor: "#c5d1d0",
                                    font: "Arial",
                                    whiteSpace: "pre-line",
                                    wordWrap: "break-word"
                                }}
                            >
                                <font face="Arial" size="10px">
                                    {'Non'}
                                </font>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(d, (e, i) => {
                            var length = 1;
                            return (
                                <tr key={i}>
                                    <td
                                        style={{
                                            width: '15%',
                                            border: "1px solid black",
                                            textAlign: "center",
                                            font: "Roboto",
                                            fontSize: "16px",
                                            whiteSpace: "pre-line",
                                            wordWrap: "break-word",
                                            verticalAlign: "center",

                                        }}

                                    >
                                        <b><span style={{

                                            font: "Arial",
                                            fontSize: "16px",


                                        }}>{e.reference}</span></b>
                                    </td>
                                    <td
                                        style={{
                                            border: "1px solid black",
                                            textAlign: "left",
                                            margin: '12px',
                                            font: "Arial",
                                            padding: '12px',
                                            fontSize: "12px",
                                            whiteSpace: "pre-line",
                                            wordWrap: "break-word",
                                            overflowWrap: 'break-word'
                                        }}
                                    >
                                        <Markup content={'<div><font face="Arial" size="10px">' + e.description.replace(/(?:\r\n|\r|\n)/g, '').replace(/font-size:\d+/g, "10") + '</font></div>'} />
                                        {e.appareils.length && e.check ?
                                            <div style={{ float: 'left', marginTop: '10px' }}>

                                                <em> <font face="Arial" size="10px">
                                                    <u>Appareils concernés:</u><ul >{e.appareils.length == props.form.installation.appareils.length ? <li style={{ marginLeft: '12px' }}>Tous</li> : _.map(e.appareils, (c, i) => {

                                                        var ap = _.keyBy(props.form.installation.appareils, 'id')
                                                        return <li key={c}>{ap[c].batterie != '-' ? ap[c].batiment_name + ' | ' + ap[c].batterie_name + ' | ' + ap[c].name : ap[c].batiment_name + ' | ' + ap[c].name}</li>

                                                    })}
                                                    </ul>
                                                </font>
                                                </em>
                                            </div> :
                                            ''}
                                        {e.prix && e.appareils.length && e.check ?
                                            <div style={{ float: 'right', marginTop: '20px' }}>
                                                <span> <font face="Arial" size="12px"> Prix HT/appareil : <b>{format(e.prix, {
                                                    currency: '€',
                                                    spacing: false,
                                                    decimalSeparator: ',',
                                                    currencyPosition: 'RIGHT',
                                                    thousandSeparator: ' '
                                                })}  </b></font></span>
                                                <span><font face="Arial" size="12px">Prix Total HT : <b>{format(e.prix * e.appareils.length, {
                                                    currency: '€',
                                                    spacing: false,
                                                    decimalSeparator: ',',
                                                    currencyPosition: 'RIGHT',
                                                    thousandSeparator: ' '
                                                })} </b></font></span>
                                            </div> : ''
                                        }

                                    </td>

                                    <td
                                        style={{
                                            width: '10%',
                                            border: "1px solid black",
                                            textAlign: "center",
                                            font: "Roboto",
                                            fontSize: "12px",
                                            whiteSpace: "pre-line",
                                            wordWrap: "break-word",
                                            verticalAlign: "center",

                                        }}

                                    >
                                        <b><span style={{

                                            font: "Arial",
                                            fontSize: "16px",


                                        }}>{e.check ? '✔' : ''}</span></b>
                                    </td>
                                    <td
                                        style={{
                                            width: '10%',
                                            border: "1px solid black",
                                            
                                            textAlign: "center",
                                            font: "Roboto",
                                            fontSize: "12px",
                                            whiteSpace: "pre-line",
                                            wordWrap: "break-word",
                                            verticalAlign: "center",

                                        }}

                                    >
                                        <b><span style={{

                                            font: "Arial",
                                            fontSize: "16px",


                                        }}>{!e.check ? 'X' : ''}</span></b>
                                    </td>
                                </tr>
                            );
                        })}
                        <tr >

                            <td colSpan={6} style={{
                                border: "1px solid black",
                                textAlign: "right",
                                margin: '12px',
                                padding: '12px',
                                font: "Arial",
                                fontSize: "12px",
                                whiteSpace: "pre-line",
                                wordWrap: "break-word",
                                overflowWrap: 'break-word'
                            }}>
                                <b> <font face="Arial" size="14px">Montant estimatif HT des travaux : {format(_.sum(_.compact(_.map(_.filter(values, { decret: decret }), c => {
                                    if (c.check)
                                        return c.prix * c.appareils.length
                                }))), {
                                    currency: '€',
                                    spacing: false,
                                    decimalSeparator: ',',
                                    currencyPosition: 'RIGHT',
                                    thousandSeparator: ' '
                                })} </font></b>

                            </td>
                        </tr>
                    </tbody>
                </table >
            )

        })

    }

    function createTableAnnexeF(batiment, app1, values, allForm, table) {


        const app = _.cloneDeep(app1)
        ////////console.log(app)

        var Results = {};
        _.map(values, c => {
            if (!Results[c.delai])
                Results[c.delai] = {};
            if (!Results[c.delai][c.localisation])
                Results[c.delai][c.localisation] = {};
            if (!Results[c.delai][c.localisation][c.value])
                Results[c.delai][c.localisation][c.value] = _.filter(
                    values,
                    e => {
                        return (
                            e.delai == c.delai &&
                            e.value == c.value &&
                            e.localisation === c.localisation
                        );
                    }
                )


            return c;

        });

        ////////console.log(Results);

        var decrets = _.groupBy(allForm.annexe_E, 'decret')
        return (
            <div style={{
                textAlign: "left",
                width: "100%",
                font: "Arial",
                fontSize: "10px",
                whiteSpace: "pre-line",
                wordWrap: "break-word"
            }}>
                Compte tenu des remarques en annexe C (Décret 2008-1325 et autre) et en annexe E (risques à éliminer sur la loi du 2 juillet 2003) et de la nécessité de garantir un degré de performance et de fiabilité, nous vous suggérons de réaliser les travaux suivants :
                <ul>
                    {allForm.annexe_C.length ?
                        <li><font face="Arial" size="10px">Travaux suivant décret 2008-1325 et autres</font></li>
                        : ''}
                    {_.map(_.keys(decrets), (d, i) => {
                        if (_.filter(decrets[d], { check: true }).length)
                            return <li key={i}><font face="Arial" size="10px">Travaux et dispositifs à mettre en place avant le {d}</font></li>
                    })}
                </ul>

                {_.map(Results, (o, delai) => {
                    return <table key={delai}
                        style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            border: "1px solid black",
                            font: "Arial",
                            fontSize: "10px",
                            whiteSpace: "pre-line",
                            wordWrap: "break-word"
                        }}
                    >
                        <thead>
                            <tr>
                                <th
                                    colSpan={2}
                                    style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        backgroundColor: "#c5d1d0",
                                        font: "Arial",
                                        fontSize: "12px",
                                        whiteSpace: "pre-line",
                                        wordWrap: "break-word",
                                        verticalAlign: "center",
                                        margin: '12px',
                                        padding: '12px',

                                    }}
                                >
                                    <font face="Arial" size="12px">Préconisation à {delai}</font>

                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            {_.map(o, (d, localisation) => {
                                var length = 1;
                                var option = 1
                                var variante = 1

                                return (
                                    <tr key={localisation}>
                                        <td
                                            style={{
                                                border: "1px solid black",
                                                textAlign: "center",
                                                font: "Arial",
                                                fontSize: "10px",
                                                whiteSpace: "pre-line",
                                                wordWrap: "break-word",
                                                verticalAlign: "top",
                                                width: 150
                                            }}

                                        >
                                            <font face="Arial" size="10px"><b>{localisation}</b></font>
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid black",
                                                //textAlign: "center",
                                                font: "Arial",
                                                fontSize: "10px",
                                                whiteSpace: "pre-line",
                                                wordWrap: "break-word",
                                                overflowWrap: 'break-word'
                                            }}
                                        >
                                            {_.map(d, (e, description) => {

                                                return (
                                                    <div
                                                        key={description}
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        {_.map(e, (m, i) => {
                                                            if (!m.variante && !m.option)
                                                                return (
                                                                    <div key={i}>
                                                                        <div key={i}>
                                                            <div   style={{ textAlign: "left", margin: '10px', padding: '10px' , width:'100%'}} >  <font face="Arial" size="10px">
                                                                            {length++} - {m.value}
                                                            </font>
                                                                </div>
                                                            <div style={{ textAlign: "right", margin: '10px', padding: '10px' }}> <div> <em> <font face="Arial" size="10px">
                                                                <u>Appareils concernés</u>:<ul >{m.appareils.length == props.form.installation.appareils.length ? <li>Tous</li> : _.map(m.appareils, (c, i) => {

                                                                            var ap = _.keyBy(props.form.installation.appareils, 'id')
                                                                            return <li key={c}>{ap[c].batterie != '-' ? ap[c].batiment_name + ' | ' + ap[c].batterie_name + ' | ' + ap[c].name : ap[c].batiment_name + ' | ' + ap[c].name}</li>

                                                                        })}
                                                                            </ul>
                                                                        </font>
                                                            </em> </div>
                                                            
                                                            <div >
                                                                <font face="Arial" size="10px">  Prix HT/appareil : {format(m.prix, {
                                                                    currency: '€',
                                                                    spacing: false,
                                                                    decimalSeparator: ',',
                                                                    currencyPosition: 'RIGHT',
                                                                    thousandSeparator: ' '
                                                                })}</font>
                                                          
                                                                <font face="Arial" size="10px"> -  Prix Total HT : <b>{format(m.prix * m.appareils.length, {
                                                                    currency: '€',
                                                                    spacing: false,
                                                                    decimalSeparator: ',',
                                                                    currencyPosition: 'RIGHT',
                                                                    thousandSeparator: ' '
                                                                })} </b></font>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        
                                                                          
                                                                        </div>
                                                                
                                                                );
                                                        })}
                                                        {_.map(e, (m, i) => {
                                                            if (m.variante)
                                                            return (
                                                                <div key={i}>
                                                                    <div key={i}>
                                                        <div   style={{ textAlign: "left", margin: '10px', padding: '10px' , width:'100%'}} >  <font face="Arial" size="10px">
                                                        <b><font face="Arial" size="10px">Variante n°{variante++}</font></b> - {m.value}
                                                                                 
                                                        </font>
                                                            </div>
                                                        <div style={{ textAlign: "right", margin: '10px', padding: '10px' }}> <div> <em> <font face="Arial" size="10px">
                                                            <u>Appareils concernés</u>:<ul >{m.appareils.length == props.form.installation.appareils.length ? <li>Tous</li> : _.map(m.appareils, (c, i) => {

                                                                        var ap = _.keyBy(props.form.installation.appareils, 'id')
                                                                        return <li key={c}>{ap[c].batterie != '-' ? ap[c].batiment_name + ' | ' + ap[c].batterie_name + ' | ' + ap[c].name : ap[c].batiment_name + ' | ' + ap[c].name}</li>

                                                                    })}
                                                                        </ul>
                                                                    </font>
                                                        </em> </div>
                                                        
                                                        <div >
                                                            <font face="Arial" size="10px">  Prix HT/appareil : {format(m.prix, {
                                                                currency: '€',
                                                                spacing: false,
                                                                decimalSeparator: ',',
                                                                currencyPosition: 'RIGHT',
                                                                thousandSeparator: ' '
                                                            })}</font>
                                                      
                                                            <font face="Arial" size="10px"> -  Prix Total HT : <b>{format(m.prix * m.appareils.length, {
                                                                currency: '€',
                                                                spacing: false,
                                                                decimalSeparator: ',',
                                                                currencyPosition: 'RIGHT',
                                                                thousandSeparator: ' '
                                                            })} </b></font>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    
                                                                      
                                                                    </div>
                                                            
                                                            );
                                                               
                                                        })}

                                                        {_.map(e, (m, i) => {
                                                            if (m.option)
                                                            return (
                                                                <div key={i}>
                                                                    <div key={i}>
                                                        <div   style={{ textAlign: "left", margin: '10px', padding: '10px' , width:'100%'}} >  <font face="Arial" size="10px">
                                                        <b><font face="Arial" size="10px">Option n°{option++}</font></b> - {m.value}
                                                                                 
                                                        </font>
                                                            </div>
                                                        <div style={{ textAlign: "right", margin: '10px', padding: '10px' }}> <div> <em> <font face="Arial" size="10px">
                                                            <u>Appareils concernés</u>:<ul >{m.appareils.length == props.form.installation.appareils.length ? <li>Tous</li> : _.map(m.appareils, (c, i) => {

                                                                        var ap = _.keyBy(props.form.installation.appareils, 'id')
                                                                        return <li key={c}>{ap[c].batterie != '-' ? ap[c].batiment_name + ' | ' + ap[c].batterie_name + ' | ' + ap[c].name : ap[c].batiment_name + ' | ' + ap[c].name}</li>

                                                                    })}
                                                                        </ul>
                                                                    </font>
                                                        </em> </div>
                                                        
                                                        <div >
                                                            <font face="Arial" size="10px">  Prix HT/appareil : {format(m.prix, {
                                                                currency: '€',
                                                                spacing: false,
                                                                decimalSeparator: ',',
                                                                currencyPosition: 'RIGHT',
                                                                thousandSeparator: ' '
                                                            })}</font>
                                                      
                                                            <font face="Arial" size="10px"> -  Prix Total HT : <b>{format(m.prix * m.appareils.length, {
                                                                currency: '€',
                                                                spacing: false,
                                                                decimalSeparator: ',',
                                                                currencyPosition: 'RIGHT',
                                                                thousandSeparator: ' '
                                                            })} </b></font>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    
                                                                      
                                                                    </div>
                                                            
                                                            );
                                                        })}

                                                        <br />

                                                    </div>
                                                );
                                            })}



                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td></td>
                                <td style={{
                                    //border: "1px solid black",
                                    textAlign: "center",
                                    margin: '12px',
                                    font: "Arial",
                                    fontSize: "10px",
                                    whiteSpace: "pre-line",
                                    wordWrap: "break-word",
                                    overflowWrap: 'break-word'
                                }}>
                                    <b> <font face="Arial" size="12px">Montant estimatif HT des travaux : {format(_.sum(_.map(values, c => {
                                        ////console.log(c)
                                        if (c.delai == delai && !c.variante && !c.option)
                                            return !_.isNaN(c.prix * c.appareils.length) ? c.prix * c.appareils.length : 0
                                    })), {
                                        currency: '€',
                                        spacing: false,
                                        decimalSeparator: ',',
                                        currencyPosition: 'RIGHT',
                                        thousandSeparator: ' '
                                    })} </font></b>

                                </td>
                            </tr>
                        </tbody>

                    </table >
                })}
                <div style={{
                    width: '100%',
                    border: "1px solid black",
                    textAlign: "center",
                    marginTop: '12px',
                    padding: '12px',
                    font: "Arial",
                    fontSize: "10px",
                    whiteSpace: "pre-line",
                    wordWrap: "break-word",
                    overflowWrap: 'break-word'
                }}>
                    <b> <font face="Arial" size="12px">Montant Estimatif Total HT des travaux : {format(_.sum(_.map(values, c => {

                        if (!c.variante && !c.option)
                            return !_.isNaN(c.prix * c.appareils.length) ? c.prix * c.appareils.length : 0
                    })), {
                        currency: '€',
                        spacing: false,
                        decimalSeparator: ',',
                        currencyPosition: 'RIGHT',
                        thousandSeparator: ' '
                    })} </font></b>
                </div>
            </div>
        )

    }

    function createTableAnnexeG(batiment, app1, values, table) {



//console.log(values)

        const app = _.cloneDeep(app1)
        ////////console.log(app)

        var Results = {};
        Results['Décret 2008-1325 et autres'] = _.sum(_.compact(_.map(values.annexe_C, c => {
            return !_.isNaN(c.prix * c.appareils.length) ? c.prix * c.appareils.length : 0
        }))) 
        var decrets = _.groupBy(values.annexe_E, 'decret')
        _.map(_.keys(decrets), (d, i) => {
            if (_.filter(decrets[d], { check: true }).length)
                Results[`Travaux et dispositifs à mettre en place avant le ${d}`] = _.sum(_.compact(_.map(values.annexe_E, c => {
                    if(c.check == true)
                    return !_.isNaN(c.prix * c.appareils.length) ? c.prix * c.appareils.length : 0
                })))
        })
        Results['Modernisations'] = _.sum(_.compact(_.map(values.annexe_F, (r, o) => {
            if (!r.variante && !r.option)
                return !_.isNaN(r.prix * r.appareils.length) ? r.prix * r.appareils.length : 0
        })))
        var variante = 1
        var option= 1
        Results['Variantes'] = _.compact(_.map(values.annexe_F, (r, o) => {
            if (r.variante && r.prix && r.appareils.length)
                return {
                    name: `Variante N°${variante++} - ${r.value}`, prix: r.prix && r.appareils.length ? r.prix * r.appareils.length : 0
                }
        }))
        Results['Options'] = _.compact(_.map(values.annexe_F, (r, o) => {
            if (r.option && r.prix && r.appareils.length)
                return {
                    name: `Option N°${option++} - ${r.value}`, prix: r.prix && r.appareils.length ? r.prix * r.appareils.length : 0
                }
        }))
        return (<table
            style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid black",
                font: "Arial",
                fontSize: "10px",
                whiteSpace: "pre-line",
                wordWrap: "break-word"
            }}
        >
            <thead>
                <tr>
                    <th

                        style={{
                            width: '80%',
                            border: "1px solid black",
                            textAlign: "center",
                            backgroundColor: "#c5d1d0",
                            font: "Arial",
                            fontSize: "12px",
                            whiteSpace: "pre-line",
                            wordWrap: "break-word",
                            verticalAlign: "center",
                            margin: '12px',

                            padding: '12px',

                        }}
                    >
                        <font face="Arial" size="12px">DESIGNATIONS DES ESTIMATIONS DE TRAVAUX</font>

                    </th>
                    <th

                        style={{
                            width: '20%',
                            border: "1px solid black",
                            textAlign: "center",
                            backgroundColor: "#c5d1d0",
                            font: "Arial",
                            fontSize: "12px",
                            whiteSpace: "pre-line",
                            wordWrap: "break-word",
                            verticalAlign: "center",
                            margin: '12px',

                            padding: '12px',

                        }}
                    >
                        <font face="Arial" size="12px">PRIX TOTAL HT</font>

                    </th>
                </tr>

            </thead>
            <tbody>
                {_.map(Results, (d, l) => {
                    
                    return <tr key={l}>
                        <td style={{

                            textAlign: "left",
                            border: "1px solid black",
                            font: "Arial",
                            fontSize: "12px",
                            whiteSpace: "pre-line",
                            wordWrap: "break-word",
                            verticalAlign: "center",
                            margin: '12px',
                            marginLeft : '24px',
                            padding: '12px',

                        }}>
                            {!_.includes(['Variantes', 'Options'], l)
                                ? <font face="Arial" size="12px">{l}</font> :   <em><div>
                                    {_.map(d, (k, h) => {
                                        if (k.name)
                                            return <div key={h}><font face="Arial" size="12px">{k.name}</font><br /></div>
                                    })}
                                </div></em>}
                        </td>
                        <td style={{
                         
                            textAlign: "center",
                            border: "1px solid black",
                            font: "Arial",
                            fontSize: "12px",
                            whiteSpace: "pre-line",
                            wordWrap: "break-word",
                            verticalAlign: "center",
                            margin: '12px',
                            padding: '12px',

                        }}>
                          {!_.includes(['Variantes', 'Options'], l)
                                ? <font face="Arial" size="12px">{format(d, {
                                    currency: '€',
                                    spacing: false,
                                    decimalSeparator: ',',
                                    currencyPosition: 'RIGHT',
                                    thousandSeparator: ' '
                                })}</font> : <em><div>
                                        {_.map(d, (k, h) => {
                                            if (k.name && k.prix)
                                                return <div key={h}><font face="Arial" size="12px">{format(k.prix, {
                                                    currency: '€',
                                                    spacing: false,
                                                    decimalSeparator: ',',
                                                    currencyPosition: 'RIGHT',
                                                    thousandSeparator: ' '
                                                })}</font><br/></div>
                                            else return <div key={h}><font face="Arial" size="12px"></font></div>
                                        })}
                                     
                                </div></em>}
                           
                        </td>

                    </tr>
                })}

            </tbody>
        </table>
        )

    }
    function createTableModernisations(batiment, app1, values, table) {
        const app = _.cloneDeep(app1)
        ////////console.log(batiment, app, values, table)
        var appareils = []
        var batteries = []
        var length = 0
        if (table == 'descriptif') {
            var input = batiment
            var field = app
            var batiment = _.find(input.batiments, c => c.id == app1)
            var batterie = _.map(_.filter(input.batteries, c => c.batiment == field), c => {
                ////////console.log(c.name)
                return {
                    name: c.name, appareils: _.filter(input.appareils, j => {
                        return j.batiment == field && c.id == j.batterie
                    }).length
                }
            })
            var autres_appareils = _.filter(input.appareils, c => c.batiment == field).length - _.sum(_.map(batterie, c => c.appareils))
            ////////console.log('====+>', batterie, autres_appareils)
            var text = ''
            text = <div style={{ fontFamily: 'Arial', fontSize: '13px' }}>
                <span><b><u>{_.find(input.batiments, { id: field }).name}</u></b> </span> <span> composé de: </span> <ul>
                    {
                        batterie.length && batterie.length == 1 ? <li> {batterie.length} batterie :</li> : batterie.length && batterie.length > 1 ? <li>{batterie.length} batteries: </li> : ''
                    }
                    {batterie.length ? <ul>
                        {_.map(batterie, (m, i) => {
                            return <li key={i}>{m.name} ({m.appareils} appareils)</li>
                        })}
                    </ul> : ''}
                    {autres_appareils ? <li>{autres_appareils} appareils indépendants</li> : ''
                    }
                </ul>
            </div>
            return text
        }
        _.map(app, b => {
            //////////////console.log(b.name, b.batterie)
            if (b.batiment === batiment.id) {
                //////////////////console.log(b.batterie, _.find(values.batteries, { id: b.batterie, batiment: b.batiment }))
                if (b.batterie) {
                    if (!batteries[b.batterie])
                        batteries[b.batterie] = { ..._.find(values.batteries, { id: b.batterie, batiment: b.batiment }), appareils: [b], name: b.batterie_name }
                    else batteries[b.batterie] = { ..._.find(values.batteries, { id: b.batterie, batiment: b.batiment }), appareils: _.concat(batteries[b.batterie].appareils, b), name: b.batterie_name }
                    length++
                } else if (!b.batterie) {

                    if (!batteries['-'])
                        batteries['-'] = { id: '-', name: '', appareils: [{ ...b }] }
                    else batteries['-'] = { id: '-', name: '', appareils: _.concat(batteries['-'], { ...b }) }
                    length++
                }
                appareils = _.concat(appareils, b)
            }
        })
        batteries = _.sortBy(_.sortBy(_.values(batteries), 'name'), 'batiment_name')
        //appareils = _.sortBy(_.sortBy(_.sortBy(_.values(appareils), 'name'), 'batterie_name'),'batiment_name')
        ////////console.log(data)

        ////////console.log(batteries)
        ////////console.log(appareils)
        var obj = ''
        if (table == 'modernisations') {
            values = _.map(values, c => {
                return {
                    ...c, appareils: _.sortBy(_.sortBy(_.sortBy(_.compact(_.map(appareils, a => {
                        if (a.batiment == batiment.id) {

                            if (_.includes(c.appareils, a.id))
                                return { ...a, isConcern: true }
                            else return { ...a, isConcern: false }
                        }
                    })), 'name'), 'batterie_name'), 'batiment_name')
                }
            })


            ////////console.log(values)

            obj = <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid black', font: 'Arial', fontSize: '10px', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                <thead>
                    <tr>
                        <th colSpan={1} style={{ border: '1px solid black', width: '200px', textAlign: 'center', backgroundColor: '#c5d1d0', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}><font face="Arial" size="10px">{'Bâtiment'}</font></th>
                        <th colSpan={length} style={{ border: '1px solid black', textAlign: 'center', backgroundColor: '#c5d1d0', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}><font face="Arial" size="10px">{batiment.name ? batiment.name : ''}</font></th>
                    </tr>
                    <tr>
                        <th style={{ border: '1px solid black', textAlign: 'center', width: '150px', backgroundColor: '#c5d1d0', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>  <font face="Arial" size="10px">{'Batterie'}</font></th>
                        {
                            _.map(batteries, (rowData, i) => {
                                ////////console.log(rowData)
                                return (<th key={i} colSpan={rowData.appareils.length} style={{ backgroundColor: rowData.name !== ' ' ? colorArray[i] : '#FFFFFF', opacity: 0.7, border: '1px solid black', textAlign: 'center', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>  <font face="Arial" size="10px">{rowData.name ? rowData.name : '.'}</font></th>);
                            })
                        }
                    </tr>
                    <tr>
                        <th style={{ border: '1px solid black', textAlign: 'center', width: '150px', backgroundColor: '#c5d1d0', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>  <font face="Arial" size="10px">{'Réf. Appareil'}</font></th>
                        {
                            _.map(batteries, (rowData, i) => {
                                return _.map(_.values(rowData.appareils), (c, j) => {
                                    return (<th key={j} style={{ border: '1px solid black', textAlign: 'center', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>  <font face="Arial" size="10px">{c.name}</font></th>);
                                })
                            })
                        }
                    </tr>

                </thead>
                <tbody>
                    {_.map(values, (rowData, i) => {


                        return (<tr key={i}>
                            <td style={{ border: '1px solid black', textAlign: 'left', width: '100px', whiteSpace: 'pre-line', wordWrap: 'break-word', paddingLeft: 10 }}><font face="Arial" size="10px"> {rowData.year + ' ' + rowData.value}</font></td>
                            {
                                _.map(rowData.appareils, (cellData, j) => {
                                    var d = 1;
                                    return (<td key={j} colSpan={d} style={{ backgroundColor: cellData.isConcern ? '#4d80cc' : '#FFFFFF', opacity: 0.3, border: '1px dashed black', textAlign: 'center', font: 'Arial', fontSize: '10px', whiteSpace: 'pre-line', wordWrap: 'break-word' }}><font face="Arial" size="10px">{/* cellData.isConcern?'X':'' */}</font></td>);

                                })
                            }
                        </tr>);
                    })}
                </tbody>
            </table>
        }
        if (table == 'installations') {
            var data = {}
            _.map(['marque', 'type', 'year', 'situation_machinerie', 'controleur', 'machine', 'charge', 'nbre_niveaux', 'niveaux_desservis', 'vitesse', 'porte_cabine_type', 'porte_paliere_type', 'type_manoeuvre', 'local_poulies', 'suspension', 'entrainement', 'reglementation'], (i) => {


                var name = i
                switch (i) {

                    case 'marque':
                        name = "Marque";
                        break;
                    case 'type':
                        name = "Type d'appareil";
                        break;
                    case 'situation_machinerie':
                        name = "Situation machinerie";
                        break;
                    case 'controleur':
                        name = "Contrôleur / Fabricant";
                        break;
                    case 'machine':
                        name = "Machine / Fabricant";
                        break;
                    case 'charge':
                        name = 'Charge';
                        break;
                    case 'nbre_niveaux':
                        name = 'Nombre de niveaux';
                        break;
                    case 'niveaux_desservis':
                        name = 'Niveaux desservis';
                        break;
                    case 'vitesse':
                        name = 'Vitesse';
                        break;
                    case 'porte_cabine_type':
                        name = 'Porte cabine';
                        break;
                    case 'porte_paliere_type':
                        name = 'Porte palière';
                        break;
                    case 'type_manoeuvre':
                        name = 'Type manoeuvre';
                        break;
                    case 'local_poulies':
                        name = 'Local poulies';
                        break;
                    case 'suspension':
                        name = 'Suspension';
                        break;
                    case 'entrainement':
                        name = 'Entraînement';
                        break;
                    case 'reglementation':
                        name = 'Règlementation';
                        break;
                    case 'year':
                        name = 'Année de construction';
                        break;
                    default:
                        name = i;
                        break;
                }
                data[i] = { name: name }

            })
            _.map(appareils, (c) => {


                var batt = {}
                var nbre = ''
                if (c.batterie) {
                    batt = _.find(batteries, { 'name': c.batterie_name })

                }

                _.map(c, (val, j) => {
                    if (_.includes(['marque', 'type', 'year', 'situation_machinerie', 'controleur', 'machine', 'charge', 'nbre_niveaux', 'niveaux_desservis', 'vitesse', 'porte_cabine_type', 'porte_paliere_type', 'type_manoeuvre', 'local_poulies', 'suspension', 'entrainement', 'reglementation'], j)) {
                        if (!data[j])
                            data[j] = {}


                        switch (j) {
                            case 'charge':
                                val = val + ' kg';
                                break;
                            case 'vitesse':
                                val = val + ' m/s';
                                break;
                            case 'situation_machinerie':
                                val = ` ${batt && batt.appareils && val == 'Commune' ? 'Commune aux ' + batt.appareils.length + ' appareils' : val}`
                                break;
                            case 'porte_cabine_type':
                                val = `${val} | ${c.porte_cabine_nbre_vantaux} vantaux | Ouverture: ${c.porte_cabine_ouverture} | Passage libre: ${c.porte_cabine_passage_libre} mm`
                                break;
                            case 'porte_paliere_type':
                                val = `${val} | ${c.porte_paliere_nbre_vantaux} vantaux | Ouverture: ${c.porte_paliere_ouverture} `
                                break;
                            case 'manoeuvre_type':
                                val = `${c.manoeuvre_type} ${c.manoeuvre_destination}`;
                                break;
                            case 'local_poulies':
                                val = `${val ? '' : 'Sans Objet'} ${c.local_poulies_comment ? c.local_poulies_comment : ''}`
                                break;
                            default:
                                val = val
                                break;
                        }


                        data[j][c.name] = _.isArray(val) ? val.join(' | ') : val
                        //data.reglementation[c.name] = `${c.reglementation_IGH ? 'IGH' : ''} ${c.reglementation_ERP ? 'ERP' : ''} ${c.reglementation_habitation ? 'Habitation' : ''}`

                    }
                })


            })
            //////////////console.log(data)

            obj = <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid black', font: 'Arial', fontSize: '10px', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                <thead>
                    <tr>

                        <th colSpan={1} style={{ border: '1px solid black', textAlign: 'center', backgroundColor: '#c5d1d0', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}><font face="Arial" size="10px">{'Bâtiment'}</font></th>
                        <th colSpan={length} style={{ border: '1px solid black', textAlign: 'center', backgroundColor: '#c5d1d0', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}><font face="Arial" size="10px">{batiment.name}</font></th>


                    </tr>
                    {batteries.length > 0 && <tr>
                        <th style={{ border: '1px solid black', textAlign: 'center', width: '150px', backgroundColor: '#c5d1d0', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>  <font face="Arial" size="10px">{'Batterie'}</font></th>
                        {
                            _.map(batteries, (rowData, i) => {

                                return (<th key={i} colSpan={rowData.appareils.length} style={{ backgroundColor: rowData.name !== ' ' ? colorArray[i] : '#FFFFFF', opacity: 0.7, border: '1px solid black', textAlign: 'center', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>  <font face="Arial" size="10px">{rowData.name}</font></th>);

                            })
                        }
                    </tr>}
                    <tr>
                        <th style={{ border: '1px solid black', textAlign: 'center', width: '150px', backgroundColor: '#c5d1d0', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>  <font face="Arial" size="10px">{'Réf. Appareil'}</font></th>
                        {
                            _.map(_.sortBy(appareils, 'batterie'), (rowData, i) => {



                                return (<th key={i} style={{ border: '1px solid black', textAlign: 'center', backgroundColor: '#c5d1d0', font: 'Arial', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>  <font face="Arial" size="10px">{rowData.name}</font></th>);


                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(_.values(data), (rowData, i) => {


                            return (<tr key={i}>
                                {

                                    _.map(_.values(rowData), (cellData, j) => {

                                        var d = 1;

                                        for (var l = j + 1; l < _.values(rowData).length; l++) {

                                            if (cellData == _.values(rowData)[l]) {
                                                d++
                                            } else break;
                                        }
                                        if (cellData !== 'name') {
                                            if (j > 0 && _.values(rowData)[j - 1] !== cellData)
                                                return (<td key={j} colSpan={d} style={{ border: '1px solid black', textAlign: 'center', font: 'Arial', fontSize: '10px', whiteSpace: 'pre-line', wordWrap: 'break-word' }}><font face="Arial" size="10px">{_.isArray(cellData) ? cellData.join(' ') : cellData}</font></td>);
                                            else if (j == 0)
                                                return (<td key={j} style={{ border: '1px solid black', textAlign: 'left', width: '100px', whiteSpace: 'pre-line', wordWrap: 'break-word' }}><font face="Arial" size="10px"> {cellData}</font></td>);
                                        }
                                    })
                                }
                            </tr>);
                        })
                    }
                </tbody>
            </table>

        }
        ////////console.log(obj)
        return obj
    }


    return (
        <React.Fragment>
            <Button variant="contained" color="default" size="large" className=" w-224 "
                variant="contained"
                color="secondary"
                type="submit"
                onClick={handleSubmit}
            //disabled={!canBeSubmitted()}
            >
                {'ENREGISTRER'}

            </Button>
            {   _.map(props.form.installation.batiments, c => {

                return <div id={c.id + '_descriptif'} key={c.id + '_descriptif'} >{c.name}</div>

            })}
            <Typography>Liste des modernisations</Typography>
            {   _.map(props.form.installation.batiments, c => {

                return <div id={c.id + '_modernisations'} key={c.id + '_modernisations'} >{c.name}</div>

            })}

            <br />
            <Typography>Annexe A: Détails Techniques de l'installation</Typography>
            {   _.map(props.form.installation.batiments, c => {

                return <div id={c.id + '_installations'} key={c.id + '_installations'} >{c.name}</div>

            })}

            <br />
            <Typography>Annexe B: ÉTAT DES DÉFAUTS D’ENTRETIEN </Typography>
            {   _.map(props.form.installation.batiments, c => {

                return <div id={c.id + '_annexeB'} key={c.id + '_annexeB'} >{c.name}</div>

            })}
            <br />
            <Typography>ANNEXE C : AMÉLIORATIONS POUR RÉPONDRE AUX EXIGENCES DE SÉCURITÉ Décret 2008 – 1325 du 15 décembre 2008. Arrêtés Divers </Typography>
            <div id={'_annexeC'} key={'_annexeC'} />
            <br />
            <Typography>ANNEXE D : AMÉLIORATIONS POUR RÉPONDRE AUX EXIGENCES DE SÉCURITÉ BATIMENTS IGH/ERP et ARTICLES AS</Typography>

            <div id={'_annexeD'} key={'_annexeD'} />

            <br />
            <Typography>ANNEXE E : AMÉLIORATIONS POUR RÉPONDRE A LA LOI URBANISME ET HABITAT DU 2 JUILLET 2003</Typography>

            <div id={'_annexeE'} key={'_annexeE'} />

            <br />
            <Typography>ANNEXE F : PÉRENNITÉS ET TRAVAUX DE SÉCURITÉ</Typography>

            <div id={'_annexeF'} key={'_annexeF'} />

            <br />
            <Typography>ANNEXE G : RÉCAPITULATIF FINANCIER DES TRAVAUX</Typography>
            <div id={'_annexeG'} key={'_annexeG'} />
            <br />
            <div id={'AnnexesH'} key={'AnnexesH'} />


            {/*  </div> */}
        </React.Fragment>
    )

}

export default withRouter(Render)