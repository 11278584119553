import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: {},



};

const timelineReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_TIMELINE:
            {
                return {
                    ...state,
                    entities: action.payload,

                };
            }


        default:
            {
                return state;
            }
    }
};

export default timelineReducer;