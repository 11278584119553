import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Actions from 'app/store/actions';
import { AppBar, Toolbar, Typography, FormControl, FormHelperText, FormLabel, RadioGroup } from '@material-ui/core'
import { bindActionCreators } from 'redux';
import RapportAudit from './editor/audit'
import Tabs from './audit/rapportAuditTabs'
function RapportContentDialog(props) {
   ////console.log(props)
    return (
        <React.Fragment>
           
            <Tabs {...props} />

        </React.Fragment>
    );

}


export default RapportContentDialog
