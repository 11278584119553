

import React from 'react';
import { Redirect } from 'react-router-dom';



export const TimelineConfig = {
    settings: {
        layout: {}
    },
    routes: [

        {
            path: '/timeline/:id',
            component: React.lazy(() => import('./timelineApp'))

        },
        {
            path: '/timeline',
            component: () => <Redirect to="/timeline/all" />
        }
    ]
};
