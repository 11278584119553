const locale = {
    SEARCH: 'Recherche...',
    STATUT: 'Statut',
    MONTANT: 'Montant HT',
    ECHEANCE: "Date d'échéance",
    DATE: 'Date',
    ADD: 'Enregistrer',
    NUMERO_FACTURE: 'N° Facture',
    MONTANT_COMMANDE: 'Montant HT',
    MONTANT_FACTURÉ: 'Montant Facturé HT',
    RESTE_A_FACTURER: 'Reste à Facturer HT',
    TYPE_D_APPAREIL: "Type d'appareil",
    TAUX_DE_TVA: "Taux de TVA",
    COUT_HORAIRE_CONSULTANT: 'Coût horaire Consultant',
    COUT_JOURNALIER_CONSULTANT: 'Coût journalier Consultant',
    '% FACTURÉ': '% facturé',
    'Date': 'Date',
    'TYPE_OFFRE': 'Type de mission',
    REFERENCE_COMMANDE: 'N° Commande',
    SITE: 'Site',
    CLIENT: 'Client',
    NEW_DEVIS: 'Nouvelle offre'
}

export default locale;