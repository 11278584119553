import axios from 'axios';
import _ from '@lodash'
import * as Actions from 'app/store/actions';
import firebaseService from 'app/services/firebaseService';
export const GET_USER_DATA = '[CONTACTS APP] GET USER DATA';
export const GET_USER_FAVORITES = '[CONTACTS APP] GET USER FAVORITES';

export function getUserData() {



    return (dispatch, getState) => {
        //console.log(firebaseService.auth)
        firebaseService.firestore.collection('users').doc(firebaseService.auth.currentUser.uid).onSnapshot((r) => {
            var data = {}
            if (r.exists) {
                //console.log(r.data())
                data = r.data()
            } else {
                data = {}
            }
            //console.log(data)
          return  Promise.all([dispatch({
                type: GET_USER_DATA,
                payload: data,

          })]).then(r => {
              dispatch(Actions.getObjectifs({ id: 'all' }))
              dispatch(Actions.getAnalytics({ id: 'all' }))
            })

        })



    }

}
