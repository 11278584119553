import * as Actions from 'app/store/actions/custom';
import _ from '@lodash';

const initialState = {
    datas: {}, 

};


const requests = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_REQUESTS:
            {
                return {
                    ...state,
                  datas:action.payload

                };
            }
      

        default:
            {
                return state;
            }
    }
};

export default requests;
