import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";

import * as Actions from 'app/custom/contacts/store/actions';
import CreatableSelect from 'react-select/creatable';
import _ from '@lodash'
import { FuseUtils } from '@fuse';

type State = {
    options: [{ [string]: string }],
    value: string | void,
};

const createOption = (label: string) => ({
    label,
    value: FuseUtils.generateGUID(),
});

const defaultOptions = [

];
const customStyles = {
    menu: base => ({
        ...base,
        zIndex: '100 !important',

    })

}

const reactSelectTheme = error => theme => {


    const errorStyling = error
        ? {
            primary: 'red',
            neutral10: 'red',
            neutral30: 'red',
            neutral20: 'red',
            neutral60: 'red',
        }
        : {};

    return ({
        ...theme,
        menu: base => ({
            ...theme.base,
            zIndex: 100
        }),
        colors: {
            ...theme.colors,
            ...errorStyling,
        },
    });
};
const CreatableAdvanced = (props) => {

    const contacts = useSelector(({ custom }) => custom.contacts.contacts.clients);
    const [state, setState] = useState({
        value: null,
        options: [],
        loading: false
    });

    const dispatch = useDispatch();


    useEffect(() => {
        if (contacts)
            var company = contacts[props.company]
        setState({
            value: company ? { label: company.name + ' ' + company.entite, value: company.id } : null, options: _.map(contacts, c => {
                return { label: c.name + ' ' + c.entite, value: c.id }
            }), isLoading: false
        })
    }, [props.company, props.gestionnaire, contacts])
    const handleChange = (newValue: any, actionMeta: any) => {


        setState({ value: newValue, options: state.options, isLoading: state.isLoading });
        props.handleChange(newValue ? contacts[newValue.value] : null, props.gestionnaire ? 'gestionnaire' : props.id, props.parent)



    };
    const handleCreate = (inputValue: any) => {
        //////console.log(inputValue)
        setState({ isLoading: true });




        const { options } = state;

        if (inputValue.id) {


            var client = { value: inputValue.value, label: `${inputValue.name} ${inputValue.entite ? inputValue.entite : ''}` }

            setState({
                isLoading: false,
                options: [options ? { ...options } : {}, client],
                value: client,
            })
            props.handleChange(inputValue, props.gestionnaire ? 'gestionnaire' : props.id, props.parent)

        } else {
            var data = {
                name: inputValue.toUpperCase(),
                select: 'client',
                handleChange: handleCreate
            }
            dispatch(Actions.openNewClientDialog({ ...data }, 'client'))
        }

    };

    const { isLoading, options, value } = state;

    return (
        <div className='flex flex-col'>
            <span>{props.gestionnaire ? 'Gestionnaire' : props.label ? props.label : 'Entreprise'}</span>
            <CreatableSelect theme={props.id == 'gestionnaire' ? reactSelectTheme(false) : reactSelectTheme(!value || (value && !value.label))}
                className="mb-24 mr-12 min-w-320 "
                styles={customStyles}
                label={props.label ? props.label : 'Entreprise'}
                id="company"
                name="company"
                required
                variant={'outlined'}
                error={!value ? true : false}
                margin="dense"
                helperText=""
                fullWidth
                isClearable
                isCreatable
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={handleChange}
                onCreateOption={handleCreate}
                options={options}
                value={value}
                placeholder={'Sélectionner...'}
            /></div>
    );

}
CreatableAdvanced.propTypes = {
    label: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    company: PropTypes.string,
}


export default CreatableAdvanced