import { config } from './firebaseServiceConfig';
import firebase from 'firebase/app';
import * as Actions from 'app/store/actions';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/messaging'
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';
import _ from '@lodash'


class firebaseService {

    init(success) {
        if (Object.entries(config).length === 0 && config.constructor === Object) {
            if (process.env.NODE_ENV === 'development') {
                ////console.warn("Missing Firebase Configuration at src/app/services/firebaseService/firebaseServiceConfig.js");
            }
            success(false);
            return;
        }

        if (firebase.apps.length) {
            return;
        }
        firebase.initializeApp(config);
        //console.log(window)
        this.db = firebase.database();
    var NotificationIsSupported = !!((window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) || window.Notification /* W3C Specification */ || window.webkitNotifications /* old WebKit Browsers */ || navigator.mozNotification /* Firefox for Android and Firefox OS */)
      /*  if (NotificationIsSupported) {
            this.messaging = firebase.messaging();
            this.messaging.usePublicVapidKey("BLhhDJ4GZsoUsuhG5wAont-vuipSv78arJJ4v7YyATeFklRjmXmfRM7nvf5YRLgbnqdPXP9mnq2eRxgcQqN01Aw");
        } */

        this.database = firebase.database;
        this.analytics = firebase.analytics();
        this.firestore = firebase.firestore();
        this.firestore.settings({
            cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
           
        });

        this.firestore.enablePersistence({ synchronizeTabs: true }).catch(function (err) {
            ////console.log(err)
            if (err.code === 'failed-precondition') {

                // Multiple tabs open, persistence can only be enabled
                // in one tab at a a time.
                // ...
            } else if (err.code === 'unimplemented') {
                // The current browser does not support all of the
                // features required to enable persistence
                // ...
            }
        });
        this.storage = firebase.storage();
        this.auth = firebase.auth();
        success(true);
    }

    getUserData = (userId) => {
        if (!firebase.apps.length) {
            return;
        }
        return new Promise((resolve, reject) => {
            this.db.ref(`users/${userId}`)
                .once('value')
                .then((snapshot) => {
                    const user = snapshot.val();
                    resolve(user);
                });
        });
    };

    updateUserData = (user) => {
        if (!firebase.apps.length) {
            return;
        }
        return this.db.ref(`users/${user.uid}`)
            .set(user);
    };

    onAuthStateChanged = (callback) => {
        if (!this.auth) {
            return;
        }

        this.auth.onAuthStateChanged(callback);
    };

    signOut = () => {
        if (!this.auth) {
            return;
        }

        this.auth.signOut();
    }
}

const instance = new firebaseService();

export default instance;
