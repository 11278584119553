import React from 'react';
/*import ChatPanel from 'app/fuse-layouts/shared-components/chatPanel/ChatPanel';*/
import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';

function RightSideLayout1(props)
{
    return (
        <React.Fragment>

            

            <QuickPanel/>
        </React.Fragment>
    );
}

export default RightSideLayout1;
