import { useCallback, useState } from 'react';
import _ from '@lodash';

function useForm(initialState, onSubmit) {
    const [form, setForm] = useState(initialState);

    const handleChange = useCallback((event) => {
        event.persist();

        if (event.target.value)
            switch (event.target.name) {
                case 'name':
                    event.target.value = event.target.value.toUpperCase()
                    break;
                case 'city':
                    event.target.value = event.target.value.toUpperCase()
                    break;
                case 'country':
                    event.target.value = event.target.value.toUpperCase()
                    break;
                case 'email':
                    event.target.value = event.target.value.toLowerCase()
                    break;
                case 'lastName':
                    event.target.value = capital_letter(event.target.value)
                    break;
                case 'jobTitle':
                    event.target.value = capital_letter(event.target.value)
                    break;
                case 'address2':
                    //event.target.value = capital_letter(event.target.value)
                    break;
                case 'address':
                    // event.target.value = capital_letter(event.target.value)
                    break;
                case 'entite':
                    event.target.value = capital_letter(event.target.value)
                    break;
                case 'access.abbreviation_consultant':
                    event.target.value = event.target.value.toUpperCase()
                    break;

            }
       
        setForm(form => _.setIn({ ...form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value ? event.target.value:''));
    }, []);

    const resetForm = useCallback(() => {
        if (!_.isEqual(initialState, form)) {
            setForm(initialState);
        }
    }, [form, initialState]);

    const setInForm = useCallback((name, value) => {
        if (value)
            switch (name) {
                case 'name':
                    value = value.toUpperCase()
                    break;
                case 'city':
                    value = value.toUpperCase()
                    break;
                case 'country':
                    value = value.toUpperCase()
                    break;
                case 'email':
                    value = value.toLowerCase()
                    break;
                case 'lastName':
                    value = capital_letter(value)
                    break;
                case 'jobTitle':
                    value = capital_letter(value)
                    break;
                case 'address':
                    //value = capital_letter(value)
                    break;
                case 'address2':
                    //value = capital_letter(value)
                    break;
                case 'entite':
                    value = capital_letter(value)
                    break;
                case 'access.abbreviation_consultant':
                    value = value.toUpperCase()
                    break;

            }

        setForm(form => _.setIn(form, name, value));
    }, []);

    const handleSubmit = useCallback((event) => {
        if (event) {
            event.preventDefault();
        }
        if (onSubmit) {
            onSubmit();
        }
    }, [onSubmit]);

    return {
        form,
        handleChange,
        handleSubmit,
        resetForm,
        setForm,
        setInForm
    }
}
function capital_letter(str) {

    str = str.split(' ');

    for (var i = 0, x = str.length; i < x; i++) {
        if (str[i][0])
            str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }

    return str.join(' ')

}
export default useForm;
