var locale = {
    'LOGIN': 'Login to your account',
    'OR': 'ou',
    EMAIL: 'Email',
    PASSWORD: "Password",
    CONNECT: "Login",
    PASSWORD_LOST: "Password lost",
    RENEW_PASSWORD: "Renew my password",

}

export default locale