import axios from 'axios';
import { getUserData } from 'app/custom/contacts/store/actions/user.actions'
import * as Actions from 'app/store/actions';
import _ from '@lodash'
import moment from 'moment'
import { config, URL_API } from 'app/services/firebaseService/firebaseServiceConfig';
import firebaseService from 'app/services/firebaseService';
import { Dropbox } from 'dropbox';
import { FuseUtils } from '@fuse'
export const GET_SITES = '[SITES APP] GET SITES';
export const GET_SITES_DROPBOX = '[SITES APP] GET SITES DROPBOX';
export const SET_SEARCH_TEXT = '[SITES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_SITES = '[SITES APP] TOGGLE IN SELECTED SITES';
export const SELECT_ALL_SITES = '[SITES APP] SELECT ALL SITES';
export const DESELECT_ALL_SITES = '[SITES APP] DESELECT ALL SITES';
export const OPEN_NEW_SITE_DIALOG = '[SITES APP] OPEN NEW SITE DIALOG';
export const CLOSE_NEW_SITE_DIALOG = '[SITES APP] CLOSE NEW SITE DIALOG';
export const OPEN_EDIT_SITE_DIALOG = '[SITES APP] OPEN EDIT SITE DIALOG';

export const CLOSE_EDIT_SITE_DIALOG = '[SITES APP] CLOSE EDIT SITE DIALOG';
export const ADD_SITE = '[SITES APP] ADD SITE';
export const UPDATE_SITE = '[SITES APP] UPDATE SITE';
export const MOVE_SITE = '[SITES APP] MOVE SITE';
export const REMOVE_SITE = '[SITES APP] REMOVE SITE';
export const REMOVE_SITES = '[SITES APP] REMOVE SITES';
export const TOGGLE_STARRED_SITE = '[SITES APP] TOGGLE STARRED SITE';
export const TOGGLE_STARRED_SITES = '[SITES APP] TOGGLE STARRED SITES';
export const SET_SITES_STARRED = '[SITES APP] SET SITES STARRED ';



/* console.log(process.env.REACT_APP_MAP_KEY)
const dbx = new Dropbox({
    accessToken: '67pUT9Ek_XAAAAAAAAAAbjle4Hofd2UFyAYRHxS84ubKDYrcDWlsM3LRMVMd8B6F',
    fetch
})

// http://dropbox.github.io/dropbox-sdk-js/Dropbox.html

dbx.filesListFolder({
    path: '/PROGICIEL_MOVVEO_APP/SITES'
}).then(res => {
    
    console.log(res)
   
}) */
export function getSitesDropbox(routeParams) {

    return async (dispatch, getState) => {
        
        var sites = {}
        var request = await axios({
            method: 'post',
            url: `${URL_API}/dropbox/list_folders`,


        }).then(async (response) => {
            console.log(response)
            
            _.map(response.data.body.entries, r => {
      
                if (r['.tag'] == 'folder')
                    sites[r.id] = r
                return
            })
           
            return Promise.all([dispatch({
                type: GET_SITES_DROPBOX,
                payload: sites,
                routeParams
            })]).then(r => {
                console.log('uploades sites dropbox')
                return sites
                //dispatch(Actions.getSites({ id: 'load' }))
                //return dispatch(Actions.getAnalytics({ id: 'all' }))
            }).catch(err => {
                console.log(err)

                return err
            })




        }).catch(err => {
            console.log(err)

            return err
        })

        console.log('request', request)
        return request
    }
}

export function getListFilesDropbox(routeParams) {

    return (dispatch, getState) => {
        var request = axios({
            method: 'post',
            url: `${URL_API}/dropbox/list_folders`,


        }).then(async (response) => {
            console.log(response)
            var sites = {}
            _.map(response.data.body.entries, r => {

                if (r['.tag'] == 'folder')
                    sites[r.id] = r
                return
            })

            return Promise.all([dispatch({
                type: GET_SITES_DROPBOX,
                payload: sites,
                routeParams
            })]).then(r => {
                console.log('uploades sites dropbox')

                //dispatch(Actions.getSites({ id: 'load' }))
                //return dispatch(Actions.getAnalytics({ id: 'all' }))
            }).catch(err => {
                console.log(err)

                return err
            })




        }).catch(err => {
            console.log(err)

            return err
        })
    }
}
export function getSites(routeParams) {

    return (dispatch, getState) => {
        if (routeParams && routeParams.id == 'load')
            dispatch(Actions.showMessage({ message: 'Chargement des sites...', variant: 'info' }))
  
        var dropboxSites = getState().custom.sites.sites.dropbox


        firebaseService.firestore.collection('sites').where('id', '>', '').onSnapshot((r) => {

         r.docChanges().forEach(change => {
                if (change.type === 'added') {
                    //console.log('New city: ', change.doc.data());
                }
                if (change.type === 'modified') {
                   // console.log('Modified city: ', change.doc.data());
                }
                if (change.type === 'removed') {
                    //console.log('Removed city: ', change.doc.data());
                }
            }); 
            const CLIENTS = getState().custom.contacts.contacts.clients
            const CONTACTS = getState().custom.contacts.contacts.entities
            console.log('contacts', CONTACTS)
            console.log('clienst', CLIENTS)
            const { navigation } = getState().fuse;
            var i = 0
            var sites = []
            if (r.size > 0) {

                r.forEach(doc => {
                    i++
                    var data = doc.data()
                    data.notInDropbox = false
                    data.nameDifferentInDropbox = false
                    data.DropBoxName = ''
                    //console.log(data.client, data.contact)
                    //data.name = data.dropbox_id.name.replace(/'/g, "\\'")
                    //console.log(data.name)
                    if (data.gestionnaire && CLIENTS[data.gestionnaire]) {
                        data.gestionnaire = { id: CLIENTS[data.gestionnaire].id, name: CLIENTS[data.gestionnaire].name, entite: CLIENTS[data.gestionnaire].entite }
                    } else { data.gestionnaire = {} }
                    if (CLIENTS[data.client]) {
                        data.client = { id: CLIENTS[data.client].id, name: CLIENTS[data.client].name, entite: CLIENTS[data.client].entite }
                    } else { data.client = {} }
                    if (CONTACTS[data.contact]) {
                        data.contact = {
                            id: CONTACTS[data.contact].id, company: CONTACTS[data.contact].company.name, entite: CONTACTS[data.contact].company.entite, email: CONTACTS[data.contact].email, name: CONTACTS[data.contact].name, lastName: CONTACTS[data.contact].lastName, mobile: CONTACTS[data.contact].mobile, phone: CONTACTS[data.contact].phone
                        }
                    } else {
                        data.contact = {}
                    }
                    if (data.createdBy && !CONTACTS[data.createdBy])
                        firebaseService.firestore.collection('users').doc(data.createdBy).get().then(r => {
                            if (r.exists)
                                data.updatedBy = { email: r.data().email, id: r.id, name: r.data().name, lastName: r.data().lastName, access: r.data().access }
                            else data.updatedBy = { email: '', id: '', name: '', lastName: '', access: { abbreviation_consultant: '', } }
                        })
                    else if (data.createdBy && CONTACTS[data.createdBy]) {
                        data.createdBy = { email: CONTACTS[data.createdBy].email, id: CONTACTS[data.createdBy].id, name: CONTACTS[data.createdBy].name, lastName: CONTACTS[data.createdBy].lastName, access: CONTACTS[data.createdBy].access }
                    }
                    if (data.updatedBy && !CONTACTS[data.updatedBy])
                        firebaseService.firestore.collection('users').doc(data.updatedBy).get().then(r => {
                            if (r.exists)
                                data.updatedBy = { email: r.data().email, id: r.id, name: r.data().name, lastName: r.data().lastName, access: r.data().access }
                            else data.updatedBy = { email: '', id: '', name: '', lastName: '', access: { abbreviation_consultant: '', } }
                        })
                    else if (data.updatedBy && CONTACTS[data.updatedBy]) {
                        data.updatedBy = { email: CONTACTS[data.updatedBy].email, id: CONTACTS[data.updatedBy].id, name: CONTACTS[data.updatedBy].name, lastName: CONTACTS[data.updatedBy].lastName, access: CONTACTS[data.updatedBy].access }
                    }
                    if (data.dropbox_id && !dropboxSites[data.dropbox_id.id] ){
                        data.notInDropbox = true
                    }
                    if (data.dropbox_id && dropboxSites[data.dropbox_id.id] && dropboxSites[data.dropbox_id.id].name != data.name ) {
                        data.nameDifferentInDropbox = true
                    }
                    if (data.dropbox_id && dropboxSites[data.dropbox_id.id] && dropboxSites[data.dropbox_id.id].name != data.name) {
                        data.DropBoxName = dropboxSites[data.dropbox_id.id].name

                    }
                    sites.push(data)

                    if (i == r.size) {
                        var sitesItem = _.find(navigation, { id: 'sites' })
                        sitesItem.badge.title = sites.length
                        dispatch(Actions.updateNavigationItem('sites', sitesItem));
                        ////////////console.log(sites)
                        dispatch(Actions.hideMessage())

                  console.log(sites)
                        console.log(dropboxSites)
                        dispatch({
                            type: GET_SITES,
                            payload: _.orderBy(sites, 'createdTime', 'desc'),
                            routeParams
                        })
                    }
                })
            }

        })
    }

}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedSites(siteId) {
    return {
        type: TOGGLE_IN_SELECTED_SITES,
        siteId
    }
}

export function selectAllSites() {
    return {
        type: SELECT_ALL_SITES
    }
}

export function deSelectAllSites() {
    return {
        type: DESELECT_ALL_SITES
    }
}

export function openNewSiteDialog(data, db) {
    return {
        type: OPEN_NEW_SITE_DIALOG,
        data: data, db: db
    }
}

export function closeNewSiteDialog() {
    return {
        type: CLOSE_NEW_SITE_DIALOG
    }
}

export function openEditSiteDialog(data, db) {
    ////////////console.log(data)
    return {
        type: OPEN_EDIT_SITE_DIALOG,
        data: data, db: db
    }
}



export function closeEditSiteDialog() {
    return {
        type: CLOSE_EDIT_SITE_DIALOG
    }
}

export function addSite(newSite, db) {
    newSite = _.omit(newSite, 'select')
    return async (dispatch, getState) => {
        ///*  const s = _.cloneDeep(newSite)
        /*  newSite.name = newSite.name.replace(/  +/g, ' ')
         newSite.name = newSite.name.replace(/\\/g, ' ')
         newSite.name = newSite.name.replace(/\/+/g, ' ')
         newSite.name =ltrim(rtrim(_.replace(newSite.name, new RegExp("/", "g"), "-")))   */



        const { routeParams } = getState().sitesApp.sites;
        const { user } = getState().auth;

        var table = null
        switch (db) {

            default:
                table = 'sites'
                break;

        }

        dispatch(Actions.showMessage({ message: "Enregistrement du site dans la base de données en cours...", variant: 'info' }))
        // newSite.dropbox_id = response.data.body.entries[0].metadata
        console.log(newSite)

        const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)
        dispatch(Actions.showMessage({ message: "Création de l'arborescence DROPBOX en cours...", variant: 'info' }))
        const req = axios({
            method: 'post',
            url: `${URL_API}/createSite`,
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Encoding': 'UTF-8',

            },
            data: {
                name: newSite.name
            }
        });
        req.then((response) => {
            console.log(response)
            if (response.data && response.data.body && response.data.body.async_job_id) {
                const async_job_id = response.data.body.async_job_id
                CheckStatus(async_job_id)
                function CheckStatus(async_job_id) {
                    const reqCheck = axios({
                        method: 'post',
                        url: `${URL_API}/getAsyncStatus`,
                        headers: {
                            'Accept-Encoding': 'UTF-8',
                            Authorization: `Bearer ${token}`

                        },
                        data: {
                            async_job_id: response.data.body.async_job_id
                        }
                    });
                    reqCheck.then((response) => {
                        //////////console.log(response)
                        if (response.data && response.data.body && response.data.body['.tag'] === 'in_progress') {
                            dispatch(Actions.showMessage({ message: "Création de l'arborescence DROPBOX en cours...", variant: 'info' }))
                            CheckStatus(async_job_id)
                        } else if (response.data && response.data.body && response.data.body.entries) {
                            dispatch(Actions.showMessage({ message: "Enrgistrement du site dans la base de données en cours...", variant: 'info' }))
                            newSite.dropbox_id = response.data.body.entries[0].metadata
                            newSite.createdBy = firebaseService.auth.currentUser.uid
                            newSite.createdTime = moment().format('YYYY-MM-DD HH:mm:ss')
                            newSite.client = newSite.client.id ? newSite.client.id : newSite.client ? newSite.client : ''
                            newSite.gestionnaire = newSite.gestionnaire.id ? newSite.gestionnaire.id : newSite.gestionnaire ? newSite.gestionnaire : ''
                            newSite.contact = newSite.contact.id ? newSite.contact.id : newSite.contact ? newSite.contact : ''
                            firebaseService.firestore.collection(table).doc(newSite.id).set(newSite).then(() => {
                                dispatch({
                                    type: ADD_SITE
                                })
                            }).catch(err => {
                                return dispatch(Actions.showMessage({ message: "Echec de l'enregistrement du site dans la base de données en cours...", variant: 'error' }))

                            })
                        }
                    })
                }

            }
        })

    };
}

export function updateSite(site, db) {
    site = _.omit(site, 'select')
    //console.log(site)
    return (dispatch, getState) => {

        const { routeParams } = getState().sitesApp.sites;
        const CLIENTS = getState().custom.contacts.contacts.clients
        const CONTACTS = getState().custom.contacts.contacts.entities
        var table = null
        switch (db) {

            default:
                table = 'sites'
                break;

        }
        site.client = site.client.id ? site.client.id : site.client ? site.client : ''
        site.gestionnaire = site.gestionnaire.id ? site.gestionnaire.id : site.gestionnaire ? site.gestionnaire : ''
        site.contact = site.contact.id ? site.contact.id : site.contact ? site.contact : ''
        site.createdBy = site.createdBy.id ? site.createdBy.id : site.createdBy ? site.createdBy : ''

        site.updatedBy = firebaseService.auth.currentUser.uid
        site.updatedTime = moment().format('YYYY-MM-DD HH:mm:ss')
        // SITE NAME
        /*  site.name = site.name.replace(/  +/g, ' ')
         site.name = site.name.replace(/\\/g, ' ')
         site.name = site.name.replace(/\/+/g, ' ')
         site.name = ltrim(rtrim(_.replace(site.name, new RegExp("/", "g"), "-"))) */
        firebaseService.firestore.collection(table).doc(site.id).update(site).then(() => {


            dispatch({
                type: UPDATE_SITE
            })

        }).catch(err => {



        })

    };
}


export function renameSite(newSiteName, db, oldSiteName) {


    //newSite = _.omit(newSite, 'select')
    return async (dispatch, getState) => {
        ///*  const s = _.cloneDeep(newSite)
        /*  newSite.name = newSite.name.replace(/  +/g, ' ')
         newSite.name = newSite.name.replace(/\\/g, ' ')
         newSite.name = newSite.name.replace(/\/+/g, ' ')
         newSite.name =ltrim(rtrim(_.replace(newSite.name, new RegExp("/", "g"), "-")))   */



        const { routeParams } = getState().sitesApp.sites;
        const { user } = getState().auth;

        var table = null
        switch (db) {

            default:
                table = 'sites'
                break;

        }

        dispatch(Actions.showMessage({ message: "Mise à jour du nom du site dans la base de données en cours...", variant: 'info' }))
        // newSite.dropbox_id = response.data.body.entries[0].metadata
        //console.log(newSite)

        const token = await firebaseService.auth.currentUser.getIdToken(/* forceRefresh */ true)
       // dispatch(Actions.showMessage({ message: "Création de l'arborescence DROPBOX en cours...", variant: 'info' }))
        const req = axios({
            method: 'post',
            url: `${URL_API}/renameSite`,
            headers: {
                Authorization: `Bearer ${token}`,
                ContentType: 'application/json'

            },
            data: {
                newPath: newSiteName,
                oldPath: oldSiteName
            }
        });
        req.then((response) => {
            console.log(response)
            if (response.data && response.data.body && response.data.body.async_job_id) {



                console.log(response.data.body)
                return response.data.body
                /* const async_job_id = response.data.body.async_job_id
                CheckStatus(async_job_id)
                function CheckStatus(async_job_id) {
                    const reqCheck = axios({
                        method: 'post',
                        url: `${URL_API}/getAsyncStatus`,
                        headers: {
                            'Accept-Encoding': 'UTF-8',
                            Authorization: `Bearer ${token}`

                        },
                        data: {
                            async_job_id: response.data.body.async_job_id
                        }
                    });
                    reqCheck.then((response) => {
                        //////////console.log(response)
                        if (response.data && response.data.body && response.data.body['.tag'] === 'in_progress') {
                            dispatch(Actions.showMessage({ message: "Création de l'arborescence DROPBOX en cours...", variant: 'info' }))
                            CheckStatus(async_job_id)
                        } else if (response.data && response.data.body && response.data.body.entries) {
                            dispatch(Actions.showMessage({ message: "Enrgistrement du site dans la base de données en cours...", variant: 'info' }))
                            newSite.dropbox_id = response.data.body.entries[0].metadata
                            newSite.createdBy = firebaseService.auth.currentUser.uid
                            newSite.createdTime = moment().format('YYYY-MM-DD HH:mm:ss')
                            newSite.client = newSite.client.id ? newSite.client.id : newSite.client ? newSite.client : ''
                            newSite.gestionnaire = newSite.gestionnaire.id ? newSite.gestionnaire.id : newSite.gestionnaire ? newSite.gestionnaire : ''
                            newSite.contact = newSite.contact.id ? newSite.contact.id : newSite.contact ? newSite.contact : ''
                            firebaseService.firestore.collection(table).doc(newSite.id).set(newSite).then(() => {
                                dispatch({
                                    type: ADD_SITE
                                })
                            }).catch(err => {
                                return dispatch(Actions.showMessage({ message: "Echec de l'enregistrement du site dans la base de données en cours...", variant: 'error' }))

                            })
                        }
                    })
                } */

            }
        }).catch(err => {
            console.log(err)
        })

    };
}

export function removeSite(siteId, db) {

    return (dispatch, getState) => {

        const { routeParams } = getState().sitesApp.sites;
        var table = null
        switch (db) {

            default:
                table = 'sites'
                break;

        }

        firebaseService.firestore.collection(table).doc(siteId).delete().then(() => {


            dispatch({
                type: REMOVE_SITE
            })

        }).catch(err => {
            ////////////console.log(err)


        })

    };
}


export function removeSites(siteIds, db) {
    ////////////console.log(siteIds, db)
    return async (dispatch, getState) => {

        const { routeParams } = getState().sitesApp.sites;
        var table = 'sites'

        var promises = []
        _.map(siteIds, siteId => {
            ////////////console.log(siteId, table)
            promises.push(new Promise(async function (resolve, reject) {

                firebaseService.firestore.collection(table).doc(siteId).delete().then((err) => {
                    ////////////console.log(err)
                    resolve()
                }).catch(err => {
                    ////////////console.log(err)
                    reject()
                })
            }))
        })



        Promise.all(promises.concat([
            dispatch({
                type: REMOVE_SITES
            }),
            dispatch({
                type: DESELECT_ALL_SITES
            })
        ])).then(() => dispatch(getSites(routeParams)))

    };
}

export function toggleStarredSite(siteId, db) {
    ////////////console.log(siteId, db)



    return (dispatch, getState) => {
        const { user } = getState().auth


        var table = null
        switch (db) {

            default:
                table = 'sites'
                break;

        }
        const { routeParams } = getState().sitesApp.sites;


    };
}

export function toggleStarredSites(siteIds, db) {

    ////////////console.log(siteIds, db)
    return (dispatch, getState) => {
        var table = null
        switch (db) {

            default:
                table = 'sites'
                break;

        }
        const { routeParams } = getState().sitesApp.sites;

        const request = axios.post('/api/sites-app/toggle-starred-sites', {
            siteIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SITES
                }),
                dispatch({
                    type: DESELECT_ALL_SITES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getSites(routeParams)))
        );
    };
}

export function setSitesStarred(siteIds, db) {
    ////////////console.log(siteIds, db)
    return (dispatch, getState) => {

        const { routeParams } = getState().sitesApp.sites;

        const request = axios.post('/api/sites-app/set-sites-starred', {
            siteIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SITES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SITES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getSites(routeParams)))
        );
    };
}

export function setSitesUnstarred(siteIds, db) {
    ////////////console.log(siteIds, db)
    return (dispatch, getState) => {

        const { routeParams } = getState().sitesApp.sites;


        const request = axios.post('/api/sites-app/set-sites-unstarred', {
            siteIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SITES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SITES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getSites(routeParams)))
        );
    };
}


function ltrim(str) {
    if (!str) return str;
    return str.replace(/^\s+/g, '');
}





function rtrim(str) {
    if (!str) return str;
    return str.replace(/\s+$/g, '');
}
