import React, { useEffect, useCallback, useState } from 'react';
import { Dialog, } from '@material-ui/core';
import { useForm } from '@fuse/hooks';
import FuseUtils from '@fuse/FuseUtils';
import * as Actions from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LeadDialogContent from './leadsDialogContent'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import _ from '@lodash'
const defaultFormState = {
    lead: {
        appareils: [{ qte: 1, type: '' }],
        type_batiment: "",
        id: '',
        name: '',
        entite: '',
        gestionnaire: '',
        client: '',
        contact: '',
        libelle: '',
        montant: '',
        note: '',
        date_relance: '',
        a_relancer_le: '',
        chance_transformation: ''



    }
};

function LeadDialog(props) {
  
    const dispatch = useDispatch();
    const leadDialog = useSelector(({ custom }) => custom.leads.leadDialog);
    const [country, setCountry] = useState([])
    const { form, handleChange, setForm } = useForm(defaultFormState.lead);

    ////////console.log(leadDialog)
    const initDialog = useCallback(
        () => {
            var country = ''

            if (leadDialog.type === 'edit' && leadDialog.data) {
                setForm({
                    ...defaultFormState.lead,
                    ..._.omit(leadDialog.data, 'handleChange')
                });
            }

            /**
             * Dialog type: 'new'
             */
            if (leadDialog.type === 'new') {
                setForm({
                    ...defaultFormState.lead,
                    ..._.omit(leadDialog.data, 'handleChange'),
                    id: FuseUtils.generateGUID()
                });
            }
        },
        [leadDialog.data, leadDialog.type, setForm],
    );
    useEffect(() => {
        /**
         * After Dialog Open
         */
        if (leadDialog.props.open) {
            initDialog();
        }

    }, [leadDialog.props.open, initDialog]);

    function closeComposeDialog() {
        leadDialog.type === 'edit' ? dispatch(Actions.closeEditLeadDialog()) : dispatch(Actions.closeNewLeadDialog());
    }

    //console.log(leadDialog)

    return (
        <Dialog
            classes={{
                paper: "m-24"
            }}
            {...leadDialog.props}

            onClose={closeComposeDialog}
            fullWidth
            maxWidth="lg"
        >
            <LeadDialogContent closeComposeDialog={closeComposeDialog} form={form} leadDialog={leadDialog} />
        </Dialog>
    );
}

export default LeadDialog;