import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import _ from '@lodash'
const App = (props) => {
    console.log(props)

    const [state, setState] = useState('')
  const ref = useRef(props.data.key)
    useEffect(() => {
        if(!_.isEqual(props.values,state))
     setState(props.values)
    }, [props.values])
    useEffect(() => {
        return () => {
            console.log('******************* UNMOUNTED');
        };
    }, []);
   const handleEditorChange = (content, editor) => {
       console.log('Content was updated:', content);
       props.data.setValue(content)
    }

    console.log(state, props.data.key, ref)
        return (
            <Editor
               id={props.data.key}
               ref={ref}
        
                apiKey="jxcg875jg1xta5swb0ov8kotbdloxja3zucni4zixf0xricg"
                initialValue={state}
                init={{
                    entity_encoding: "raw",
                    encoding: "UTF-8",
                    //theme: "modern",
                    //selector:  'textarea',
                    //height: 500,
                   //inline: true,
                    language: 'fr_FR',
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link  charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help'
                }}
                onEditorChange={handleEditorChange}
            />
        );
    
}

export default App;